

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as Optional from "../common/Optional.mjs";
import * as FormInput from "../inputs/FormInput.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Settings_Types from "./Settings_Types.mjs";
import * as Settings_BusinessHours from "./Settings_BusinessHours.mjs";

var subdomain$p = Utils.Translations.t("js.settings.labels.subdomain");

var companyName$p = Utils.Translations.t("js.settings.labels.company_name");

var phone$p = Utils.Translations.t("js.settings.labels.office_phone");

function Settings_General(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return React.createElement(UI_Form.Section.make, {
              children: React.createElement(UI_Form.Container.make, {
                    children: null
                  }, React.createElement(FormInput.make, {
                        _type: "text",
                        value: state.subdomain,
                        onValueChange: (function (param) {
                            
                          }),
                        disabled: true,
                        labelText: subdomain$p(ctx)
                      }), React.createElement(FormInput.make, {
                        _type: "text",
                        value: RCore.$$Option.getOr(state.companyName, ""),
                        onValueChange: (function (v) {
                            setState(function (current) {
                                  return {
                                          loaded: current.loaded,
                                          subdomain: current.subdomain,
                                          companyName: v,
                                          officePhone: current.officePhone,
                                          dadataToken: current.dadataToken,
                                          mapType: current.mapType,
                                          international: current.international,
                                          region: current.region,
                                          showPreview: current.showPreview,
                                          hasSms: current.hasSms,
                                          hasSmsProvider: current.hasSmsProvider,
                                          userUuid: current.userUuid,
                                          owner: current.owner,
                                          users: current.users,
                                          trackingForms: current.trackingForms,
                                          businessHours: current.businessHours,
                                          smsSettings: current.smsSettings,
                                          locale: current.locale,
                                          lookupRestrictions: current.lookupRestrictions
                                        };
                                });
                          }),
                        disabled: ctx.disposable,
                        labelText: companyName$p(ctx),
                        maxLength: Settings_Types.Limits.limits.companyName
                      }), React.createElement(FormInput.make, {
                        _type: "text",
                        value: RCore.$$Option.getOr(state.officePhone, ""),
                        onValueChange: (function (v) {
                            setState(function (current) {
                                  return {
                                          loaded: current.loaded,
                                          subdomain: current.subdomain,
                                          companyName: current.companyName,
                                          officePhone: v,
                                          dadataToken: current.dadataToken,
                                          mapType: current.mapType,
                                          international: current.international,
                                          region: current.region,
                                          showPreview: current.showPreview,
                                          hasSms: current.hasSms,
                                          hasSmsProvider: current.hasSmsProvider,
                                          userUuid: current.userUuid,
                                          owner: current.owner,
                                          users: current.users,
                                          trackingForms: current.trackingForms,
                                          businessHours: current.businessHours,
                                          smsSettings: current.smsSettings,
                                          locale: current.locale,
                                          lookupRestrictions: current.lookupRestrictions
                                        };
                                });
                          }),
                        disabled: ctx.disposable,
                        labelText: phone$p(ctx),
                        maxLength: Settings_Types.Limits.limits.officePhone
                      }), React.createElement(Optional.make, {
                        show: !ctx.disposable,
                        children: React.createElement(Settings_BusinessHours.make, {
                              businessHours: state.businessHours,
                              setBusinessHours: (function (businessHours) {
                                  setState(function (current) {
                                        return {
                                                loaded: current.loaded,
                                                subdomain: current.subdomain,
                                                companyName: current.companyName,
                                                officePhone: current.officePhone,
                                                dadataToken: current.dadataToken,
                                                mapType: current.mapType,
                                                international: current.international,
                                                region: current.region,
                                                showPreview: current.showPreview,
                                                hasSms: current.hasSms,
                                                hasSmsProvider: current.hasSmsProvider,
                                                userUuid: current.userUuid,
                                                owner: current.owner,
                                                users: current.users,
                                                trackingForms: current.trackingForms,
                                                businessHours: businessHours,
                                                smsSettings: current.smsSettings,
                                                locale: current.locale,
                                                lookupRestrictions: current.lookupRestrictions
                                              };
                                      });
                                }),
                              errors: errors,
                              setErrors: setErrors
                            })
                      }))
            });
}

var make = Settings_General;

export {
  make ,
}
/* subdomain' Not a pure module */
