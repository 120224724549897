

import * as Fun from "../../../libraries/Fun.mjs";
import * as Hint from "../../common/Hint/Hint.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../inputs/Select.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as UserTypes from "./UserTypes.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SkillsSelect from "../../inputs/SkillsSelect.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as UserFormInput from "./show/UserFormInput.mjs";
import * as UserShowState from "./UserShowState.mjs";
import * as UserCustomFields from "./UserCustomFields.mjs";
import * as UserTeamsWarning from "./show/UserTeamsWarning.mjs";
import * as TerritoriesSelect from "../../inputs/TerritoriesSelect.mjs";
import * as UserDeletePopover from "./show/UserDeletePopover.mjs";
import * as UserPermissionInput from "./show/UserPermissionInput.mjs";
import * as UserPermissionCheckbox from "./show/UserPermissionCheckbox.mjs";
import * as UserShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/UserShow.module.css";

var headerNew$p = Utils.Translations.tr("js.admin.users.form.headings.new");

var headerName$p = Utils.Translations.txr("js.admin.users.form.headings.name");

var headerEdit$p = Utils.Translations.tr("js.admin.users.form.headings.edit");

var ownerBadge$p = Utils.Translations.tr("js.admin.users.form.owner_badge");

var firstName$p = Utils.Translations.tr("js.admin.users.form.first_name");

var lastName$p = Utils.Translations.tr("js.admin.users.form.last_name");

var mobilePhone$p = Utils.Translations.tr("js.admin.users.form.mobile_phone");

var timezone$p = Utils.Translations.tr("js.admin.users.form.timezone");

var defaultTimezone$p = Utils.Translations.tx("js.admin.users.form.default_timezone");

var email$p = Utils.Translations.tr("js.admin.users.form.email");

var password$p = Utils.Translations.tr("js.admin.users.form.password");

var useSeat$p = Utils.Translations.tr("js.admin.users.form.use_seat");

var useSeatHint$p = Utils.Translations.t("js.admin.users.form.popovers.use_seat");

var adminHint$p = Utils.Translations.t("js.admin.users.form.popovers.admin");

var ownerSeat$p = Utils.Translations.tr("js.admin.users.form.help.owner_seat");

var licenseDisabled$p = Utils.Translations.tr("js.admin.users.form.help.license_disabled");

var accountGroup$p = Utils.Translations.tr("js.admin.users.form.groups.account");

var accessGroup$p = Utils.Translations.tr("js.admin.users.form.groups.access");

var skillsGroup$p = Utils.Translations.tr("js.admin.users.form.groups.skills");

var territoryGroup$p = Utils.Translations.tr("js.admin.users.form.groups.territory");

var webGroup$p = Utils.Translations.tr("js.admin.users.form.groups.web");

var mobileGroup$p = Utils.Translations.tr("js.admin.users.form.groups.mobile");

var writePermissions$p = Utils.Translations.t("js.admin.users.form.permissions.have_write_permissions");

var webPermission$p = Utils.Translations.t("js.admin.users.form.permissions.login_web");

var jobPermission$p = Utils.Translations.t("js.admin.users.form.permissions.jobs");

var schedulePermission$p = Utils.Translations.t("js.admin.users.form.permissions.schedule");

var clientsPermission$p = Utils.Translations.t("js.admin.users.form.permissions.clients");

var teamsPermission$p = Utils.Translations.t("js.admin.users.form.permissions.teams");

var mapPermission$p = Utils.Translations.t("js.admin.users.form.permissions.map");

var shiftsPermission$p = Utils.Translations.t("js.admin.users.form.permissions.shifts");

var jobsExportPermission$p = Utils.Translations.t("js.admin.users.form.permissions.jobs_export");

var adminPermission$p = Utils.Translations.t("js.admin.users.form.permissions.admin");

var mobilePermission$p = Utils.Translations.t("js.admin.users.form.permissions.login_mobile");

var createJobMobilePermission$p = Utils.Translations.t("js.admin.users.form.permissions.create_job_mobile");

var completeJobsPermission$p = Utils.Translations.t("js.admin.users.form.permissions.complete_jobs");

var skillsSelectPlaceholder$p = Utils.Translations.t("js.components.skills_select.placeholder");

var territoriesSelectPlaceholder$p = Utils.Translations.t("js.components.territories_select.placeholder");

var submitNew$p = Utils.Translations.tr("js.admin.users.form.buttons.submit_new");

var submitEdit$p = Utils.Translations.tr("js.admin.users.form.buttons.submit_edit");

var cancel$p = Utils.Translations.tr("js.admin.users.form.buttons.cancel");

var delete$p = Utils.Translations.tr("js.admin.users.form.buttons.delete");

var styles = UserShowModuleCss;

function UserShow$Header(Props) {
  var wire = Props.wire;
  var owner = Props.owner;
  var isNew = Props.isNew;
  var firstName = Props.firstName;
  var lastName = Props.lastName;
  var ctx = wire.ctx;
  var renderOwnerBadge = owner ? React.createElement("span", {
          className: "badge badge-info owner-badge"
        }, ownerBadge$p(ctx)) : null;
  var renderHeaderText = isNew ? React.createElement("strong", undefined, headerNew$p(ctx)) : React.createElement("span", undefined, React.createElement("strong", undefined, headerName$p({
                  firstName: firstName,
                  lastName: lastName
                }, ctx)), React.createElement("span", {
              className: "text-gray"
            }, headerEdit$p(ctx)));
  return React.createElement("div", {
              className: "panel-heading"
            }, React.createElement("span", {
                  className: "panel-title"
                }, renderHeaderText), React.createElement("div", {
                  className: "float-right"
                }, renderOwnerBadge, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "User",
                        VAL: "Index"
                      },
                      className: "close",
                      children: React.createElement("span", {
                            className: Icon.style(undefined, undefined, undefined, undefined, "times")
                          })
                    })));
}

function UserShow$SubmitButton(Props) {
  var isNew = Props.isNew;
  var ctx = Props.ctx;
  return React.createElement("button", {
              className: "btn btn-primary float-right btn-footer",
              type: "submit"
            }, isNew ? submitNew$p(ctx) : submitEdit$p(ctx));
}

function UserShow$DeleteButton(Props) {
  var onDelete = Props.onDelete;
  var isNew = Props.isNew;
  var owner = Props.owner;
  var match = AppContext.useWire();
  var buttonId = Hooks.useId(undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  if (isNew || owner) {
    return null;
  }
  var setShowConfirmation = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                  show: match$1[0],
                  children: React.createElement(UserDeletePopover.make, {
                        buttonId: buttonId,
                        onClose: (function (param) {
                            setShowConfirmation(function (param) {
                                  return false;
                                });
                          }),
                        onDelete: onDelete
                      })
                }), React.createElement("div", {
                  className: "footer-inline",
                  id: buttonId
                }, React.createElement("button", {
                      className: "btn btn-danger",
                      type: "button",
                      onClick: (function (param) {
                          setShowConfirmation(function (prev) {
                                return !prev;
                              });
                        })
                    }, delete$p(match.ctx))));
}

function UserShow(Props) {
  var isNew = Props.isNew;
  var owner = Props.owner;
  var tenantHasSeats = Props.tenantHasSeats;
  var availableTerritories = Props.availableTerritories;
  var timezoneVariants = Props.timezoneVariants;
  var teams = Props.teams;
  var userData = Props.userData;
  var onDelete = Props.onDelete;
  var onSubmit = Props.onSubmit;
  var errors = Props.errors;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = Fun.both(userData.uuid, ctx.user);
  var editingThemselves = match !== undefined && Caml_obj.equal(match[1].uuid, match[0]) ? true : false;
  var match$1 = React.useReducer(UserShowState.reducer, userData);
  var send = match$1[1];
  var state = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowTeamWarning = match$2[1];
  var renderLicenseBlock = owner ? React.createElement("p", {
          className: "license-help-block"
        }, ownerSeat$p(ctx)) : (
      tenantHasSeats ? null : React.createElement("p", {
              className: "license-help-block"
            }, licenseDisabled$p(ctx))
    );
  var renderLicenseHint = React.createElement("span", {
        className: styles.licenseHint
      }, React.createElement(Hint.make, {
            content: useSeatHint$p(ctx)
          }), React.createElement("span", undefined, useSeat$p(ctx)));
  var adminHint = React.createElement(Hint.make, {
        content: adminHint$p(ctx),
        classes: styles.adminHint
      });
  var onWarningClose = function () {
    setShowTeamWarning(function (param) {
          return false;
        });
    send("CloseWarningWindow");
  };
  var onFormSubmit = function (evt) {
    evt.preventDefault();
    onSubmit(state);
  };
  var onTimezoneChange = function (tz) {
    send({
          TAG: "SetTimezone",
          _0: Context_Types.TimeZone.codeFromVariantsById(tz === null ? undefined : Caml_option.some(tz), timezoneVariants)
        });
  };
  var firstNameId = Hooks.useId(undefined);
  var lastNameId = Hooks.useId(undefined);
  var mobilePhoneId = Hooks.useId(undefined);
  var emailId = Hooks.useId(undefined);
  var passwordId = Hooks.useId(undefined);
  var stripMailTo = function (str) {
    var regex = /^\s*mailto:/g;
    return RCore.$$Option.map(str, (function (s) {
                  return Js_string.replaceByRe(regex, "", s);
                }));
  };
  var id = RCore.$$Option.getExn(Context_Types.TimeZone.idFromVariantsByCode(ctx.timezone, timezoneVariants), undefined);
  var defaultTimezone = RCore.$$Option.getExn(RCore.$$Array.getBy(timezoneVariants, (function (t) {
              return Caml_obj.equal(t.id, id);
            })), undefined).name;
  var hasAllPermissions = owner || state.permissions.admin;
  return React.createElement("form", {
              className: "form-horizontal panel form-user",
              onSubmit: onFormSubmit
            }, React.createElement(UserShow$Header, {
                  wire: wire,
                  owner: owner,
                  isNew: isNew,
                  firstName: userData.firstName,
                  lastName: userData.lastName
                }), React.createElement("div", {
                  className: "panel-body"
                }, React.createElement(UserFormInput.make, {
                      id: firstNameId,
                      value: RCore.$$Option.getOr(state.firstName, ""),
                      onChange: (function (firstName) {
                          send({
                                TAG: "SetFirstName",
                                _0: firstName
                              });
                        }),
                      type_: "text",
                      error: UserTypes.$$Error.firstName(errors),
                      label: firstName$p(ctx),
                      maxLength: 50,
                      limited: true
                    }), React.createElement(UserFormInput.make, {
                      id: lastNameId,
                      value: RCore.$$Option.getOr(state.lastName, ""),
                      onChange: (function (lastName) {
                          send({
                                TAG: "SetLastName",
                                _0: lastName
                              });
                        }),
                      type_: "text",
                      error: UserTypes.$$Error.lastName(errors),
                      label: lastName$p(ctx),
                      maxLength: 50,
                      limited: true
                    }), React.createElement(UserFormInput.make, {
                      id: mobilePhoneId,
                      value: RCore.$$Option.getOr(state.mobilePhone, ""),
                      onChange: (function (mobilePhone) {
                          send({
                                TAG: "SetMobilePhone",
                                _0: mobilePhone
                              });
                        }),
                      type_: "text",
                      error: undefined,
                      label: mobilePhone$p(ctx),
                      maxLength: 20,
                      containerClass: "mobile_phone"
                    }), React.createElement("div", {
                      className: styles.timezone
                    }, React.createElement("label", {
                          className: styles.smallLabel
                        }, timezone$p(ctx)), React.createElement("div", {
                          className: styles.input
                        }, React.createElement(Select.make, {
                              placeholder: defaultTimezone$p({
                                    timezone: defaultTimezone
                                  }, ctx),
                              onChange: onTimezoneChange,
                              value: Js_null.fromOption(Context_Types.TimeZone.idFromVariantsByCode(state.timezone, timezoneVariants)),
                              options: timezoneVariants.map(function (t) {
                                    return {
                                            value: t.id,
                                            label: t.name
                                          };
                                  })
                            }))), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("customFields", ctx.features) && Caml_obj.notequal(state.customFields, []),
                      children: React.createElement("div", {
                            className: styles.customFieldsWrapper
                          }, React.createElement(UserCustomFields.make, {
                                dictionaries: state.dictionaries,
                                fieldTypes: state.customFields,
                                fieldValues: state.customFieldValues,
                                onChange: (function (fields) {
                                    send({
                                          TAG: "SetCustomFieldValues",
                                          _0: fields
                                        });
                                  })
                              }))
                    }), React.createElement("div", {
                      className: styles.groupLabel
                    }, React.createElement("strong", undefined, accountGroup$p(ctx))), React.createElement(UserFormInput.make, {
                      id: emailId,
                      value: RCore.$$Option.getOr(state.email, ""),
                      onChange: (function (email) {
                          send({
                                TAG: "SetEmail",
                                _0: stripMailTo(email)
                              });
                        }),
                      type_: "email",
                      error: UserTypes.$$Error.email(errors),
                      label: email$p(ctx),
                      disabled: ctx.disposable && owner
                    }), React.createElement(UserFormInput.make, {
                      id: passwordId,
                      value: RCore.$$Option.getOr(state.password, ""),
                      onChange: (function (password) {
                          send({
                                TAG: "SetPassword",
                                _0: password
                              });
                        }),
                      type_: "password",
                      error: UserTypes.$$Error.password(errors),
                      label: password$p(ctx),
                      disabled: ctx.disposable && owner
                    }), React.createElement("div", {
                      className: styles.groupLabel
                    }, React.createElement("strong", undefined, accessGroup$p(ctx))), React.createElement("div", {
                      className: "form-group form-input"
                    }, React.createElement(UserPermissionCheckbox.make, {
                          value: hasAllPermissions || state.paid,
                          onChange: (function (paid) {
                              send({
                                    TAG: "SetPaid",
                                    _0: owner,
                                    _1: paid
                                  });
                            }),
                          label: writePermissions$p(ctx),
                          disabled: owner || editingThemselves || state.permissions.admin || !userData.paid && !tenantHasSeats,
                          containerClass: "form-input-checkbox-headline",
                          wrapperClasses: {
                            hd: [
                              "react-checkbox_users-headline",
                              true
                            ],
                            tl: /* [] */0
                          },
                          inputClasses: Js_dict.fromList({
                                hd: [
                                  "test-paid",
                                  true
                                ],
                                tl: /* [] */0
                              }),
                          hint: renderLicenseBlock,
                          children: renderLicenseHint
                        })), React.createElement("div", {
                      className: "form-group form-input"
                    }, React.createElement(UserPermissionCheckbox.make, {
                          value: hasAllPermissions || state.permissions.loginWeb,
                          onChange: (function (loginWeb) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: init.jobs,
                                      schedule: init.schedule,
                                      clients: init.clients,
                                      teams: init.teams,
                                      loginWeb: loginWeb,
                                      map: init.map,
                                      shifts: init.shifts,
                                      admin: init.admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: init.jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          label: webPermission$p(ctx),
                          disabled: owner || editingThemselves || state.permissions.admin,
                          containerClass: "form-input-checkbox-list",
                          wrapperClasses: {
                            hd: [
                              "react-checkbox_users-headline",
                              true
                            ],
                            tl: /* [] */0
                          },
                          inputClasses: Js_dict.fromList({
                                hd: [
                                  "test-login_web",
                                  true
                                ],
                                tl: /* [] */0
                              }),
                          children: webGroup$p(ctx)
                        }), React.createElement(UserPermissionInput.make, {
                          value: hasAllPermissions ? "Delete" : state.permissions.jobs,
                          onChange: (function (jobs) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: jobs,
                                      schedule: init.schedule,
                                      clients: init.clients,
                                      teams: init.teams,
                                      loginWeb: init.loginWeb,
                                      map: init.map,
                                      shifts: init.shifts,
                                      admin: init.admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: init.jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          deleteMode: true,
                          disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb,
                          paid: state.paid,
                          label: jobPermission$p(ctx)
                        }), React.createElement(UserPermissionInput.make, {
                          value: hasAllPermissions ? "Write" : state.permissions.schedule,
                          onChange: (function (schedule) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: init.jobs,
                                      schedule: schedule,
                                      clients: init.clients,
                                      teams: init.teams,
                                      loginWeb: init.loginWeb,
                                      map: init.map,
                                      shifts: init.shifts,
                                      admin: init.admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: init.jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb,
                          paid: state.paid,
                          label: schedulePermission$p(ctx)
                        }), React.createElement(UserPermissionInput.make, {
                          value: hasAllPermissions ? "Write" : state.permissions.clients,
                          onChange: (function (clients) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: init.jobs,
                                      schedule: init.schedule,
                                      clients: clients,
                                      teams: init.teams,
                                      loginWeb: init.loginWeb,
                                      map: init.map,
                                      shifts: init.shifts,
                                      admin: init.admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: init.jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb,
                          paid: state.paid,
                          label: clientsPermission$p(ctx)
                        }), React.createElement(Optional.make, {
                          show: Context_Types.Features.hasFlag("oldTeamsUi", ctx.features),
                          children: React.createElement(UserPermissionInput.make, {
                                value: hasAllPermissions ? "Write" : state.permissions.teams,
                                onChange: (function (teams) {
                                    var init = state.permissions;
                                    send({
                                          TAG: "SetPermissions",
                                          _0: {
                                            jobs: init.jobs,
                                            schedule: init.schedule,
                                            clients: init.clients,
                                            teams: teams,
                                            loginWeb: init.loginWeb,
                                            map: init.map,
                                            shifts: init.shifts,
                                            admin: init.admin,
                                            loginMobile: init.loginMobile,
                                            createJobsMobile: init.createJobsMobile,
                                            completeJobs: init.completeJobs,
                                            jobsExport: init.jobsExport
                                          },
                                          _1: owner
                                        });
                                  }),
                                disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb,
                                paid: state.paid,
                                label: teamsPermission$p(ctx)
                              })
                        }), React.createElement(UserPermissionCheckbox.make, {
                          value: hasAllPermissions || state.permissions.map,
                          onChange: (function (map) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: init.jobs,
                                      schedule: init.schedule,
                                      clients: init.clients,
                                      teams: init.teams,
                                      loginWeb: init.loginWeb,
                                      map: map,
                                      shifts: init.shifts,
                                      admin: init.admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: init.jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          label: mapPermission$p(ctx),
                          disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb
                        }), React.createElement(UserPermissionCheckbox.make, {
                          value: hasAllPermissions || state.permissions.jobsExport,
                          onChange: (function (jobsExport) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: init.jobs,
                                      schedule: init.schedule,
                                      clients: init.clients,
                                      teams: init.teams,
                                      loginWeb: init.loginWeb,
                                      map: init.map,
                                      shifts: init.shifts,
                                      admin: init.admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          label: jobsExportPermission$p(ctx),
                          disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb
                        }), React.createElement(Optional.make, {
                          show: Context_Types.Features.hasFlag("shifts", ctx.features),
                          children: React.createElement(UserPermissionCheckbox.make, {
                                value: hasAllPermissions || state.permissions.shifts,
                                onChange: (function (shifts) {
                                    var tmp;
                                    if (Context_Types.Features.hasFlag("oldTeamsUi", ctx.features)) {
                                      var init = state.permissions;
                                      tmp = {
                                        jobs: init.jobs,
                                        schedule: init.schedule,
                                        clients: init.clients,
                                        teams: init.teams,
                                        loginWeb: init.loginWeb,
                                        map: init.map,
                                        shifts: shifts,
                                        admin: init.admin,
                                        loginMobile: init.loginMobile,
                                        createJobsMobile: init.createJobsMobile,
                                        completeJobs: init.completeJobs,
                                        jobsExport: init.jobsExport
                                      };
                                    } else {
                                      var init$1 = state.permissions;
                                      tmp = {
                                        jobs: init$1.jobs,
                                        schedule: init$1.schedule,
                                        clients: init$1.clients,
                                        teams: shifts ? "Write" : "Denied",
                                        loginWeb: init$1.loginWeb,
                                        map: init$1.map,
                                        shifts: shifts,
                                        admin: init$1.admin,
                                        loginMobile: init$1.loginMobile,
                                        createJobsMobile: init$1.createJobsMobile,
                                        completeJobs: init$1.completeJobs,
                                        jobsExport: init$1.jobsExport
                                      };
                                    }
                                    send({
                                          TAG: "SetPermissions",
                                          _0: tmp,
                                          _1: owner
                                        });
                                  }),
                                label: shiftsPermission$p(ctx),
                                disabled: owner || editingThemselves || state.permissions.admin || !state.permissions.loginWeb
                              })
                        }), React.createElement(UserPermissionCheckbox.make, {
                          value: hasAllPermissions || state.permissions.admin,
                          onChange: (function (admin) {
                              var init = state.permissions;
                              send({
                                    TAG: "SetPermissions",
                                    _0: {
                                      jobs: init.jobs,
                                      schedule: init.schedule,
                                      clients: init.clients,
                                      teams: init.teams,
                                      loginWeb: init.loginWeb,
                                      map: init.map,
                                      shifts: init.shifts,
                                      admin: admin,
                                      loginMobile: init.loginMobile,
                                      createJobsMobile: init.createJobsMobile,
                                      completeJobs: init.completeJobs,
                                      jobsExport: init.jobsExport
                                    },
                                    _1: owner
                                  });
                            }),
                          label: adminPermission$p(ctx),
                          disabled: owner || editingThemselves || !state.permissions.loginWeb || !state.paid,
                          containerClass: styles.admin,
                          hint: adminHint
                        })), React.createElement(UserPermissionCheckbox.make, {
                      value: state.permissions.loginMobile,
                      onChange: (function (loginMobile) {
                          setShowTeamWarning(function (param) {
                                return !loginMobile;
                              });
                          var init = state.permissions;
                          send({
                                TAG: "SetPermissions",
                                _0: {
                                  jobs: init.jobs,
                                  schedule: init.schedule,
                                  clients: init.clients,
                                  teams: init.teams,
                                  loginWeb: init.loginWeb,
                                  map: init.map,
                                  shifts: init.shifts,
                                  admin: init.admin,
                                  loginMobile: loginMobile,
                                  createJobsMobile: init.createJobsMobile,
                                  completeJobs: init.completeJobs,
                                  jobsExport: init.jobsExport
                                },
                                _1: owner
                              });
                        }),
                      label: mobilePermission$p(ctx),
                      containerClass: "form-input-checkbox-list",
                      wrapperClasses: {
                        hd: [
                          "react-checkbox_users-headline",
                          true
                        ],
                        tl: /* [] */0
                      },
                      children: mobileGroup$p(ctx)
                    }), React.createElement(UserPermissionCheckbox.make, {
                      value: state.permissions.completeJobs,
                      onChange: (function (completeJobs) {
                          setShowTeamWarning(function (param) {
                                return !completeJobs;
                              });
                          var init = state.permissions;
                          send({
                                TAG: "SetPermissions",
                                _0: {
                                  jobs: init.jobs,
                                  schedule: init.schedule,
                                  clients: init.clients,
                                  teams: init.teams,
                                  loginWeb: init.loginWeb,
                                  map: init.map,
                                  shifts: init.shifts,
                                  admin: init.admin,
                                  loginMobile: init.loginMobile,
                                  createJobsMobile: init.createJobsMobile,
                                  completeJobs: completeJobs,
                                  jobsExport: init.jobsExport
                                },
                                _1: owner
                              });
                        }),
                      label: completeJobsPermission$p(ctx),
                      disabled: !state.permissions.loginMobile || !state.paid
                    }), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("createJobsInApp", wire.ctx.features),
                      children: React.createElement(UserPermissionCheckbox.make, {
                            value: state.permissions.createJobsMobile,
                            onChange: (function (createJobsMobile) {
                                var init = state.permissions;
                                send({
                                      TAG: "SetPermissions",
                                      _0: {
                                        jobs: init.jobs,
                                        schedule: init.schedule,
                                        clients: init.clients,
                                        teams: init.teams,
                                        loginWeb: init.loginWeb,
                                        map: init.map,
                                        shifts: init.shifts,
                                        admin: init.admin,
                                        loginMobile: init.loginMobile,
                                        createJobsMobile: createJobsMobile,
                                        completeJobs: init.completeJobs,
                                        jobsExport: init.jobsExport
                                      },
                                      _1: owner
                                    });
                              }),
                            label: createJobMobilePermission$p(ctx),
                            disabled: !state.permissions.loginMobile || !state.paid
                          })
                    }), React.createElement("div", {
                      className: styles.row
                    }, React.createElement("label", {
                          className: styles.label
                        }, skillsGroup$p(ctx)), React.createElement("div", {
                          className: styles.input
                        }, React.createElement(SkillsSelect.make, {
                              inputName: "user[skill_uuids]",
                              selectedSkillUuids: state.skillUuids,
                              onChange: (function (skillUuids) {
                                  send({
                                        TAG: "SetSkillUuids",
                                        _0: skillUuids
                                      });
                                }),
                              availableSkills: ctx.availableSkills.map(function (s) {
                                    return {
                                            uuid: s.uuid,
                                            name: s.name
                                          };
                                  }),
                              placeholder: skillsSelectPlaceholder$p(ctx),
                              disabled: !state.permissions.loginMobile
                            }))), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("territories", ctx.features),
                      children: React.createElement("div", {
                            className: styles.row
                          }, React.createElement("label", {
                                className: styles.label
                              }, territoryGroup$p(ctx)), React.createElement("div", {
                                className: styles.input
                              }, React.createElement(TerritoriesSelect.make, {
                                    inputName: "user[territory_uuid]",
                                    selectedTerritoryUuid: Js_null.fromOption(state.territoryUuid),
                                    onChange: (function (territoryUuid) {
                                        send({
                                              TAG: "SetTerritoryUuid",
                                              _0: territoryUuid === null ? undefined : Caml_option.some(territoryUuid)
                                            });
                                      }),
                                    availableTerritories: availableTerritories.map(function (s) {
                                          return {
                                                  uuid: s.uuid,
                                                  name: s.name
                                                };
                                        }),
                                    placeholder: territoriesSelectPlaceholder$p(ctx),
                                    disabled: !state.permissions.loginMobile
                                  })))
                    })), React.createElement("div", {
                  className: styles.footer
                }, React.createElement(UserShow$DeleteButton, {
                      onDelete: onDelete,
                      isNew: isNew,
                      owner: owner
                    }), React.createElement("div", {
                      className: styles.footerRight
                    }, React.createElement(Link.make, {
                          wire: wire,
                          route: {
                            NAME: "User",
                            VAL: "Index"
                          },
                          className: "btn btn-default float-right btn-footer",
                          children: cancel$p(ctx)
                        }), React.createElement(UserShow$SubmitButton, {
                          isNew: isNew,
                          ctx: ctx
                        }))), React.createElement(Optional.make, {
                  show: teams.length > 0 && userData.permissions.completeJobs && !state.permissions.completeJobs && match$2[0],
                  children: React.createElement(UserTeamsWarning.make, {
                        onClose: onWarningClose,
                        onSubmit: (function () {
                            setShowTeamWarning(function (param) {
                                  return false;
                                });
                          }),
                        teams: teams
                      })
                }));
}

var make = UserShow;

export {
  make ,
}
/* headerNew' Not a pure module */
