

import * as Case from "../../utils/Case.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as PromiseF from "../PromiseF.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Js_promise2 from "rescript/lib/es6/js_promise2.js";
import * as Backend_Errors from "./Backend_Errors.mjs";
import * as Webapi__FormData from "rescript-webapi/lib/es6/src/Webapi/Webapi__FormData.mjs";
import * as Webapi__Iterator from "rescript-webapi/lib/es6/src/Webapi/Webapi__Iterator.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function make(decamelizeOpt, data) {
  var decamelize = decamelizeOpt !== undefined ? decamelizeOpt : false;
  if (data.NAME === "FormData") {
    return Caml_option.some(data.VAL);
  }
  var json = data.NAME === "Json" ? data.VAL : Utils.objToJson(data.VAL);
  var json$1 = decamelize ? Case.decamelize(json) : json;
  var string = JSON.stringify(json$1);
  if (string !== undefined) {
    return Caml_option.some(string);
  }
  
}

function isFormData(data) {
  return data.NAME === "FormData";
}

var BodyInit = {
  make: make,
  isFormData: isFormData
};

async function toJSON(formData) {
  var promises = [];
  Webapi__Iterator.forEach((function (entry) {
          var key = entry[0];
          var match = Webapi__FormData.EntryValue.classify(entry[1]);
          var promise;
          if (match.NAME === "File") {
            var f = match.VAL;
            promise = Js_promise2.then(f.text(), (function (text) {
                    return Promise.resolve([
                                key,
                                Js_dict.fromArray([
                                      [
                                        "name",
                                        f.name
                                      ],
                                      [
                                        "text",
                                        text
                                      ]
                                    ])
                              ]);
                  }));
          } else {
            promise = Promise.resolve([
                  key,
                  match.VAL
                ]);
          }
          promises.push(promise);
        }), formData.entries());
  return Js_dict.fromArray(await Promise.all(promises));
}

function parseError(error) {
  if (typeof error !== "object") {
    return error;
  }
  if (error.TAG !== "HTTPError") {
    return error;
  }
  var match = error._0;
  var tmp = match[0];
  if (typeof tmp === "object") {
    return error;
  }
  if (tmp !== "UnprocessableEntity") {
    return error;
  }
  var json;
  try {
    json = JSON.parse(match[1]);
  }
  catch (exn){
    return error;
  }
  return {
          TAG: "ValidationError",
          _0: Backend_Errors.make(json)
        };
}

function decode(promise, decoder) {
  return PromiseF.map(promise, (function (response) {
                if (response.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: parseError(response._0)
                        };
                }
                var json = response._0;
                if (json === undefined) {
                  return {
                          TAG: "Error",
                          _0: {
                            TAG: "DecodeError",
                            _0: "No response"
                          }
                        };
                }
                var camelized = Case.camelize(json);
                var res = Json_Decode$JsonCombinators.decode(camelized, decoder);
                if (res.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: res._0
                        };
                }
                var message = res._0;
                console.log(message);
                return {
                        TAG: "Error",
                        _0: {
                          TAG: "DecodeError",
                          _0: message
                        }
                      };
              }));
}

function discard(promise) {
  return PromiseF.map(promise, (function (response) {
                if (response.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: parseError(response._0)
                        };
                }
              }));
}

function $$finally(promise, func) {
  PromiseF.$$finally(func, (function (prim) {
          
        }), promise);
}

function finallyOnSuccess(promise, func) {
  $$finally(promise, (function (result) {
          if (result.TAG === "Ok") {
            return func(result._0);
          }
          
        }));
}

function FormData_make(prim) {
  return new FormData(prim);
}

function FormData_entryToJson(prim) {
  return prim;
}

var $$FormData$1 = {
  make: FormData_make,
  entryToJson: FormData_entryToJson,
  toJSON: toJSON
};

export {
  BodyInit ,
  $$FormData$1 as $$FormData,
  parseError ,
  decode ,
  discard ,
  $$finally ,
  finallyOnSuccess ,
}
/* Case Not a pure module */
