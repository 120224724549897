

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Territory from "./Types_Territory.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";
import * as Serialize_assigneesJs from "planado/utils/serialize_assignees.js";

function fromString(s) {
  switch (s) {
    case "edit" :
        return "Edit";
    case "view" :
        return "View";
    default:
      return ;
  }
}

function toString(s) {
  if (s === "Edit") {
    return "edit";
  } else {
    return "view";
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Skill = {
  decode: decode$1
};

function uuid(param) {
  return param.uuid;
}

function decode$2(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.string),
          permission: field.required("permission", decode),
          skills: field.required("skills", JsonDecode.array(decode$1)),
          territory: RCore.$$Option.flatMap(field.optional("territory", JsonDecode.option(Types_Territory.decode)), (function (x) {
                  return x;
                })),
          permanent: RCore.$$Option.getOr(field.optional("permanent", JsonDecode.bool), true)
        };
}

function encode(w) {
  return {
          uuid: Uuid.toString(w.uuid),
          name: w.name,
          permission: toString(w.permission),
          skills: w.skills,
          territory: Js_null.fromOption(w.territory),
          permanent: w.permanent
        };
}

var User = {
  uuid: uuid,
  decode: decode$2,
  encode: encode
};

function name(worker) {
  return worker._0.name;
}

function uuid$1(worker) {
  return worker._0.uuid;
}

function eql(w1, w2) {
  return Caml_obj.equal(w1._0.uuid, w2._0.uuid);
}

var decode$3 = JsonDecode.object(function (field) {
      var match = field.required("access", decode);
      if (match === "Edit") {
        return {
                TAG: "Edit",
                _0: decode$2(field)
              };
      } else {
        return {
                TAG: "View",
                _0: decode$2(field)
              };
      }
    });

function encode$1(worker) {
  var match;
  match = worker.TAG === "Edit" ? [
      "edit",
      worker._0
    ] : [
      "view",
      worker._0
    ];
  var w = match[1];
  return {
          uuid: Uuid.toString(w.uuid),
          name: w.name,
          permission: toString(w.permission),
          access: match[0],
          skills: w.skills,
          territory: Js_null.fromOption(w.territory)
        };
}

function uuid$2(param) {
  return param.uuid;
}

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              permission: field.required("permission", decode),
              permanent: RCore.$$Option.getOr(field.optional("permanent", JsonDecode.bool), true)
            };
    });

function encode$2(w) {
  return {
          uuid: Uuid.toString(w.uuid),
          name: w.name,
          permission: toString(w.permission)
        };
}

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              permission: field.required("permission", decode),
              dateFrom: field.required("dateFrom", JsonDecode.date),
              dateTo: field.required("dateTo", JsonDecode.date)
            };
    });

function toMember(temp) {
  return {
          uuid: temp.uuid,
          name: temp.name,
          permission: temp.permission,
          permanent: false
        };
}

function workers(param) {
  return param.workers;
}

function foreman(param) {
  return param.foreman;
}

function uuid$3(param) {
  return param.uuid;
}

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              foreman: RCore.$$Option.flatMap(field.optional("foreman", JsonDecode.option(decode$4)), (function (x) {
                      return x;
                    })),
              workers: field.required("workers", JsonDecode.array(decode$4)),
              temporaryWorkers: RCore.$$Option.getOr(field.optional("temporaryWorkers", JsonDecode.array(decode$5)), []),
              skills: field.required("skills", JsonDecode.array(decode$1)),
              territory: RCore.$$Option.flatMap(field.optional("territory", JsonDecode.option(Types_Territory.decode)), (function (x) {
                      return x;
                    }))
            };
    });

function encode$3(team) {
  return {
          uuid: Uuid.toString(team.uuid),
          name: team.name,
          foreman: Js_null.fromOption(RCore.$$Option.map(team.foreman, encode$2)),
          workers: team.workers.map(encode$2),
          temporaryWorkers: team.temporaryWorkers.map(function (t) {
                return encode$2(toMember(t));
              }),
          skills: team.skills,
          territory: Js_null.fromOption(team.territory)
        };
}

function canEdit(w) {
  if (w.TAG !== "Team") {
    return w._0.permission === "Edit";
  }
  var t = w._0;
  if (RCore.$$Option.isSome(t.foreman)) {
    return true;
  } else {
    return t.workers.some(function (w) {
                return w.permission === "Edit";
              });
  }
}

function isTeam(w) {
  if (w.TAG === "Team") {
    return true;
  } else {
    return false;
  }
}

function uuid$4(w) {
  return w._0.uuid;
}

function selectTeams(a) {
  return RCore.$$Array.filterMap(a, (function (w) {
                if (w.TAG === "Team") {
                  return w._0;
                }
                
              }));
}

var decode$7 = JsonDecode.object(function (field) {
      return Belt_Array.concatMany([
                  field.required("workers", JsonDecode.array(JsonDecode.object(decode$2))).map(function (w) {
                        return {
                                TAG: "Worker",
                                _0: w
                              };
                      }),
                  field.required("teams", JsonDecode.array(decode$6)).map(function (t) {
                        return {
                                TAG: "Team",
                                _0: t
                              };
                      })
                ]);
    });

function encode$4(assignees) {
  return assignees.map(function (a) {
              if (a.TAG === "Team") {
                return encode$3(a._0);
              } else {
                return encode(a._0);
              }
            });
}

function findByUuid(assigneeUuid, assignees) {
  return RCore.$$Array.keep(assignees, (function (a) {
                  return Caml_obj.equal(a._0.uuid, assigneeUuid);
                }))[0];
}

var PossibleAssignee = {
  canEdit: canEdit,
  isTeam: isTeam,
  uuid: uuid$4,
  selectTeams: selectTeams,
  decode: decode$7,
  encode: encode$4,
  findByUuid: findByUuid
};

function uuid$5(a) {
  if (a.TAG === "Team") {
    return a._0.uuid;
  } else {
    return a._0._0.uuid;
  }
}

var decode$8 = JsonDecode.object(function (field) {
      return Belt_Array.concatMany([
                  field.required("teams", JsonDecode.array(decode$6)).map(function (t) {
                        return {
                                TAG: "Team",
                                _0: t
                              };
                      }),
                  field.required("workers", JsonDecode.array(decode$3)).map(function (w) {
                        return {
                                TAG: "Worker",
                                _0: w
                              };
                      })
                ]);
    });

function canEdit$1(w) {
  if (w.TAG === "Team") {
    return RCore.$$Option.isSome(w._0.foreman);
  } else {
    var worker = w._0;
    return worker._0.permission === "Edit";
  }
}

function isTeam$1(a) {
  if (a.TAG === "Team") {
    return true;
  } else {
    return false;
  }
}

function eql$1(w1, w2) {
  if (w1.TAG === "Team") {
    if (w2.TAG === "Team") {
      return Caml_obj.equal(w1._0.uuid, w2._0.uuid);
    } else {
      return false;
    }
  } else if (w2.TAG === "Team") {
    return false;
  } else {
    return eql(w1._0, w2._0);
  }
}

function haveWorker(w1, w2) {
  if (w1.TAG !== "Team") {
    return Uuid.equal(w1._0._0.uuid, w2.uuid);
  }
  var team = w1._0;
  if (RCore.$$Option.getOr(RCore.$$Option.map(team.foreman, (function (f) {
                return Uuid.equal(f.uuid, w2.uuid);
              })), false)) {
    return true;
  } else {
    return team.workers.some(function (w) {
                return Uuid.equal(w.uuid, w2.uuid);
              });
  }
}

function allUuids(a) {
  if (a.TAG !== "Team") {
    return [a._0._0.uuid];
  }
  var match = a._0;
  var f = match.foreman;
  var uuid = match.uuid;
  if (f !== undefined) {
    return Belt_Array.concatMany([
                match.workers.map(function (param) {
                      return param.uuid;
                    }),
                [
                  f.uuid,
                  uuid
                ]
              ]);
  } else {
    return Belt_Array.concatMany([
                match.workers.map(function (param) {
                      return param.uuid;
                    }),
                [uuid]
              ]);
  }
}

function toJson(assignees) {
  return assignees.map(function (a) {
              return Json_Encode$JsonCombinators.object([
                          [
                            "uuid",
                            a.uuid
                          ],
                          [
                            "access",
                            a.access
                          ],
                          [
                            "teamUuid",
                            Json_Encode$JsonCombinators.option(function (prim) {
                                    return prim;
                                  })(a.teamUuid)
                          ],
                          [
                            "permanent",
                            a.permanent
                          ]
                        ]);
            });
}

function encode$5(w) {
  if (w.TAG === "Team") {
    var team = w._0;
    var foreman = RCore.$$Option.mapWithDefault(team.foreman, [], (function (w) {
            return [{
                      uuid: Uuid.toString(w.uuid),
                      access: "edit",
                      teamUuid: Uuid.toString(team.uuid),
                      permanent: true
                    }];
          }));
    var workers = team.workers.map(function (w) {
          return {
                  uuid: Uuid.toString(w.uuid),
                  access: "edit",
                  teamUuid: Uuid.toString(team.uuid),
                  permanent: true
                };
        });
    return Belt_Array.concatMany([
                foreman,
                workers
              ]);
  }
  var worker = w._0;
  if (worker.TAG === "Edit") {
    return [{
              uuid: Uuid.toString(worker._0.uuid),
              access: "edit",
              teamUuid: undefined,
              permanent: true
            }];
  } else {
    return [{
              uuid: Uuid.toString(worker._0.uuid),
              access: "view",
              teamUuid: undefined,
              permanent: true
            }];
  }
}

var SelectedAssignee = {
  toJson: toJson,
  encode: encode$5
};

function skills(a) {
  if (a.TAG === "Team") {
    return a._0.skills;
  } else {
    return a._0._0.skills;
  }
}

function territory(a) {
  if (a.TAG === "Team") {
    return a._0.territory;
  } else {
    return a._0._0.territory;
  }
}

function encode$6(assignees) {
  var teams = RCore.$$Array.filterMap(assignees, (function (a) {
          if (a.TAG === "Team") {
            return a._0;
          }
          
        }));
  var workers = RCore.$$Array.filterMap(assignees, (function (a) {
          if (a.TAG === "Team") {
            return ;
          } else {
            return a._0;
          }
        }));
  return {
          teams: teams.map(encode$3),
          workers: workers.map(encode$1)
        };
}

function mainAssigneeName(assignees) {
  var match = RCore.$$Array.getBy(assignees, (function (a) {
          if (a.TAG === "Team" || a._0.TAG === "Edit") {
            return true;
          } else {
            return false;
          }
        }));
  if (match === undefined) {
    return ;
  }
  if (match.TAG === "Team") {
    return match._0.name;
  }
  var w = match._0;
  if (w.TAG === "Edit") {
    return w._0.name;
  }
  
}

function mainAssigneeUuid(assignees) {
  var match = RCore.$$Array.getBy(assignees, (function (a) {
          if (a.TAG === "Team" || a._0.TAG === "Edit") {
            return true;
          } else {
            return false;
          }
        }));
  if (match === undefined) {
    return ;
  }
  if (match.TAG === "Team") {
    return Caml_option.some(match._0.uuid);
  }
  var match$1 = match._0;
  if (match$1.TAG === "Edit") {
    return Caml_option.some(match$1._0.uuid);
  }
  
}

function teamContainsWorker(team, uuid$6) {
  var f = foreman(team);
  if (f !== undefined && Caml_obj.equal(f.uuid, uuid$6)) {
    return true;
  }
  return team.workers.some(function (a) {
              return Caml_obj.equal(uuid$2(a), uuid$6);
            });
}

function setMainAssignee(assignees, newMain) {
  if (Caml_obj.equal(assignees, [])) {
    return [newMain];
  } else {
    var assignees$1 = assignees.map(function (a) {
          if (a.TAG === "Team") {
            if (Caml_obj.notequal(uuid$3(a._0), uuid$5(newMain))) {
              return newMain;
            } else {
              return a;
            }
          }
          var w = a._0;
          if (w.TAG === "Edit" && Caml_obj.notequal(uuid(w._0), uuid$5(newMain))) {
            return newMain;
          } else {
            return a;
          }
        });
    return RCore.$$Array.reduce(assignees$1, [], (function (acc, current) {
                  if (acc.some(function (alreadyInAssignees) {
                          if (alreadyInAssignees.TAG !== "Team") {
                            return Caml_obj.equal(alreadyInAssignees._0._0.uuid, uuid$5(current));
                          }
                          var t = alreadyInAssignees._0;
                          return Caml_obj.equal(uuid$3(t), uuid$5(current)) ? true : teamContainsWorker(t, uuid$5(current));
                        })) {
                    return acc;
                  } else {
                    return Belt_Array.concatMany([
                                acc,
                                [current]
                              ]);
                  }
                }));
  }
}

function workerUuids(assignees) {
  return assignees.flatMap(function (a) {
              if (a.TAG === "Team") {
                var t = a._0;
                return Belt_Array.concatMany([
                            t.workers.map(function (w) {
                                  return w.uuid;
                                }),
                            RCore.$$Option.mapWithDefault(t.foreman, [], (function (f) {
                                    return [f.uuid];
                                  }))
                          ]);
              }
              var match = a._0;
              if (match.TAG === "Edit") {
                return [];
              } else {
                return [match._0.uuid];
              }
            });
}

function workerNumberValid(assignees) {
  return RCore.$$Array.reduce(assignees, 0, (function (total, assignee) {
                if (assignee.TAG === "Team") {
                  return total + assignee._0.workers.length | 0;
                } else {
                  return total + 1 | 0;
                }
              })) < 10;
}

var maxWorkers = 10;

var Permission = {};

var $$Worker = {
  User: User,
  name: name,
  uuid: uuid$1,
  eql: eql,
  decode: decode$3,
  encode: encode$1
};

var Team_Member = {
  uuid: uuid$2,
  encode: encode$2
};

var Team_TemporaryWorker = {
  toMember: toMember
};

var Team = {
  Member: Team_Member,
  TemporaryWorker: Team_TemporaryWorker,
  workers: workers,
  foreman: foreman,
  uuid: uuid$3
};

function serialize(prim) {
  return Serialize_assigneesJs.serializeAssignees(prim);
}

export {
  maxWorkers ,
  Permission ,
  Skill ,
  $$Worker ,
  Team ,
  PossibleAssignee ,
  uuid$5 as uuid,
  decode$8 as decode,
  canEdit$1 as canEdit,
  isTeam$1 as isTeam,
  eql$1 as eql,
  haveWorker ,
  allUuids ,
  SelectedAssignee ,
  skills ,
  territory ,
  encode$6 as encode,
  mainAssigneeName ,
  mainAssigneeUuid ,
  setMainAssignee ,
  workerUuids ,
  workerNumberValid ,
  serialize ,
}
/* decode Not a pure module */
