

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as MapDateScrollModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/MapDateScroll.module.css";

var styles = MapDateScrollModuleCss;

function MapDateScroll(Props) {
  var wire = Props.wire;
  var size = Props.size;
  var minimalDate = Props.minimalDate;
  var current = Props.current;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return current;
      });
  var setPageStart = match[1];
  var pageStart = match[0];
  var send = function (action) {
    var prev = Locale.T.prevDays(size)(pageStart);
    if (action === "Next") {
      return setPageStart(function (param) {
                  return Locale.T.nextDays(size)(pageStart);
                });
    } else if (Locale.T.lessThan(prev, minimalDate)) {
      return setPageStart(function (param) {
                  return minimalDate;
                });
    } else {
      return setPageStart(function (param) {
                  return prev;
                });
    }
  };
  var dates = Locale.T.dateRange(size, pageStart);
  var btnClass = El.Cn.fromList((function (__x) {
            return El.Cn.add("disabled", Locale.T.equal(pageStart, minimalDate), __x);
          })({
            hd: [
              "btn",
              true
            ],
            tl: /* [] */0
          }));
  return React.createElement("ul", {
              className: styles.dates
            }, React.createElement("li", {
                  className: btnClass,
                  onClick: (function (_event) {
                      send("Prev");
                    })
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, undefined, undefined, "chevron-left")
                    })), Core__List.toArray(Core__List.map(dates, (function (x) {
                        var fmtDate = function (v) {
                          return Locale.T.fmtM("DateNoYear", wire)(v);
                        };
                        var className = El.Cn.fromList(El.Cn.cons([
                                  styles.current,
                                  Locale.T.equal(current, x)
                                ], {
                                  hd: [
                                    styles.date,
                                    true
                                  ],
                                  tl: /* [] */0
                                }));
                        return React.createElement("li", {
                                    key: Locale.T.isoDate(x),
                                    className: className,
                                    onClick: (function (_event) {
                                        onChange(x);
                                      })
                                  }, fmtDate(x));
                      }))), React.createElement("li", {
                  className: "btn",
                  onClick: (function (_event) {
                      send("Next");
                    })
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, undefined, undefined, "chevron-right")
                    })));
}

var make = MapDateScroll;

export {
  make ,
}
/* styles Not a pure module */
