

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../../../../ui/UI_Icon.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as UI_Button from "../../../../../../ui/UI_Button.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as ServiceItem from "../../../../../common/Catalog/ServiceItem.mjs";
import * as CurrencyText from "../../../../../inputs/CurrencyText.mjs";
import * as JobEdit_JobType from "../../../lib/types/JobEdit_JobType.mjs";
import * as JobForm_ServicesSelect from "./JobForm_ServicesSelect.mjs";
import * as CatalogModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Catalog/Catalog.module.css";
import * as JobForm_ServicesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/services/JobForm_Services.module.css";

var label$p = Utils.Translations.tr("js.jobs.labels.services");

var addService$p = Utils.Translations.tr("js.jobs.services.add_service");

var orderService$p = Utils.Translations.tr("js.jobs.services.order_service");

var ordered$p = Utils.Translations.tr("js.jobs.services.ordered");

var required$p = Utils.Translations.tr("js.jobs.services.required");

var provided$p = Utils.Translations.tr("js.jobs.services.provided");

var styles = JobForm_ServicesModuleCss;

function make(status) {
  switch (status) {
    case "Started" :
        return "AutoProvide";
    case "Finished" :
        return "Provide";
    case "Suspended" :
    case "Canceled" :
        return "None";
    default:
      return "Order";
  }
}

function JobForm_Services$OrderedServiceItem(Props) {
  var onRemove = Props.onRemove;
  var orderedServiceUuid = Props.orderedServiceUuid;
  var service = Props.service;
  var required = Props.required;
  var ctx = Props.ctx;
  var disabled = Props.disabled;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.statusContainer
                }, React.createElement("div", {
                      className: styles.status
                    }, ordered$p(ctx)), React.createElement(Optional.make, {
                      show: required,
                      children: React.createElement("div", {
                            className: styles.status
                          }, required$p(ctx))
                    })), React.createElement(ServiceItem.make, {
                  ctx: ctx,
                  name: service.name,
                  description: service.description,
                  price: service.priceDefault,
                  currency: service.currency,
                  archived: service.archived,
                  onRemove: onRemove,
                  disabled: disabled,
                  key: Uuid.toString(orderedServiceUuid)
                }));
}

function JobForm_Services$ProvidedServiceItem(Props) {
  var onRemove = Props.onRemove;
  var service = Props.service;
  var ordered = Props.ordered;
  var required = Props.required;
  var ctx = Props.ctx;
  var disabled = Props.disabled;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.statusContainer
                }, React.createElement(Optional.make, {
                      show: ordered,
                      children: React.createElement("div", {
                            className: styles.status
                          }, ordered$p(ctx))
                    }), React.createElement(Optional.make, {
                      show: required,
                      children: React.createElement("div", {
                            className: styles.status
                          }, required$p(ctx))
                    }), React.createElement("div", {
                      className: styles.status
                    }, provided$p(ctx))), React.createElement(ServiceItem.make, {
                  ctx: ctx,
                  name: service.name,
                  description: service.description,
                  price: service.price,
                  currency: service.currency,
                  archived: service.archived,
                  onRemove: onRemove,
                  disabled: disabled,
                  children: RCore.$$Option.getWithDefault(children, null),
                  key: Uuid.toString(service.uuid)
                }));
}

var styles$1 = CatalogModuleCss;

function formatQuantity(quantity) {
  var q = Core__Float.fromString(quantity);
  if (q !== undefined) {
    return q.toString();
  } else {
    return quantity;
  }
}

function JobForm_Services$MaterialItem(Props) {
  var ctx = Props.ctx;
  var material = Props.material;
  var disabled = Props.disabled;
  var v = material.amount;
  return React.createElement("div", {
              className: styles$1.row
            }, React.createElement("div", {
                  className: styles$1.itemLabel
                }, React.createElement("i", {
                      className: Icon.style("lg", undefined, undefined, undefined, "cubes")
                    })), React.createElement("div", {
                  className: styles$1.itemInfo
                }, React.createElement("div", {
                      className: styles$1.header
                    }, Utils.Translations.concat([
                          material.name,
                          " ",
                          "(" + formatQuantity(material.quantity) + " " + material.unit.code + ")"
                        ], ctx))), v !== undefined ? React.createElement("div", {
                    className: El.Cn.concat(styles$1.itemInfo, styles$1.price)
                  }, React.createElement(CurrencyText.make, {
                        ctx: ctx,
                        currency: material.currency,
                        value: v.toString()
                      })) : null, React.createElement(Optional.make, {
                  show: !disabled,
                  children: React.createElement("div", {
                        className: styles$1.itemDelete
                      })
                }));
}

function JobForm_Services$AddService(Props) {
  var ctx = Props.ctx;
  var availableServices = Props.availableServices;
  var addService = Props.addService;
  var mode = Props.mode;
  var match = React.useState(function () {
        return false;
      });
  var setOpened = match[1];
  var elementRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, elementRef, (function () {
          setOpened(function (param) {
                return false;
              });
        }));
  var openSelect = function (evt) {
    evt.stopPropagation();
    setOpened(function (param) {
          return true;
        });
  };
  if (match[0]) {
    return React.createElement("div", {
                ref: elementRef
              }, React.createElement(JobForm_ServicesSelect.make, {
                    onSelect: addService,
                    availableServices: availableServices
                  }));
  }
  var tmp;
  switch (mode) {
    case "Order" :
        tmp = orderService$p(ctx);
        break;
    case "AutoProvide" :
    case "Provide" :
        tmp = addService$p(ctx);
        break;
    case "None" :
        tmp = null;
        break;
    
  }
  return React.createElement(UI_Button.make, {
              flavor: "link",
              className: styles.add,
              onClick: openSelect,
              children: null
            }, React.createElement(UI_Icon.make, {
                  icon: "plus"
                }), El.space, tmp);
}

function JobForm_Services(Props) {
  var ctx = Props.ctx;
  var availableServices = Props.availableServices;
  var orderedServices = Props.orderedServices;
  var providedServices = Props.providedServices;
  var setProvidedServices = Props.setProvidedServices;
  var setOrderedServices = Props.setOrderedServices;
  var status = Props.status;
  var mode = make(status);
  var servicesForSelect;
  switch (mode) {
    case "Order" :
        servicesForSelect = RCore.$$Array.keep(availableServices, (function (a) {
                return !orderedServices.some(function (o) {
                            return Uuid.equal(o.serviceUuid, a.serviceUuid);
                          });
              }));
        break;
    case "AutoProvide" :
    case "Provide" :
        servicesForSelect = availableServices;
        break;
    case "None" :
        servicesForSelect = [];
        break;
    
  }
  React.useEffect((function () {
          if (mode === "AutoProvide") {
            var orderedNotProvided = RCore.$$Array.keep(orderedServices, (function (o) {
                    return !providedServices.some(function (p) {
                                return Uuid.equal(p.serviceUuid, o.serviceUuid);
                              });
                  }));
            var picked = RCore.$$Array.keepMap(availableServices, (function (a) {
                    var o = RCore.$$Array.getBy(orderedNotProvided, (function (o) {
                            return Uuid.equal(o.serviceUuid, a.serviceUuid);
                          }));
                    if (o !== undefined) {
                      return JobEdit_JobType.ProvidedService.fromOrderedService(o.uuid, JobEdit_JobType.AvailableService.toServiceItem(a));
                    }
                    
                  }));
            setProvidedServices(Belt_Array.concatMany([
                      providedServices,
                      picked
                    ]));
          }
          
        }), []);
  var ordered = React.useMemo((function () {
          if (mode === "Order") {
            return RCore.$$Array.keepMap(availableServices, (function (a) {
                          var o = RCore.$$Array.getBy(orderedServices, (function (o) {
                                  return Uuid.equal(o.serviceUuid, a.serviceUuid);
                                }));
                          if (o !== undefined) {
                            return [
                                    o.uuid,
                                    JobEdit_JobType.AvailableService.toServiceItem(a)
                                  ];
                          }
                          
                        }));
          } else {
            return [];
          }
        }), [
        availableServices,
        orderedServices
      ]);
  var addService = function (service) {
    switch (mode) {
      case "Order" :
          var ordered = JobEdit_JobType.OrderedService.fromAvailableService(service);
          return setOrderedServices(Belt_Array.concatMany([
                          orderedServices,
                          [ordered]
                        ]));
      case "AutoProvide" :
      case "Provide" :
          break;
      case "None" :
          return ;
      
    }
    var provided = JobEdit_JobType.ProvidedService.fromAvailableService(service);
    setProvidedServices(Belt_Array.concatMany([
              providedServices,
              [provided]
            ]));
  };
  var isRequired = function (uuid) {
    return RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(orderedServices, (function (s) {
                          return Uuid.equal(s.uuid, uuid);
                        })), (function (s) {
                      return s.required;
                    })), false);
  };
  var isOrdered = function (uuid) {
    if (uuid === undefined) {
      return false;
    }
    var uuid$1 = Caml_option.valFromOption(uuid);
    return orderedServices.some(function (o) {
                return Uuid.equal(o.uuid, uuid$1);
              });
  };
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.header
                }, label$p(ctx)), React.createElement(Optional.make, {
                  show: servicesForSelect.length > 0 && mode !== "None",
                  children: React.createElement(JobForm_Services$AddService, {
                        ctx: ctx,
                        availableServices: servicesForSelect,
                        addService: addService,
                        mode: mode
                      })
                }), React.createElement("div", {
                  className: styles.providedServices
                }, ordered.map(function (param) {
                      var service = param[1];
                      var orderedServiceUuid = param[0];
                      var required = isRequired(orderedServiceUuid);
                      return React.createElement(JobForm_Services$OrderedServiceItem, {
                                  onRemove: (function () {
                                      var newOrderedServices = RCore.$$Array.keep(orderedServices, (function (o) {
                                              return !Uuid.equal(o.serviceUuid, service.serviceUuid);
                                            }));
                                      setOrderedServices(newOrderedServices);
                                    }),
                                  orderedServiceUuid: orderedServiceUuid,
                                  service: service,
                                  required: required,
                                  ctx: ctx,
                                  disabled: required,
                                  key: Uuid.toString(service.serviceUuid)
                                });
                    }), providedServices.map(function (service) {
                      return React.createElement(JobForm_Services$ProvidedServiceItem, {
                                  onRemove: (function () {
                                      var newProvidedServices = RCore.$$Array.keep(providedServices, (function (s) {
                                              return !Uuid.equal(s.uuid, service.uuid);
                                            }));
                                      setProvidedServices(newProvidedServices);
                                    }),
                                  service: service,
                                  ordered: isOrdered(service.orderedServiceUuid),
                                  required: isRequired(service.uuid),
                                  ctx: ctx,
                                  disabled: mode === "None",
                                  children: service.usedMaterials.map(function (material) {
                                        return React.createElement(JobForm_Services$MaterialItem, {
                                                    ctx: ctx,
                                                    material: material,
                                                    disabled: mode === "None",
                                                    key: Uuid.toString(material.uuid)
                                                  });
                                      }),
                                  key: Uuid.toString(service.uuid)
                                });
                    })));
}

var make$1 = JobForm_Services;

export {
  make$1 as make,
}
/* label' Not a pure module */
