

import * as El from "../../../../../libraries/El.mjs";
import * as Icon from "../../../../../Icon.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_Button from "../../button/Schedule_Button.mjs";
import * as Schedule_ButtonGroup from "../../button_group/Schedule_ButtonGroup.mjs";
import * as Schedule_HeaderSwitchModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/switch/Schedule_HeaderSwitch.module.css";

var styles = Schedule_HeaderSwitchModuleCss;

var day$p = Utils.Translations.tr("js.schedule.v2.buttons.day");

var week$p = Utils.Translations.tr("js.schedule.v2.buttons.week");

function Schedule_HeaderSwitch(Props) {
  var view = Props.view;
  var wire = AppContext.useWire();
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var setPeriod = Schedule_State.useSetPeriod();
  var mode = view.NAME === "Calendar" ? "Calendar" : "Timeline";
  var openCalendarWeek = React.useCallback((function (_event) {
          navigate(undefined, {
                NAME: "Schedule",
                VAL: {
                  NAME: "Calendar",
                  VAL: "Week"
                }
              });
          setPeriod([
                Locale.T.Schedule.startOfCurrentWeek(wire),
                Locale.T.Schedule.endOfCurrentWeek(wire)
              ]);
        }), []);
  var openTimelineDay = React.useCallback((function (_event) {
          navigate(undefined, {
                NAME: "Schedule",
                VAL: {
                  NAME: "Timeline",
                  VAL: "Day"
                }
              });
          setPeriod([
                Locale.T.Schedule.startOfCurrentDay(wire),
                Locale.T.Schedule.endOfCurrentDay(wire)
              ]);
        }), []);
  var openTimelineWeek = React.useCallback((function (_event) {
          navigate(undefined, {
                NAME: "Schedule",
                VAL: {
                  NAME: "Timeline",
                  VAL: "Week"
                }
              });
          setPeriod([
                Locale.T.Schedule.startOfCurrentWeek(wire),
                Locale.T.Schedule.endOfCurrentWeek(wire)
              ]);
        }), []);
  var modes = React.createElement(Schedule_ButtonGroup.make, {
        className: styles.modes,
        children: null
      }, React.createElement(Schedule_Button.make, {
            onClick: openTimelineDay,
            disabled: mode === "Timeline",
            children: React.createElement("span", {
                  className: styles.iconTimeline
                })
          }), React.createElement(Schedule_Button.make, {
            onClick: openCalendarWeek,
            disabled: mode === "Calendar",
            children: React.createElement("span", {
                  className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "calendar-alt"), styles.iconCalendar)
                })
          }));
  var views = mode === "Calendar" ? null : React.createElement(Schedule_ButtonGroup.make, {
          className: styles.views,
          children: null
        }, React.createElement(Schedule_Button.make, {
              onClick: openTimelineDay,
              disabled: Caml_obj.equal(view, {
                    NAME: "Timeline",
                    VAL: "Day"
                  }),
              children: day$p(ctx)
            }), React.createElement(Schedule_Button.make, {
              onClick: openTimelineWeek,
              disabled: Caml_obj.equal(view, {
                    NAME: "Timeline",
                    VAL: "Week"
                  }),
              children: week$p(ctx)
            }));
  return React.createElement(React.Fragment, undefined, modes, views);
}

var make = Schedule_HeaderSwitch;

export {
  make ,
}
/* styles Not a pure module */
