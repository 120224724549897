

import * as Fun from "../../libraries/Fun.mjs";
import * as Url from "../../bindings/url/Url.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as DomUtils from "../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Reports_API from "./Reports_API.mjs";
import * as Reports_DatesFilter from "./Reports_DatesFilter.mjs";
import * as Reports_FilterComponent from "./Reports_FilterComponent.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var finishedAtFilter$p = Utils.Translations.tr("js.reports.workers.finished_at_filter");

var templatesPlaceholder$p = Utils.Translations.tr("js.reports.workers.templates_filter.placeholder");

var templatesFilterSelected$p = Utils.Translations.txr("js.reports.workers.templates_filter.selected");

var templatesFilterEmpty$p = Utils.Translations.tr("js.reports.workers.templates_filter.empty");

var templatesDescription$p = Utils.Translations.tr("js.reports.jobs.templates_filter.description");

var jobTypesPlaceholder$p = Utils.Translations.tr("js.reports.workers.job_types_filter.placeholder");

var jobTypesSelected$p = Utils.Translations.txr("js.reports.workers.job_types_filter.selected");

var jobTypesEmpty$p = Utils.Translations.tr("js.reports.workers.job_types_filter.empty");

var jobTypesDescription$p = Utils.Translations.tr("js.reports.jobs.job_types_filter.description");

var territoriesPlaceholder$p = Utils.Translations.tr("js.reports.workers.territories_filter.placeholder");

var territoriesSelected$p = Utils.Translations.txr("js.reports.workers.territories_filter.selected");

var territoriesEmpty$p = Utils.Translations.tr("js.reports.workers.territories_filter.empty");

var territoriesDescription$p = Utils.Translations.tr("js.reports.jobs.territories_filter.description");

var Translations = {
  finishedAtFilter$p: finishedAtFilter$p,
  templatesPlaceholder$p: templatesPlaceholder$p,
  templatesFilterSelected$p: templatesFilterSelected$p,
  templatesFilterEmpty$p: templatesFilterEmpty$p,
  templatesDescription$p: templatesDescription$p,
  jobTypesPlaceholder$p: jobTypesPlaceholder$p,
  jobTypesSelected$p: jobTypesSelected$p,
  jobTypesEmpty$p: jobTypesEmpty$p,
  jobTypesDescription$p: jobTypesDescription$p,
  territoriesPlaceholder$p: territoriesPlaceholder$p,
  territoriesSelected$p: territoriesSelected$p,
  territoriesEmpty$p: territoriesEmpty$p,
  territoriesDescription$p: territoriesDescription$p
};

function $$default(wire) {
  return {
          finishedAt: [
            Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: -4
                    })),
            Locale.T.endOf("day", Locale.T.now(wire))
          ],
          templateUuids: [],
          jobTypeUuids: [],
          territoryUuids: []
        };
}

function encode(filters, token) {
  var finishedAt = RCore.$$Option.map(filters.finishedAt, (function (param) {
          return Js_dict.fromArray([
                      [
                        "start",
                        Locale.T.isoDate(param[0])
                      ],
                      [
                        "end",
                        Locale.T.isoDate(param[1])
                      ]
                    ]);
        }));
  return {
          token: token,
          finishedAtFilter: Js_null.fromOption(finishedAt),
          templateUuids: filters.templateUuids.map(Uuid.toString),
          jobTypeUuids: filters.jobTypeUuids.map(Uuid.toString),
          territoryUuids: filters.territoryUuids.map(Uuid.toString)
        };
}

var Filters = {
  $$default: $$default,
  encode: encode
};

var styles = ReportsModuleCss;

function Reports_Workers$FiltersDescription(Props) {
  var ctx = Props.ctx;
  var filters = Props.filters;
  var filtersData = Props.filtersData;
  var renderItem = function (label, selected, options) {
    if (selected.length !== 0) {
      return React.createElement("div", {
                  className: styles.descriptionItem
                }, React.createElement("span", undefined, label), Utils.Translations.joinWithComma(RCore.$$Array.keep(options, (function (t) {
                              return RCore.$$Option.isSome(RCore.$$Array.getBy(selected, (function (u) {
                                                return Uuid.equal(u, t.uuid);
                                              })));
                            })).map(function (t) {
                          return t.name;
                        })));
    } else {
      return null;
    }
  };
  return React.createElement("div", {
              className: styles.filtersDescription
            }, renderItem(templatesDescription$p(ctx), filters.templateUuids, filtersData.templates), renderItem(jobTypesDescription$p(ctx), filters.jobTypeUuids, filtersData.types), renderItem(territoriesDescription$p(ctx), filters.territoryUuids, filtersData.territories));
}

var FiltersDescription = {
  make: Reports_Workers$FiltersDescription
};

function Reports_Workers(Props) {
  var wire = Props.wire;
  var iframeHeight = Props.iframeHeight;
  var token = Props.token;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default(wire);
      });
  var setFilters = match[1];
  var filters = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setFiltersData = match$1[1];
  var filtersData = match$1[0];
  var match$2 = React.useState(function () {
        var match = filters.finishedAt;
        if (match !== undefined) {
          return [
                  Caml_option.some(match[0]),
                  Caml_option.some(match[1])
                ];
        } else {
          return [
                  undefined,
                  undefined
                ];
        }
      });
  var setPeriod = match$2[1];
  var iframeRef = React.useRef(null);
  var setPeriodDate = function (fst, snd) {
    setPeriod(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  finishedAt: [
                    start,
                    finish
                  ],
                  templateUuids: filters.templateUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids
                };
        });
  };
  var postMessage = function (url) {
    var iframe = iframeRef.current;
    if (!(iframe == null)) {
      return DomUtils.$$Window.postMessage(DomUtils.$$Window.contentWindow(iframe), encode(filters, token), Url.toString(url));
    }
    
  };
  var processAPIResponse = function (response) {
    if (response.TAG !== "Ok") {
      return ;
    }
    var data = response._0;
    setFiltersData(function (param) {
          return data;
        });
  };
  React.useEffect((function () {
          Backend.$$finally(Reports_API.Workers.index(wire), processAPIResponse);
        }), []);
  React.useEffect((function () {
          var match = Fun.both(Caml_option.nullable_to_opt(iframeRef.current), ctx.reportsUrl);
          if (match === undefined) {
            return ;
          }
          var url = match[1];
          var iframe = match[0];
          DomUtils.Listeners.onLoad(undefined, iframe, (function (param) {
                  setTimeout((function () {
                          postMessage(url);
                        }), 100);
                }));
          return (function () {
                    DomUtils.Listeners.removeOnLoad(undefined, iframe, (function (param) {
                            postMessage(url);
                          }));
                  });
        }), [filtersData]);
  React.useEffect((function () {
          var url = ctx.reportsUrl;
          if (url !== undefined) {
            postMessage(Caml_option.valFromOption(url));
          }
          
        }), [
        filters,
        filtersData
      ]);
  var setTemplatesFilter = function (templateUuids) {
    setFilters(function (param) {
          return {
                  finishedAt: filters.finishedAt,
                  templateUuids: templateUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids
                };
        });
  };
  var setJobTypesFilter = function (jobTypeUuids) {
    setFilters(function (param) {
          return {
                  finishedAt: filters.finishedAt,
                  templateUuids: filters.templateUuids,
                  jobTypeUuids: jobTypeUuids,
                  territoryUuids: filters.territoryUuids
                };
        });
  };
  var setTerritoriesFilter = function (territoryUuids) {
    setFilters(function (param) {
          return {
                  finishedAt: filters.finishedAt,
                  templateUuids: filters.templateUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: territoryUuids
                };
        });
  };
  var match$3 = Fun.both(wire.ctx.reportsUrl, filtersData);
  if (match$3 === undefined) {
    return null;
  }
  var filtersData$1 = match$3[1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.filtersContainer
                }, React.createElement("div", {
                      className: styles.dateFiltersContainer
                    }, React.createElement(Reports_DatesFilter.make, {
                          wire: wire,
                          period: match$2[0],
                          setPeriodDate: setPeriodDate,
                          label: React.createElement("span", undefined, finishedAtFilter$p(ctx))
                        })), React.createElement("div", {
                      className: styles.row
                    }, React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.templates,
                          selected: filters.templateUuids,
                          setFilter: setTemplatesFilter,
                          label: templatesPlaceholder$p(ctx),
                          labelSelected: templatesFilterSelected$p({
                                count: filters.templateUuids.length
                              }, ctx),
                          labelEmpty: templatesFilterEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.types,
                          selected: filters.jobTypeUuids,
                          setFilter: setJobTypesFilter,
                          label: jobTypesPlaceholder$p(ctx),
                          labelSelected: jobTypesSelected$p({
                                count: filters.jobTypeUuids.length
                              }, ctx),
                          labelEmpty: jobTypesEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.territories,
                          selected: filters.territoryUuids,
                          setFilter: setTerritoriesFilter,
                          label: territoriesPlaceholder$p(ctx),
                          labelSelected: territoriesSelected$p({
                                count: filters.territoryUuids.length
                              }, ctx),
                          labelEmpty: territoriesEmpty$p(ctx)
                        })), React.createElement(Reports_Workers$FiltersDescription, {
                      ctx: ctx,
                      filters: filters,
                      filtersData: filtersData$1
                    })), React.createElement("iframe", {
                  ref: iframeRef,
                  style: {
                    border: "none",
                    minHeight: "100vh",
                    overflow: "hidden"
                  },
                  height: iframeHeight,
                  scrolling: "no",
                  src: Reports_API.buildReportsUrl(ctx, Url.toString(match$3[0]) + "workers"),
                  width: "100%"
                }));
}

var make = Reports_Workers;

export {
  Translations ,
  Filters ,
  styles ,
  FiltersDescription ,
  make ,
}
/* finishedAtFilter' Not a pure module */
