

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Spinner from "../../common/Spinner/Spinner.mjs";
import * as ApiRoute from "../../../types/ApiRoute.mjs";
import * as AddButton from "../../common/AddButton/AddButton.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as ClientForms_API from "./ClientForms_API.mjs";
import * as JobSettingsTabs from "../JobSettingsTabs.mjs";
import * as ClientForms_Show from "./ClientForms_Show.mjs";
import * as ClientForms_Types from "./ClientForms_Types.mjs";
import * as JobSettingsHeader from "../JobSettingsHeader.mjs";
import * as TableModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Table.module.css";
import * as ClientFormsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/client_forms/ClientForms.module.css";

var addNewForm$p = Utils.Translations.tr("js.admin.client_forms.add_form");

var name$p = Utils.Translations.tr("js.admin.client_forms.name");

var defaultForm$p = Utils.Translations.tr("js.admin.client_forms.default_form");

var description$p = Utils.Translations.th("js.admin.client_forms.description");

var clientForms$p = Utils.Translations.tr("js.admin.client_forms.client_forms");

var learnMore$p = Utils.Translations.tr("js.admin.client_forms.learn_more");

var file$p = Utils.Translations.tr("js.admin.client_forms.file");

var availability$p = Utils.Translations.tr("js.admin.client_forms.availability_period");

var styles = ClientFormsModuleCss;

var tableStyles = TableModuleCss;

function ClientForms_Index$Download(Props) {
  var filename = Props.filename;
  var uuid = Props.uuid;
  return React.createElement("td", {
              className: styles.filename
            }, React.createElement("a", {
                  download: "download",
                  href: ApiRoute.path({
                        NAME: "API",
                        VAL: {
                          NAME: "ClientForm",
                          VAL: {
                            NAME: "Download",
                            VAL: uuid
                          }
                        }
                      }),
                  target: "_blank"
                }, filename));
}

function ClientForms_Index$FormRow(Props) {
  var form = Props.form;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var label = function (availabilityPeriodMin) {
    if (availabilityPeriodMin < 1440) {
      var hours = availabilityPeriodMin / 60 | 0;
      return Utils.Translations.txr("js.admin.client_forms.hours")({
                  count: hours
                }, ctx);
    } else {
      var days = availabilityPeriodMin / 1440 | 0;
      return Utils.Translations.txr("js.admin.client_forms.days")({
                  count: days
                }, ctx);
    }
  };
  if (form.TAG === "Default") {
    var uuid = form.uuid;
    return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                        wire: wire,
                        route: {
                          NAME: "ClientForm",
                          VAL: {
                            NAME: "Edit",
                            VAL: uuid
                          }
                        },
                        children: defaultForm$p(ctx)
                      })), React.createElement(ClientForms_Index$Download, {
                    filename: form.filename,
                    uuid: uuid
                  }), React.createElement("td", undefined, label(form.availabilityPeriodMin)));
  }
  var uuid$1 = form.uuid;
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "ClientForm",
                        VAL: {
                          NAME: "Edit",
                          VAL: uuid$1
                        }
                      },
                      children: form.name
                    })), React.createElement(ClientForms_Index$Download, {
                  filename: form.filename,
                  uuid: uuid$1
                }), React.createElement("td", undefined, label(form.availabilityPeriodMin)));
}

function ClientForms_Index$List(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var reload = function () {
    var loaded = function (response) {
      if (response.TAG === "Ok") {
        var forms = response._0.forms;
        return setState(function (param) {
                    return ClientForms_Types.Index.State.make(forms);
                  });
      }
      console.log(response._0);
    };
    Backend.$$finally(ClientForms_API.Index.index(wire), loaded);
  };
  React.useEffect((function () {
          reload();
        }), []);
  var match$1 = ctx.localizator.lang;
  var href = match$1 === "ru" ? "https://planado.freshdesk.com/a/solutions/articles/13000086753?lang=ru-RU" : "https://planado.freshdesk.com/a/solutions/articles/13000086753?lang=en";
  var tmp;
  var exit = 0;
  if (typeof mode === "object" || mode === "New") {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(ClientForms_Show.make, {
          mode: mode,
          onAction: reload
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "panel-body__hl"
                }, clientForms$p(ctx)), React.createElement("div", {
                  className: styles.description
                }, description$p(ctx), El.space, React.createElement("a", {
                      href: href,
                      target: "blank"
                    }, learnMore$p(ctx), El.space, React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "external-link-alt")
                        }))), tmp, state !== undefined ? React.createElement("table", {
                    className: El.Cn.concat(tableStyles.table, styles.forms)
                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", {
                                className: styles.filename
                              }, file$p(ctx)), React.createElement("th", undefined, availability$p(ctx)))), React.createElement("tbody", undefined, state.forms.map(function (form) {
                            return React.createElement(ClientForms_Index$FormRow, {
                                        form: form,
                                        key: Uuid.toString(ClientForms_Types.Index.Form.uuid(form))
                                      });
                          }))) : React.createElement(Spinner.make, {}), React.createElement(AddButton.make, {
                  wire: wire,
                  route: {
                    NAME: "ClientForm",
                    VAL: "New"
                  },
                  children: addNewForm$p(ctx)
                }));
}

function ClientForms_Index(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("form", {
                      className: "form-horizontal"
                    }, React.createElement(JobSettingsHeader.make, {
                          ctx: wire.ctx
                        }), React.createElement("div", {
                          className: "settings-page row"
                        }, React.createElement(JobSettingsTabs.make, {
                              wire: wire,
                              tab: "ClientForms"
                            }), React.createElement("div", {
                              className: "tab-content"
                            }, React.createElement("div", {
                                  className: "tab-pane active"
                                }, React.createElement("div", {
                                      className: "panel"
                                    }, React.createElement("div", {
                                          className: "panel-body"
                                        }, React.createElement(ClientForms_Index$List, {
                                              wire: wire,
                                              mode: mode
                                            })))))))));
}

var make = ClientForms_Index;

export {
  make ,
}
/* addNewForm' Not a pure module */
