

import * as Types from "../../../types/Types.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Types_FeatureFlag from "../../../types/Types_FeatureFlag.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

function encode(request) {
  return Json_Encode$JsonCombinators.object([[
                "requested_features",
                Json_Encode$JsonCombinators.array(Types_FeatureFlag.encode)(request.requestedFeatures)
              ]]);
}

function decode(field) {
  return {
          finishAt: field.required("finishAt", Types.Time.decode)
        };
}

function fromString(reason) {
  switch (reason) {
    case "buff_already_exists" :
        return "BuffAlreadyExists";
    case "buff_requested_too_soon" :
        return "BuffRequestedTooSoon";
    default:
      return ;
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString);

function decode$2(field) {
  return {
          reason: field.required("rejectedReason", decode$1)
        };
}

var decode$3 = JsonDecode.object(function (field) {
      var match = field.required("status", JsonDecode.string);
      switch (match) {
        case "approved" :
            return {
                    TAG: "Granted",
                    _0: decode(field)
                  };
        case "rejected" :
            return {
                    TAG: "Rejected",
                    _0: decode$2(field)
                  };
        default:
          return JsonDecode.cannotDecode("invalid status");
      }
    });

function post(wire, features) {
  var request = {
    requestedFeatures: features
  };
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "FeatureRequests"
                }, {
                  NAME: "Json",
                  VAL: encode(request)
                }), decode$3);
}

var Create_Response = {
  Granted: {},
  Rejected: {
    Reason: {}
  }
};

var Create = {
  $$Response: Create_Response,
  post: post
};

export {
  Create ,
}
/* decode Not a pure module */
