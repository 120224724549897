

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Link from "../../../common/Link/Link.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as ApiRoute from "../../../../types/ApiRoute.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as Bitrix_API from "./Bitrix_API.mjs";
import * as Bitrix_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/integrations/bitrix24/Bitrix_Index.module.css";

var styles = Bitrix_IndexModuleCss;

var addSettings$p = Utils.Translations.t("js.admin.bitrix24.add_settings");

var list$p = Utils.Translations.tr("js.admin.bitrix24.list");

var integrations$p = Utils.Translations.tr("js.titles.integrations.index");

function Bitrix_Index(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        return false;
      });
  var setLoaded = match[1];
  var match$1 = React.useState(function () {
        return [];
      });
  var setSettings = match$1[1];
  var responseToLoad = function (data) {
    if (data.TAG === "Ok") {
      var settings = data._0;
      setSettings(function (param) {
            return settings;
          });
      return setLoaded(function (param) {
                  return true;
                });
    }
    console.log(data._0);
  };
  React.useEffect((function () {
          Backend.$$finally(Bitrix_API.Show.index(wire), responseToLoad);
        }), []);
  return React.createElement(Optional.make, {
              show: match[0],
              children: React.createElement("div", {
                    className: "form"
                  }, React.createElement("section", {
                        className: styles.form
                      }, React.createElement("div", {
                            className: styles.formContainer
                          }, React.createElement("h2", {
                                className: styles.caption
                              }, React.createElement("span", undefined, list$p(wire.ctx))), React.createElement("div", {
                                className: styles.settings
                              }, match$1[0].map(function (s) {
                                    return React.createElement("div", {
                                                key: Uuid.toString(s.uuid),
                                                className: styles.domain
                                              }, React.createElement("a", {
                                                    href: ApiRoute.path({
                                                          NAME: "API",
                                                          VAL: {
                                                            NAME: "Bitrix24",
                                                            VAL: {
                                                              NAME: "Show",
                                                              VAL: s.bitrixTenantUid
                                                            }
                                                          }
                                                        })
                                                  }, s.domain));
                                  }))), React.createElement("div", {
                            className: styles.footer
                          }, React.createElement(Link.make, {
                                wire: wire,
                                route: {
                                  NAME: "Integrations",
                                  VAL: "Index"
                                },
                                className: "btn " + styles.back,
                                children: React.createElement(React.Fragment, undefined, React.createElement("i", {
                                          className: Icon.style(undefined, undefined, undefined, undefined, "chevron-left")
                                        }), El.space, integrations$p(wire.ctx))
                              }), React.createElement(Link.make, {
                                wire: wire,
                                route: {
                                  NAME: "Integrations",
                                  VAL: {
                                    NAME: "Bitrix24",
                                    VAL: "New"
                                  }
                                },
                                className: styles.addSettings + " btn-link add-link",
                                children: null
                              }, React.createElement("i", {
                                    className: Icon.style(undefined, undefined, undefined, undefined, "plus")
                                  }), " " + addSettings$p(wire.ctx)))))
            });
}

var make = Bitrix_Index;

export {
  make ,
}
/* styles Not a pure module */
