

import * as Fun from "../../libraries/Fun.mjs";
import * as Url from "../../bindings/url/Url.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Radio from "../inputs/Radio.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "../inputs/Checkbox.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Reports_API from "./Reports_API.mjs";
import * as Reports_DatesFilter from "./Reports_DatesFilter.mjs";
import * as Reports_FilterComponent from "./Reports_FilterComponent.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var statusPlaceholder$p = Utils.Translations.tr("js.reports.jobs.status_filter.placeholder");

var statusFilterSelected$p = Utils.Translations.txr("js.reports.jobs.status_filter.selected");

var statusFilterEmpty$p = Utils.Translations.tr("js.reports.jobs.status_filter.empty");

var statusDescription$p = Utils.Translations.tr("js.reports.jobs.status_filter.description");

var templatesPlaceholder$p = Utils.Translations.tr("js.reports.jobs.templates_filter.placeholder");

var templatesFilterSelected$p = Utils.Translations.txr("js.reports.jobs.templates_filter.selected");

var templatesFilterEmpty$p = Utils.Translations.tr("js.reports.jobs.templates_filter.empty");

var templatesDescription$p = Utils.Translations.tr("js.reports.jobs.templates_filter.description");

var resolutionsPlaceholder$p = Utils.Translations.tr("js.reports.jobs.resolutions_filter.placeholder");

var resolutionsFilterSelected$p = Utils.Translations.txr("js.reports.jobs.resolutions_filter.selected");

var resolutionsFilterEmpty$p = Utils.Translations.tr("js.reports.jobs.resolutions_filter.empty");

var resolutionsDescription$p = Utils.Translations.tr("js.reports.jobs.resolutions_filter.description");

var jobTypesPlaceholder$p = Utils.Translations.tr("js.reports.jobs.job_types_filter.placeholder");

var jobTypesSelected$p = Utils.Translations.txr("js.reports.jobs.job_types_filter.selected");

var jobTypesEmpty$p = Utils.Translations.tr("js.reports.jobs.job_types_filter.empty");

var jobTypesDescription$p = Utils.Translations.tr("js.reports.jobs.job_types_filter.description");

var territoriesPlaceholder$p = Utils.Translations.tr("js.reports.jobs.territories_filter.placeholder");

var territoriesSelected$p = Utils.Translations.txr("js.reports.jobs.territories_filter.selected");

var territoriesEmpty$p = Utils.Translations.tr("js.reports.jobs.territories_filter.empty");

var territoriesDescription$p = Utils.Translations.tr("js.reports.jobs.territories_filter.description");

var assigneesPlaceholder$p = Utils.Translations.tr("js.reports.jobs.assignees_filter.placeholder");

var assigneesFilterSelected$p = Utils.Translations.txr("js.reports.jobs.assignees_filter.selected");

var assigneesFilterEmpty$p = Utils.Translations.tr("js.reports.jobs.assignees_filter.empty");

var assigneesDescription$p = Utils.Translations.tr("js.reports.jobs.assignees_filter.description");

var showPreviousPeriod$p = Utils.Translations.t("js.reports.jobs.show_previous_period");

var posted$p = Utils.Translations.t("js.reports.jobs.status_filter.posted");

var scheduled$p = Utils.Translations.t("js.reports.jobs.status_filter.scheduled");

var published$p = Utils.Translations.t("js.reports.jobs.status_filter.published");

var enRoute$p = Utils.Translations.t("js.reports.jobs.status_filter.en_route");

var started$p = Utils.Translations.t("js.reports.jobs.status_filter.started");

var suspended$p = Utils.Translations.t("js.reports.jobs.status_filter.suspended");

var canceled$p = Utils.Translations.t("js.reports.jobs.status_filter.canceled");

var finished$p = Utils.Translations.t("js.reports.jobs.status_filter.finished");

var datesFilterTitle$p = Utils.Translations.tr("js.reports.jobs.dates_filter.title");

var finishedAtFilter$p = Utils.Translations.t("js.reports.jobs.dates_filter.finished_at");

var createdAtFilter$p = Utils.Translations.t("js.reports.jobs.dates_filter.created_at");

var Translations = {
  statusPlaceholder$p: statusPlaceholder$p,
  statusFilterSelected$p: statusFilterSelected$p,
  statusFilterEmpty$p: statusFilterEmpty$p,
  statusDescription$p: statusDescription$p,
  templatesPlaceholder$p: templatesPlaceholder$p,
  templatesFilterSelected$p: templatesFilterSelected$p,
  templatesFilterEmpty$p: templatesFilterEmpty$p,
  templatesDescription$p: templatesDescription$p,
  resolutionsPlaceholder$p: resolutionsPlaceholder$p,
  resolutionsFilterSelected$p: resolutionsFilterSelected$p,
  resolutionsFilterEmpty$p: resolutionsFilterEmpty$p,
  resolutionsDescription$p: resolutionsDescription$p,
  jobTypesPlaceholder$p: jobTypesPlaceholder$p,
  jobTypesSelected$p: jobTypesSelected$p,
  jobTypesEmpty$p: jobTypesEmpty$p,
  jobTypesDescription$p: jobTypesDescription$p,
  territoriesPlaceholder$p: territoriesPlaceholder$p,
  territoriesSelected$p: territoriesSelected$p,
  territoriesEmpty$p: territoriesEmpty$p,
  territoriesDescription$p: territoriesDescription$p,
  assigneesPlaceholder$p: assigneesPlaceholder$p,
  assigneesFilterSelected$p: assigneesFilterSelected$p,
  assigneesFilterEmpty$p: assigneesFilterEmpty$p,
  assigneesDescription$p: assigneesDescription$p,
  showPreviousPeriod$p: showPreviousPeriod$p,
  posted$p: posted$p,
  scheduled$p: scheduled$p,
  published$p: published$p,
  enRoute$p: enRoute$p,
  started$p: started$p,
  suspended$p: suspended$p,
  canceled$p: canceled$p,
  finished$p: finished$p,
  datesFilterTitle$p: datesFilterTitle$p,
  finishedAtFilter$p: finishedAtFilter$p,
  createdAtFilter$p: createdAtFilter$p
};

function toString(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Canceled" :
        return "canceled";
    case "Finished" :
        return "finished";
    
  }
}

function fromString(status) {
  switch (status) {
    case "canceled" :
        return "Canceled";
    case "enRoute" :
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

var Status = {
  toString: toString,
  fromString: fromString
};

function defaultPeriod(wire) {
  return [
          Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                    NAME: "weeks",
                    VAL: -4
                  })),
          Locale.T.endOf("day", Locale.T.now(wire))
        ];
}

var DatesFilter = {
  defaultPeriod: defaultPeriod
};

function $$default(wire) {
  return {
          statuses: [],
          datesFilter: {
            TAG: "CreatedAt",
            _0: defaultPeriod(wire)
          },
          showPreviousPeriod: false,
          templateUuids: [],
          resolutionUuids: [],
          jobTypeUuids: [],
          territoryUuids: [],
          assigneeUuids: []
        };
}

function isFinishedFilter(filter) {
  var match = filter.datesFilter;
  if (match.TAG === "FinishedAt") {
    return true;
  } else {
    return false;
  }
}

function isCreatedFilter(filter) {
  var match = filter.datesFilter;
  if (match.TAG === "FinishedAt") {
    return false;
  } else {
    return true;
  }
}

function statusOptions(ctx) {
  return [
          {
            uuid: Uuid.fromString("posted"),
            name: posted$p(ctx)
          },
          {
            uuid: Uuid.fromString("scheduled"),
            name: scheduled$p(ctx)
          },
          {
            uuid: Uuid.fromString("published"),
            name: published$p(ctx)
          },
          {
            uuid: Uuid.fromString("en_route"),
            name: enRoute$p(ctx)
          },
          {
            uuid: Uuid.fromString("started"),
            name: started$p(ctx)
          },
          {
            uuid: Uuid.fromString("suspended"),
            name: suspended$p(ctx)
          },
          {
            uuid: Uuid.fromString("canceled"),
            name: canceled$p(ctx)
          },
          {
            uuid: Uuid.fromString("finished"),
            name: finished$p(ctx)
          }
        ];
}

function selectedStatuses(statuses) {
  return statuses.map(toString).map(Uuid.fromString);
}

function filterOptions(entities) {
  return entities.map(function (t) {
              return {
                      value: Uuid.toString(t.uuid),
                      label: t.name
                    };
            });
}

function encode(filters, token) {
  var statuses = filters.statuses.map(toString).map(function (s) {
        return s;
      });
  var match = filters.datesFilter;
  var finishedAt;
  if (match.TAG === "FinishedAt") {
    var match$1 = match._0;
    finishedAt = match$1 !== undefined ? Js_dict.fromArray([
            [
              "start",
              Locale.T.isoDate(match$1[0])
            ],
            [
              "end",
              Locale.T.isoDate(match$1[1])
            ]
          ]) : null;
  } else {
    finishedAt = null;
  }
  var match$2 = filters.datesFilter;
  var createdAt;
  if (match$2.TAG === "FinishedAt") {
    createdAt = null;
  } else {
    var match$3 = match$2._0;
    createdAt = match$3 !== undefined ? Js_dict.fromArray([
            [
              "start",
              Locale.T.isoDate(match$3[0])
            ],
            [
              "end",
              Locale.T.isoDate(match$3[1])
            ]
          ]) : null;
  }
  return {
          token: token,
          statusFilter: statuses,
          finishedAtFilter: finishedAt,
          createdAtFilter: createdAt,
          showPreviousPeriod: filters.showPreviousPeriod,
          templateUuids: filters.templateUuids.map(Uuid.toString),
          resolutionUuids: filters.resolutionUuids.map(Uuid.toString),
          jobTypeUuids: filters.jobTypeUuids.map(Uuid.toString),
          territoryUuids: filters.territoryUuids.map(Uuid.toString),
          assigneeUuids: filters.assigneeUuids.map(Uuid.toString)
        };
}

var Filters = {
  Status: Status,
  DatesFilter: DatesFilter,
  $$default: $$default,
  isFinishedFilter: isFinishedFilter,
  isCreatedFilter: isCreatedFilter,
  statusOptions: statusOptions,
  selectedStatuses: selectedStatuses,
  filterOptions: filterOptions,
  encode: encode
};

var styles = ReportsModuleCss;

function Reports_Jobs$FiltersDescription(Props) {
  var ctx = Props.ctx;
  var filters = Props.filters;
  var filtersData = Props.filtersData;
  var renderItem = function (label, selected, options) {
    if (selected.length !== 0) {
      return React.createElement("div", {
                  className: styles.descriptionItem
                }, React.createElement("span", undefined, label), Utils.Translations.joinWithComma(RCore.$$Array.keep(options, (function (t) {
                              return RCore.$$Option.isSome(RCore.$$Array.getBy(selected, (function (u) {
                                                return Uuid.equal(u, t.uuid);
                                              })));
                            })).map(function (t) {
                          return t.name;
                        })));
    } else {
      return null;
    }
  };
  return React.createElement("div", {
              className: styles.filtersDescription
            }, renderItem(statusDescription$p(ctx), selectedStatuses(filters.statuses), statusOptions(ctx)), renderItem(assigneesDescription$p(ctx), filters.assigneeUuids, filtersData.assignees), renderItem(templatesDescription$p(ctx), filters.templateUuids, filtersData.templates), renderItem(resolutionsDescription$p(ctx), filters.resolutionUuids, filtersData.resolutions), renderItem(jobTypesDescription$p(ctx), filters.jobTypeUuids, filtersData.types), renderItem(territoriesDescription$p(ctx), filters.territoryUuids, filtersData.territories));
}

var FiltersDescription = {
  make: Reports_Jobs$FiltersDescription
};

function Reports_Jobs(Props) {
  var wire = Props.wire;
  var iframeHeight = Props.iframeHeight;
  var token = Props.token;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default(wire);
      });
  var setFilters = match[1];
  var filters = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setFiltersData = match$1[1];
  var filtersData = match$1[0];
  var match$2 = React.useState(function () {
        var match = defaultPeriod(wire);
        return [
                Caml_option.some(match[0]),
                Caml_option.some(match[1])
              ];
      });
  var setCurrentDates = match$2[1];
  var currentDates = match$2[0];
  var iframeRef = React.useRef(null);
  var setStatusFilter = function (statuses) {
    setFilters(function (param) {
          return {
                  statuses: RCore.$$Array.keepSome(statuses.map(Uuid.toString).map(fromString)),
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setTemplatesFilter = function (templateUuids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setResolutionsFilter = function (resolutionUuids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setJobTypesFilter = function (jobTypeUuids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setTerritoriesFilter = function (territoryUuids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setAssigneesFilter = function (assigneeUuids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: assigneeUuids
                };
        });
  };
  var setShowPreviousPeriod = function (showPreviousPeriod) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setFinishedAt = function (fst, snd) {
    setCurrentDates(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "FinishedAt",
                    _0: [
                      start,
                      finish
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var setCreatedAt = function (fst, snd) {
    setCurrentDates(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "CreatedAt",
                    _0: [
                      start,
                      finish
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var pickCreatedFilter = function () {
    var match = defaultPeriod(wire);
    var snd = match[1];
    var fst = match[0];
    setCurrentDates(function (param) {
          return [
                  Caml_option.some(fst),
                  Caml_option.some(snd)
                ];
        });
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "CreatedAt",
                    _0: [
                      fst,
                      snd
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var pickFinsihedFilter = function () {
    var match = defaultPeriod(wire);
    var snd = match[1];
    var fst = match[0];
    setCurrentDates(function (param) {
          return [
                  Caml_option.some(fst),
                  Caml_option.some(snd)
                ];
        });
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "FinishedAt",
                    _0: [
                      fst,
                      snd
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids,
                  territoryUuids: filters.territoryUuids,
                  assigneeUuids: filters.assigneeUuids
                };
        });
  };
  var renderDatesFilters = function () {
    var match = filters.datesFilter;
    if (match.TAG === "FinishedAt") {
      return React.createElement(Reports_DatesFilter.make, {
                  wire: wire,
                  period: currentDates,
                  setPeriodDate: setFinishedAt
                });
    } else {
      return React.createElement(Reports_DatesFilter.make, {
                  wire: wire,
                  period: currentDates,
                  setPeriodDate: setCreatedAt
                });
    }
  };
  var postMessage = function (url, token) {
    var iframe = iframeRef.current;
    if (!(iframe == null)) {
      return DomUtils.$$Window.postMessage(DomUtils.$$Window.contentWindow(iframe), encode(filters, token), Url.toString(url));
    }
    
  };
  var processAPIResponse = function (response) {
    if (response.TAG !== "Ok") {
      return ;
    }
    var data = response._0;
    setFiltersData(function (param) {
          return data;
        });
  };
  React.useEffect((function () {
          Backend.$$finally(Reports_API.Jobs.index(wire), processAPIResponse);
        }), []);
  React.useEffect((function () {
          var match = Fun.both(Caml_option.nullable_to_opt(iframeRef.current), ctx.reportsUrl);
          if (match === undefined) {
            return ;
          }
          var url = match[1];
          var iframe = match[0];
          DomUtils.Listeners.onLoad(undefined, iframe, (function (param) {
                  setTimeout((function () {
                          postMessage(url, token);
                        }), 100);
                }));
          return (function () {
                    DomUtils.Listeners.removeOnLoad(undefined, iframe, (function (param) {
                            postMessage(url, token);
                          }));
                  });
        }), [filtersData]);
  React.useEffect((function () {
          var url = ctx.reportsUrl;
          if (url !== undefined) {
            postMessage(Caml_option.valFromOption(url), token);
          }
          
        }), [
        filters,
        filtersData
      ]);
  var match$3 = Fun.both(wire.ctx.reportsUrl, filtersData);
  if (match$3 === undefined) {
    return null;
  }
  var filtersData$1 = match$3[1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.filtersContainer
                }, React.createElement("div", {
                      className: styles.dateFiltersContainer
                    }, React.createElement("div", {
                          className: styles.selectDateMode
                        }, React.createElement("span", undefined, datesFilterTitle$p(ctx)), React.createElement(Radio.make, {
                              onChange: (function (param) {
                                  pickCreatedFilter();
                                }),
                              checked: isCreatedFilter(filters),
                              labelText: createdAtFilter$p(ctx)
                            }), React.createElement(Radio.make, {
                              onChange: (function (param) {
                                  pickFinsihedFilter();
                                }),
                              checked: isFinishedFilter(filters),
                              labelText: finishedAtFilter$p(ctx)
                            })), React.createElement("div", {
                          className: styles.dateFilter
                        }, renderDatesFilters(), React.createElement(Checkbox.make, {
                              onToggle: setShowPreviousPeriod,
                              checked: filters.showPreviousPeriod,
                              label: showPreviousPeriod$p(ctx),
                              wrapperClasses: Js_dict.fromList({
                                    hd: [
                                      styles.showPreviousPeriod,
                                      true
                                    ],
                                    tl: /* [] */0
                                  })
                            }))), React.createElement("div", {
                      className: styles.row
                    }, React.createElement(Reports_FilterComponent.make, {
                          options: statusOptions(ctx),
                          selected: selectedStatuses(filters.statuses),
                          setFilter: setStatusFilter,
                          label: statusPlaceholder$p(ctx),
                          labelSelected: statusFilterSelected$p({
                                count: filters.statuses.length
                              }, ctx),
                          labelEmpty: statusFilterEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.assignees,
                          selected: filters.assigneeUuids,
                          setFilter: setAssigneesFilter,
                          label: assigneesPlaceholder$p(ctx),
                          labelSelected: assigneesFilterSelected$p({
                                count: filters.assigneeUuids.length
                              }, ctx),
                          labelEmpty: assigneesFilterEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.templates,
                          selected: filters.templateUuids,
                          setFilter: setTemplatesFilter,
                          label: templatesPlaceholder$p(ctx),
                          labelSelected: templatesFilterSelected$p({
                                count: filters.templateUuids.length
                              }, ctx),
                          labelEmpty: templatesFilterEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.resolutions,
                          selected: filters.resolutionUuids,
                          setFilter: setResolutionsFilter,
                          label: resolutionsPlaceholder$p(ctx),
                          labelSelected: resolutionsFilterSelected$p({
                                count: filters.resolutionUuids.length
                              }, ctx),
                          labelEmpty: resolutionsFilterEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.types,
                          selected: filters.jobTypeUuids,
                          setFilter: setJobTypesFilter,
                          label: jobTypesPlaceholder$p(ctx),
                          labelSelected: jobTypesSelected$p({
                                count: filters.jobTypeUuids.length
                              }, ctx),
                          labelEmpty: jobTypesEmpty$p(ctx)
                        }), React.createElement(Reports_FilterComponent.make, {
                          options: filtersData$1.territories,
                          selected: filters.territoryUuids,
                          setFilter: setTerritoriesFilter,
                          label: territoriesPlaceholder$p(ctx),
                          labelSelected: territoriesSelected$p({
                                count: filters.territoryUuids.length
                              }, ctx),
                          labelEmpty: territoriesEmpty$p(ctx)
                        })), React.createElement(Reports_Jobs$FiltersDescription, {
                      ctx: ctx,
                      filters: filters,
                      filtersData: filtersData$1
                    })), React.createElement("iframe", {
                  ref: iframeRef,
                  id: "reports-iframe",
                  style: {
                    border: "none",
                    minHeight: "100vh",
                    overflow: "hidden"
                  },
                  height: iframeHeight,
                  scrolling: "no",
                  src: Reports_API.buildReportsUrl(ctx, Url.toString(match$3[0])),
                  width: "100%"
                }));
}

var make = Reports_Jobs;

export {
  Translations ,
  Filters ,
  styles ,
  FiltersDescription ,
  make ,
}
/* statusPlaceholder' Not a pure module */
