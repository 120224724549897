// extracted by mini-css-extract-plugin
export var apiForm = "api-form-_QEmbjHve4JTny1SBamI";
export var apiFormDesc = "api-form-desc-gYsLcyGNJjbd7QwROwHm";
export var apiFormHeadline = "api-form-headline-QeOFTRLP5Ve0Dd4M28tg";
export var apiKey = "api-key-cJ953fkTM3aFH_Jtdij0";
export var copyKey = "copy-key-hjthvsgXrKfERHL4oGV3";
export var generateKey = "generate-key-KnJwmYYdc5BLbrKT1tG6";
export var generatedKey = "generated-key-itCE4aIwEgke2xDP7EYy";
export var keyContainer = "key-container-JsvGg7NsSpxiF5HmwiQ_";
export var keyWarning = "key-warning-ihiT26ZWAjIZBzebeu6R";
export var locked = "locked-kRZFAyGhhjV2HzeUO91R";
export var teaser = "teaser-EJ6PEPHGjbq5tufxllRT";
export var warning = "warning-ePoHYM3oTBFgT27mLhnI";
export var webhooks = "webhooks-BopFYuW4uVWldccleP4w";
export var webhooksWarning = "webhooks-warning-AfOO5e_AomkNQej5XfJM";