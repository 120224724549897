

import * as Icon from "../../../../Icon.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var styles = BillingModuleCss;

var chargeError$p = Utils.Translations.txr("js.billing.card.charge_error");

var canEditCard$p = Utils.Translations.tr("js.billing.wizard.can_edit_card");

function icon(cardName) {
  var match = cardName.toLowerCase();
  var iconName;
  switch (match) {
    case "jcb" :
        iconName = "cc-jcb";
        break;
    case "mastercard" :
        iconName = "cc-mastercard";
        break;
    case "visa" :
        iconName = "cc-visa";
        break;
    default:
      iconName = "credit-card";
  }
  return React.createElement("i", {
              className: Icon.style("2x", undefined, undefined, undefined, iconName)
            });
}

function Billing_Components_Card(Props) {
  var ctx = Props.ctx;
  var children = Props.children;
  var param = Props.details;
  var chargeError = Props.chargeError;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.cardInfo
                }, icon(param.cardType), React.createElement("span", {
                      className: styles.pan
                    }, param.pan), React.createElement("span", {
                      className: styles.expiresAt
                    }, param.expiresAt), RCore.$$Option.getOr(children, null)), chargeError !== undefined ? React.createElement("div", {
                    className: styles.chargeError + " alert alert-danger"
                  }, chargeError$p({
                        message: chargeError
                      }, ctx), React.createElement("br", undefined), canEditCard$p(ctx)) : null);
}

var Types;

var make = Billing_Components_Card;

export {
  Types ,
  make ,
}
/* styles Not a pure module */
