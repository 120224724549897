

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as React from "react";
import * as CollapseModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Collapse/Collapse.module.css";

var styles = CollapseModuleCss;

function Collapse(Props) {
  var label = Props.label;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  return React.createElement("div", {
              className: styles.collapse
            }, React.createElement("button", {
                  className: styles.btnCollapse,
                  onClick: (function (evt) {
                      evt.preventDefault();
                      setIsOpen(function (param) {
                            return !isOpen;
                          });
                    })
                }, label, React.createElement("span", {
                      className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, isOpen ? "caret-up" : "caret-down"), styles.icon)
                    })), isOpen ? children : null);
}

var make = Collapse;

export {
  make ,
}
/* styles Not a pure module */
