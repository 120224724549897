

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../common/Optional.mjs";
import * as ActivateFieldUserModuleCss from "/home/runner/work/planado/planado/client/rescript/components/activate_field_user/ActivateFieldUser.module.css";

var maxlength$p = Utils.Translations.txr("js.activate_field_user.maxlength");

var styles = ActivateFieldUserModuleCss;

function ActivateFieldUserInput(Props) {
  var ctx = Props.ctx;
  var id = Props.id;
  var label = Props.label;
  var type_ = Props.type_;
  var value = Props.value;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var revealPasswordBtnOpt = Props.revealPasswordBtn;
  var onClickRevealPasswordOpt = Props.onClickRevealPassword;
  var disabledOpt = Props.disabled;
  var errors = Props.errors;
  var limitOpt = Props.limit;
  var autoFocusOpt = Props.autoFocus;
  var revealPasswordBtn = revealPasswordBtnOpt !== undefined ? revealPasswordBtnOpt : false;
  var onClickRevealPassword = onClickRevealPasswordOpt !== undefined ? onClickRevealPasswordOpt : (function () {
        
      });
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var limit = limitOpt !== undefined ? limitOpt : 100;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var inputRef = React.useRef(null);
  var displayError = function (errors) {
    if (errors.length > 0) {
      return React.createElement("p", {
                  className: styles.error
                }, Utils.Translations.joinWithComma(errors));
    } else {
      return null;
    }
  };
  return React.createElement("div", {
              className: styles.inputContainer
            }, React.createElement("label", {
                  htmlFor: id
                }, label), React.createElement("input", {
                  ref: inputRef,
                  className: styles.input,
                  id: id,
                  autoFocus: autoFocus,
                  disabled: disabled,
                  type: type_,
                  value: value,
                  onBlur: (function (evt) {
                      evt.preventDefault();
                      onBlur();
                    }),
                  onChange: (function (evt) {
                      evt.preventDefault();
                      var newValue = evt.target.value;
                      if (newValue.length > limit) {
                        return ;
                      } else {
                        return onChange(newValue);
                      }
                    })
                }), React.createElement(Optional.make, {
                  show: value.length === limit,
                  children: React.createElement("p", {
                        className: styles.limit
                      }, maxlength$p({
                            max: limit
                          }, ctx))
                }), displayError(errors), React.createElement(Optional.make, {
                  show: revealPasswordBtn,
                  children: React.createElement("div", {
                        className: styles.iconEye,
                        onClick: (function (param) {
                            onClickRevealPassword();
                            El.focusRef(true, inputRef);
                          })
                      }, React.createElement("span", {
                            className: Icon.style(undefined, undefined, undefined, undefined, type_ === "text" ? "eye" : "eye-slash") + styles.icon
                          }))
                }));
}

var make = ActivateFieldUserInput;

export {
  make ,
}
/* maxlength' Not a pure module */
