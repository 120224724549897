

import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as Panel from "../../common/Panel/Panel.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CategoryItem from "../../common/Catalog/CategoryItem.mjs";
import * as Template_Service from "./Template_Service.mjs";
import * as Template_Catalog_Types from "./Template_Catalog_Types.mjs";
import * as Template_Services_Select from "./Template_Services_Select.mjs";
import * as Template_ServicesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template_catalog/Template_Services.module.css";

var empty$p = Utils.Translations.tr("js.templates.services.empty");

var addButton$p = Utils.Translations.tr("js.templates.services.add_button");

var styles = Template_ServicesModuleCss;

function Template_Catalog(Props) {
  var availableItems = Props.availableItems;
  var selectedItems = Props.selectedItems;
  var setItems = Props.setItems;
  var match = AppContext.useWire();
  var subscriptions = match.subscriptions;
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return false;
      });
  var setOpened = match$1[1];
  var popoverRef = React.useRef(null);
  var buttonId = Hooks.useId(undefined);
  var onOpen = function (e) {
    e.preventDefault();
    setOpened(function (prev) {
          return !prev;
        });
  };
  var onSelect = function (item) {
    setOpened(function (param) {
          return false;
        });
    setItems(Belt_Array.concatMany([
              [item],
              selectedItems
            ]));
  };
  var removeSelected = function (item) {
    setItems(RCore.$$Array.keep(selectedItems, (function (i) {
                return !Template_Catalog_Types.ListItem.equal(i, item);
              })));
  };
  var click = function ($$event) {
    var target = $$event.target;
    $$event.stopPropagation();
    var popover = popoverRef.current;
    if (!(popover == null) && !DomUtils.contains(popover, target)) {
      return setOpened(function (param) {
                  return false;
                });
    }
    
  };
  var onUpdate = function (service) {
    setItems(selectedItems.map(function (i) {
              if (i.TAG === "Category" || !Uuid.equal(i._0.uuid, service.uuid)) {
                return i;
              } else {
                return {
                        TAG: "Service",
                        _0: service
                      };
              }
            }));
  };
  var filteredAvailable = RCore.$$Array.keep(availableItems, (function (i) {
          return !selectedItems.some(function (s) {
                      return Template_Catalog_Types.ListItem.equal(i, s);
                    });
        }));
  React.useEffect((function () {
          return subscriptions.click.subscribe(click);
        }), []);
  return React.createElement("div", {
              className: styles.container
            }, selectedItems.length === 0 ? React.createElement("div", {
                    className: styles.empty
                  }, empty$p(ctx)) : selectedItems.map(function (i) {
                    if (i.TAG === "Category") {
                      var category = i._0;
                      return React.createElement(CategoryItem.make, {
                                  ctx: ctx,
                                  category: category,
                                  onRemove: (function () {
                                      removeSelected(i);
                                    }),
                                  key: Uuid.toString(category.uuid)
                                });
                    }
                    var service = i._0;
                    return React.createElement(Template_Service.make, {
                                ctx: ctx,
                                service: service,
                                onRemove: (function () {
                                    removeSelected(i);
                                  }),
                                onUpdate: onUpdate,
                                key: Uuid.toString(service.uuid)
                              });
                  }), React.createElement(Optional.make, {
                  show: filteredAvailable.length > 0,
                  children: null
                }, React.createElement("button", {
                      className: styles.addButton,
                      id: buttonId,
                      onClick: onOpen
                    }, React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "plus")
                        }), addButton$p(ctx), React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "caret-down")
                        })), React.createElement(Panel.make, {
                      footer: match.footer,
                      show: match$1[0],
                      domId: buttonId,
                      className: styles.panel,
                      children: React.createElement("div", {
                            ref: popoverRef
                          }, React.createElement(Template_Services_Select.make, {
                                ctx: ctx,
                                onSelect: onSelect,
                                availableItems: filteredAvailable
                              }))
                    })));
}

function $$default(param) {
  var setCategories = param.setCategories;
  var setServices = param.setServices;
  var selectedServiceCategories = param.selectedServiceCategories;
  var selectedServices = param.selectedServices;
  var setItems = function (items) {
    var match = RCore.$$Array.reduce(items, [
          [],
          []
        ], (function (param, item) {
            var categories = param[1];
            var services = param[0];
            if (item.TAG === "Category") {
              return [
                      services,
                      Belt_Array.concatMany([
                            categories,
                            [{
                                uuid: item._0.uuid
                              }]
                          ])
                    ];
            }
            var match = item._0;
            return [
                    Belt_Array.concatMany([
                          services,
                          [{
                              uuid: match.uuid,
                              preOrdered: match.preOrdered,
                              required: match.required
                            }]
                        ]),
                    categories
                  ];
          }));
    setServices(match[0]);
    setCategories(match[1]);
  };
  var availableItems = Belt_Array.concatMany([
        param.availableServices.map(Template_Catalog_Types.ListItem.fromJsService),
        param.availableServiceCategories.map(Template_Catalog_Types.ListItem.fromJsCategory)
      ]);
  var selectedItems = Belt_Array.concatMany([
        RCore.$$Array.filterMap(availableItems, (function (i) {
                var s = RCore.$$Array.getBy(selectedServices, (function (s) {
                        return Uuid.equal(Template_Catalog_Types.ListItem.uuid(i), s.uuid);
                      }));
                if (s === undefined) {
                  return ;
                }
                if (i.TAG === "Category") {
                  return ;
                }
                var s$1 = i._0;
                return {
                        TAG: "Service",
                        _0: {
                          uuid: s$1.uuid,
                          name: s$1.name,
                          description: s$1.description,
                          priceDefault: s$1.priceDefault,
                          currency: s$1.currency,
                          category: s$1.category,
                          preOrdered: s.preOrdered,
                          required: s.required,
                          archived: s$1.archived
                        }
                      };
              })),
        RCore.$$Array.keep(availableItems, (function (i) {
                return selectedServiceCategories.some(function (s) {
                            return Uuid.equal(Template_Catalog_Types.ListItem.uuid(i), s.uuid);
                          });
              }))
      ]);
  return Template_Catalog({
              selectedItems: selectedItems,
              setItems: setItems,
              availableItems: availableItems
            });
}

var make = Template_Catalog;

export {
  make ,
  $$default as default,
}
/* empty' Not a pure module */
