

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";
import * as AddressInput_ResetButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/address_input/reset_button/AddressInput_ResetButton.module.css";

var styles = AddressInput_ResetButtonModuleCss;

var InputType = {};

function AddressInput_ResetButton$ResetButton(Props) {
  var onReset = Props.onReset;
  var inputType = Props.inputType;
  var disabled = Props.disabled;
  var class_;
  class_ = inputType === "Regular" ? "" : styles.clearConstraint;
  return React.createElement(Optional.make, {
              show: !disabled,
              children: React.createElement("span", {
                    className: El.Cn.concat(El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "times-circle"), "pd-form-control-reset"), class_),
                    onClick: onReset
                  })
            });
}

var ResetButton = {
  InputType: InputType,
  make: AddressInput_ResetButton$ResetButton
};

export {
  ResetButton ,
}
/* styles Not a pure module */
