

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../../common/Button.mjs";
import * as Select from "../../../inputs/Select.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Popover from "../../../common/Popover/Popover.mjs";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as FormInput from "../../../inputs/FormInput.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_Lib from "../Catalog_Lib.mjs";
import * as Catalog_Form from "../Catalog_Form.mjs";
import * as Catalog_Types from "../Catalog_Types.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as Categories_State from "./Categories_State.mjs";
import * as TableModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Table.module.css";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var addCategory$p = Utils.Translations.tr("js.admin.catalog.add_category");

var noCategories$p = Utils.Translations.tr("js.admin.catalog.no_categories");

var newCategory$p = Utils.Translations.tr("js.admin.catalog.new_category");

var name$p = Utils.Translations.t("js.admin.catalog.form.name");

var description$p = Utils.Translations.t("js.admin.catalog.form.description");

var rootCategory$p = Utils.Translations.t("js.admin.catalog.form.root_category");

var externalId$p = Utils.Translations.t("js.admin.catalog.form.external_id");

var category$p = Utils.Translations.tr("js.admin.catalog.form.category");

var archived$p = Utils.Translations.t("js.admin.catalog.form.archived_category");

var noResults$p = Utils.Translations.t("js.components.select.no_results");

var deleteCategoryConfirm$p = Utils.Translations.tr("js.admin.catalog.form.delete_category_confirm");

var deleteWithDescendants$p = Utils.Translations.tx("js.admin.catalog.form.delete_with_descendants");

var deleteWithProducts$p = Utils.Translations.tx("js.admin.catalog.form.delete_with_products");

var deleteWithServices$p = Utils.Translations.tx("js.admin.catalog.form.delete_with_services");

var deleteWithTemplates$p = Utils.Translations.tx("js.admin.catalog.form.delete_with_templates");

var close$p = Utils.Translations.tr("common.close");

var styles = Catalog_IndexModuleCss;

var tableStyles = TableModuleCss;

var empty_descendants = [];

var empty_products = [];

var empty_services = [];

var empty_templates = [];

var empty = {
  descendants: empty_descendants,
  products: empty_products,
  services: empty_services,
  templates: empty_templates
};

function Categories_List$Form(Props) {
  var mode = Props.mode;
  var category = Props.category;
  var type_ = Props.type_;
  var onClose = Props.onClose;
  var categories = Props.categories;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var hasFlag = Context_Types.Features.hasFlag("catalog", ctx.features);
  var match = React.useReducer(Categories_State.reducer, Categories_State.fromProps(category, type_));
  var send = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowConfirmation = match$1[1];
  var match$2 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$2[1];
  var errors = match$2[0];
  var match$3 = React.useState(function () {
        return empty;
      });
  var setRelatedEntities = match$3[1];
  var relatedEntities = match$3[0];
  var match$4 = Categories_State.form(state);
  var removeButtonId = Hooks.useId(undefined);
  var removeButton = React.useRef(null);
  var title = React.useMemo((function () {
          var name = category !== undefined ? category.name : newCategory$p(ctx);
          return React.createElement(Modal.Header.make, {
                      children: name
                    });
        }), [RCore.$$Option.isSome(category)]);
  var onErrors = function (errors) {
    setErrors(function (param) {
          return errors;
        });
  };
  var parentSelectValue = Catalog_Lib.parentSelectValue(match$4.parentUuid, categories);
  var parentOptions = categories.map(function (c) {
        return Select.$$Option.make(c.name, Catalog_Types.uuidToId(c.uuid));
      });
  var onSubmit = function (e) {
    e.preventDefault();
    if (category !== undefined) {
      return onUpdate(category.uuid, Categories_State.form(state), onClose, onErrors);
    } else {
      return onCreate(Categories_State.form(state), onClose, onErrors);
    }
  };
  var onError = function (descendants, products, services, templates) {
    setRelatedEntities(function (param) {
          return {
                  descendants: descendants,
                  products: products,
                  services: services,
                  templates: templates
                };
        });
    setShowConfirmation(function (param) {
          return true;
        });
  };
  var onRemoveClick = function () {
    if (category !== undefined) {
      return onDelete(category.uuid, onClose, onError);
    }
    
  };
  var items = relatedEntities.descendants;
  var descendants = items.length !== 0 ? deleteWithDescendants$p({
          names: items.map(function (i) {
                return i.name;
              })
        }, ctx) : undefined;
  var items$1 = relatedEntities.products;
  var products = items$1.length !== 0 ? deleteWithProducts$p({
          names: items$1.map(function (i) {
                return i.name;
              })
        }, ctx) : undefined;
  var items$2 = relatedEntities.services;
  var services = items$2.length !== 0 ? deleteWithServices$p({
          names: items$2.map(function (i) {
                return i.name;
              })
        }, ctx) : undefined;
  var items$3 = relatedEntities.templates;
  var templates = items$3.length !== 0 ? deleteWithTemplates$p({
          names: items$3.map(function (i) {
                return i.name;
              })
        }, ctx) : undefined;
  var renderErrors = Utils.Translations.joinWithComma(RCore.$$Array.filterMap([
            templates,
            descendants,
            products,
            services
          ], Fun.id));
  var tmp = {
    _type: "input",
    value: RCore.$$Option.getOr(match$4.name, ""),
    onValueChange: (function (name) {
        send({
              TAG: "SetName",
              _0: Fun.optString(name)
            });
      }),
    disabled: !hasFlag,
    labelText: name$p(ctx),
    autoFocus: true
  };
  var tmp$1 = Backend_Errors.get(errors, "name");
  if (tmp$1 !== undefined) {
    tmp.errors = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    _type: "input",
    value: RCore.$$Option.getOr(match$4.description, ""),
    onValueChange: (function (description) {
        send({
              TAG: "SetDescription",
              _0: Fun.optString(description)
            });
      }),
    disabled: !hasFlag,
    labelText: description$p(ctx)
  };
  var tmp$3 = Backend_Errors.get(errors, "description");
  if (tmp$3 !== undefined) {
    tmp$2.errors = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    placeholder: rootCategory$p(ctx),
    onChange: (function (value) {
        send({
              TAG: "SetParent",
              _0: RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), Catalog_Types.idToUuid)
            });
      }),
    value: parentSelectValue,
    disabled: !hasFlag,
    options: parentOptions,
    className: Js_null.fromOption(styles.select),
    noResultsText: noResults$p(ctx),
    up: true
  };
  var tmp$5 = Backend_Errors.get(errors, "parentUuid");
  if (tmp$5 !== undefined) {
    tmp$4.errors = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = {
    _type: "input",
    value: RCore.$$Option.getOr(match$4.externalId, ""),
    onValueChange: (function (externalId) {
        send({
              TAG: "SetExternalId",
              _0: Fun.optString(externalId)
            });
      }),
    disabled: !hasFlag,
    labelText: externalId$p(ctx)
  };
  var tmp$7 = Backend_Errors.get(errors, "externalId");
  if (tmp$7 !== undefined) {
    tmp$6.errors = Caml_option.valFromOption(tmp$7);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Catalog_Form.make, {
                  mode: mode,
                  onClose: onClose,
                  title: title,
                  onSubmit: onSubmit,
                  locked: false,
                  onRemove: onRemoveClick,
                  removeButtonId: removeButtonId,
                  removeButton: removeButton,
                  children: null
                }, React.createElement(FormInput.make, tmp), React.createElement(FormInput.make, tmp$2), React.createElement("div", {
                      className: styles.inputWrapper
                    }, React.createElement("p", {
                          className: styles.inputLabel
                        }, category$p(ctx)), React.createElement(Select.make, tmp$4)), React.createElement(FormInput.make, tmp$6), React.createElement(Optional.make, {
                      show: mode !== "New",
                      children: React.createElement("div", {
                            className: styles.inputWrapper
                          }, React.createElement(Checkbox.make, {
                                onToggle: (function (value) {
                                    send({
                                          TAG: "SetArchived",
                                          _0: value
                                        });
                                  }),
                                checked: match$4.archived,
                                label: archived$p(ctx),
                                disabled: !hasFlag
                              }))
                    })), typeof mode === "object" ? React.createElement(Popover.make, {
                    footer: wire.footer,
                    show: match$1[0],
                    buttonId: removeButtonId,
                    position: "right",
                    children: React.createElement("div", undefined, React.createElement("h5", undefined, Utils.Translations.concatEl([
                                  deleteCategoryConfirm$p(ctx),
                                  El.space,
                                  renderErrors
                                ], ctx)), React.createElement("footer", undefined, React.createElement(Button.make, {
                                  onClick: (function (param) {
                                      setShowConfirmation(function (param) {
                                            return false;
                                          });
                                    }),
                                  children: close$p(ctx)
                                })))
                  }) : null);
}

function Categories_List$Header(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, category$p(ctx)), React.createElement("th", undefined, description$p(ctx)), React.createElement("th", undefined, externalId$p(ctx))));
}

function Categories_List$CategoryRow(Props) {
  var category = Props.category;
  var onCategoryOpen = Props.onCategoryOpen;
  var allCategories = Props.allCategories;
  var onClick = function (e) {
    e.preventDefault();
    onCategoryOpen(category.uuid);
  };
  return React.createElement("tr", {
              className: styles.tableRow,
              onClick: onClick
            }, React.createElement("td", undefined, React.createElement("div", {
                      className: El.Cn.concat(styles.categoryName, styles.link)
                    }, category.name)), React.createElement("td", undefined, RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap(category.parentUuid, (function (uuid) {
                                return RCore.$$Array.getBy(allCategories, (function (a) {
                                              return Uuid.equal(a.uuid, uuid);
                                            }));
                              })), (function (c) {
                            return c.name;
                          })), "—")), React.createElement("td", undefined, RCore.$$Option.getOr(category.description, "")), React.createElement("td", undefined, RCore.$$Option.getOr(category.externalId, "")));
}

function Categories_List$Table(Props) {
  var ctx = Props.ctx;
  var categories = Props.categories;
  var onCategoryOpen = Props.onCategoryOpen;
  var rootCategories = React.useMemo((function () {
          return RCore.$$Array.keep(categories, (function (c) {
                        return RCore.$$Option.isNone(c.parentUuid);
                      }));
        }), [categories]);
  var renderChildren = function (parent) {
    var children = RCore.$$Array.keep(categories, (function (c) {
            var uuid = c.parentUuid;
            if (uuid !== undefined) {
              return Uuid.equal(Caml_option.valFromOption(uuid), parent.uuid);
            } else {
              return false;
            }
          }));
    return children.map(function (category) {
                return React.createElement(React.Fragment, undefined, React.createElement(Categories_List$CategoryRow, {
                                category: category,
                                onCategoryOpen: onCategoryOpen,
                                allCategories: categories,
                                key: Uuid.toString(category.uuid)
                              }), renderChildren(category));
              });
  };
  return React.createElement("table", {
              className: El.Cn.concat(tableStyles.table, styles.dataTable)
            }, React.createElement(Categories_List$Header, {
                  ctx: ctx
                }), React.createElement("tbody", undefined, rootCategories.map(function (category) {
                      return React.createElement(React.Fragment, undefined, React.createElement(Categories_List$CategoryRow, {
                                      category: category,
                                      onCategoryOpen: onCategoryOpen,
                                      allCategories: [],
                                      key: Uuid.toString(category.uuid)
                                    }), renderChildren(category));
                    })));
}

function Categories_List(Props) {
  var param = Props.wire;
  var categories = Props.categories;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var type_ = Props.type_;
  var ctx = param.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setAddNew = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setOpenedCategory = match$1[1];
  var openedCategory = match$1[0];
  var onAddNew = function (e) {
    e.preventDefault();
    setAddNew(function (param) {
          return true;
        });
  };
  var onClose = function () {
    setAddNew(function (param) {
          return false;
        });
  };
  var onCategoryOpen = function (uuid) {
    setOpenedCategory(function (param) {
          return Caml_option.some(uuid);
        });
  };
  var onCategoryClose = function () {
    setOpenedCategory(function (param) {
          
        });
  };
  var tmp;
  if (openedCategory !== undefined) {
    var uuid = Caml_option.valFromOption(openedCategory);
    tmp = React.createElement(Categories_List$Form, {
          mode: {
            NAME: "Edit",
            VAL: uuid
          },
          category: RCore.$$Array.getBy(categories, (function (c) {
                  return Uuid.equal(c.uuid, uuid);
                })),
          type_: type_,
          onClose: onCategoryClose,
          categories: RCore.$$Array.keep(categories, (function (c) {
                  return !Uuid.equal(c.uuid, uuid);
                })),
          onCreate: onCreate,
          onUpdate: onUpdate,
          onDelete: onDelete
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "panel-body"
            }, React.createElement(Optional.make, {
                  show: Context_Types.Features.hasFlag("catalog", ctx.features),
                  children: React.createElement("a", {
                        className: "add-link btn btn-outline btn-32px",
                        onClick: onAddNew
                      }, React.createElement("i", {
                            className: Icon.style(undefined, undefined, undefined, undefined, "plus")
                          }), El.space, addCategory$p(ctx))
                }), categories.length === 0 ? React.createElement("div", {
                    className: styles.noEntriesContainer
                  }, React.createElement("span", {
                        className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "tags"), styles.tags)
                      }), React.createElement("p", {
                        className: styles.noEntries
                      }, noCategories$p(ctx))) : React.createElement(Categories_List$Table, {
                    ctx: ctx,
                    categories: categories,
                    onCategoryOpen: onCategoryOpen
                  }), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement(Categories_List$Form, {
                        mode: "New",
                        category: undefined,
                        type_: type_,
                        onClose: onClose,
                        categories: categories,
                        onCreate: onCreate,
                        onUpdate: onUpdate,
                        onDelete: onDelete
                      })
                }), tmp);
}

var API;

var make = Categories_List;

export {
  API ,
  make ,
}
/* addCategory' Not a pure module */
