

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../../components/common/Link/Link.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Collapse from "../../../components/common/Collapse/Collapse.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as ModalQuery from "../../../types/ModalQuery.mjs";
import * as Pages_Territories_RemoveWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/territories/remove_warning/Pages_Territories_RemoveWarning.module.css";

var styles = Pages_Territories_RemoveWarningModuleCss;

var territoryInUse$p = Utils.Translations.tr("js.admin.territories.modal.territory_in_use");

var deleteQuestion$p = Utils.Translations.tr("js.admin.territories.modal.delete_question");

var showWhereTerritoryUsed$p = Utils.Translations.t("js.admin.territories.modal.show_where_territory_used");

var usedInWorkers$p = Utils.Translations.txh("js.admin.territories.modal.used_in_workers");

var usedInTeams$p = Utils.Translations.txh("js.admin.territories.modal.used_in_teams");

var usedInClients$p = Utils.Translations.txh("js.admin.territories.modal.used_in_clients");

var usedInSites$p = Utils.Translations.txh("js.admin.territories.modal.used_in_sites");

var usedInJobs$p = Utils.Translations.txh("js.admin.territories.modal.used_in_jobs");

var usedInTemplates$p = Utils.Translations.txh("js.admin.territories.modal.used_in_templates");

var usedInMany$p = Utils.Translations.tr("js.admin.territories.modal.used_in_many");

var usedInNotMany$p = Utils.Translations.tr("js.admin.territories.modal.used_in_not_many");

var delete$p = Utils.Translations.tr("js.admin.territories.modal.delete");

var cancel$p = Utils.Translations.tr("js.admin.territories.modal.cancel");

var jobNo$p = Utils.Translations.tr("common.job_serial_no");

function Pages_Territories_RemoveWarning$Entity(Props) {
  var wire = Props.wire;
  var entity = Props.entity;
  var index = Props.index;
  var eType = Props.eType;
  var match;
  switch (eType) {
    case "Worker" :
        match = [
          {
            NAME: "User",
            VAL: {
              NAME: "Custom",
              VAL: {
                NAME: "Edit",
                VAL: entity.uuid
              }
            }
          },
          /* [] */0
        ];
        break;
    case "Team" :
        match = [
          {
            NAME: "Team",
            VAL: {
              NAME: "Edit",
              VAL: entity.uuid
            }
          },
          /* [] */0
        ];
        break;
    case "Client" :
        match = [
          {
            NAME: "Client",
            VAL: "Index"
          },
          {
            hd: {
              NAME: "Client",
              VAL: {
                NAME: "Edit",
                VAL: entity.uuid
              }
            },
            tl: /* [] */0
          }
        ];
        break;
    case "Site" :
        match = [
          {
            NAME: "Site",
            VAL: "Index"
          },
          {
            hd: {
              NAME: "Site",
              VAL: {
                NAME: "Edit",
                VAL: entity.uuid
              }
            },
            tl: /* [] */0
          }
        ];
        break;
    case "Job" :
        match = [
          {
            NAME: "Job",
            VAL: "Index"
          },
          {
            hd: {
              NAME: "Job",
              VAL: {
                NAME: "Edit",
                VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, entity.uuid)
              }
            },
            tl: /* [] */0
          }
        ];
        break;
    case "Template" :
        match = [
          {
            NAME: "Template",
            VAL: {
              NAME: "Edit",
              VAL: entity.uuid
            }
          },
          /* [] */0
        ];
        break;
    
  }
  var tmp;
  tmp = eType === "Job" ? jobNo$p(wire.ctx) : null;
  return React.createElement(Optional.make, {
              show: index < 10,
              children: React.createElement("li", undefined, React.createElement(Link.make, {
                        wire: wire,
                        route: match[0],
                        modals: match[1],
                        target: "blank",
                        children: React.createElement(React.Fragment, undefined, tmp, entity.name, React.createElement("span", {
                                  className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "external-link-alt"), styles.icon)
                                }))
                      }))
            });
}

function Pages_Territories_RemoveWarning$Entities(Props) {
  var wire = Props.wire;
  var usedBy = Props.usedBy;
  var eType = Props.eType;
  var ctx = wire.ctx;
  var count = usedBy.length;
  var text;
  switch (eType) {
    case "Worker" :
        text = usedInWorkers$p({
              count: count
            }, ctx);
        break;
    case "Team" :
        text = usedInTeams$p({
              count: count
            }, ctx);
        break;
    case "Client" :
        text = usedInClients$p({
              count: count
            }, ctx);
        break;
    case "Site" :
        text = usedInSites$p({
              count: count
            }, ctx);
        break;
    case "Job" :
        text = usedInJobs$p({
              count: count
            }, ctx);
        break;
    case "Template" :
        text = usedInTemplates$p({
              count: count
            }, ctx);
        break;
    
  }
  return React.createElement(Optional.make, {
              show: count > 0,
              children: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, text, count > 10 ? usedInMany$p(ctx) : usedInNotMany$p(ctx)), React.createElement("ul", undefined, usedBy.map(function (u, i) {
                            return React.createElement(Pages_Territories_RemoveWarning$Entity, {
                                        wire: wire,
                                        entity: u,
                                        index: i,
                                        eType: eType,
                                        key: i.toString()
                                      });
                          })))
            });
}

function Pages_Territories_RemoveWarning$TerritoryModal(Props) {
  var wire = Props.wire;
  var onClose = Props.onClose;
  var onAccept = Props.onAccept;
  var uuid = Props.uuid;
  var users = Props.users;
  var teams = Props.teams;
  var clients = Props.clients;
  var sites = Props.sites;
  var jobs = Props.jobs;
  var templates = Props.templates;
  return React.createElement("div", {
              className: styles.modalContainer
            }, React.createElement("div", {
                  className: styles.modal
                }, React.createElement("div", {
                      className: styles.modalHeader
                    }, React.createElement("div", undefined, territoryInUse$p(wire.ctx)), React.createElement("div", undefined, React.createElement("a", {
                              className: "close",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  onClose();
                                })
                            }, React.createElement("span", {
                                  className: Icon.style(undefined, undefined, undefined, undefined, "times")
                                })))), React.createElement("div", {
                      className: styles.modalBody
                    }, React.createElement("p", undefined, deleteQuestion$p(wire.ctx)), React.createElement(Collapse.make, {
                          label: showWhereTerritoryUsed$p(wire.ctx),
                          children: React.createElement("div", undefined, React.createElement(Pages_Territories_RemoveWarning$Entities, {
                                    wire: wire,
                                    usedBy: users,
                                    eType: "Worker"
                                  }), React.createElement(Pages_Territories_RemoveWarning$Entities, {
                                    wire: wire,
                                    usedBy: templates,
                                    eType: "Template"
                                  }), React.createElement(Pages_Territories_RemoveWarning$Entities, {
                                    wire: wire,
                                    usedBy: teams,
                                    eType: "Team"
                                  }), React.createElement(Pages_Territories_RemoveWarning$Entities, {
                                    wire: wire,
                                    usedBy: clients,
                                    eType: "Client"
                                  }), React.createElement(Pages_Territories_RemoveWarning$Entities, {
                                    wire: wire,
                                    usedBy: sites,
                                    eType: "Site"
                                  }), React.createElement(Pages_Territories_RemoveWarning$Entities, {
                                    wire: wire,
                                    usedBy: jobs,
                                    eType: "Job"
                                  }))
                        })), React.createElement("div", {
                      className: styles.modalFooter
                    }, React.createElement("button", {
                          className: "btn btn-default",
                          disabled: false,
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              onClose();
                            })
                        }, cancel$p(wire.ctx)), React.createElement("button", {
                          className: "btn btn-danger",
                          disabled: false,
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              onAccept(uuid);
                            })
                        }, delete$p(wire.ctx)))));
}

function Pages_Territories_RemoveWarning(Props) {
  var wire = Props.wire;
  var usedBy = Props.usedBy;
  var onClose = Props.onClose;
  var onAccept = Props.onAccept;
  var uuid = Props.uuid;
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              children: React.createElement(Pages_Territories_RemoveWarning$TerritoryModal, {
                    wire: wire,
                    onClose: onClose,
                    onAccept: onAccept,
                    uuid: uuid,
                    users: usedBy.users,
                    teams: usedBy.teams,
                    clients: usedBy.clients,
                    sites: usedBy.sites,
                    jobs: usedBy.jobs,
                    templates: usedBy.templates
                  })
            });
}

var make = Pages_Territories_RemoveWarning;

export {
  make ,
}
/* styles Not a pure module */
