

import * as Case from "../../../utils/Case.mjs";
import * as Wire from "../../../types/Wire.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JobUpload from "./JobUpload.mjs";
import * as JsContext from "../../../libraries/JsContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JobIndexAPI from "./JobIndexAPI.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as JobIndexState from "./JobIndexState.mjs";
import * as JobIndexTypes from "./JobIndexTypes.mjs";
import * as JobViewsIndex from "./views/JobViewsIndex.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as JobIndexFilter from "./job_index_filter/JobIndexFilter.mjs";
import * as JobIndexHeader from "./header/JobIndexHeader.mjs";
import * as JobViewEncoder from "./views/JobViewEncoder.mjs";
import * as JobIndexTableJS from "./JobIndexTableJS.mjs";
import * as Shared_Lib_Reducer from "../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as Jobs_index from "planado/reducers/jobs_index";

function enableOptionalAlwaysOnFields(columns, ctx) {
  return {
          jobFields: Context_Types.Features.hasFlag("catalog", ctx.features) ? Belt_Array.concatMany([
                  columns.jobFields,
                  ["TotalAmount"]
                ]) : columns.jobFields,
          customFields: columns.customFields,
          reportFields: columns.reportFields,
          virtual: columns.virtual,
          catalog: columns.catalog
        };
}

function JobIndex(Props) {
  var wire = Props.wire;
  var reloadBackgroundTasks = Props.reloadBackgroundTasks;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setFetched = match[1];
  var match$1 = Shared_Lib_Reducer.use(JobIndexState.reducer(wire), JobIndexState.initialState);
  var send = match$1[1];
  var state = match$1[0];
  var onJobEdit = function (uuid) {
    Wire.openJob(undefined, undefined, wire, uuid);
  };
  var searchComplete = function (onFail) {
    return function (result) {
      if (result.TAG === "Ok") {
        return onJobEdit(result._0.uuid);
      } else {
        return onFail();
      }
    };
  };
  var onJobSearch = function (input, onFail) {
    Backend.$$finally(JobIndexAPI.Search.search(wire, input), searchComplete(onFail));
  };
  var loaded = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var data$1 = data._0;
    if (data$1 !== undefined) {
      var camelized = Case.camelize(data$1);
      var data$2 = Json_Decode$JsonCombinators.decode(camelized, JobIndexTypes.decode(JsContext.get(wire)));
      if (data$2.TAG === "Ok") {
        var data$3 = data$2._0;
        var oldViews = RCore.$$Array.keep(data$3.views, (function (v) {
                return !v.settings.upToDate;
              }));
        if (oldViews.length > 0) {
          Shared_Lib_IO.unsafeRunAsync(JobIndexAPI.JobViewSettings.update(wire, oldViews), (function (prim) {
                  
                }));
        }
        send({
              TAG: "FetchJobs",
              _0: data$3
            });
        return setFetched(function (param) {
                    return true;
                  });
      }
      console.log(data$2._0);
      return ;
    }
    
  };
  React.useEffect((function () {
          Backend.$$finally(JobIndexAPI.Index.index(wire), loaded);
          return wire.subscriptions.jobs.subscribe(function ($$event) {
                      if (typeof $$event !== "object" || $$event.TAG !== "JobSubmitted") {
                        return ;
                      } else {
                        return send({
                                    TAG: "RefreshJobsWithLog",
                                    _0: $$event._0
                                  });
                      }
                    });
        }), []);
  React.useEffect((function () {
          return wire.subscriptions.backgroundTasks.subscribe(function ($$event) {
                      if ($$event === "FinishedDeleteOperation") {
                        return send("RefreshJobs");
                      } else {
                        return send({
                                    TAG: "SelectView",
                                    _0: RCore.$$Option.getOr(state.activeView, state.views[0].uuid)
                                  });
                      }
                    });
        }), [state.views]);
  var exportJobs = function (exportColumns) {
    Backend.$$finally(JobIndexAPI.Index.$$export(wire, state.currentFilter, state.currentColumns, state.data, state.activeView, exportColumns), reloadBackgroundTasks);
  };
  var createBulkOperation = function (operation) {
    var operationSet = state.operationSet;
    if (operationSet !== undefined) {
      send("CancelOperationPicking");
      return Backend.$$finally(JobIndexAPI.Index.OperationRequest.create(operation, operationSet, state.currentFilter, state.data, wire), reloadBackgroundTasks);
    }
    
  };
  return React.createElement("div", {
              className: "j-wrapper"
            }, match[0] ? React.createElement(React.Fragment, undefined, React.createElement(JobIndexHeader.make, {
                        onJobSearch: onJobSearch,
                        pickingForBulk: state.pickingForBulk,
                        exportJobs: exportJobs,
                        operationSet: state.operationSet,
                        count: state.count,
                        createBulkOperation: createBulkOperation,
                        send: send
                      }), React.createElement(JobViewsIndex.make, {
                        count: state.count,
                        currentColumns: state.currentColumns,
                        customFields: state.data.customFields,
                        reportFields: state.data.reportFields,
                        services: state.data.services,
                        views: state.views,
                        activeView: state.activeView,
                        viewsSaving: false,
                        selectView: (function (uuid) {
                            send({
                                  TAG: "SelectView",
                                  _0: uuid
                                });
                          }),
                        saveViews: (function (views, cb) {
                            send({
                                  TAG: "SaveViews",
                                  _0: views,
                                  _1: cb
                                });
                          }),
                        returnDefaultView: (function () {
                            send("ReturnDefaultView");
                          }),
                        createView: (function (view, cb) {
                            send({
                                  TAG: "CreateView",
                                  _0: view,
                                  _1: cb
                                });
                          }),
                        saveView: (function (uuid, cb) {
                            send({
                                  TAG: "SaveView",
                                  _0: uuid,
                                  _1: cb
                                });
                          }),
                        toggleVirtualColumn: (function (column) {
                            send({
                                  TAG: "ToggleVirtualColumn",
                                  _0: column
                                });
                          }),
                        toggleCustomColumn: (function (column) {
                            send({
                                  TAG: "ToggleCustomColumn",
                                  _0: column
                                });
                          }),
                        toggleReportColumn: (function (column) {
                            send({
                                  TAG: "ToggleReportColumn",
                                  _0: column
                                });
                          }),
                        toggleJobColumn: (function (column) {
                            send({
                                  TAG: "ToggleJobColumn",
                                  _0: column
                                });
                          }),
                        toggleCatalogColumn: (function (column) {
                            send({
                                  TAG: "ToggleCatalogColumn",
                                  _0: column
                                });
                          }),
                        updateServiceList: (function (services) {
                            send({
                                  TAG: "UpdateServiceList",
                                  _0: services
                                });
                          })
                      }), React.createElement("div", {
                        className: "j-container"
                      }, React.createElement(JobIndexFilter.make, {
                            data: state.data,
                            currentFilter: state.currentFilter,
                            activeFilters: state.activeFilters,
                            activeView: state.activeView,
                            filterDisabled: state.jobsRefreshing,
                            dictionaries: state.dictionaries,
                            send: send
                          }), React.createElement(JobIndexTableJS.make, {
                            jobs: Jobs_index.groupJobsByDate(state.jobs, ctx),
                            bounds: state.bounds,
                            count: JobIndexTypes.Count.encode(state.count),
                            loadingMore: state.loadingMore,
                            onJobEdit: onJobEdit,
                            filterByBounds: (function (bounds) {
                                send({
                                      TAG: "FilterByBounds",
                                      _0: bounds
                                    });
                              }),
                            filter: state.currentFilter,
                            columns: JobViewEncoder.encodeColumns(enableOptionalAlwaysOnFields(state.currentColumns, ctx)),
                            customFields: state.data.customFields,
                            reportFields: state.data.reportFields,
                            services: state.data.services,
                            jobsRefreshing: state.jobsRefreshing,
                            numberOfJobsDisplayed: state.jobs.length,
                            isFilterEmpty: Caml_obj.equal(state.currentFilter, JobIndexState.emptyFilter),
                            pickingForBulk: state.pickingForBulk,
                            pickForOperation: (function (uuid) {
                                send({
                                      TAG: "PickUuidForOperation",
                                      _0: uuid
                                    });
                              }),
                            operationSet: Js_null.fromOption(RCore.$$Option.map(state.operationSet, JobIndexTypes.OperationSet.encode(null))),
                            pickFilterOperationSet: (function () {
                                send("PickFilterOperationSet");
                              }),
                            emptyOperationSet: (function () {
                                send("EmptyOperationSet");
                              })
                          })), React.createElement(JobUpload.make, {
                        wire: wire
                      })) : null);
}

var make = JobIndex;

export {
  make ,
}
/* Case Not a pure module */
