

import * as Hooks from "../../../../../libraries/hooks/Hooks.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../../context/Context_Types.mjs";
import * as ColumnsPopover from "./ColumnsPopover.mjs";
import * as ManageColumnsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/columns/ManageColumns.module.css";

var styles = ManageColumnsModuleCss;

function ManageColumns(Props) {
  var currentColumns = Props.currentColumns;
  var customFields = Props.customFields;
  var reportFields = Props.reportFields;
  var services = Props.services;
  var toggleVirtualColumn = Props.toggleVirtualColumn;
  var toggleCustomColumn = Props.toggleCustomColumn;
  var toggleReportColumn = Props.toggleReportColumn;
  var toggleJobColumn = Props.toggleJobColumn;
  var toggleCatalogColumn = Props.toggleCatalogColumn;
  var updateServiceList = Props.updateServiceList;
  var ctx = AppContext.useCtx();
  var match = React.useState(function () {
        return false;
      });
  var setShowPopover = match[1];
  var popover = React.useRef(null);
  Hooks.useOnClickOutside(undefined, popover, (function () {
          setShowPopover(function (param) {
                return false;
              });
        }));
  var openPopver = function (evt) {
    evt.stopPropagation();
    setShowPopover(function (param) {
          return true;
        });
  };
  var className = Context_Types.Features.hasFlag("catalog", ctx.features) ? styles.popoverWithCatalog : styles.popover;
  return React.createElement("div", undefined, React.createElement("button", {
                  className: "JobsViews-columns__btn js-view-elements",
                  type: "button",
                  onClick: openPopver
                }), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement("div", {
                        ref: popover,
                        className: className
                      }, React.createElement(ColumnsPopover.make, {
                            currentColumns: currentColumns,
                            customFields: customFields,
                            reportFields: reportFields,
                            services: services,
                            toggleVirtualColumn: toggleVirtualColumn,
                            toggleCustomColumn: toggleCustomColumn,
                            toggleReportColumn: toggleReportColumn,
                            toggleJobColumn: toggleJobColumn,
                            toggleCatalogColumn: toggleCatalogColumn,
                            updateServiceList: updateServiceList,
                            hidePopover: (function () {
                                setShowPopover(function (param) {
                                      return false;
                                    });
                              })
                          }))
                }));
}

var make = ManageColumns;

export {
  make ,
}
/* styles Not a pure module */
