

import * as Case from "../utils/Case.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../libraries/JsonDecode.mjs";

var features = [
  "amocrm",
  "api",
  "bitrix24",
  "bulkOperations",
  "catalog",
  "clientPortal",
  "clientTracking",
  "createJobsInApp",
  "customFields",
  "linkedJobs",
  "printLayouts",
  "recurringJobs",
  "shifts",
  "signature",
  "sms",
  "smsGateway",
  "teams",
  "territories",
  "tracking",
  "zapier"
];

var flags = [
  "captcha",
  "disablePlanChange",
  "geojson",
  "multipleBitrixSettings",
  "newFields",
  "noDictionaryValuesLimit",
  "oldTeamsUi",
  "scheduleZoom",
  "showOldTimeline",
  "temporaryWorkers",
  "useGallery",
  "webApiSignature",
  "yandexSatelliteView"
];

var all = Belt_Array.concatMany([
      features,
      flags
    ]);

function fromString(s) {
  var match = Case.camelizeString(s);
  switch (match) {
    case "amocrm" :
        return "amocrm";
    case "api" :
        return "api";
    case "audit" :
        return "audit";
    case "bitrix24" :
        return "bitrix24";
    case "bulkOperations" :
        return "bulkOperations";
    case "captcha" :
        return "captcha";
    case "catalog" :
        return "catalog";
    case "clientPortal" :
        return "clientPortal";
    case "clientTracking" :
        return "clientTracking";
    case "createJobsInApp" :
        return "createJobsInApp";
    case "customFields" :
        return "customFields";
    case "disablePlanChange" :
        return "disablePlanChange";
    case "geojson" :
        return "geojson";
    case "linkedJobs" :
        return "linkedJobs";
    case "multipleBitrixSettings" :
        return "multipleBitrixSettings";
    case "newFields" :
        return "newFields";
    case "noDictionaryValuesLimit" :
        return "noDictionaryValuesLimit";
    case "oldTeamsUi" :
        return "oldTeamsUi";
    case "printLayouts" :
        return "printLayouts";
    case "recurringJobs" :
        return "recurringJobs";
    case "scheduleTemplateFilter" :
        return "scheduleTemplateFilter";
    case "scheduleZoom" :
        return "scheduleZoom";
    case "shifts" :
        return "shifts";
    case "showOldTimeline" :
        return "showOldTimeline";
    case "signature" :
        return "signature";
    case "sms" :
        return "sms";
    case "smsGateway" :
        return "smsGateway";
    case "teams" :
        return "teams";
    case "temporaryWorkers" :
        return "temporaryWorkers";
    case "territories" :
        return "territories";
    case "tracking" :
        return "tracking";
    case "useGallery" :
        return "useGallery";
    case "webApiSignature" :
        return "webApiSignature";
    case "yandexSatelliteView" :
        return "yandexSatelliteView";
    case "zapier" :
        return "zapier";
    default:
      return ;
  }
}

var decode = JsonDecode.map(JsonDecode.string, fromString);

function encode(feature) {
  if (feature === "smsGateway") {
    return "sms_gateway";
  } else if (feature === "geojson") {
    return "geojson";
  } else if (feature === "territories") {
    return "territories";
  } else if (feature === "amocrm") {
    return "amocrm";
  } else if (feature === "disablePlanChange") {
    return "disable_plan_change";
  } else if (feature === "oldTeamsUi") {
    return "old_teams_ui";
  } else if (feature === "noDictionaryValuesLimit") {
    return "no_dictionary_values_limit";
  } else if (feature === "catalog") {
    return "catalog";
  } else if (feature === "webApiSignature") {
    return "web_api_signature";
  } else if (feature === "zapier") {
    return "zapier";
  } else if (feature === "showOldTimeline") {
    return "show_old_timeline";
  } else if (feature === "createJobsInApp") {
    return "create_jobs_in_app";
  } else if (feature === "useGallery") {
    return "use_gallery";
  } else if (feature === "tracking") {
    return "tracking";
  } else if (feature === "clientPortal") {
    return "client_portal";
  } else if (feature === "customFields") {
    return "custom_fields";
  } else if (feature === "api") {
    return "api";
  } else if (feature === "sms") {
    return "sms";
  } else if (feature === "multipleBitrixSettings") {
    return "multiple_bitrix_settings";
  } else if (feature === "teams") {
    return "teams";
  } else if (feature === "bulkOperations") {
    return "bulk_operations";
  } else if (feature === "clientTracking") {
    return "client_tracking";
  } else if (feature === "signature") {
    return "signature";
  } else if (feature === "temporaryWorkers") {
    return "temporary_workers";
  } else if (feature === "scheduleZoom") {
    return "schedule_zoom";
  } else if (feature === "shifts") {
    return "shifts";
  } else if (feature === "captcha") {
    return "captcha";
  } else if (feature === "audit") {
    return "audit";
  } else if (feature === "recurringJobs") {
    return "recurring_jobs";
  } else if (feature === "printLayouts") {
    return "print_layouts";
  } else if (feature === "yandexSatelliteView") {
    return "yandex_satellite_view";
  } else if (feature === "scheduleTemplateFilter") {
    return "schedule_template_filter";
  } else if (feature === "newFields") {
    return "new_fields";
  } else if (feature === "bitrix24") {
    return "bitrix24";
  } else {
    return "linked_jobs";
  }
}

export {
  features ,
  flags ,
  all ,
  fromString ,
  decode ,
  encode ,
}
/* all Not a pure module */
