

import * as El from "../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../inputs/Checkbox.mjs";
import * as Optional from "../../../../common/Optional.mjs";
import * as UI_Button from "../../../../../ui/UI_Button.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobViewTypes from "../JobViewTypes.mjs";
import * as Services_API from "../../../../admin/catalog/services/Services_API.mjs";
import * as Shared_Lib_IO from "../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as ServiceColumnsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/columns/ServiceColumns.module.css";

var addService$p = Utils.Translations.tr("js.jobs.index.views.columns.add_service");

var searchPlaceholder$p = Utils.Translations.t("js.jobs.index.views.columns.service_search_placeholder");

function columnTranslation$p(code) {
  return Utils.Translations.t("js.jobs.index.views.columns." + code);
}

var styles = ServiceColumnsModuleCss;

var bounds = Utils.objToJson({
      limit: 5,
      offset: 0
    });

var empty_categories = [];

var empty_services = [];

var empty = {
  categories: empty_categories,
  services: empty_services,
  loading: false
};

var allColumns = [
    "Price",
    "Quantity",
    "Amount"
  ].map(function (column) {
      return {
              column: column,
              code: JobViewTypes.Catalog.Columns.toString(column)
            };
    });

function ServiceColumns$CheckboxGroup(Props) {
  var settings = Props.settings;
  var name = Props.name;
  var toggleColumn = Props.toggleColumn;
  var ctx = Props.ctx;
  return React.createElement("div", {
              className: styles.checkboxGroup
            }, React.createElement(Checkbox.make, {
                  onToggle: (function (param) {
                      toggleColumn({
                            serviceUuid: settings.serviceUuid,
                            columns: []
                          });
                    }),
                  checked: true,
                  label: name
                }), React.createElement("div", {
                  className: styles.checkboxRow
                }, React.createElement("div", {
                      className: styles.checkboxRow
                    }, allColumns.map(function (param) {
                          var code = param.code;
                          var column = param.column;
                          var checked = settings.columns.includes(column);
                          return React.createElement(Checkbox.make, {
                                      onToggle: (function (param) {
                                          toggleColumn({
                                                serviceUuid: settings.serviceUuid,
                                                columns: checked ? settings.columns.filter(function (configured) {
                                                        return configured !== column;
                                                      }) : RCore.$$Array.filterMap(allColumns, (function (item) {
                                                          var toggledColumn = item.column;
                                                          if (toggledColumn === column || settings.columns.includes(toggledColumn)) {
                                                            return toggledColumn;
                                                          }
                                                          
                                                        }))
                                              });
                                        }),
                                      checked: checked,
                                      label: columnTranslation$p(code)(ctx),
                                      key: Uuid.toString(settings.serviceUuid) + code
                                    });
                        }))));
}

function ServiceColumns$AddServiceButton(Props) {
  var onClick = Props.onClick;
  var ctx = Props.ctx;
  return React.createElement(UI_Button.make, {
              flavor: "link",
              className: styles.addButton,
              onClick: (function (e) {
                  e.preventDefault();
                  onClick();
                }),
              children: null
            }, React.createElement(UI_Icon.make, {
                  className: styles.iconAddModel,
                  icon: "plus"
                }), El.space, addService$p(ctx));
}

function ServiceColumns(Props) {
  var columns = Props.columns;
  var services = Props.services;
  var toggleColumn = Props.toggleColumn;
  var updateServiceList = Props.updateServiceList;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowSearch = match[1];
  var match$1 = React.useState(function () {
        return empty;
      });
  var setSearchState = match$1[1];
  var searchState = match$1[0];
  var addToServiceList = function (uuid, name) {
    updateServiceList(services.some(function (existing) {
              return Caml_obj.equal(existing.uuid, uuid);
            }) ? services : Belt_Array.concatMany([
                services,
                [{
                    uuid: uuid,
                    name: name
                  }]
              ]));
  };
  return React.createElement(React.Fragment, undefined, RCore.$$Array.keepMap(columns, (function (s) {
                    var name = RCore.$$Option.map(RCore.$$Array.getBy(services, (function (search) {
                                return Caml_obj.equal(search.uuid, s.serviceUuid);
                              })), (function (s) {
                            return s.name;
                          }));
                    if (name !== undefined) {
                      return Caml_option.some(React.createElement(ServiceColumns$CheckboxGroup, {
                                      settings: s,
                                      name: name,
                                      toggleColumn: toggleColumn,
                                      ctx: ctx,
                                      key: Uuid.toString(s.serviceUuid) + "-checkbox-group-"
                                    }));
                    }
                    
                  })), match[0] ? React.createElement("div", undefined, React.createElement(Optional.make, {
                        show: !searchState.loading && Caml_obj.notequal(searchState.services, []),
                        children: React.createElement("div", {
                              className: styles.serviceSearchOptions
                            }, searchState.services.map(function (s) {
                                  var categoryUuid = s.categoryUuid;
                                  var tmp;
                                  if (categoryUuid !== undefined) {
                                    var categoryUuid$1 = Caml_option.valFromOption(categoryUuid);
                                    tmp = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(searchState.categories, (function (c) {
                                                    return Caml_obj.equal(c.uuid, categoryUuid$1);
                                                  })), (function (c) {
                                                return " (" + c.name + ")";
                                              })), "");
                                  } else {
                                    tmp = "";
                                  }
                                  return React.createElement("div", {
                                              key: Uuid.toString(s.uuid),
                                              className: styles.serviceSearchOption,
                                              onClick: (function (evt) {
                                                  evt.preventDefault();
                                                  toggleColumn({
                                                        serviceUuid: s.uuid,
                                                        columns: ["Amount"]
                                                      });
                                                  addToServiceList(s.uuid, s.name);
                                                  setShowSearch(function (param) {
                                                        return false;
                                                      });
                                                  setSearchState(function (param) {
                                                        return empty;
                                                      });
                                                })
                                            }, React.createElement("div", undefined, s.name + tmp));
                                }))
                      }), React.createElement("input", {
                        className: styles.serviceSearchInput,
                        placeholder: searchPlaceholder$p(ctx),
                        type: "text",
                        onChange: (function (evt) {
                            evt.preventDefault();
                            var newValue = evt.target.value;
                            if (newValue.length < 3) {
                              return ;
                            } else {
                              setSearchState(function (param) {
                                    return {
                                            categories: [],
                                            services: [],
                                            loading: true
                                          };
                                  });
                              return Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(Services_API.filter([
                                                  wire,
                                                  bounds,
                                                  newValue,
                                                  undefined
                                                ]), (function (result) {
                                                if (result === undefined) {
                                                  return ;
                                                }
                                                if (result.TAG === "Ok") {
                                                  var match = result._0;
                                                  var services = match.services;
                                                  var categories = match.categories;
                                                  return setSearchState(function (param) {
                                                              return {
                                                                      categories: categories,
                                                                      services: RCore.$$Array.keep(services, (function ($$new) {
                                                                              return !columns.some(function (selected) {
                                                                                          return Caml_obj.equal(selected.serviceUuid, $$new.uuid);
                                                                                        });
                                                                            })),
                                                                      loading: false
                                                                    };
                                                            });
                                                }
                                                console.log(result._0);
                                              })), (function (prim) {
                                            
                                          }));
                            }
                          })
                      })) : React.createElement(ServiceColumns$AddServiceButton, {
                    onClick: (function () {
                        setShowSearch(function (param) {
                              return true;
                            });
                      }),
                    ctx: ctx,
                    key: "service-search-button"
                  }));
}

var make = ServiceColumns;

export {
  make ,
}
/* addService' Not a pure module */
