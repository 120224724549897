

import * as El from "../../El.mjs";
import * as Fun from "../../Fun.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as RCore from "../../RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Subscription from "../../Subscription.mjs";
import * as Bindings_Slate from "../../../bindings/Bindings_Slate.mjs";
import * as Liquid_EditorHooks from "./Liquid_EditorHooks.mjs";
import * as Liquid_EditorTypes from "./lib/Liquid_EditorTypes.mjs";
import * as Liquid_EditorErrors from "./components/errors/Liquid_EditorErrors.mjs";
import * as Liquid_EditorElement from "./components/element/Liquid_EditorElement.mjs";
import * as Liquid_EditorTemplate from "./lib/Liquid_EditorTemplate.mjs";
import * as Liquid_EditorAddSubstitutionButton from "./components/add_substitution_button/Liquid_EditorAddSubstitutionButton.mjs";
import * as Liquid_EditorModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/liquid/editor/Liquid_Editor.module.css";

var styles = Liquid_EditorModuleCss;

var remaining$p = Utils.Translations.txr("js.components.limited_input.remaining");

var maxLength$p = Utils.Translations.txr("js.components.limited_input.maxlength");

function Liquid_Editor$MaxLength(Props) {
  var maxLength = Props.maxLength;
  var length = Props.length;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var lengthRemaining = maxLength - length | 0;
  if (lengthRemaining <= (maxLength / 10 | 0) && lengthRemaining > 0) {
    return React.createElement("div", {
                className: styles.maxLength
              }, remaining$p({
                    count: maxLength,
                    current: length
                  }, ctx));
  } else if (lengthRemaining <= 0) {
    return React.createElement("div", {
                className: El.Cn.concat(styles.maxLength, (maxLength - length | 0) < 0 ? styles.hasError : "")
              }, maxLength$p({
                    count: maxLength
                  }, ctx));
  } else {
    return null;
  }
}

function validateSubstitutions(ctx, substitutions, elements) {
  return elements.every(function (element) {
              var match = Bindings_Slate.$$Element.payload(element);
              var match$1 = Bindings_Slate.$$Element.children(element);
              if (match !== undefined) {
                if (typeof match !== "object") {
                  if (match$1 !== undefined) {
                    return validateSubstitutions(ctx, substitutions, match$1);
                  } else {
                    return true;
                  }
                } else {
                  return !Liquid_EditorTypes.Suggestion.hasSuggestions(ctx, match._0, substitutions);
                }
              } else {
                return true;
              }
            });
}

function Liquid_Editor(Props) {
  var maxLength = Props.maxLength;
  var disabled = Props.disabled;
  var label = Props.label;
  var initialValue = Props.value;
  var onValueChange = Props.onValueChange;
  var substitutions = Props.substitutions;
  var errorsOpt = Props.errors;
  var onErrorsChange = Props.onErrorsChange;
  var errors = errorsOpt !== undefined ? errorsOpt : [];
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsValidate = match$1[1];
  var isValidate = match$1[0];
  var match$2 = Liquid_EditorHooks.useValue(initialValue);
  var value = match$2[0];
  var editor = Liquid_EditorHooks.useEditor();
  var keydownSubscription = React.useMemo((function () {
          return Subscription.make();
        }), []);
  var renderElement = React.useCallback((function (props) {
          var tmp = {
            substitutions: substitutions,
            keydownSubscription: keydownSubscription,
            isValidate: isValidate,
            props: props
          };
          if (disabled !== undefined) {
            tmp.disabled = disabled;
          }
          return React.createElement(Liquid_EditorElement.make, tmp);
        }), [substitutions]);
  var partial_arg = keydownSubscription.trigger;
  var onKeyDown = React.useCallback(Curry.__1(partial_arg), []);
  var onClick = React.useCallback((function (_event) {
          Bindings_Slate.React.ReactEditor.focus(editor);
        }), []);
  var valueString = Liquid_EditorTemplate.toString(value).trim();
  var valueLength = valueString.length;
  var blurTimeoutId = React.useRef(undefined);
  var onFocus = React.useCallback((function (_event) {
          RCore.$$Option.map(blurTimeoutId.current, (function (t) {
                  clearTimeout(t);
                }));
        }), []);
  var maxLengthError = function () {
    if (maxLength !== undefined && valueLength > maxLength) {
      return "MaxLengthError";
    }
    
  };
  var substitutionsError = function () {
    if (validateSubstitutions(ctx, substitutions, value)) {
      return ;
    } else {
      return "InvalidSubtitutionError";
    }
  };
  var onBlur = function (_event) {
    blurTimeoutId.current = Caml_option.some(setTimeout((function () {
                blurTimeoutId.current = undefined;
                var errors = RCore.$$Array.filterMap([
                      maxLengthError(),
                      substitutionsError()
                    ], Fun.id);
                onErrorsChange(errors);
                if (Caml_obj.equal(errors, [])) {
                  onValueChange(valueString);
                  if (isValidate) {
                    return setIsValidate(function (param) {
                                return false;
                              });
                  } else {
                    return ;
                  }
                } else if (!isValidate) {
                  return setIsValidate(function (param) {
                              return true;
                            });
                } else {
                  return ;
                }
              }), 300));
  };
  React.useEffect((function () {
          if (isValidate) {
            onErrorsChange(RCore.$$Array.filterMap([
                      maxLengthError(),
                      substitutionsError()
                    ], Fun.id));
          }
          
        }), [
        isValidate,
        valueLength,
        maxLength
      ]);
  var maxLengthBlock = maxLength !== undefined ? React.createElement(Liquid_Editor$MaxLength, {
          maxLength: maxLength,
          length: valueLength
        }) : null;
  var tmp = {
    editor: editor
  };
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  return React.createElement("div", {
              className: styles.field
            }, React.createElement("div", {
                  className: styles.header
                }, React.createElement("label", {
                      className: styles.label,
                      onClick: onClick
                    }, label), React.createElement(Liquid_EditorAddSubstitutionButton.make, tmp)), React.createElement(Bindings_Slate.React.Slate.make, {
                  editor: editor,
                  value: value,
                  onChange: match$2[1],
                  children: React.createElement(Bindings_Slate.React.Editable.make, {
                        className: El.Cn.concat(styles.input, Caml_obj.notequal(errors, []) ? styles.hasError : ""),
                        renderElement: renderElement,
                        onFocus: onFocus,
                        onBlur: onBlur,
                        onKeyDown: onKeyDown,
                        spellCheck: false,
                        readOnly: RCore.$$Option.getOr(disabled, false)
                      })
                }), maxLengthBlock, React.createElement(Liquid_EditorErrors.make, {
                  errors: errors
                }));
}

var Substitution;

var make = Liquid_Editor;

export {
  Substitution ,
  make ,
}
/* styles Not a pure module */
