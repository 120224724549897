

import * as Fun from "../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Bitrix_Types from "./Bitrix_Types.mjs";
import * as Js_undefined from "rescript/lib/es6/js_undefined.js";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              domain: field.required("domain", JsonDecode.string),
              bitrixTenantUid: field.required("bitrixTenantUid", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.array(decode);

var decode$2 = JsonDecode.object(function (field) {
      return {
              settings: field.required("settings", JsonDecode.option(Bitrix_Types.Settings.decode)),
              enabled: field.required("enabled", JsonDecode.bool),
              applicationUrl: field.required("applicationUrl", JsonDecode.string),
              suggest: field.required("bitrixSuggest", JsonDecode.string),
              error: field.required("error", JsonDecode.option(JsonDecode.string)),
              bitrixTenantUid: field.required("bitrixTenantUid", JsonDecode.option(JsonDecode.string))
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              redirectUrl: field.required("redirectUrl", JsonDecode.string)
            };
    });

function mapFields(jobField, dealField) {
  var tmp;
  switch (jobField.TAG) {
    case "Custom" :
        tmp = {
          customFieldUuid: jobField._0,
          reportFieldUuid: undefined,
          code: undefined
        };
        break;
    case "Report" :
        tmp = {
          customFieldUuid: undefined,
          reportFieldUuid: jobField._0,
          code: undefined
        };
        break;
    case "Predefined" :
        tmp = {
          customFieldUuid: undefined,
          reportFieldUuid: undefined,
          code: Bitrix_Types.ConfiguredStage.JobField.PredefinedField.toString(jobField._0)
        };
        break;
    
  }
  var match = dealField._0;
  return {
          jobField: tmp,
          dealField: {
            dealFieldId: match.id,
            dataType: match.dataType,
            multiple: match.multiple,
            statusType: Js_undefined.fromOption(match.statusType)
          }
        };
}

function jobFields(stage) {
  return RCore.$$Array.filterMap(stage.jobFields, (function (param) {
                  return Fun.both(param[0], param[1]);
                })).map(function (param) {
              return mapFields(param[0], param[1]);
            });
}

function dealFields(stage) {
  return RCore.$$Array.filterMap(stage.dealFields, (function (param) {
                  return Fun.both(param[0], param[1]);
                })).map(function (param) {
              return mapFields(param[1], param[0]);
            });
}

function types(cat) {
  return RCore.$$Array.filterMap(cat.types, (function (dealType) {
                var stages = RCore.$$Array.filterMap(dealType.stages, (function (stage) {
                        var match = Fun.both(stage.id, stage.templateId);
                        if (match !== undefined && dealType.configured) {
                          return {
                                  id: match[0],
                                  templateId: match[1],
                                  onSuccess: Js_undefined.fromOption(stage.onSuccess),
                                  onFailure: Js_undefined.fromOption(stage.onFailure),
                                  onCancel: Js_undefined.fromOption(stage.onCancel),
                                  jobFields: jobFields(stage),
                                  dealFields: dealFields(stage),
                                  createClient: stage.createClient
                                };
                        }
                        
                      }));
                if (stages.length > 0) {
                  return {
                          id: dealType.id,
                          stages: stages
                        };
                }
                
              }));
}

function request(settings) {
  return {
          categories: settings.categories.map(function (cat) {
                return {
                        id: cat.id,
                        types: types(cat)
                      };
              })
        };
}

function show(wire, bitrixTenantUid) {
  var route = bitrixTenantUid !== undefined ? ({
        NAME: "API",
        VAL: {
          NAME: "Bitrix24",
          VAL: {
            NAME: "Show",
            VAL: bitrixTenantUid
          }
        }
      }) : ({
        NAME: "API",
        VAL: {
          NAME: "Bitrix24",
          VAL: "New"
        }
      });
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, route), decode$2);
}

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Bitrix24",
                    VAL: "Index"
                  }
                }), decode$1);
}

function create(wire, address) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Bitrix24",
                    VAL: "New"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    bitrix24Address: address
                  }
                }), decode$3);
}

function sync(wire, bitrixTenantUid) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Bitrix24",
                    VAL: {
                      NAME: "SyncDeals",
                      VAL: bitrixTenantUid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {}
                }), JsonDecode.unit);
}

function update(wire, settings, bitrixTenantUid) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Bitrix24",
                    VAL: {
                      NAME: "Show",
                      VAL: bitrixTenantUid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: request(settings)
                }), JsonDecode.unit);
}

function destroy(wire, bitrixTenantUid) {
  return Backend.decode(Backend.$$delete(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Bitrix24",
                    VAL: {
                      NAME: "Show",
                      VAL: bitrixTenantUid
                    }
                  }
                }), JsonDecode.unit);
}

var Show_Index = {
  Settings: {}
};

var Show_Get = {};

var Show_Post = {};

var Show = {
  Index: Show_Index,
  Get: Show_Get,
  Post: Show_Post,
  show: show,
  index: index,
  create: create,
  sync: sync,
  update: update,
  destroy: destroy
};

export {
  Show ,
}
/* decode Not a pure module */
