

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../../libraries/Fun.mjs";
import * as Icon from "../../../../../../../../../Icon.mjs";
import * as Modal from "../../../../../../../../modal/Modal.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as UI_Icon from "../../../../../../../../../ui/UI_Icon.mjs";
import * as Carousel from "../../../../../../../../common/Carousel.mjs";
import * as DomUtils from "../../../../../../../../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobForm_HistoryTypes from "../../../../../../lib/types/JobForm_HistoryTypes.mjs";
import * as JobForm_HistoryFieldUpdatedEventModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/events/field_updated_event/JobForm_HistoryFieldUpdatedEvent.module.css";

var fileNotUploaded$p = Utils.Translations.tr("js.jobs.history.file.not_uploaded");

var imageNotUploaded$p = Utils.Translations.tr("js.jobs.history.image.not_uploaded");

var signatureNotUploaded$p = Utils.Translations.tr("js.jobs.history.signature.not_uploaded");

function actionChecked$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.action_checked")({
              name: name
            }, ctx);
}

function actionUnchecked$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.action_unchecked")({
              name: name
            }, ctx);
}

function checkboxChecked$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.checkbox_checked")({
              name: name
            }, ctx);
}

function checkboxUnchecked$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.checkbox_unchecked")({
              name: name
            }, ctx);
}

function fieldCleared$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.field_cleared")({
              name: name
            }, ctx);
}

function fieldUpdated$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.field_updated")({
              name: name
            }, ctx);
}

function fileAdded$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.file_added")({
              name: name
            }, ctx);
}

function fileCleared$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.file_cleared")({
              name: name
            }, ctx);
}

function imageAdded$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.image_added")({
              name: name
            }, ctx);
}

function imageCleared$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.image_cleared")({
              name: name
            }, ctx);
}

function signatureAdded$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.signature_added")({
              name: name
            }, ctx);
}

function signatureCleared$p(name, ctx) {
  return Utils.Translations.txr("js.jobs.history.events.signature_cleared")({
              name: name
            }, ctx);
}

var styles = JobForm_HistoryFieldUpdatedEventModuleCss;

function JobForm_HistoryFieldUpdatedEvent$InputComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, value))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, ctx)));
}

var InputComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$InputComponent
};

function JobForm_HistoryFieldUpdatedEvent$NumberInputComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, value))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, ctx)));
}

var NumberInputComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$NumberInputComponent
};

function JobForm_HistoryFieldUpdatedEvent$DictionaryComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, value))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, ctx)));
}

var DictionaryComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$DictionaryComponent
};

var $$Image = {};

function JobForm_HistoryFieldUpdatedEvent$ImagesComponent(Props) {
  var wire = Props.wire;
  var name = Props.name;
  var images = Props.images;
  var fieldType = Props.fieldType;
  var allImages = Props.allImages;
  var ctx = wire.ctx;
  var uniqueImages = JobForm_HistoryTypes.FieldUpdated.FieldValue.removeDuplicates(images);
  var galleryRef = Types.ReactRef.use();
  var match = React.useState(function () {
        
      });
  var setCurrentImage = match[1];
  var currentImage = match[0];
  var match$1 = React.useState(function () {
        return allImages.map(function (param) {
                    return {
                            url: param.url,
                            previewUrl: param.previewUrl,
                            name: param.name,
                            rotationDeg: 0
                          };
                  });
      });
  var setRotatedImages = match$1[1];
  var rotatedImages = match$1[0];
  var notUploadedText;
  notUploadedText = fieldType === "Image" ? imageNotUploaded$p(ctx) : signatureNotUploaded$p(ctx);
  var addedText;
  addedText = fieldType === "Image" ? imageAdded$p(name, ctx) : signatureAdded$p(name, ctx);
  var clearedText;
  clearedText = fieldType === "Image" ? imageCleared$p(name, ctx) : signatureCleared$p(name, ctx);
  var onRotate = function (changeDeg, index) {
    setRotatedImages(function (images) {
          return images.map(function (image, idx) {
                      if (idx === index) {
                        return {
                                url: image.url,
                                previewUrl: image.previewUrl,
                                name: image.name,
                                rotationDeg: (image.rotationDeg + changeDeg | 0) % 360
                              };
                      } else {
                        return image;
                      }
                    });
        });
  };
  var footerFromIndex = function (param) {
    var views = param.views;
    var currentIndex = param.currentIndex;
    return React.createElement("div", {
                className: "react-images-gallery-footer"
              }, React.createElement("div", {
                    className: "react-images-gallery-footer-info"
                  }, React.createElement("div", undefined, RCore.$$Option.getOr(RCore.$$Option.map(views[currentIndex], (function (v) {
                                  return v.caption;
                                })), "")), React.createElement("div", undefined, (currentIndex + 1 | 0).toString() + "/" + views.length.toString())), React.createElement("div", {
                    className: "react-images-gallery-control-panel-wrapper"
                  }, React.createElement("div", {
                        className: "react-images-gallery-control-panel"
                      }, React.createElement(UI_Icon.make, {
                            onClick: (function (param) {
                                if (currentIndex === 0) {
                                  return ;
                                } else {
                                  return setCurrentImage(function (param) {
                                              return currentIndex - 1 | 0;
                                            });
                                }
                              }),
                            className: "react-images-gallery-control-panel-icon",
                            icon: "chevron-left"
                          }), React.createElement(UI_Icon.make, {
                            onClick: (function (param) {
                                if (currentIndex === (views.length - 1 | 0)) {
                                  return ;
                                } else {
                                  return setCurrentImage(function (param) {
                                              return currentIndex + 1 | 0;
                                            });
                                }
                              }),
                            className: "react-images-gallery-control-panel-icon",
                            icon: "chevron-right"
                          }), React.createElement(UI_Icon.make, {
                            onClick: (function (param) {
                                onRotate(-90, currentIndex);
                              }),
                            className: "react-images-gallery-control-panel-icon",
                            icon: "arrow-rotate-left"
                          }), React.createElement(UI_Icon.make, {
                            onClick: (function (param) {
                                onRotate(90, currentIndex);
                              }),
                            className: "react-images-gallery-control-panel-icon",
                            icon: "arrow-rotate-right"
                          }), React.createElement(UI_Icon.make, {
                            onClick: (function (param) {
                                setCurrentImage(function (param) {
                                      
                                    });
                              }),
                            className: "react-images-gallery-control-panel-close-icon",
                            icon: "xmark"
                          }))), React.createElement("div", {
                    className: "react-images-gallery-footer-thumbnails"
                  }, views.map(function (value, index) {
                        return React.createElement("img", {
                                    className: index === currentIndex ? "react-images-gallery-footer-active-thumbnail" : "react-images-gallery-footer-thumbnail-image",
                                    src: value.source,
                                    onClick: (function (param) {
                                        setCurrentImage(function (param) {
                                              return index;
                                            });
                                      })
                                  });
                      })));
  };
  var previews = uniqueImages.map(function (param) {
        var name = param.name;
        var url = param.url;
        if (url === undefined) {
          return React.createElement("div", {
                      className: El.Cn.concat(styles.imageContainer, styles.notUploaded)
                    }, React.createElement("div", {
                          className: styles.notUploadedImage
                        }, React.createElement("div", {
                              className: Icon.style(undefined, undefined, undefined, undefined, "picture-o")
                            }), notUploadedText), React.createElement("div", {
                          className: styles.imageHover
                        }, React.createElement("div", {
                              className: El.Cn.concat(styles.imageContent, styles.notUploaded)
                            }, React.createElement("div", {
                                  className: styles.imageContentText
                                }, name))));
        }
        var index = RCore.$$Option.getOr(RCore.$$Array.findIndexOpt(RCore.$$Array.filterMap(rotatedImages, (function (i) {
                        return RCore.$$Option.map(i.url, (function (url) {
                                      return [
                                              url,
                                              i.name
                                            ];
                                    }));
                      })), (function (param) {
                    if (param[0] === url) {
                      return param[1] === name;
                    } else {
                      return false;
                    }
                  })), 0);
        return React.createElement("div", {
                    className: styles.imageContainer,
                    onClick: (function (param) {
                        setCurrentImage(function (param) {
                              return index;
                            });
                      })
                  }, React.createElement("div", {
                        className: styles.image,
                        style: {
                          backgroundColor: "white",
                          backgroundImage: "url('" + RCore.$$Option.getOr(param.previewUrl, url) + "')",
                          border: "1px solid #f6f6f6"
                        }
                      }), React.createElement("div", {
                        className: styles.imageHover
                      }, React.createElement("div", {
                            className: styles.imageLight
                          }), React.createElement("div", {
                            className: styles.imageContent
                          }, React.createElement("span", {
                                className: Icon.style(undefined, undefined, undefined, undefined, "eye")
                              }))));
      });
  React.useEffect((function () {
          var match = Fun.both(currentImage, Types.ReactRef.toOption(galleryRef));
          if (match !== undefined) {
            DomUtils.focus(match[1].frame, undefined);
          }
          
        }), [currentImage]);
  var tmp;
  if (currentImage !== undefined) {
    var onClose = function () {
      setCurrentImage(function (param) {
            
          });
    };
    var views = RCore.$$Array.filterMap(rotatedImages, (function (image) {
            return RCore.$$Option.map(image.url, (function (link) {
                          return Carousel.View.make(image.name, link);
                        }));
          }));
    tmp = React.createElement(Modal.make, {
          wire: wire,
          onClose: onClose,
          children: React.createElement(Carousel.make, {
                views: views,
                currentIndex: currentImage,
                isModal: true,
                components: {
                  Footer: footerFromIndex
                },
                frameProps: {
                  accessibility: true
                },
                modalProps: {
                  onClose: onClose
                },
                trackProps: {
                  onViewChange: (function (index) {
                      setCurrentImage(function (param) {
                            return index;
                          });
                    })
                },
                styles: Carousel.Styles.make(RCore.$$Option.map(rotatedImages[currentImage], (function (param) {
                            return param.rotationDeg;
                          }))),
                ref: galleryRef
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", undefined, uniqueImages.length > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, addedText), React.createElement("div", {
                        className: styles.images
                      }, previews)) : React.createElement("div", {
                    className: styles.name
                  }, clearedText), tmp);
}

var ImagesComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$ImagesComponent
};

function JobForm_HistoryFieldUpdatedEvent$FilesComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var files = Props.files;
  var onClickLink = function ($$event) {
    $$event.stopPropagation();
  };
  var fileList = files.map(function (param, index) {
        var name = param.name;
        var url = param.url;
        var key = index.toString() + name;
        if (url !== undefined) {
          return React.createElement("a", {
                      key: key,
                      href: url,
                      target: "_blank",
                      onClick: onClickLink
                    }, name);
        } else {
          return React.createElement("p", {
                      key: key
                    }, fileNotUploaded$p(ctx));
        }
      });
  var filesCount = files.length;
  return React.createElement("div", undefined, filesCount > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fileAdded$p(name, ctx)), React.createElement("div", {
                        className: styles.files
                      }, fileList)) : React.createElement("div", {
                    className: styles.name
                  }, fileCleared$p(name, ctx)));
}

var FilesComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$FilesComponent
};

function JobForm_HistoryFieldUpdatedEvent$CheckboxComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var value = Props.value;
  var tmp;
  var exit = 0;
  if (value !== undefined && value) {
    tmp = React.createElement("div", {
          className: styles.name
        }, checkboxChecked$p(name, ctx));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: styles.name
        }, checkboxUnchecked$p(name, ctx));
  }
  return React.createElement("div", undefined, tmp);
}

var CheckboxComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$CheckboxComponent
};

function JobForm_HistoryFieldUpdatedEvent$ActionComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var value = Props.value;
  var tmp;
  var exit = 0;
  if (value !== undefined && value) {
    tmp = React.createElement("div", {
          className: styles.name
        }, actionChecked$p(name, ctx));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: styles.name
        }, actionUnchecked$p(name, ctx));
  }
  return React.createElement("div", undefined, tmp);
}

var ActionComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$ActionComponent
};

function JobForm_HistoryFieldUpdatedEvent$Barcode(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, value))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, ctx)));
}

var Barcode = {
  make: JobForm_HistoryFieldUpdatedEvent$Barcode
};

function JobForm_HistoryFieldUpdatedEvent$CurrencyComponent(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var currency = Props.currency;
  var tmp;
  var exit = 0;
  if (currency !== undefined) {
    var value = currency.value;
    if (value !== undefined) {
      tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: styles.name
              }, fieldUpdated$p(name, ctx)), React.createElement("div", {
                className: styles.value
              }, React.createElement("span", undefined, El.Cn.concat(value, currency.currency))));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: styles.name
        }, fieldCleared$p(name, ctx));
  }
  return React.createElement("div", undefined, tmp);
}

var CurrencyComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$CurrencyComponent
};

function JobForm_HistoryFieldUpdatedEvent$DateComponent(Props) {
  var wire = Props.wire;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, wire.ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, Locale.T.fmtM("Date", wire)(Caml_option.valFromOption(value))))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, wire.ctx)));
}

var DateComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$DateComponent
};

function JobForm_HistoryFieldUpdatedEvent$DatetimeComponent(Props) {
  var wire = Props.wire;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, wire.ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, Locale.T.fmtM("DateTime", wire)(Caml_option.valFromOption(value))))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, wire.ctx)));
}

var DatetimeComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$DatetimeComponent
};

function JobForm_HistoryFieldUpdatedEvent$TimeComponent(Props) {
  var wire = Props.wire;
  var name = Props.name;
  var value = Props.value;
  return React.createElement("div", undefined, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.name
                      }, fieldUpdated$p(name, wire.ctx)), React.createElement("div", {
                        className: styles.value
                      }, React.createElement("span", undefined, Locale.T.fmtM("Time", wire)(Caml_option.valFromOption(value))))) : React.createElement("div", {
                    className: styles.name
                  }, fieldCleared$p(name, wire.ctx)));
}

var TimeComponent = {
  make: JobForm_HistoryFieldUpdatedEvent$TimeComponent
};

function JobForm_HistoryFieldUpdatedEvent(Props) {
  var wire = Props.wire;
  var param = Props.field;
  var allImages = Props.allImages;
  var value = param.value;
  var name = param.name;
  var ctx = wire.ctx;
  var variant = value.NAME;
  return React.createElement("div", undefined, variant === "Action" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$ActionComponent, {
                    ctx: ctx,
                    name: name,
                    value: value.VAL
                  }) : (
                variant === "DecimalInput" || variant === "IntegerInput" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$NumberInputComponent, {
                        ctx: ctx,
                        name: name,
                        value: value.VAL
                      }) : (
                    variant === "Textarea" || variant === "Input" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$InputComponent, {
                            ctx: ctx,
                            name: name,
                            value: value.VAL
                          }) : (
                        variant === "Barcode" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$Barcode, {
                                ctx: ctx,
                                name: name,
                                value: value.VAL
                              }) : (
                            variant === "Currency" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$CurrencyComponent, {
                                    ctx: ctx,
                                    name: name,
                                    currency: value.VAL
                                  }) : (
                                variant === "Checkbox" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$CheckboxComponent, {
                                        ctx: ctx,
                                        name: name,
                                        value: value.VAL
                                      }) : (
                                    variant === "Files" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$FilesComponent, {
                                            ctx: ctx,
                                            name: name,
                                            files: value.VAL
                                          }) : (
                                        variant === "Date" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$DateComponent, {
                                                wire: wire,
                                                name: name,
                                                value: value.VAL
                                              }) : (
                                            variant === "Unknown" ? null : (
                                                variant === "Images" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$ImagesComponent, {
                                                        wire: wire,
                                                        name: name,
                                                        images: value.VAL,
                                                        fieldType: "Image",
                                                        allImages: allImages
                                                      }) : (
                                                    variant === "Signature" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$ImagesComponent, {
                                                            wire: wire,
                                                            name: name,
                                                            images: value.VAL,
                                                            fieldType: "Signature",
                                                            allImages: allImages
                                                          }) : (
                                                        variant === "Time" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$TimeComponent, {
                                                                wire: wire,
                                                                name: name,
                                                                value: value.VAL
                                                              }) : (
                                                            variant === "Dictionary" ? React.createElement(JobForm_HistoryFieldUpdatedEvent$DictionaryComponent, {
                                                                    ctx: ctx,
                                                                    name: name,
                                                                    value: value.VAL
                                                                  }) : React.createElement(JobForm_HistoryFieldUpdatedEvent$DatetimeComponent, {
                                                                    wire: wire,
                                                                    name: name,
                                                                    value: value.VAL
                                                                  })
                                                          )
                                                      )
                                                  )
                                              )
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              ));
}

var make = JobForm_HistoryFieldUpdatedEvent;

export {
  InputComponent ,
  NumberInputComponent ,
  DictionaryComponent ,
  $$Image ,
  ImagesComponent ,
  FilesComponent ,
  CheckboxComponent ,
  ActionComponent ,
  Barcode ,
  CurrencyComponent ,
  DateComponent ,
  DatetimeComponent ,
  TimeComponent ,
  make ,
}
/* fileNotUploaded' Not a pure module */
