import * as React from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { showTime, isSameYear, now } from 'planado/utils/time/index.js'
import { meterToMileRatio } from 'planado/utils/map'
import { jobPath } from 'planado/routes.js'
import humps from 'humps'
import Title from 'planado/components/title'
import { mToKm, format } from 'rscrpt/libraries/Distance.mjs'
import { format as formatNumber } from 'rscrpt/libraries/Number.mjs'
import CurrencyText from 'rscrpt/components/inputs/CurrencyText.mjs'

import Checkbox from 'planado/components/input/checkbox'
import * as styles from './styles.module.css'

const JobField = withContext((props) => {
  const { ctx, job, field, openJob } = props

  switch (field) {
    case 'completionJobs':
      if (job.completionJobs.length === 0) {
        return ''
      } else {
        return job.completionJobs.map((field, index) => (
          <div>
            <a
              href={jobPath(field.uuid)}
              onClick={(e) => openJob(e, field.uuid)}
            >
              {field.serialNo}
            </a>
            {index < job.completionJobs.size ? <br /> : ''}
          </div>
        ))
      }
    case 'address': {
      if (job.address.apartment && job.address.formatted) {
        if (job.client) {
          if (job.client.organization) {
            return `${job.address.formatted}, ${ctx.t(
              'js.jobs.index.table.apartment_organization'
            )}${job.address.apartment}`
          } else {
            return `${job.address.formatted}, ${ctx.t(
              'js.jobs.index.table.apartment_individual'
            )}${job.address.apartment}`
          }
        } else {
          return `${job.address.formatted}, ${ctx.t(
            'js.jobs.index.table.apartment_none'
          )}${job.address.apartment}`
        }
      } else {
        return job.address.formatted || ''
      }
    }
    case 'addressDescription':
      return job.addressDescription || ''
    case 'site':
    case 'client':
      if (job.site) {
        if (job.client) {
          return `${job.site.name} (${job.client.name})`
        } else {
          return job.site.name
        }
      } else {
        return job.client?.name || ''
      }
    case 'clientExternalId':
      if (job.site) {
        if (job.client) {
          return `${job.site.externalId || '—'} (${
            job.client.externalId || '-'
          })`
        } else {
          return job.site.externalId || ''
        }
      } else {
        return job.client?.externalId || ''
      }
    case 'entranceNo':
    case 'floor':
      return job.address[field] || ''
    case 'scheduledAt':
      if (job[field]) {
        return showTime(job[field], 'time', ctx)
      } else {
        return <span className={styles.center}>—</span>
      }
    case 'typeCode':
      if (job.typeCode === 'default') {
        return ''
      } else {
        return job.typeCode
      }
    case 'viewedAt':
    case 'enRouteAt':
    case 'startedAt':
    case 'finishedAt':
    case 'createdAt':
    case 'suspendedAt':
    case 'resumedAt':
      if (job[field]) {
        return showTime(job[field], 'datetime', ctx)
      } else {
        return ''
      }
    case 'skills':
      return job[field].join(', ')
    case 'assignee':
      if (job[field].trim().length > 0) {
        return job[field]
      } else {
        return (
          <span className={styles.pre}>
            {ctx.t('js.jobs.index.heading.not_assigned')}
          </span>
        )
      }
    case 'assignees':
      if (job.assignees.length > 0) {
        return job.assignees.join(', ')
      } else {
        return (
          <span className={styles.pre}>
            {ctx.t('js.jobs.index.heading.not_assigned')}
          </span>
        )
      }
    case 'assigneesCount':
      return job.assignees.length
    case 'status': {
      let resolution
      if (job.resolutionSuccessful === null) {
        resolution = null
      } else if (job.resolutionSuccessful) {
        resolution = (
          <span className={`${styles.successful} fa fa-check-circle`} />
        )
      } else {
        resolution = (
          <span className={`${styles.unsuccessful} fa fa-times-circle`} />
        )
      }
      return (
        <Title content={job.resolutionName || ''}>
          <span>
            {ctx.t(`js.jobs.statuses.${humps.decamelize(job[field])}`)}
            {resolution}
          </span>
        </Title>
      )
    }
    case 'externalResourceLink': {
      const { externalResourceLink, externalResourceLinkText } = job

      if (externalResourceLink && externalResourceLinkText) {
        return (
          <a href={externalResourceLink} target="_blank">
            {externalResourceLinkText}
          </a>
        )
      } else {
        return ''
      }
    }
    default:
      return job[field] || ''
  }
})

const VirtualColumnValue = withContext((props) => {
  const {
    field,
    job: { virtualFields },
    ctx,
  } = props

  switch (field) {
    case 'odometerM': {
      const { odometerM } = virtualFields

      if (odometerM && ctx.localizator.measurementSystem === 'imperial') {
        return format(
          5,
          (meterToMileRatio * odometerM).toFixed(3),
          ctx.wire.ctx
        )
      } else if (odometerM) {
        const odometerKm = mToKm(odometerM)
        const formatted = format(5, odometerKm, ctx.wire.ctx)

        return formatted
      } else {
        return ''
      }
    }
    case 'photoArchiveUrl': {
      const { photoArchiveUrl } = virtualFields

      if (photoArchiveUrl) {
        return (
          <a href={photoArchiveUrl} target="_blank">
            {ctx.t('common.download')}
          </a>
        )
      } else {
        return ''
      }
    }
    case 'photoArchiveUrlShared': {
      const { photoArchiveUrlShared } = virtualFields

      if (photoArchiveUrlShared) {
        return (
          <a href={photoArchiveUrlShared} target="_blank">
            {ctx.t('common.download')}
          </a>
        )
      } else {
        return ''
      }
    }
    default:
      return ''
  }
})

const Link = (field) => {
  if (field.value === null) {
    return null
  } else {
    return (
      <a href={field.value} target="_blank">
        {field.value.length <= 50
          ? field.value
          : field.value.slice(0, 47) + '...'}
      </a>
    )
  }
}

const DownloadLink = (field, ctx) => {
  if (field.value === null || field.value.length === 0) {
    return null
  } else {
    return (
      <a href={field.value[0].url} target="_blank">
        {ctx.t('common.download')}
      </a>
    )
  }
}

const CustomField = ({ field, ctx }) => {
  switch (true) {
    case field.fieldType === 'currency':
      return <p className={styles.cellCurrency}>{field.value}</p>
    case field.fieldType === 'file':
      return DownloadLink(field, ctx)
    case field.fieldType === 'link':
      return Link(field)
    default:
      return field.value
  }
}

const ReportField = withContext((props) => {
  const { field, ctx } = props
  switch (true) {
    case field && field.dataType === 'boolean':
      if (field.value === null) {
        return ''
      } else if (field.value) {
        return ctx.t('js.jobs.index.table.yes')
      } else {
        return ctx.t('js.jobs.index.table.no')
      }
    case field && field.fieldType === 'image':
      return field.value === null || field.value.length === 0
        ? ''
        : ctx.t('js.jobs.index.table.image', { count: field.value.length })
    case field && field.fieldType === 'signature':
      return field.value === null || field.value.length === 0
        ? ''
        : ctx.t('js.jobs.index.table.signed')
    case field && field.fieldType === 'file':
      return DownloadLink(field, ctx)
    case field && field.fieldType === 'link':
      return Link(field)
    case field && field.fieldType === 'currency':
      return <p className={styles.cellCurrency}>{field.value}</p>
    default:
      return field.value
  }
})

const Row = withContext((props) => {
  const {
    ctx,
    columns,
    onJobEdit,
    job,
    pickingForBulk,
    pickForOperation,
    picked,
  } = props
  const { uuid, serialNo } = job

  const openJob = (event, uuid) => {
    event.preventDefault()
    onJobEdit(uuid)
  }

  const serialNoEl = (
    <a href={jobPath(uuid)} onClick={(evt) => openJob(evt, uuid)}>
      {serialNo}
    </a>
  )

  return (
    <tr className="job-row">
      <td key="jf_serialNo" className={`${styles.cell} ${styles.serialNo}`}>
        {pickingForBulk ? (
          <div className={styles.serialNoBulk}>
            <Checkbox
              checked={picked}
              onToggle={() => pickForOperation(job.uuid)}
              wrapperClasses={{ 'bulk-op__checkbox': true }}
            />
            {serialNoEl}
          </div>
        ) : (
          serialNoEl
        )}
      </td>

      {columns.jobFields.map((field, index) => (
        <td className={styles.cell} key={`jf_${index}`}>
          <JobField job={job} field={field} openJob={openJob} />
        </td>
      ))}

      {columns.customFields.map((c, index) => {
        const field = job.customFields.find((f) => f.uuid === c)
        return (
          <td key={`cf_${index}`}>
            {field && <CustomField field={field} ctx={ctx} />}
          </td>
        )
      })}

      {columns.reportFields.map((c, index) => {
        const field = job.reportFields.find((f) => f.uuid === c)
        return (
          <td key={`rf_${index}`}>{field && <ReportField field={field} />}</td>
        )
      })}

      {columns.virtual
        .filter(
          (c) =>
            c !== 'odometerM' ||
            (c === 'odometerM' && ctx.features.flags.includes('tracking'))
        )
        .map((field, index) => (
          <td className={styles.cell} key={`rf_${index}`}>
            <VirtualColumnValue job={job} field={field} />
          </td>
        ))}

      {columns.catalog.map((c, _index) => {
        const { delimiter, separator } = ctx.localizator.numbers

        return c.columns.map((column) => {
          const servicesInfo = job.providedServices.filter(
            (provided) => provided.serviceUuid === c.serviceUuid
          )

          const displayedValue = (servicesInfo, column) => {
            if (servicesInfo.length > 0) {
              const first = servicesInfo[0]

              switch (column) {
                case 'price':
                  return (
                    <CurrencyText
                      ctx={ctx.wire.ctx}
                      currency={first['currency'] || job.currency}
                      value={first['price']}
                    />
                  )
                case 'amount':
                  let value = servicesInfo
                    .reduce((acc, info) => {
                      if (info['amount']) {
                        return acc + parseFloat(info['amount'])
                      } else {
                        return acc + 0
                      }
                    }, 0)
                    .toFixed(2)

                  return (
                    <CurrencyText
                      ctx={ctx.wire.ctx}
                      currency={first['currency'] || job.currency}
                      value={value}
                    />
                  )
                case 'quantity':
                  return (
                    <span>
                      {formatNumber(
                        delimiter,
                        separator,
                        5,
                        servicesInfo.length.toString()
                      )}
                    </span>
                  )
              }
            } else {
              return null
            }
          }
          return (
            <td
              className={styles.cell}
              key={'catalog_' + c.serviceUuid + column}
            >
              {displayedValue(servicesInfo, column)}
            </td>
          )
        })
      })}
    </tr>
  )
})

const picked = (uuid, operationSet) => {
  if (operationSet === null) {
    return false
  } else {
    if (operationSet.type === 'filter') {
      return !operationSet.excludeUuids.includes(uuid)
    } else {
      return operationSet.uuids.includes(uuid)
    }
  }
}

const dayLabel = (date, ctx) => {
  if (date === null) {
    return ctx.t('js.jobs.index.table.not_scheduled')
  } else {
    if (isSameYear(date, now(ctx))) {
      return showTime(date, 'week_day_with_day_with_month', ctx)
    } else {
      return showTime(date, 'week_day_with_day_with_month_with_year', ctx)
    }
  }
}

const DayJobs = withContext((props) => {
  const {
    columns,
    date,
    jobs: group,
    colNumber,
    onJobEdit,
    numberOfJobs,
    lastDay,
    pickingForBulk,
    pickForOperation,
    operationSet,
    ctx,
  } = props

  const rows = group.reduce((rows, { date, jobs }) => {
    if (date === 'scheduled') {
      rows = [
        ...rows,
        ...jobs.map((job, index) => (
          <Row
            columns={columns}
            job={job}
            onJobEdit={onJobEdit}
            key={index}
            pickingForBulk={pickingForBulk}
            pickForOperation={pickForOperation}
            picked={picked(job.uuid, operationSet)}
          />
        )),
      ]
    } else {
      const scheduledAt = ctx.t('js.jobs.index.table.scheduled_at', {
        count: jobs.length,
      })

      const dateRow = (
        <tr key={date} colSpan={colNumber} className={styles.rowScheduled}>
          <td colSpan={colNumber} className={styles.cell}>
            {`${scheduledAt} ${dayLabel(date, ctx)}`}
          </td>
        </tr>
      )

      rows = [
        ...rows,
        dateRow,
        ...jobs.map((job) => (
          <Row
            columns={columns}
            key={job.uuid}
            job={job}
            onJobEdit={onJobEdit}
            pickingForBulk={pickingForBulk}
            pickForOperation={pickForOperation}
            picked={picked(job.uuid, operationSet)}
          />
        )),
      ]
    }

    return rows
  }, [])

  return (
    <tbody>
      <tr colSpan={colNumber} className={styles.rowDay}>
        <td colSpan={colNumber} className={styles.cell}>
          {dayLabel(date, ctx)}
          <span>
            {ctx.t(
              lastDay
                ? 'js.jobs.index.table.jobs_for_last_day'
                : 'js.jobs.index.table.jobs',
              {
                count: numberOfJobs,
              }
            )}
          </span>
        </td>
      </tr>
      {rows}
    </tbody>
  )
})

export default DayJobs
