

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as React from "react";
import * as DropDownMenuModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/DropDownMenu/DropDownMenu.module.css";

function makeClassName(position) {
  if (position === "none") {
    return ;
  } else if (position === "bottom") {
    return "dropdown-menu-bottom";
  } else if (position === "right") {
    return "dropdown-menu-right";
  } else if (position === "top") {
    return "dropdown-menu-top";
  } else {
    return "dropdown-menu-left";
  }
}

var styles = DropDownMenuModuleCss;

function DropDownMenu$Separator(Props) {
  return React.createElement("li", {
              key: "divider",
              className: "divider",
              role: "separator"
            });
}

var Separator = {
  make: DropDownMenu$Separator
};

function defaultButton(_ref) {
  return React.createElement("a", {
              ref: _ref,
              className: "btn btn-default btn-more-options",
              href: "#",
              onClick: (function (prim) {
                  prim.preventDefault();
                })
            }, React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, undefined, "circle")
                }), React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, undefined, "circle")
                }), React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, undefined, "circle")
                }));
}

function DropDownMenu(Props) {
  var positionOpt = Props.position;
  var buttonOpt = Props.button;
  var className = Props.className;
  var listClassName = Props.listClassName;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var position = positionOpt !== undefined ? positionOpt : "none";
  var button = buttonOpt !== undefined ? buttonOpt : defaultButton;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setShowMenu = match[1];
  var showMenu = match[0];
  var ref_ = React.useRef(null);
  Hooks.useToggleOnClick1(ref_, showMenu, (function (show) {
          setShowMenu(function (param) {
                return show;
              });
        }));
  var openClass = showMenu && !disabled ? "open opened" : undefined;
  return React.createElement("div", {
              className: El.Cn.concat(El.Cn.concatOpt(El.Cn.concatOpt("dropdown", className), openClass), styles.menu)
            }, button(ref_), React.createElement("ul", {
                  className: El.Cn.concatOpt(El.Cn.concatOpt("dropdown-menu", makeClassName(position)), listClassName)
                }, children));
}

var make = DropDownMenu;

export {
  Separator ,
  make ,
}
/* styles Not a pure module */
