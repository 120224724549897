

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as APIShow from "../api/APIShow.mjs";
import * as AmoCrmShow from "../amocrm/AmoCrmShow.mjs";
import * as Bitrix_Show from "./bitrix24/Bitrix_Show.mjs";
import * as WebhookEdit from "../webhook/WebhookEdit.mjs";
import * as Bitrix_Index from "./bitrix24/Bitrix_Index.mjs";
import * as AmoCrmConnect from "../amocrm/AmoCrmConnect.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Integrations_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/integrations/Integrations_Index.module.css";

var styles = Integrations_IndexModuleCss;

var settings$p = Utils.Translations.tr("js.admin.settings.title.settings");

var integrations$p = Utils.Translations.tr("js.admin.integrations.title");

var bitrix24Description$p = Utils.Translations.tr("js.admin.integrations.bitrix24.description");

var apiDescription$p = Utils.Translations.tr("js.admin.integrations.api.description");

var zapierDescription$p = Utils.Translations.tr("js.admin.integrations.zapier.description");

var bitrix24$p = Utils.Translations.tr("js.admin.integrations.bitrix24.name");

var amocrm$p = Utils.Translations.tr("js.admin.integrations.amocrm.name");

var amocrmDescription$p = Utils.Translations.tr("js.admin.integrations.amocrm.description");

var api$p = Utils.Translations.tr("js.admin.integrations.api.name");

var configure$p = Utils.Translations.tr("js.admin.integrations.configure");

var newWebhook$p = Utils.Translations.tr("js.admin.integrations.webhooks.new");

var manageWebhook$p = Utils.Translations.tr("js.admin.integrations.webhooks.manage");

function Integrations_Index(Props) {
  var wire = Props.wire;
  var section = Props.section;
  var ctx = wire.ctx;
  var tmp;
  var exit = 0;
  if (typeof section === "object" || section === "API") {
    exit = 1;
  } else {
    tmp = React.createElement("span", undefined, integrations$p(ctx));
  }
  if (exit === 1) {
    var tmp$1;
    var exit$1 = 0;
    if (typeof section === "object") {
      var variant = section.NAME;
      if (variant === "Bitrix24") {
        tmp$1 = React.createElement("span", undefined, bitrix24$p(ctx));
      } else if (variant === "Webhooks") {
        exit$1 = 2;
      } else {
        tmp$1 = React.createElement("span", undefined, amocrm$p(ctx));
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (typeof section === "object") {
        var label = typeof section.VAL === "object" ? manageWebhook$p(ctx) : newWebhook$p(ctx);
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Link.make, {
                  wire: wire,
                  route: {
                    NAME: "Integrations",
                    VAL: "API"
                  },
                  children: api$p(ctx)
                }), " / ", React.createElement("span", undefined, label));
      } else {
        tmp$1 = React.createElement("span", undefined, api$p(ctx));
      }
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Link.make, {
              wire: wire,
              route: {
                NAME: "Integrations",
                VAL: "Index"
              },
              children: integrations$p(ctx)
            }), " / ", tmp$1);
  }
  var tmp$2;
  if (typeof section === "object") {
    var variant$1 = section.NAME;
    if (variant$1 === "Bitrix24") {
      var match = section.VAL;
      if (typeof match === "object") {
        var match$1 = match.VAL;
        tmp$2 = React.createElement(Bitrix_Show.make, {
              wire: wire,
              bitrixTenantUid: match$1.bitrixTenantUid,
              pathCategory: match$1.category
            });
      } else {
        tmp$2 = match === "New" ? React.createElement(Bitrix_Show.make, {
                wire: wire,
                bitrixTenantUid: undefined,
                pathCategory: undefined
              }) : React.createElement(Bitrix_Index.make, {
                wire: wire
              });
      }
    } else if (variant$1 === "Webhooks") {
      tmp$2 = React.createElement(WebhookEdit.make, {
            wire: wire,
            route: section.VAL
          });
    } else {
      var match$2 = section.VAL;
      tmp$2 = match$2.NAME === "Show" ? React.createElement(AmoCrmShow.make, {
              wire: wire,
              pathPipeline: match$2.VAL[1]
            }) : React.createElement(AmoCrmConnect.make, {
              wire: wire,
              host: match$2.VAL[1]
            });
    }
  } else {
    tmp$2 = section === "API" ? React.createElement(APIShow.make, {
            wire: wire
          }) : React.createElement("ul", {
            className: "col-md-12 " + styles.integrations
          }, React.createElement("li", undefined, React.createElement("h3", undefined, api$p(ctx)), apiDescription$p(ctx), React.createElement(Link.make, {
                    wire: wire,
                    route: {
                      NAME: "Integrations",
                      VAL: "API"
                    },
                    className: "btn btn-default " + styles.configure,
                    children: configure$p(ctx)
                  })), Context_Types.Features.hasFlag("zapier", ctx.features) ? React.createElement("li", undefined, React.createElement("h3", undefined, "Zapier"), zapierDescription$p(ctx), React.createElement("a", {
                      className: "btn btn-default " + styles.configure,
                      href: "https://zapier.com/apps/planado/integrations",
                      target: "_blank"
                    }, configure$p(ctx), El.space, React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "external-link-alt")
                        }))) : null, Context_Types.Features.hasFlag("bitrix24", ctx.features) ? React.createElement("li", undefined, React.createElement("h3", undefined, bitrix24$p(ctx)), React.createElement("span", undefined, bitrix24Description$p(ctx)), React.createElement(Link.make, {
                      wire: wire,
                      route: Context_Types.Features.hasFlag("multipleBitrixSettings", ctx.features) ? ({
                            NAME: "Integrations",
                            VAL: {
                              NAME: "Bitrix24",
                              VAL: "Index"
                            }
                          }) : ({
                            NAME: "Integrations",
                            VAL: {
                              NAME: "Bitrix24",
                              VAL: {
                                NAME: "Edit",
                                VAL: {
                                  category: undefined,
                                  bitrixTenantUid: "primary"
                                }
                              }
                            }
                          }),
                      className: "btn btn-default " + styles.configure,
                      children: configure$p(ctx)
                    })) : null, Context_Types.Features.hasFlag("amocrm", ctx.features) ? React.createElement("li", undefined, React.createElement("h3", undefined, amocrm$p(ctx)), React.createElement("span", undefined, amocrmDescription$p(ctx)), React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "Integrations",
                        VAL: {
                          NAME: "amoCRM",
                          VAL: {
                            NAME: "Show",
                            VAL: [
                              ctx.localizator.lang !== "ru",
                              undefined
                            ]
                          }
                        }
                      },
                      className: "btn btn-default " + styles.configure,
                      children: configure$p(ctx)
                    })) : null);
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "page-header"
                        }, React.createElement("div", {
                              className: "row"
                            }, React.createElement("h1", {
                                  className: styles.header
                                }, settings$p(ctx), tmp))), React.createElement("div", {
                          className: "row"
                        }, tmp$2))));
}

var make = Integrations_Index;

export {
  make ,
}
/* styles Not a pure module */
