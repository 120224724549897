

import * as React from "react";
import * as IconModuleCss from "/home/runner/work/planado/planado/client/rescript/Icon.module.css";

var styles = IconModuleCss;

var Size = {};

var Style = {};

var Gauge = {};

var Icon = {};

function className(s) {
  if (s === "warning") {
    return styles.warning;
  } else if (s === "pending") {
    return styles.pending;
  } else if (s === "success") {
    return styles.success;
  } else {
    return styles.error;
  }
}

function style(size, spinOpt, style$1, status, icon) {
  var spin = spinOpt !== undefined ? spinOpt : false;
  var fasize = size !== undefined ? "fa-" + size : "";
  var style$2 = style$1 !== undefined ? "fa-" + style$1 : "";
  var status$1 = status !== undefined ? className(status) : "";
  var prefix = icon === "cc-jcb" || icon === "cc-mastercard" || icon === "cc-paypal" || icon === "cc-visa" ? "fab" : (
      icon === "map" ? "far" : (
          icon === "ruble-sign" ? "fas" : "fa"
        )
    );
  var spin$1 = spin ? "fa-spin" : "";
  return prefix + " " + fasize + " fa-" + icon + " " + spin$1 + " " + style$2 + " " + status$1;
}

function Icon$1(Props) {
  var size = Props.size;
  var spin = Props.spin;
  var style$1 = Props.style;
  var status = Props.status;
  var icon = Props.icon;
  return React.createElement("i", {
              className: style(size, spin, style$1, status, icon)
            });
}

var Status = {};

var make = Icon$1;

export {
  Size ,
  Style ,
  Gauge ,
  Icon ,
  Status ,
  style ,
  make ,
}
/* styles Not a pure module */
