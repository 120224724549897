

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Tour from "../../../libraries/tours/Tour.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as Shared_Lib_Array from "../../../shared/lib/Shared_Lib_Array.mjs";
import * as MultipleAssignees_Team from "./MultipleAssignees_Team.mjs";
import * as MultipleAssignees_Input from "./MultipleAssignees_Input.mjs";
import * as MultipleAssignees_Utils from "./MultipleAssignees_Utils.mjs";
import * as MultipleAssignees_Worker from "./MultipleAssignees_Worker.mjs";
import * as MultipleAssignees_Options from "./MultipleAssignees_Options.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as MultipleAssigneesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/MultipleAssignees/MultipleAssignees.module.css";

var noEditAccess$p = Utils.Translations.tr("js.components.assignee_select.no_edit_access");

var noSkills$p = Utils.Translations.txr("js.components.assignee_select.no_skills");

var noTerritory$p = Utils.Translations.txr("js.components.assignee_select.no_territory");

var emptyTeam$p = Utils.Translations.t("js.components.assignee_select.empty_team");

var add$p = Utils.Translations.tr("js.components.assignee_select.add");

var styles = MultipleAssigneesModuleCss;

function pickTeamForeman(team) {
  if (team.foreman !== undefined) {
    return team;
  }
  var worker = RCore.$$Array.getBy(team.workers, (function (w) {
          return w.permission === "Edit";
        }));
  if (worker !== undefined) {
    return {
            uuid: team.uuid,
            name: team.name,
            foreman: worker,
            workers: RCore.$$Array.keep(team.workers, (function (w) {
                    return !Uuid.equal(w.uuid, worker.uuid);
                  })),
            temporaryWorkers: team.temporaryWorkers,
            skills: team.skills,
            territory: team.territory
          };
  } else {
    return team;
  }
}

function placeNewAssignee(newAssignee, assignees) {
  if (newAssignee.TAG === "Team") {
    if (assignees.length !== 0) {
      return assignees;
    } else {
      return [{
                TAG: "Team",
                _0: pickTeamForeman(newAssignee._0)
              }];
    }
  }
  var worker = newAssignee._0;
  if (worker.permission === "Edit") {
    if (assignees.some(function (a) {
            return MultipleAssignee.canEdit(a);
          })) {
      return Belt_Array.concatMany([
                  assignees,
                  [{
                      TAG: "Worker",
                      _0: {
                        TAG: "View",
                        _0: worker
                      }
                    }]
                ]);
    } else {
      return Belt_Array.concatMany([
                  [{
                      TAG: "Worker",
                      _0: {
                        TAG: "Edit",
                        _0: worker
                      }
                    }],
                  assignees
                ]);
    }
  } else {
    return Belt_Array.concatMany([
                assignees,
                [{
                    TAG: "Worker",
                    _0: {
                      TAG: "View",
                      _0: worker
                    }
                  }]
              ]);
  }
}

function pickEditAssignee(assignees) {
  var match = RCore.$$Array.getBy(assignees, (function (a) {
          return MultipleAssignee.canEdit(a);
        }));
  if (match === undefined) {
    return assignees;
  }
  if (match.TAG === "Team") {
    return assignees;
  }
  var edit = match._0;
  if (edit.TAG === "Edit") {
    return assignees;
  }
  var assignee = {
    TAG: "Worker",
    _0: {
      TAG: "Edit",
      _0: edit._0
    }
  };
  return Belt_Array.concatMany([
              [assignee],
              RCore.$$Array.keep(assignees, (function (a) {
                      return !MultipleAssignee.eql(assignee, a);
                    }))
            ]);
}

function selectBySelected(possibleAssignee, selected) {
  if (selected.length === 0) {
    return true;
  }
  if (possibleAssignee.TAG === "Team") {
    return false;
  }
  var w = possibleAssignee._0;
  return selected.every(function (a) {
              return !MultipleAssignee.haveWorker(a, w);
            });
}

function selectBySkills(possibleAssignee, skillUuids) {
  if (skillUuids.length === 0) {
    return true;
  }
  var skills;
  skills = possibleAssignee.TAG === "Team" ? possibleAssignee._0.skills : possibleAssignee._0.skills;
  var assigneeSkills = skills.map(function (s) {
        return s.uuid;
      });
  return skillUuids.every(function (u) {
              return assigneeSkills.some(function (a) {
                          return Uuid.equal(a, u);
                        });
            });
}

function selectByTerritory(possibleAssignee, territoryUuid) {
  if (territoryUuid === undefined) {
    return true;
  }
  var uuid = Caml_option.valFromOption(territoryUuid);
  if (possibleAssignee.TAG === "Team") {
    var ter = possibleAssignee._0.territory;
    if (ter !== undefined) {
      return Uuid.equal(uuid, ter.uuid);
    } else {
      return false;
    }
  }
  var ter$1 = possibleAssignee._0.territory;
  if (ter$1 !== undefined) {
    return Uuid.equal(uuid, ter$1.uuid);
  } else {
    return false;
  }
}

function selectByFilter(possibleAssignee, filter) {
  switch (filter) {
    case "All" :
        return true;
    case "Teams" :
        return MultipleAssignee.PossibleAssignee.isTeam(possibleAssignee);
    case "Workers" :
        return !MultipleAssignee.PossibleAssignee.isTeam(possibleAssignee);
    
  }
}

function MultipleAssignees(Props) {
  var inputId = Props.inputId;
  var fallbackTeamName = Props.fallbackTeamName;
  var availableAt = Props.availableAt;
  var selectedAssignees = Props.selectedAssignees;
  var territoryUuid = Props.territoryUuid;
  var skillUuids = Props.skillUuids;
  var availableSkills = Props.availableSkills;
  var disabled = Props.disabled;
  var onChange = Props.onChange;
  var openUp = Props.openUp;
  var inputId$1 = Hooks.useId(inputId);
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var tour = Tour.use(wire);
  var match = React.useState(function () {
        if (selectedAssignees.length === 0) {
          return fallbackTeamName === undefined;
        } else {
          return false;
        }
      });
  var setShowInput = match[1];
  var showInput = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setInputValue = match$1[1];
  var inputValue = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setFocused = match$2[1];
  var focused = match$2[0];
  var setFocused$1 = function (f) {
    var focused = f();
    if (focused) {
      tour.trigger("assigneeSelectShown");
    }
    setFocused(function (param) {
          return focused;
        });
  };
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowEditError = match$3[1];
  var showEditError = match$3[0];
  var match$4 = React.useState(function () {
        return [];
      });
  var setMissingSkills = match$4[1];
  var missingSkills = match$4[0];
  var match$5 = React.useState(function () {
        return [];
      });
  var setMissingTerritories = match$5[1];
  var missingTerritories = match$5[0];
  var match$6 = React.useState(function () {
        return "All";
      });
  var setActiveFilters = match$6[1];
  var activeFilters = match$6[0];
  var match$7 = React.useState(function () {
        return MultipleAssignees_Options.ChipState.$$default;
      });
  var setActiveChips = match$7[1];
  var activeChips = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setCursor = match$8[1];
  var cursor = match$8[0];
  var decoration = !disabled && showEditError ? "Error" : (
      !disabled && (Caml_obj.notequal(missingSkills, []) || Caml_obj.notequal(missingTerritories, [])) ? "Warning" : undefined
    );
  var teamsEnabled = Context_Types.Features.hasFlag("teams", ctx.features);
  var showAddButton = function () {
    if (!disabled && teamsEnabled && MultipleAssignee.workerNumberValid(selectedAssignees) && !showInput) {
      return !focused;
    } else {
      return false;
    }
  };
  var filteredAssignees = RCore.$$Array.keep(ctx.allAssignees, (function (p) {
          if (MultipleAssignee.PossibleAssignee.canEdit(p) || teamsEnabled) {
            if (selectBySelected(p, selectedAssignees) && (!activeChips.skills || selectBySkills(p, skillUuids)) && (!activeChips.territory || selectByTerritory(p, territoryUuid)) && selectByFilter(p, activeFilters)) {
              var inputValue$1 = RCore.$$Option.getOr(inputValue, "");
              var name;
              if (p.TAG === "Team") {
                var t = p._0;
                name = Js_string.concatMany(t.workers.map(function (a) {
                          return a.name;
                        }), t.name);
              } else {
                name = p._0.name;
              }
              return Fun.stringContains(inputValue$1, name);
            } else {
              return false;
            }
          } else {
            return false;
          }
        }));
  var elementRef = Types.ReactRef.use();
  var close = function () {
    setFocused$1(function () {
          return false;
        });
    setInputValue(function (param) {
          
        });
    setActiveFilters(function (param) {
          return "All";
        });
    setActiveChips(function (param) {
          return MultipleAssignees_Options.ChipState.$$default;
        });
  };
  Hooks.useOnClickOutside(undefined, elementRef, close);
  var checkWarnings = function (skills, assignees) {
    if (assignees.length === 0) {
      setShowEditError(function (param) {
            return false;
          });
      setMissingSkills(function (param) {
            return [];
          });
      return setMissingTerritories(function (param) {
                  return [];
                });
    } else {
      setShowEditError(function (param) {
            return !assignees.some(function (a) {
                        return MultipleAssignee.canEdit(a);
                      });
          });
      setMissingSkills(function (param) {
            var assigneeSkills = (function (__x) {
                  return Shared_Lib_Array.flatMap((function (a) {
                                return MultipleAssignee.skills(a);
                              }), __x);
                })(assignees);
            var missingSkills = RCore.$$Array.keep(skills, (function (skill) {
                    return !assigneeSkills.some(function (a) {
                                return Uuid.equal(skill, a.uuid);
                              });
                  }));
            return RCore.$$Array.keep(availableSkills, (function (a) {
                          return missingSkills.some(function (m) {
                                      return Uuid.equal(m, a.uuid);
                                    });
                        }));
          });
      return setMissingTerritories(function (param) {
                  if (territoryUuid === undefined) {
                    return [];
                  }
                  var territory = Caml_option.valFromOption(territoryUuid);
                  return RCore.$$Array.filterMap(assignees, (function (a) {
                                var userTerritory = MultipleAssignee.territory(a);
                                if (userTerritory !== undefined && !Uuid.equal(userTerritory.uuid, territory)) {
                                  return userTerritory.name;
                                }
                                
                              }));
                });
    }
  };
  React.useEffect((function () {
          checkWarnings(skillUuids, selectedAssignees);
        }), [
        territoryUuid,
        skillUuids.length,
        selectedAssignees.flatMap(MultipleAssignee.SelectedAssignee.encode).length
      ]);
  var onChangeInner = function (values, withActualTempWorkersOpt) {
    var withActualTempWorkers = withActualTempWorkersOpt !== undefined ? withActualTempWorkersOpt : true;
    if (withActualTempWorkers) {
      onChange(MultipleAssignees_Utils.withActualTempWorkers(values, availableAt, ctx.allAssignees));
    } else {
      onChange(values);
    }
    if (values.length === 0 && fallbackTeamName === undefined) {
      setShowInput(function (param) {
            return true;
          });
    }
    setCursor(function (param) {
          
        });
  };
  var updateChips = function (newState) {
    setActiveChips(function (param) {
          return newState;
        });
    setCursor(function (param) {
          
        });
    setFocused$1(function () {
          return true;
        });
  };
  var onFilterClick = function (filter) {
    setActiveFilters(function (param) {
          return filter;
        });
    setCursor(function (param) {
          
        });
  };
  var onPick = function (assignee) {
    if (tour.trigger({
            NAME: "assigneeChosen",
            VAL: MultipleAssignee.PossibleAssignee.uuid(assignee)
          })) {
      close();
      onChangeInner(placeNewAssignee(assignee, selectedAssignees), undefined);
      setShowInput(function (param) {
            return false;
          });
      return setInputValue(function (param) {
                  
                });
    }
    
  };
  var onNavigation = function (action) {
    switch (action) {
      case "Up" :
          return setCursor(function (param) {
                      if (cursor !== undefined && cursor > 0) {
                        return cursor - 1 | 0;
                      } else {
                        return 0;
                      }
                    });
      case "Down" :
          return setCursor(function (param) {
                      var max = filteredAssignees.length;
                      if (cursor !== undefined && cursor < (max - 1 | 0)) {
                        return cursor + 1 | 0;
                      } else {
                        return 0;
                      }
                    });
      case "Submit" :
          RCore.$$Option.map(RCore.$$Option.flatMap(cursor, (function (c) {
                      return filteredAssignees[c];
                    })), (function (a) {
                  onPick(a);
                }));
          return ;
      
    }
  };
  var onAddAssignee = function (evt) {
    evt.preventDefault();
    setShowInput(function (param) {
          return true;
        });
    setFocused$1(function () {
          return true;
        });
  };
  var selectedBlock = React.createElement("div", {
        className: styles.selected
      }, selectedAssignees.map(function (a) {
            if (a.TAG === "Team") {
              var team = a._0;
              return React.createElement(MultipleAssignees_Team.make, {
                          team: team,
                          availableAt: RCore.$$Option.map(availableAt, (function (t) {
                                  return {
                                          TAG: "Specific",
                                          _0: t
                                        };
                                })),
                          onWorkerDelete: (function (worker) {
                              var assignees = RCore.$$Array.filterMap(selectedAssignees, (function (a) {
                                      if (a.TAG !== "Team") {
                                        return a;
                                      }
                                      var match = a._0;
                                      var foreman = match.foreman;
                                      if (!Uuid.equal(match.uuid, team.uuid)) {
                                        return a;
                                      }
                                      var newForeman = foreman !== undefined && Uuid.equal(foreman.uuid, worker.uuid) ? undefined : foreman;
                                      var newTeam_uuid = team.uuid;
                                      var newTeam_name = team.name;
                                      var newTeam_workers = RCore.$$Array.keep(team.workers, (function (w) {
                                              return !Uuid.equal(w.uuid, worker.uuid);
                                            }));
                                      var newTeam_temporaryWorkers = RCore.$$Array.keep(team.temporaryWorkers, (function (w) {
                                              return !Uuid.equal(w.uuid, worker.uuid);
                                            }));
                                      var newTeam_skills = team.skills;
                                      var newTeam_territory = team.territory;
                                      var newTeam = {
                                        uuid: newTeam_uuid,
                                        name: newTeam_name,
                                        foreman: newForeman,
                                        workers: newTeam_workers,
                                        temporaryWorkers: newTeam_temporaryWorkers,
                                        skills: newTeam_skills,
                                        territory: newTeam_territory
                                      };
                                      if (newForeman !== undefined || newTeam_workers.length !== 0) {
                                        return {
                                                TAG: "Team",
                                                _0: pickTeamForeman(newTeam)
                                              };
                                      }
                                      
                                    }));
                              onChangeInner(pickEditAssignee(assignees), false);
                            }),
                          onDelete: (function () {
                              var assignees = RCore.$$Array.keep(selectedAssignees, (function (a) {
                                      if (a.TAG === "Team" && Uuid.equal(a._0.uuid, team.uuid)) {
                                        return false;
                                      } else {
                                        return true;
                                      }
                                    }));
                              onChangeInner(pickEditAssignee(assignees), undefined);
                            }),
                          onTempWorkerDelete: (function (worker) {
                              var assignees = RCore.$$Array.filterMap(selectedAssignees, (function (a) {
                                      if (a.TAG !== "Team") {
                                        return a;
                                      }
                                      var match = a._0;
                                      if (Uuid.equal(match.uuid, team.uuid)) {
                                        return {
                                                TAG: "Team",
                                                _0: {
                                                  uuid: team.uuid,
                                                  name: team.name,
                                                  foreman: team.foreman,
                                                  workers: team.workers,
                                                  temporaryWorkers: RCore.$$Array.keep(match.temporaryWorkers, (function (w) {
                                                          if (!Uuid.equal(w.uuid, worker.uuid) || Caml_obj.notequal(w.dateFrom, worker.dateFrom)) {
                                                            return true;
                                                          } else {
                                                            return Caml_obj.notequal(w.dateTo, worker.dateTo);
                                                          }
                                                        })),
                                                  skills: team.skills,
                                                  territory: team.territory
                                                }
                                              };
                                      } else {
                                        return a;
                                      }
                                    }));
                              onChangeInner(pickEditAssignee(assignees), false);
                            }),
                          disabled: disabled,
                          decoration: decoration,
                          key: Uuid.toString(team.uuid)
                        });
            }
            var worker = a._0;
            return React.createElement(MultipleAssignees_Worker.make, {
                        worker: {
                          TAG: "Worker",
                          _0: worker
                        },
                        onDelete: (function () {
                            var assignees = RCore.$$Array.keep(selectedAssignees, (function (a) {
                                    if (a.TAG === "Team") {
                                      return true;
                                    } else {
                                      return !Uuid.equal(a._0._0.uuid, MultipleAssignee.$$Worker.uuid(worker));
                                    }
                                  }));
                            onChangeInner(pickEditAssignee(assignees), undefined);
                          }),
                        teamMember: false,
                        disabled: disabled,
                        decoration: decoration,
                        single: selectedAssignees.length === 1,
                        key: Uuid.toString(MultipleAssignee.$$Worker.uuid(worker))
                      });
          }), fallbackTeamName !== undefined && Caml_obj.equal(selectedAssignees, []) ? React.createElement("div", {
              className: styles.fallbackContainer
            }, React.createElement("div", {
                  className: styles.fallbackName
                }, El.Cn.concat(fallbackTeamName, "(" + emptyTeam$p(ctx) + ")"))) : null, React.createElement(Optional.make, {
            show: showAddButton(),
            children: React.createElement("div", {
                  className: styles.add,
                  onClick: onAddAssignee
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, undefined, undefined, "plus")
                    }), El.space, add$p(ctx))
          }));
  var inputBlock = React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
            show: showInput,
            children: React.createElement(MultipleAssignees_Input.make, {
                  inputId: inputId$1,
                  value: inputValue,
                  setValue: setInputValue,
                  setFocused: setFocused$1,
                  autoFocus: focused,
                  disabled: disabled,
                  onNavigation: onNavigation
                })
          }), React.createElement(Optional.make, {
            show: focused,
            children: React.createElement(MultipleAssignees_Options.make, {
                  possibleAssignees: filteredAssignees,
                  cursor: cursor,
                  activeChips: activeChips,
                  updateChips: updateChips,
                  activeFilters: activeFilters,
                  onFilterClick: onFilterClick,
                  onPick: onPick,
                  openUp: openUp,
                  territoryUuid: territoryUuid,
                  skillUuids: skillUuids
                })
          }), React.createElement(Optional.make, {
            show: !disabled,
            children: React.createElement("div", {
                  className: styles.errors
                }, React.createElement(Optional.make, {
                      show: showEditError,
                      children: React.createElement("div", {
                            className: styles.errorText
                          }, noEditAccess$p(ctx))
                    }), React.createElement(Optional.make, {
                      show: missingSkills.length > 0,
                      children: React.createElement("div", {
                            className: styles.warningText
                          }, noSkills$p({
                                skills: Utils.Translations.joinWithComma(missingSkills.map(function (a) {
                                          return a.name;
                                        }))
                              }, ctx))
                    }), React.createElement(Optional.make, {
                      show: missingTerritories.length > 0,
                      children: React.createElement("div", {
                            className: styles.warningText
                          }, noTerritory$p({
                                count: missingTerritories.length,
                                territories: Utils.Translations.joinWithComma(missingTerritories)
                              }, ctx))
                    }))
          }));
  return React.createElement("div", {
              ref: elementRef,
              className: styles.container
            }, openUp ? React.createElement(React.Fragment, undefined, inputBlock, selectedBlock) : React.createElement(React.Fragment, undefined, selectedBlock, inputBlock));
}

function make(inputId, selectedAssignees, fallbackTeamName, availableAt, territoryUuid, skillUuids, availableSkills, disabled, onChange, openUp) {
  return {
          inputId: inputId,
          selectedAssignees: selectedAssignees,
          fallbackTeamName: fallbackTeamName,
          availableAt: availableAt,
          territoryUuid: territoryUuid,
          skillUuids: skillUuids,
          availableSkills: availableSkills,
          disabled: disabled,
          onChange: onChange,
          openUp: openUp
        };
}

function decode(jsProps) {
  return make(jsProps.inputId, Core__Result.getOr(Json_Decode$JsonCombinators.decode(jsProps.selectedAssignees, MultipleAssignee.decode), []), Caml_option.undefined_to_opt(jsProps.fallbackTeamName), Caml_option.undefined_to_opt(jsProps.availableAt), RCore.$$Option.map(Caml_option.null_to_opt(jsProps.territoryUuid), Uuid.fromString), jsProps.skillUuids.map(Uuid.fromString), jsProps.availableSkills, jsProps.disabled, (function (a) {
                jsProps.onChange(MultipleAssignee.encode(a));
              }), RCore.$$Option.getOr(Caml_option.undefined_to_opt(jsProps.openUp), false));
}

var Props = {
  make: make,
  decode: decode
};

function $$default(jsProps) {
  var props = decode(jsProps);
  return MultipleAssignees({
              inputId: props.inputId,
              selectedAssignees: props.selectedAssignees,
              fallbackTeamName: props.fallbackTeamName,
              availableAt: props.availableAt,
              territoryUuid: props.territoryUuid,
              skillUuids: props.skillUuids,
              availableSkills: props.availableSkills,
              disabled: props.disabled,
              onChange: props.onChange,
              openUp: props.openUp
            });
}

var make$1 = MultipleAssignees;

export {
  make$1 as make,
  Props ,
  $$default as default,
}
/* noEditAccess' Not a pure module */
