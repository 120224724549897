

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Option from "../../../shared/lib/Shared_Lib_Option.mjs";
import * as OldFileInputModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/OldFileInput/OldFileInput.module.css";

var clear$p = Utils.Translations.tr("js.components.file_input.clear");

var select$p = Utils.Translations.tr("js.components.file_input.select");

var chooseFile$p = Utils.Translations.tr("js.components.file_input.choose_file");

var styles = OldFileInputModuleCss;

function OldFileInput$FileName(Props) {
  var ctx = Props.ctx;
  var file = Props.file;
  var tmp;
  if (file !== undefined) {
    var file$1 = Caml_option.valFromOption(file);
    var fileSize = Utils.Translations.fileSize(file$1.size | 0);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("span", undefined, file$1.name), React.createElement("span", {
              className: styles.fileSize
            }, "(" + fileSize + ")"));
  } else {
    tmp = React.createElement("span", undefined, chooseFile$p(ctx));
  }
  return React.createElement("div", {
              className: styles.fileNameWrapper
            }, tmp);
}

function className(error) {
  return RCore.$$Option.map(error, (function (param) {
                return "has-error";
              }));
}

function OldFileInput$Error(Props) {
  var text = Props.text;
  if (text !== undefined) {
    return React.createElement("div", {
                className: "pd-control-error"
              }, React.createElement("div", undefined, text));
  } else {
    return null;
  }
}

function OldFileInput$ClearButton(Props) {
  var ctx = Props.ctx;
  var clearFile = Props.clearFile;
  var disabled = Props.disabled;
  return React.createElement("a", {
              className: "btn btn-xs",
              disabled: disabled,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  $$event.stopPropagation();
                  if (!disabled) {
                    return clearFile();
                  }
                  
                })
            }, clear$p(ctx), " ", React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, undefined, "times")
                }));
}

function OldFileInput$ChooseButton(Props) {
  var ctx = Props.ctx;
  var chooseFile = Props.chooseFile;
  var disabled = Props.disabled;
  return React.createElement("a", {
              className: "btn btn-xs btn-primary pfi-choose " + styles.btnChoose,
              disabled: disabled,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  $$event.stopPropagation();
                  if (!disabled) {
                    return chooseFile();
                  }
                  
                })
            }, select$p(ctx));
}

function OldFileInput(Props) {
  var ctx = Props.ctx;
  var onFileChooseAction = Props.onFileChooseAction;
  var onFileClearAction = Props.onFileClearAction;
  var file = Props.file;
  var id = Props.id;
  var limit = Props.limit;
  var disabledOpt = Props.disabled;
  var nameOpt = Props.name;
  var error = Props.error;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var name = nameOpt !== undefined ? nameOpt : "file";
  var inputRef = React.useRef(null);
  var handleClickChoose = function (_event) {
    var ref = inputRef.current;
    if (!(ref == null)) {
      return ref.click();
    }
    
  };
  var handleChange = function ($$event) {
    var target = $$event.target;
    var jsFile = Shared_Lib_Option.flatten(target.files[0]);
    if (jsFile === undefined) {
      return ;
    }
    var jsFile$1 = Caml_option.valFromOption(jsFile);
    if (limit !== undefined && (jsFile$1.size | 0) > limit[0]) {
      alert(limit[1]);
      return ;
    } else {
      return onFileChooseAction(jsFile$1);
    }
  };
  var id$1 = Hooks.useId(id);
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.inputContainer, className(error)),
              onClick: handleClickChoose
            }, React.createElement("input", {
                  ref: inputRef,
                  id: id$1,
                  disabled: disabled,
                  name: name,
                  type: "file",
                  onChange: handleChange
                }), React.createElement(OldFileInput$FileName, {
                  ctx: ctx,
                  file: file
                }), React.createElement("div", {
                  className: styles.buttons
                }, file !== undefined ? React.createElement(OldFileInput$ClearButton, {
                        ctx: ctx,
                        clearFile: onFileClearAction,
                        disabled: disabled
                      }) : null, React.createElement(OldFileInput$ChooseButton, {
                      ctx: ctx,
                      chooseFile: handleClickChoose,
                      disabled: disabled
                    })), React.createElement(OldFileInput$Error, {
                  text: error
                }));
}

var make = OldFileInput;

export {
  make ,
}
/* clear' Not a pure module */
