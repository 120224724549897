

import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as React from "react";
import * as ShiftsIndexWorkerCell from "./ShiftsIndexWorkerCell.mjs";
import * as ShiftsIndexWorkersModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/index/workers/ShiftsIndexWorkers.module.css";

var styles = ShiftsIndexWorkersModuleCss;

function ShiftsIndexWorkers$Names(Props) {
  var workers = Props.workers;
  var constructorWorkers = Props.constructorWorkers;
  var deleteConstructorWorker = Props.deleteConstructorWorker;
  var deleteConstructorWorkerContainer = function (uuid) {
    var match = RCore.$$Array.getBy(constructorWorkers, (function (cu) {
            return Uuid.equal(cu.uuid, uuid);
          }));
    if (match === undefined) {
      return null;
    }
    var match$1 = match.dates;
    var uuid$1 = match.uuid;
    if (match$1 !== undefined && match$1[1] !== undefined) {
      return React.createElement("div", {
                  className: styles.nameCancelConstr,
                  onClick: (function (param) {
                      deleteConstructorWorker(uuid$1);
                    })
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, undefined, undefined, "times-circle")
                    }));
    } else {
      return null;
    }
  };
  var rowCN = function (uuid) {
    var match = RCore.$$Array.getBy(constructorWorkers, (function (cu) {
            return Uuid.equal(cu.uuid, uuid);
          }));
    if (match === undefined) {
      return styles.name;
    }
    var match$1 = match.dates;
    if (match$1 !== undefined && match$1[1] !== undefined) {
      return styles.name + " " + styles.nameConstructor;
    } else {
      return styles.name;
    }
  };
  return React.createElement("div", {
              className: styles.sidebar
            }, workers.map(function (param) {
                  var uuid = param.uuid;
                  return React.createElement("div", {
                              key: Uuid.toString(uuid),
                              className: rowCN(uuid)
                            }, React.createElement("span", undefined, param.name), deleteConstructorWorkerContainer(uuid));
                }));
}

function ShiftsIndexWorkers$Row(Props) {
  var worker = Props.worker;
  var updateWorker = Props.updateWorker;
  var constructorWorker = Props.constructorWorker;
  var updateConstructorWorker = Props.updateConstructorWorker;
  var dateRange = Props.dateRange;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var mode = Props.mode;
  return React.createElement("div", {
              className: styles.row
            }, dateRange.map(function (day, idx) {
                  return React.createElement(ShiftsIndexWorkerCell.make, {
                              day: day,
                              worker: worker,
                              jobTypes: jobTypes,
                              territories: territories,
                              updateWorker: updateWorker,
                              constructorWorker: constructorWorker,
                              updateConstructorWorker: updateConstructorWorker,
                              mode: mode,
                              key: idx.toString()
                            });
                }));
}

function ShiftsIndexWorkers(Props) {
  var dateRange = Props.dateRange;
  var workers = Props.workers;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var constructorWorkers = Props.constructorWorkers;
  var deleteConstructorWorker = Props.deleteConstructorWorker;
  var updateWorker = Props.updateWorker;
  var updateConstructorWorker = Props.updateConstructorWorker;
  var mode = Props.mode;
  return React.createElement(React.Fragment, undefined, React.createElement(ShiftsIndexWorkers$Names, {
                  workers: workers,
                  constructorWorkers: constructorWorkers,
                  deleteConstructorWorker: deleteConstructorWorker
                }), React.createElement("div", {
                  className: styles.table
                }, workers.map(function (worker) {
                      return React.createElement(ShiftsIndexWorkers$Row, {
                                  worker: worker,
                                  updateWorker: updateWorker,
                                  constructorWorker: RCore.$$Array.getBy(constructorWorkers, (function (cu) {
                                          return Uuid.equal(cu.uuid, worker.uuid);
                                        })),
                                  updateConstructorWorker: updateConstructorWorker,
                                  dateRange: dateRange,
                                  jobTypes: jobTypes,
                                  territories: territories,
                                  mode: mode,
                                  key: Uuid.toString(worker.uuid)
                                });
                    })));
}

var make = ShiftsIndexWorkers;

export {
  make ,
}
/* styles Not a pure module */
