// extracted by mini-css-extract-plugin
export var checkbox = "checkbox-R0Uj1Kko8UPDA3pKd7wd";
export var column = "column-MqX2pwrdiS0TGouPg0R1";
export var dropdownList = "dropdown-list-SueM7MtauGC4Bw3qTBt4";
export var fieldsBlock = "fields-block-MVVZpkkXSpEZnrPPjgJM";
export var fieldsHeader = "fields-header-BwsyGTCewAIeJD1m4p8g";
export var fieldsWrapper = "fields-wrapper-QgdzmXFdmIE78qkn9hRA";
export var footer = "footer-RSBbI8FcQtB4hGlukG2W";
export var noExtraBottomPadding = "no-extra-bottom-padding-REmWG3wCt2iRyX4kYPHF";
export var resetLink = "reset-link-lPzfmlqhDHeFM1XbaCwc";
export var saveButton = "save-button-QufyJpOZ9shPVivS_tMa";
export var sectionTitle = "section-title-ereRaVih6mIqxb2z4bSz";
export var sectionTitleSuffix = "section-title-suffix-YX3uaq01Eh4pfGV4tH0m";
export var selectedTemplates = "selected-templates-j88xx8K7ebtYzEuT436h";
export var teaser = "teaser-bdPjPa0lhpNfeuoFjtQV";
export var teaserLogo = "teaser-logo-qJgSmxbMqeIjkLAtXmqv";
export var templatesBlock = "templates-block-DDIMSDJYiBgwrLitGegR";
export var templatesTitleWrapper = "templates-title-wrapper-LYJtnyN9V4WTwBN1iAuZ";
export var wordBreak = "word-break-Y69N0D4TmN5o29hr02fx";
export var wrapper = "wrapper-_ZCE6jGKIF6UmU1Okn1x";