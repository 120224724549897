

import * as MomentRe from "../bindings/moment/MomentRe.mjs";
import * as HelpersJs from "planado/utils/time/helpers.js";

function _formatString(format) {
  switch (format) {
    case "Day" :
        return "day";
    case "Date" :
        return "date";
    case "Time" :
        return "time";
    case "TimeSeconds" :
        return "time_seconds";
    case "DateTime" :
        return "datetime";
    case "DateTimeNoYear" :
        return "datetime_no_year";
    case "DateShort" :
        return "date_short";
    case "DateFullNoYear" :
        return "date_full_no_year";
    case "DateFullMonthAbbr" :
        return "date_full_month_abbr";
    case "DateFullNoDay" :
        return "date_full_no_day";
    case "DateNoYear" :
        return "date_no_year";
    case "DayAndMonthAbbr" :
        return "day_and_month_abbr";
    case "MonthAbbrAndDay" :
        return "month_abbr_and_day";
    case "DateFull" :
        return "date_full";
    case "DateWithDayOfWeek" :
        return "date_with_day_of_week";
    case "DateWithMonthAbbrAndDayOfWeek" :
        return "date_with_month_abbr_and_day_of_week";
    case "MonthWithFullYear" :
        return "month_with_full_year";
    case "Month" :
        return "month";
    case "TimeStamp" :
        return "timestamp";
    
  }
}

function durationFormatWithLocalizator(format, localizator, value) {
  return MomentRe.durationFormat(value, HelpersJs.toMomentJSFormat(HelpersJs.presentFormatWithLocalizator(_formatString(format), localizator)));
}

var T = {
  _formatString: _formatString,
  durationFormatWithLocalizator: durationFormatWithLocalizator
};

export {
  T ,
}
/* MomentRe Not a pure module */
