

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as EncodeJson from "../../../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../types/Types_Date.mjs";
import * as JobFieldType from "../JobFieldType.mjs";

function toString(version) {
  switch (version) {
    case "V1" :
        return "v1";
    case "V2" :
        return "v2";
    case "V3" :
        return "v3";
    case "V4" :
        return "v4";
    case "V5" :
        return "v5";
    case "V6" :
        return "v6";
    case "V7" :
        return "v7";
    
  }
}

function fromString(version) {
  switch (version) {
    case "v1" :
        return "V1";
    case "v2" :
        return "V2";
    case "v3" :
        return "V3";
    case "v4" :
        return "V4";
    case "v5" :
        return "V5";
    case "v6" :
        return "V6";
    case "v7" :
        return "V7";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Version = {
  toString: toString,
  fromString: fromString,
  currentVersion: "V7",
  decode: decode
};

function toString$1(virtual_) {
  switch (virtual_) {
    case "OdometerM" :
        return "odometerM";
    case "PhotoArchiveUrl" :
        return "photoArchiveUrl";
    case "PhotoArchiveUrlShared" :
        return "photoArchiveUrlShared";
    
  }
}

function fromString$1(virtual_) {
  switch (virtual_) {
    case "odometerM" :
    case "odometer_m" :
        return "OdometerM";
    case "photoArchiveUrl" :
        return "PhotoArchiveUrl";
    case "photoArchiveUrlShared" :
        return "PhotoArchiveUrlShared";
    default:
      return ;
  }
}

var allFields = [
  "PhotoArchiveUrl",
  "PhotoArchiveUrlShared",
  "OdometerM"
];

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

function fromString$2(column) {
  switch (column) {
    case "amount" :
        return "Amount";
    case "price" :
        return "Price";
    case "quantity" :
        return "Quantity";
    default:
      return ;
  }
}

function toString$2(column) {
  switch (column) {
    case "Price" :
        return "price";
    case "Quantity" :
        return "quantity";
    case "Amount" :
        return "amount";
    
  }
}

var decode$2 = JsonDecode.fromVariant(JsonDecode.string, fromString$2);

var decode$3 = JsonDecode.object(function (field) {
      return {
              serviceUuid: field.required("serviceUuid", Uuid.decode),
              columns: field.required("columns", JsonDecode.array(decode$2))
            };
    });

function encode(data) {
  return EncodeJson.object([
              [
                "serviceUuid",
                EncodeJson.string(Uuid.toString(data.serviceUuid))
              ],
              [
                "columns",
                EncodeJson.array(function (col) {
                        return EncodeJson.string(toString$2(col));
                      })(data.columns)
              ]
            ]);
}

function toString$3(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Canceled" :
        return "canceled";
    case "Finished" :
        return "finished";
    
  }
}

function fromString$3(status) {
  switch (status) {
    case "canceled" :
        return "Canceled";
    case "enRoute" :
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

var decode$4 = JsonDecode.map(JsonDecode.string, fromString$3);

var Status = {
  toString: toString$3,
  fromString: fromString$3,
  decode: decode$4
};

function toString$4(resolution) {
  if (resolution === "Unsuccessful") {
    return "UNSUCCESSFUL";
  } else {
    return "SUCCESSFUL";
  }
}

function fromString$4(resolution) {
  switch (resolution) {
    case "SUCCESSFUL" :
        return "Successful";
    case "UNSUCCESSFUL" :
        return "Unsuccessful";
    default:
      return ;
  }
}

var decode$5 = JsonDecode.fromVariant(JsonDecode.string, fromString$4);

function toString$5(scheduled) {
  return "LAST_WEEK";
}

function fromString$5(scheduled) {
  if (scheduled === "LAST_WEEK") {
    return "LastWeek";
  }
  
}

var decode$6 = JsonDecode.fromVariant(JsonDecode.string, fromString$5);

var Bounds = {};

var makeEmpty_jobFields = [];

var makeEmpty_customFields = [];

var makeEmpty_reportFields = [];

var makeEmpty_virtual = [];

var makeEmpty_catalog = [];

var makeEmpty = {
  jobFields: makeEmpty_jobFields,
  customFields: makeEmpty_customFields,
  reportFields: makeEmpty_reportFields,
  virtual: makeEmpty_virtual,
  catalog: makeEmpty_catalog
};

var makeDefault_jobFields = [
  "Address",
  "Client",
  "Template",
  "ScheduledAt",
  "Assignee",
  "Status",
  "TotalAmount"
];

var makeDefault_customFields = [];

var makeDefault_reportFields = [];

var makeDefault_virtual = [];

var makeDefault_catalog = [];

var makeDefault = {
  jobFields: makeDefault_jobFields,
  customFields: makeDefault_customFields,
  reportFields: makeDefault_reportFields,
  virtual: makeDefault_virtual,
  catalog: makeDefault_catalog
};

var Columns = {
  makeEmpty: makeEmpty,
  makeDefault: makeDefault
};

var Days = {};

var decode$7 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "now" :
            return "Now";
        case "relative" :
            return {
                    TAG: "Relative",
                    _0: field.required("days", JsonDecode.$$int)
                  };
        case "specific" :
            return {
                    TAG: "Specific",
                    _0: field.required("date", Types_Date.decode)
                  };
        default:
          return JsonDecode.cannotDecode("ExpectedValidOption");
      }
    });

var $$Range = {
  decode: decode$7
};

var decode$8 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "in" :
            return {
                    TAG: "In",
                    _0: field.required("interval", JsonDecode.object(function (field) {
                              return [
                                      field.required("daysFromToday", JsonDecode.$$int),
                                      field.required("rangeDays", JsonDecode.$$int)
                                    ];
                            }))
                  };
        case "specific" :
            return {
                    TAG: "Specific",
                    _0: field.required("interval", JsonDecode.object(function (field) {
                              return [
                                      field.required("start", Types_Date.decode),
                                      field.required("finish", Types_Date.decode)
                                    ];
                            }))
                  };
        case "starting" :
            return {
                    TAG: "Starting",
                    _0: field.required("interval", JsonDecode.object(function (field) {
                              return [
                                      field.required("daysFromToday", JsonDecode.$$int),
                                      field.required("rangeDays", JsonDecode.$$int)
                                    ];
                            }))
                  };
        default:
          return JsonDecode.cannotDecode("ExpectedValidOption");
      }
    });

var Interval = {
  decode: decode$8
};

function decode$9(field) {
  var match = field.required("type", JsonDecode.string);
  switch (match) {
    case "any" :
        return "Any";
    case "earlier" :
        return {
                TAG: "Earlier",
                _0: field.required("values", decode$7)
              };
    case "eql" :
        return {
                TAG: "Eql",
                _0: field.required("values", JsonDecode.object(function (field) {
                          return field.required("date", Types_Date.decode);
                        }))
              };
    case "interval" :
        return {
                TAG: "Interval",
                _0: field.required("values", decode$8)
              };
    case "later" :
        return {
                TAG: "Later",
                _0: field.required("values", decode$7)
              };
    case "not_filled" :
        return "NotFilled";
    default:
      return JsonDecode.cannotDecode("ExpectedValidOption");
  }
}

var DateFilter = {
  Days: Days,
  $$Range: $$Range,
  Interval: Interval,
  decode: decode$9
};

var Template = {};

function fromString$6(territory) {
  if (territory === "withoutTerritory") {
    return "WithoutTerritory";
  } else if (Uuid.test(territory)) {
    return {
            TAG: "Uuid",
            _0: Uuid.fromString(territory)
          };
  } else {
    return ;
  }
}

function toString$6(territory) {
  if (typeof territory !== "object") {
    return "withoutTerritory";
  } else {
    return Uuid.toString(territory._0);
  }
}

var decode$10 = JsonDecode.fromVariant(JsonDecode.string, fromString$6);

var Territory = {
  fromString: fromString$6,
  toString: toString$6,
  decode: decode$10
};

var decode$11 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              type_: field.required("type", JsonDecode.string)
            };
    });

var Client = {
  decode: decode$11
};

var $$Worker = {};

var Team = {};

var Resolution = {};

function decodeString(dictionaryValue) {
  switch (dictionaryValue) {
    case "any" :
        return "Any";
    case "not_filled" :
        return "NotFilled";
    default:
      return ;
  }
}

var decode$12 = JsonDecode.object(function (field) {
      var variant = RCore.$$Option.flatMap(field.optional("type", JsonDecode.string), decodeString);
      if (variant !== undefined) {
        return variant;
      } else {
        return {
                TAG: "Filled",
                _0: field.required("values", JsonDecode.array(Uuid.decode))
              };
      }
    });

var Dictionary = {
  decodeString: decodeString,
  decode: decode$12
};

function decodeString$1(stringValue) {
  switch (stringValue) {
    case "any" :
        return "Any";
    case "not_filled" :
        return "NotFilled";
    default:
      return ;
  }
}

var decode$13 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "any" :
            return "Any";
        case "not_filled" :
            return "NotFilled";
        default:
          return {
                  TAG: "Filled",
                  _0: RCore.$$Option.flatMap(field.optional("value", JsonDecode.option(JsonDecode.string)), (function (x) {
                          return x;
                        }))
                };
      }
    });

var $$String = {
  decodeString: decodeString$1,
  decode: decode$13
};

function fromBoolean(polyTypeValue) {
  if (polyTypeValue) {
    return "Any";
  } else {
    return "NotFilled";
  }
}

function toBoolean(polyTypeValue) {
  if (polyTypeValue === "NotFilled") {
    return false;
  } else {
    return true;
  }
}

function decodeString$2(polyTypeValue) {
  switch (polyTypeValue) {
    case "any" :
        return "Any";
    case "not_filled" :
        return "NotFilled";
    default:
      return ;
  }
}

var decode$14 = JsonDecode.object(function (field) {
      return field.required("type", JsonDecode.fromVariant(JsonDecode.string, decodeString$2));
    });

var decode$15 = JsonDecode.object(function (field) {
      return {
              start: field.optional("start", JsonDecode.string),
              finish: field.optional("finish", JsonDecode.string)
            };
    });

var Interval$1 = {
  decode: decode$15
};

var decode$16 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "any" :
            return "Any";
        case "eql" :
            return {
                    TAG: "Eql",
                    _0: field.required("value", JsonDecode.string)
                  };
        case "interval" :
            return {
                    TAG: "Interval",
                    _0: field.required("value", decode$15)
                  };
        case "not_filled" :
            return "NotFilled";
        default:
          return JsonDecode.cannotDecode("ExpectedValidOption");
      }
    });

var $$Number = {
  Interval: Interval$1,
  decode: decode$16
};

function decode$17(field) {
  var match = field.required("dataType", JsonDecode.string);
  switch (match) {
    case "[uuid]" :
        return {
                TAG: "Uuids",
                _0: field.required("uuids", decode$14)
              };
    case "attachment" :
        return {
                TAG: "Attachment",
                _0: field.required("attachmentValue", decode$14)
              };
    case "boolean" :
        return {
                TAG: "Bool",
                _0: field.required("booleanValue", JsonDecode.bool)
              };
    case "currency" :
        return {
                TAG: "Currency",
                _0: field.required("currencyValue", decode$14)
              };
    case "date" :
        return {
                TAG: "Dates",
                _0: field.required("dateValue", JsonDecode.object(decode$9))
              };
    case "decimal" :
        return {
                TAG: "Decimal",
                _0: field.required("decimalValue", decode$16)
              };
    case "dictionary" :
        return {
                TAG: "Dictionary",
                _0: field.required("dictionaryValue", decode$12)
              };
    case "duration" :
        return {
                TAG: "Duration",
                _0: field.required("durationValue", decode$14)
              };
    case "geolocation" :
        return {
                TAG: "Geolocation",
                _0: field.required("geolocationValue", decode$14)
              };
    case "integer" :
        return {
                TAG: "Integer",
                _0: field.required("integerValue", decode$16)
              };
    case "string" :
        var match$1 = field.optional("fieldType", JsonDecode.string);
        if (match$1 === "barcode") {
          return {
                  TAG: "Barcode",
                  _0: field.required("stringValue", decode$14)
                };
        } else {
          return {
                  TAG: "String",
                  _0: field.required("stringValue", decode$13)
                };
        }
    default:
      return JsonDecode.cannotDecode("ExpectedValidOption");
  }
}

var decode$18 = JsonDecode.object(function (field) {
      return {
              key: field.required("key", JobFieldType.decode),
              value: decode$17(field)
            };
    });

var PredefinedField = {
  decode: decode$18
};

var decode$19 = JsonDecode.object(function (field) {
      return {
              typeUuid: field.required("typeUuid", Uuid.decode),
              value: decode$17(field)
            };
    });

var CustomField = {
  decode: decode$19
};

var makeEmpty_teams = [];

var makeEmpty_workers = [];

var makeEmpty_statuses = [];

var makeEmpty_templates = [];

var makeEmpty_resolutions = [];

var makeEmpty_canceledResolutions = [];

var makeEmpty_territories = [];

var makeEmpty_predefinedFields = [];

var makeEmpty_customFields$1 = [];

var makeEmpty_reportFields$1 = [];

var makeEmpty$1 = {
  teams: makeEmpty_teams,
  client: undefined,
  workers: makeEmpty_workers,
  statuses: makeEmpty_statuses,
  templates: makeEmpty_templates,
  resolutions: makeEmpty_resolutions,
  canceledResolutions: makeEmpty_canceledResolutions,
  territories: makeEmpty_territories,
  finishedAt: undefined,
  scheduledAt: undefined,
  createdAt: undefined,
  suspendedAt: undefined,
  resumedAt: undefined,
  predefinedFields: makeEmpty_predefinedFields,
  customFields: makeEmpty_customFields$1,
  reportFields: makeEmpty_reportFields$1
};

var Virtual = {
  toString: toString$1,
  allFields: allFields,
  decode: decode$1
};

var Catalog_Columns = {
  toString: toString$2
};

var Catalog = {
  Columns: Catalog_Columns,
  decode: decode$3,
  encode: encode
};

var Resolutions = {
  toString: toString$4,
  decode: decode$5
};

var Scheduled = {
  toString: toString$5,
  decode: decode$6
};

var Settings_Filter = {
  DateFilter: DateFilter,
  Template: Template,
  Territory: Territory,
  Client: Client,
  $$Worker: $$Worker,
  Team: Team,
  Resolution: Resolution,
  Value: {
    Dictionary: Dictionary,
    $$String: $$String,
    PolytypeAnyOrEmpty: {
      fromBoolean: fromBoolean,
      toBoolean: toBoolean,
      decode: decode$14
    },
    $$Number: $$Number,
    decode: decode$17
  },
  PredefinedField: PredefinedField,
  CustomField: CustomField,
  makeEmpty: makeEmpty$1
};

var Settings = {
  Columns: Columns,
  Filter: Settings_Filter
};

var maxViewNameLength = 50;

export {
  Version ,
  Virtual ,
  Catalog ,
  Status ,
  Resolutions ,
  Scheduled ,
  Bounds ,
  Settings ,
  maxViewNameLength ,
}
/* decode Not a pure module */
