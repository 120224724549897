

import * as El from "../libraries/El.mjs";
import * as React from "react";
import * as Optional from "../components/common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UI_TabsModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_Tabs.module.css";

var styles = UI_TabsModuleCss;

function UI_Tabs$Tab(Props) {
  var active = Props.active;
  var renderLabel = Props.renderLabel;
  var onSelect = Props.onSelect;
  var children = Props.children;
  var labelContent = renderLabel();
  var onClick = function ($$event) {
    $$event.preventDefault();
    if (!active) {
      return onSelect();
    }
    
  };
  var className = active ? El.Cn.concat(styles.label, styles.active) : styles.label;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: className,
                  onClick: onClick
                }, labelContent), React.createElement(Optional.make, {
                  show: active,
                  children: React.createElement("div", {
                        className: styles.tab
                      }, children)
                }));
}

var Tab = {
  make: UI_Tabs$Tab
};

function UI_Tabs(Props) {
  var addon = Props.addon;
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.tabs, className)
            }, children, addon !== undefined ? React.createElement("div", {
                    className: styles.addon
                  }, Caml_option.valFromOption(addon)) : null);
}

var make = UI_Tabs;

export {
  Tab ,
  make ,
}
/* styles Not a pure module */
