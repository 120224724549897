

import * as Icon from "../../../../../../../Icon.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_JobCore from "../../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as WeekCalendar_JobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/job/WeekCalendar_Job.module.css";

var noAssignee$p = Utils.Translations.tr("js.schedule.v2.job.without_assignee");

var dots$p = Utils.Translations.tr("js.schedule.v2.job.dots");

var styles = WeekCalendar_JobModuleCss;

function WeekCalendar_JobContent(Props) {
  var compact = Props.compact;
  var job = Props.job;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var template = Schedule_Types_Job.ScheduledJob.template(job);
  var address = Schedule_Types_Job.ScheduledJob.address(job);
  if (compact) {
    return React.createElement("div", {
                className: styles.compact
              }, dots$p(ctx));
  }
  var templateBlock = template !== undefined ? React.createElement("div", {
          className: styles.template
        }, template.name) : null;
  var match$1 = Schedule_Types_Job.ScheduledJob.assignees(job)[0];
  var assigneeBlock;
  var exit = 0;
  var name;
  if (match$1 !== undefined) {
    if (match$1.TAG === "Team") {
      name = match$1._0.name;
      exit = 1;
    } else {
      name = match$1._0._0.name;
      exit = 1;
    }
  } else {
    assigneeBlock = React.createElement("div", {
          className: styles.noAssignee
        }, React.createElement("i", {
              className: Icon.style(undefined, undefined, undefined, undefined, "user")
            }), noAssignee$p(ctx));
  }
  if (exit === 1) {
    assigneeBlock = React.createElement("div", {
          className: styles.assignee
        }, name);
  }
  var addressBlock = address !== undefined ? React.createElement("div", {
          className: styles.address
        }, Schedule_Types_JobCore.Address.toString(Caml_option.valFromOption(address))) : null;
  return React.createElement("div", {
              className: styles.container
            }, templateBlock, assigneeBlock, addressBlock);
}

var make = WeekCalendar_JobContent;

export {
  make ,
}
/* noAssignee' Not a pure module */
