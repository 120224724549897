

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Spinner from "../common/Spinner/Spinner.mjs";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as MomentRe from "../../bindings/moment/MomentRe.mjs";
import * as Token_API from "../../Token_API.mjs";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as UI_PageTabs from "../../ui/UI_PageTabs.mjs";
import * as Reports_Jobs from "./Reports_Jobs.mjs";
import * as Reports_Workers from "./Reports_Workers.mjs";
import * as Shared_Lib_Timer from "../../shared/lib/Shared_Lib_Timer.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var pageTitle$p = Utils.Translations.tr("js.reports.title");

var jobsTab$p = Utils.Translations.tr("js.reports.jobs.title");

var workersTab$p = Utils.Translations.tr("js.reports.workers.title");

var styles = ReportsModuleCss;

function Reports_Index(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        return "100vh";
      });
  var setIframeHeight = match[1];
  var iframeHeight = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setToken = match$1[1];
  var token = match$1[0];
  var handleSizeEvent = function (evt) {
    var data = evt.data;
    setIframeHeight(function (param) {
          return PervasivesU.string_of_float(data.height) + "px";
        });
  };
  var refreshToken = function () {
    Token_API.Refresh.post(wire);
    setToken(function (param) {
          return wire.token.contents;
        });
  };
  var checkToken = function () {
    var match = wire.tokenPayload.contents;
    if (match !== undefined && match.exp >= MomentRe.Moment.unix(Locale.T.now(wire))) {
      return ;
    } else {
      return refreshToken();
    }
  };
  React.useEffect((function () {
          DomUtils.Listeners.onMessage(handleSizeEvent);
          setToken(function (param) {
                return wire.token.contents;
              });
          var unsub = Shared_Lib_Timer.repeat(1000, (function () {
                  checkToken();
                }));
          return (function () {
                    DomUtils.Listeners.removeOnMessage(handleSizeEvent);
                    unsub();
                  });
        }), []);
  return React.createElement(UI_Page.make, {
              children: null
            }, React.createElement("div", {
                  className: styles.header
                }, React.createElement("h1", undefined, pageTitle$p(wire.ctx))), React.createElement(UI_PageTabs.make, {
                  children: null
                }, React.createElement(UI_PageTabs.Tab.make, {
                      route: {
                        NAME: "Reports",
                        VAL: "Jobs"
                      },
                      renderLabel: (function () {
                          return jobsTab$p(wire.ctx);
                        }),
                      children: token !== undefined ? React.createElement(Reports_Jobs.make, {
                              wire: wire,
                              iframeHeight: iframeHeight,
                              token: token
                            }) : React.createElement(Spinner.make, {})
                    }), React.createElement(UI_PageTabs.Tab.make, {
                      route: {
                        NAME: "Reports",
                        VAL: "Workers"
                      },
                      renderLabel: (function () {
                          return workersTab$p(wire.ctx);
                        }),
                      children: token !== undefined ? React.createElement(Reports_Workers.make, {
                              wire: wire,
                              iframeHeight: iframeHeight,
                              token: token
                            }) : React.createElement(Spinner.make, {})
                    })));
}

var make = Reports_Index;

export {
  make ,
}
/* pageTitle' Not a pure module */
