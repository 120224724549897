

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../../../../../Portal.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as UI_Icon from "../../../../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../../inputs/Checkbox.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_UnscheduledFilterByTemplatesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/uscheduled_jobs/components/filter/Schedule_UnscheduledFilterByTemplates.module.css";

var styles = Schedule_UnscheduledFilterByTemplatesModuleCss;

var label$p = Utils.Translations.tr("js.schedule.v2.filter.templates_label");

var empty$p = Utils.Translations.tr("js.schedule.v2.filter.empty.templates");

function Schedule_UnscheduledFilterByTemplates$Button(Props) {
  var selected = Props.selected;
  var active = Props.active;
  var togglePopover = Props.togglePopover;
  var resetFilter = Props.resetFilter;
  var classes = El.Cn.concat(El.Cn.concat(styles.button, selected ? styles.selected : ""), active ? styles.active : "");
  return React.createElement("button", {
              className: classes,
              type: "button",
              onClick: togglePopover
            }, React.createElement(UI_Icon.make, {
                  icon: "filter"
                }), React.createElement(Optional.make, {
                  show: selected,
                  children: React.createElement("div", {
                        className: styles.resetButton,
                        onClick: resetFilter
                      }, React.createElement("span", {
                            className: Icon.style(undefined, undefined, undefined, undefined, "times")
                          }))
                }));
}

var Button = {
  make: Schedule_UnscheduledFilterByTemplates$Button
};

function Schedule_UnscheduledFilterByTemplates$Popover(Props) {
  var filterVariants = Props.filterVariants;
  var selectedFilterVariants = Props.selectedFilterVariants;
  var onToggle = Props.onToggle;
  var close = Props.close;
  var ctx = Props.ctx;
  var popoverRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, popoverRef, close);
  Hooks.useOnEscPress(undefined, close);
  var itemsBlock = Caml_obj.equal(filterVariants, []) ? React.createElement("div", {
          className: styles.empty
        }, empty$p(ctx)) : React.createElement("div", {
          className: styles.items
        }, filterVariants.map(function (param) {
              var uuid = param.uuid;
              return React.createElement(Checkbox.make, {
                          onToggle: (function (param) {
                              onToggle(uuid);
                            }),
                          checked: selectedFilterVariants.some(function (u) {
                                return Caml_obj.equal(u, uuid);
                              }),
                          label: param.name,
                          wrapperClasses: Js_dict.fromList({
                                hd: [
                                  styles.item,
                                  true
                                ],
                                tl: /* [] */0
                              }),
                          key: Uuid.toString(uuid)
                        });
            }));
  return React.createElement("div", {
              ref: popoverRef,
              className: styles.popover
            }, React.createElement("label", {
                  className: styles.label
                }, label$p(ctx)), itemsBlock);
}

var Popover = {
  make: Schedule_UnscheduledFilterByTemplates$Popover
};

function toggle(uuids, uuid) {
  var opt = RCore.$$Option.map(RCore.$$Array.getBy(uuids, (function (u) {
              return Caml_obj.equal(u, uuid);
            })), (function (uuid) {
          return RCore.$$Array.keep(uuids, (function (u) {
                        return Caml_obj.notequal(u, uuid);
                      }));
        }));
  if (opt !== undefined) {
    return opt;
  } else {
    return Belt_Array.concatMany([
                uuids,
                [uuid]
              ]);
  }
}

function Schedule_UnscheduledFilterByTemplates(Props) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var filterVariants = ctx.templates.map(function (t) {
        return {
                uuid: t.uuid,
                name: t.name
              };
      });
  var match$1 = Schedule_State.useFilter();
  var selectedFilterVariants = match$1.templates;
  var setFilter = Schedule_State.useSetFilterTemplates();
  var match$2 = React.useState(function () {
        return false;
      });
  var setShow = match$2[1];
  var show = match$2[0];
  var togglePopover = function (_event) {
    setShow(function (param) {
          return !show;
        });
  };
  var closePopover = function () {
    setShow(function (param) {
          return false;
        });
  };
  var resetFilter = function ($$event) {
    $$event.stopPropagation();
    setShow(function (param) {
          return false;
        });
    setFilter([]);
  };
  var toggleFilterVariant = function (uuid) {
    setFilter(toggle(selectedFilterVariants, uuid));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Schedule_UnscheduledFilterByTemplates$Button, {
                  selected: Caml_obj.notequal(selectedFilterVariants, []),
                  active: show,
                  togglePopover: togglePopover,
                  resetFilter: resetFilter
                }), React.createElement(Optional.make, {
                  show: show,
                  children: React.createElement(Portal.make, {
                        root: match.footer,
                        children: React.createElement(Schedule_UnscheduledFilterByTemplates$Popover, {
                              filterVariants: filterVariants,
                              selectedFilterVariants: selectedFilterVariants,
                              onToggle: toggleFilterVariant,
                              close: closePopover,
                              ctx: ctx
                            })
                      })
                }));
}

var make = Schedule_UnscheduledFilterByTemplates;

export {
  styles ,
  label$p ,
  empty$p ,
  Button ,
  Popover ,
  toggle ,
  make ,
}
/* styles Not a pure module */
