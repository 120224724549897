

import * as Url from "../../../bindings/url/Url.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromString(s) {
  switch (s) {
    case "disabled" :
        return "Disabled";
    case "enabled" :
        return "Enabled";
    case "locked" :
        return "Locked";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

function toString(s) {
  switch (s) {
    case "Enabled" :
        return "enabled";
    case "Disabled" :
        return "disabled";
    case "Suspended" :
        return "suspended";
    case "Locked" :
        return "locked";
    
  }
}

function inverse(s) {
  switch (s) {
    case "Enabled" :
        return "Disabled";
    case "Suspended" :
        return "Suspended";
    case "Disabled" :
    case "Locked" :
        return "Enabled";
    
  }
}

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              url: field.required("url", Url.decode),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              eventTypes: field.required("eventTypes", JsonDecode.array(JsonDecode.string)),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString))
            };
    });

var Webhook = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", JsonDecode.string),
              sentAt: field.required("sentAt", Types.Time.decode),
              errorType: field.required("errorType", JsonDecode.option(JsonDecode.string)),
              errorDescription: field.required("errorDescription", JsonDecode.option(JsonDecode.string)),
              httpResponseCode: field.required("httpResponseCode", JsonDecode.option(JsonDecode.$$int)),
              eventType: field.required("eventType", JsonDecode.string),
              entityType: field.required("entityType", JsonDecode.string),
              entityUuid: field.required("entityUuid", Uuid.decode),
              entityVersion: field.required("entityVersion", JsonDecode.$$int)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", JsonDecode.string),
              sentAt: field.required("sentAt", Types.Time.decode),
              errorType: field.required("errorType", JsonDecode.option(JsonDecode.string)),
              errorDescription: field.required("errorDescription", JsonDecode.option(JsonDecode.string)),
              httpResponseCode: field.required("httpResponseCode", JsonDecode.option(JsonDecode.$$int)),
              httpResponseHeaders: field.required("httpResponseHeaders", JsonDecode.option(JsonDecode.string)),
              httpResponseBody: field.required("httpResponseBody", JsonDecode.option(JsonDecode.string)),
              httpRequestUrl: field.required("httpRequestUrl", Url.decode),
              httpRequestMethod: field.required("httpRequestMethod", JsonDecode.string),
              httpRequestHeaders: field.required("httpRequestHeaders", JsonDecode.string),
              httpRequestBody: field.required("httpRequestBody", JsonDecode.string),
              entityType: field.required("entityType", JsonDecode.string),
              entityUuid: field.required("entityUuid", Uuid.decode),
              entityVersion: field.required("entityVersion", JsonDecode.$$int),
              eventType: field.required("eventType", JsonDecode.string),
              attemptNo: field.required("attemptNo", JsonDecode.$$int),
              requestTimeMs: field.required("requestTimeMs", JsonDecode.$$int)
            };
    });

var WebhookDelivery = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      var url = field.required("url", Url.decode);
      return {
              uuid: field.required("uuid", Uuid.decode),
              url: Caml_option.some(url),
              urlInput: Url.href(url),
              secret: field.required("secret", JsonDecode.option(JsonDecode.string)),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              eventTypes: field.required("eventTypes", JsonDecode.array(JsonDecode.string)),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString)),
              version: field.required("version", JsonDecode.$$int),
              alternativeVersions: field.required("alternativeVersions", JsonDecode.array(JsonDecode.$$int)),
              deliveries: field.required("deliveries", JsonDecode.array(decode$1))
            };
    });

var State = {
  decode: decode$3
};

var maxWebhooks = 10;

var Status = {
  toString: toString,
  inverse: inverse
};

var WebhookDeliveryShort = {};

export {
  maxWebhooks ,
  Status ,
  Webhook ,
  WebhookDeliveryShort ,
  WebhookDelivery ,
  State ,
}
/* decode Not a pure module */
