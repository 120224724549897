

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as EncodeJson from "../../../../libraries/EncodeJson.mjs";
import * as JobFieldType from "../JobFieldType.mjs";
import * as JobViewTypes from "./JobViewTypes.mjs";

function encode(data) {
  return EncodeJson.object([
              [
                "jobFields",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(JobFieldType.toString(v));
                      })(data.jobFields)
              ],
              [
                "customFields",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(data.customFields)
              ],
              [
                "reportFields",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(data.reportFields)
              ],
              [
                "virtual",
                EncodeJson.array(function (data) {
                        return EncodeJson.string(JobViewTypes.Virtual.toString(data));
                      })(data.virtual)
              ],
              [
                "catalog",
                EncodeJson.array(function (data) {
                        return JobViewTypes.Catalog.encode(data);
                      })(data.catalog)
              ]
            ]);
}

function encodeDateValues(type_, values) {
  return EncodeJson.object([
              [
                "type",
                EncodeJson.string(type_)
              ],
              [
                "values",
                values
              ]
            ]);
}

function encodeDate(x) {
  return EncodeJson.string(Locale.T.formatM("DateISO")(x));
}

function encode$1(value) {
  if (typeof value !== "object") {
    if (value === "NotFilled") {
      return encodeDateValues("not_filled", EncodeJson.$$null);
    } else {
      return encodeDateValues("any", EncodeJson.$$null);
    }
  }
  switch (value.TAG) {
    case "Eql" :
        return encodeDateValues("eql", EncodeJson.object([
                        [
                          "type",
                          EncodeJson.string("eql")
                        ],
                        [
                          "date",
                          encodeDate(value._0)
                        ]
                      ]));
    case "Earlier" :
        var date = value._0;
        if (typeof date !== "object") {
          return encodeDateValues("earlier", EncodeJson.object([[
                            "type",
                            EncodeJson.string("now")
                          ]]));
        } else if (date.TAG === "Relative") {
          return encodeDateValues("earlier", EncodeJson.object([
                          [
                            "type",
                            EncodeJson.string("relative")
                          ],
                          [
                            "days",
                            EncodeJson.$$int(date._0)
                          ]
                        ]));
        } else {
          return encodeDateValues("earlier", EncodeJson.object([
                          [
                            "type",
                            EncodeJson.string("specific")
                          ],
                          [
                            "date",
                            encodeDate(date._0)
                          ]
                        ]));
        }
    case "Later" :
        var date$1 = value._0;
        if (typeof date$1 !== "object") {
          return encodeDateValues("later", EncodeJson.object([[
                            "type",
                            EncodeJson.string("now")
                          ]]));
        } else if (date$1.TAG === "Relative") {
          return encodeDateValues("later", EncodeJson.object([
                          [
                            "type",
                            EncodeJson.string("relative")
                          ],
                          [
                            "days",
                            EncodeJson.$$int(date$1._0)
                          ]
                        ]));
        } else {
          return encodeDateValues("later", EncodeJson.object([
                          [
                            "type",
                            EncodeJson.string("specific")
                          ],
                          [
                            "date",
                            encodeDate(date$1._0)
                          ]
                        ]));
        }
    case "Interval" :
        var match = value._0;
        switch (match.TAG) {
          case "Starting" :
              var match$1 = match._0;
              return encodeDateValues("interval", EncodeJson.object([
                              [
                                "type",
                                EncodeJson.string("starting")
                              ],
                              [
                                "interval",
                                EncodeJson.object([
                                      [
                                        "daysFromToday",
                                        EncodeJson.$$int(match$1[0])
                                      ],
                                      [
                                        "rangeDays",
                                        EncodeJson.$$int(match$1[1])
                                      ]
                                    ])
                              ]
                            ]));
          case "In" :
              var match$2 = match._0;
              return encodeDateValues("interval", EncodeJson.object([
                              [
                                "type",
                                EncodeJson.string("in")
                              ],
                              [
                                "interval",
                                EncodeJson.object([
                                      [
                                        "daysFromToday",
                                        EncodeJson.$$int(match$2[0])
                                      ],
                                      [
                                        "rangeDays",
                                        EncodeJson.$$int(match$2[1])
                                      ]
                                    ])
                              ]
                            ]));
          case "Specific" :
              var match$3 = match._0;
              return encodeDateValues("interval", EncodeJson.object([
                              [
                                "type",
                                EncodeJson.string("specific")
                              ],
                              [
                                "interval",
                                EncodeJson.object([
                                      [
                                        "start",
                                        encodeDate(match$3[0])
                                      ],
                                      [
                                        "finish",
                                        encodeDate(match$3[1])
                                      ]
                                    ])
                              ]
                            ]));
          
        }
    
  }
}

function encode$2(template) {
  if (typeof template !== "object") {
    return EncodeJson.string("withoutTemplate");
  } else {
    return EncodeJson.string(Uuid.toString(template._0));
  }
}

var toString = JobViewTypes.Settings.Filter.Territory.toString;

function encode$3(data) {
  return EncodeJson.object([
              [
                "uuid",
                EncodeJson.string(Uuid.toString(data.uuid))
              ],
              [
                "name",
                EncodeJson.string(data.name)
              ],
              [
                "type",
                EncodeJson.string(data.type_)
              ]
            ]);
}

function encodeDictValues(type_, values) {
  return EncodeJson.object([
              [
                "type",
                EncodeJson.string(type_)
              ],
              [
                "values",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(values)
              ]
            ]);
}

function encodeStringValue(type_, value) {
  return EncodeJson.object([
              [
                "type",
                EncodeJson.string(type_)
              ],
              [
                "value",
                EncodeJson.option(EncodeJson.string)(value)
              ]
            ]);
}

function encodeFilledValue(type_) {
  return EncodeJson.object([[
                "type",
                EncodeJson.string(type_)
              ]]);
}

function encodeFilledType(value) {
  if (value === "NotFilled") {
    return encodeFilledValue("not_filled");
  } else {
    return encodeFilledValue("any");
  }
}

function removeNulls(l) {
  return RCore.$$Array.keep(l, (function (param) {
                return Caml_obj.notequal(param[1], EncodeJson.$$null);
              }));
}

function encodeIntervalValue(value) {
  return EncodeJson.object(removeNulls([
                  [
                    "start",
                    EncodeJson.option(EncodeJson.string)(value.start)
                  ],
                  [
                    "finish",
                    EncodeJson.option(EncodeJson.string)(value.finish)
                  ]
                ]));
}

function encodeNumberIntervalType(type_, value) {
  return EncodeJson.object([
              [
                "type",
                EncodeJson.string(type_)
              ],
              [
                "values",
                encodeIntervalValue(value)
              ]
            ]);
}

function encodeNumberEqlType(value) {
  return EncodeJson.object([
              [
                "type",
                EncodeJson.string("eql")
              ],
              [
                "value",
                EncodeJson.string(value)
              ]
            ]);
}

function encodeIntegerType(value) {
  if (value.TAG !== "Integer") {
    return EncodeJson.$$null;
  }
  var v = value._0;
  if (typeof v !== "object") {
    if (v === "NotFilled") {
      return encodeFilledValue("not_filled");
    } else {
      return encodeFilledValue("any");
    }
  }
  if (v.TAG === "Eql") {
    return encodeNumberEqlType(v._0);
  }
  var i = v._0;
  if (i.start !== undefined || i.finish !== undefined) {
    return encodeNumberIntervalType("interval", i);
  } else {
    return encodeFilledValue("any");
  }
}

function encodeDecimalType(value) {
  if (value.TAG !== "Decimal") {
    return EncodeJson.$$null;
  }
  var v = value._0;
  if (typeof v !== "object") {
    if (v === "NotFilled") {
      return encodeFilledValue("not_filled");
    } else {
      return encodeFilledValue("any");
    }
  }
  if (v.TAG === "Eql") {
    return encodeNumberEqlType(v._0);
  }
  var i = v._0;
  if (i.start !== undefined || i.finish !== undefined) {
    return encodeNumberIntervalType("interval", i);
  } else {
    return encodeFilledValue("any");
  }
}

function encodeDictType(value) {
  if (value.TAG !== "Dictionary") {
    return EncodeJson.$$null;
  }
  var values = value._0;
  if (typeof values !== "object") {
    if (values === "Any") {
      return encodeDictValues("any", []);
    } else {
      return encodeDictValues("not_filled", []);
    }
  } else {
    return encodeDictValues("filled", values._0);
  }
}

function encodeStringType(value) {
  switch (value.TAG) {
    case "String" :
        var value$1 = value._0;
        if (typeof value$1 !== "object") {
          if (value$1 === "NotFilled") {
            return encodeStringValue("not_filled", undefined);
          } else {
            return encodeStringValue("any", undefined);
          }
        } else {
          return encodeStringValue("filled", value$1._0);
        }
    case "Barcode" :
        return encodeFilledType(value._0);
    default:
      return EncodeJson.$$null;
  }
}

function encodeBoolType(value) {
  if (value.TAG === "Bool") {
    return EncodeJson.bool(value._0);
  } else {
    return EncodeJson.$$null;
  }
}

function encodePolytypeAnyOrEmpty(value, predicate) {
  switch (value.TAG) {
    case "Attachment" :
        if (predicate === "Attachment") {
          return encodeFilledType(value._0);
        } else {
          return EncodeJson.$$null;
        }
    case "Currency" :
        if (predicate === "Currency") {
          return encodeFilledType(value._0);
        } else {
          return EncodeJson.$$null;
        }
    case "Duration" :
        if (predicate === "Duration") {
          return encodeFilledType(value._0);
        } else {
          return EncodeJson.$$null;
        }
    case "Geolocation" :
        if (predicate === "Geolocation") {
          return encodeFilledType(value._0);
        } else {
          return EncodeJson.$$null;
        }
    case "Uuids" :
        if (predicate === "Uuids") {
          return encodeFilledType(value._0);
        } else {
          return EncodeJson.$$null;
        }
    default:
      return EncodeJson.$$null;
  }
}

function encodeDateType(value) {
  if (value.TAG === "Dates") {
    return encode$1(value._0);
  } else {
    return EncodeJson.$$null;
  }
}

function encodeType(value) {
  switch (value.TAG) {
    case "Bool" :
        return "boolean";
    case "Dates" :
        return "date";
    case "Dictionary" :
        return "dictionary";
    case "Decimal" :
        return "decimal";
    case "Integer" :
        return "integer";
    case "Attachment" :
        return "attachment";
    case "String" :
    case "Barcode" :
        return "string";
    case "Currency" :
        return "currency";
    case "Duration" :
        return "duration";
    case "Geolocation" :
        return "geolocation";
    case "Uuids" :
        return "[uuid]";
    
  }
}

function encodeFieldType(value) {
  switch (value.TAG) {
    case "Bool" :
        return "checkbox";
    case "Dates" :
        return "date_picker";
    case "Dictionary" :
        return "dictionary";
    case "Attachment" :
        return "signature";
    case "Barcode" :
        return "barcode";
    case "Currency" :
        return "currency";
    case "Duration" :
        return "duration";
    case "Geolocation" :
        return "geolocation";
    case "Uuids" :
        return "[uuid]";
    default:
      return "input";
  }
}

function encode$4(data) {
  return EncodeJson.object([
              [
                "typeUuid",
                EncodeJson.string(Uuid.toString(data.typeUuid))
              ],
              [
                "dictionaryValue",
                encodeDictType(data.value)
              ],
              [
                "stringValue",
                encodeStringType(data.value)
              ],
              [
                "integerValue",
                encodeIntegerType(data.value)
              ],
              [
                "decimalValue",
                encodeDecimalType(data.value)
              ],
              [
                "currencyValue",
                encodePolytypeAnyOrEmpty(data.value, "Currency")
              ],
              [
                "attachmentValue",
                encodePolytypeAnyOrEmpty(data.value, "Attachment")
              ],
              [
                "booleanValue",
                encodeBoolType(data.value)
              ],
              [
                "dateValue",
                encodeDateType(data.value)
              ],
              [
                "dataType",
                EncodeJson.string(encodeType(data.value))
              ],
              [
                "fieldType",
                EncodeJson.string(encodeFieldType(data.value))
              ]
            ]);
}

function encode$5(data) {
  return EncodeJson.object([
              [
                "key",
                EncodeJson.string(JobFieldType.toString(data.key))
              ],
              [
                "dictionaryValue",
                encodeDictType(data.value)
              ],
              [
                "booleanValue",
                encodeBoolType(data.value)
              ],
              [
                "dateValue",
                encodeDateType(data.value)
              ],
              [
                "dataType",
                EncodeJson.string(encodeType(data.value))
              ],
              [
                "geolocationValue",
                encodePolytypeAnyOrEmpty(data.value, "Geolocation")
              ],
              [
                "durationValue",
                encodePolytypeAnyOrEmpty(data.value, "Duration")
              ],
              [
                "stringValue",
                encodeStringType(data.value)
              ],
              [
                "uuids",
                encodePolytypeAnyOrEmpty(data.value, "Uuids")
              ]
            ]);
}

function encode$6(data) {
  return EncodeJson.object([
              [
                "teams",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(data.teams)
              ],
              [
                "client",
                EncodeJson.option(encode$3)(data.client)
              ],
              [
                "workers",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(data.workers)
              ],
              [
                "statuses",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(JobViewTypes.Status.toString(v));
                      })(data.statuses)
              ],
              [
                "templates",
                EncodeJson.array(encode$2)(data.templates)
              ],
              [
                "resolutions",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(data.resolutions)
              ],
              [
                "canceledResolutions",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(Uuid.toString(v));
                      })(data.canceledResolutions)
              ],
              [
                "territories",
                EncodeJson.array(function (v) {
                        return EncodeJson.string(toString(v));
                      })(data.territories)
              ],
              [
                "finishedAt",
                EncodeJson.option(encode$1)(data.finishedAt)
              ],
              [
                "scheduledAt",
                EncodeJson.option(encode$1)(data.scheduledAt)
              ],
              [
                "createdAt",
                EncodeJson.option(encode$1)(data.createdAt)
              ],
              [
                "predefinedFields",
                EncodeJson.array(encode$5)(data.predefinedFields)
              ],
              [
                "customFields",
                EncodeJson.array(encode$4)(data.customFields)
              ],
              [
                "reportFields",
                EncodeJson.array(encode$4)(data.reportFields)
              ]
            ]);
}

function encode$7(data) {
  return EncodeJson.object([
              [
                "columns",
                encode(data.columns)
              ],
              [
                "filter",
                encode$6(data.filter)
              ],
              [
                "scheduled",
                EncodeJson.option(function (v) {
                        return EncodeJson.string(JobViewTypes.Scheduled.toString(v));
                      })(data.scheduled)
              ],
              [
                "resolutions",
                EncodeJson.option(function (v) {
                        return EncodeJson.string(JobViewTypes.Resolutions.toString(v));
                      })(data.resolutions)
              ],
              [
                "version",
                EncodeJson.string(JobViewTypes.Version.toString(JobViewTypes.Version.currentVersion))
              ]
            ]);
}

function encode$8(data) {
  return EncodeJson.object([
              [
                "uuid",
                EncodeJson.string(Uuid.toString(data.uuid))
              ],
              [
                "name",
                EncodeJson.string(data.name)
              ],
              [
                "settings",
                encode$7(data.settings)
              ],
              [
                "orderNo",
                EncodeJson.$$int(data.orderNo)
              ],
              [
                "system",
                EncodeJson.bool(data.system)
              ],
              [
                "_destroy",
                EncodeJson.bool(data.destroy)
              ]
            ]);
}

var encodeColumns = encode;

export {
  encode$8 as encode,
  encodeColumns ,
}
/* Uuid Not a pure module */
