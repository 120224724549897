

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Url from "../../../bindings/url/Url.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../Errors.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as $$Number from "../../../libraries/Number.mjs";
import * as Switch from "../../inputs/Switch.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Popover from "../../common/Popover/Popover.mjs";
import * as UI_Tabs from "../../../ui/UI_Tabs.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../common/Optional.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as WebhookAPI from "./WebhookAPI.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UI_PageTabs from "../../../ui/UI_PageTabs.mjs";
import * as WebhookTypes from "./WebhookTypes.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as UI_FeatureTeaser from "../../../ui/UI_FeatureTeaser.mjs";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as WebhookEditModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/webhook/WebhookEdit.module.css";

var t = Utils.Translations.t;

var tr = Utils.Translations.tr;

var tx = Utils.Translations.tx;

var clientCrud$p = tr("js.admin.api.show.webhook_types.client_crud");

var cancel$p = tr("js.admin.api.modals.cancel");

var delete$p = tr("js.admin.api.show.delete");

var deleteConfirmation$p = tr("js.admin.api.modals.delete_confirmation");

var deleteQuestion$p = tr("js.admin.api.modals.delete_question");

var hasErrors$p = t("js.errors.form_contains_errors");

var jobCrud$p = tr("js.admin.api.show.webhook_types.job_crud");

var jobEvents$p = tr("js.admin.api.show.webhook_types.job_events");

var name$p = t("js.admin.api.show.webhook.name");

var new$p = t("js.admin.api.show.new");

var old$p = t("js.admin.api.show.old");

var save$p = tr("common.save");

var secret$p = t("js.admin.api.show.secret");

var siteCrud$p = tr("js.admin.api.show.webhook_types.site_crud");

var title$p = t("js.admin.api.show.webhook.title");

function tooMany$p(c) {
  return tx("js.admin.api.errors.too_many_webhooks")({
              count: WebhookTypes.maxWebhooks
            }, c);
}

var blank$p = tx("js.admin.api.errors.blank");

var invalid$p = tx("js.admin.api.errors.invalid");

var version$p = tr("js.admin.api.show.version");

var activeDescription$p = tr("js.admin.api.show.webhook.webhook_active_description");

var lockedDescription$p = tr("js.admin.api.show.webhook.webhook_locked_description");

var newWebhook$p = tr("js.titles.integrations.webhooks.new");

var active$p = tr("js.admin.api.show.webhook.active");

var recentDeliveries$p = tr("js.admin.api.show.webhook.recent_deliveries");

var noRecentDeliveries$p = tr("js.admin.api.show.webhook.no_recent_deliveries");

var settings$p = tr("js.admin.api.show.webhook.settings");

var completedIn$p = Utils.Translations.txr("js.admin.api.show.webhook.completed_in");

function completedIn$p$1(ctx, seconds) {
  return completedIn$p({
              time: seconds
            }, ctx);
}

var request$p = tr("js.admin.api.show.webhook.request");

var response$p = tr("js.admin.api.show.webhook.response");

var headers$p = tr("js.admin.api.show.webhook.headers");

var payload$p = tr("js.admin.api.show.webhook.payload");

var body$p = tr("js.admin.api.show.webhook.body");

var emptyResponseBody$p = tr("js.admin.api.show.webhook.empty_response_body");

var styles = WebhookEditModuleCss;

var uuid = Uuid.createV6();

var url = Url.make("https://webhook.site/" + Uuid.toString(uuid));

var fakeWebhookData_url = RCore.$$Option.getExn(url, undefined);

var fakeWebhookData_name = "Demo";

var fakeWebhookData_eventTypes = [
  "job_created",
  "job_finished",
  "job_removed"
];

var fakeWebhookData = {
  uuid: uuid,
  url: fakeWebhookData_url,
  name: fakeWebhookData_name,
  eventTypes: fakeWebhookData_eventTypes,
  status: "Enabled"
};

function requestBody(eventType, uuid) {
  return "{\"event_type\":\"" + eventType + "\",\"event_uuid\":\"" + Uuid.toString(uuid) + "\",\"event\":\"/event data/\",\"job\":\"/job data/\"}";
}

function fakeDeliverySuccess(wire) {
  var uuid = Uuid.createV6();
  return {
          uuid: uuid,
          status: "sent",
          sentAt: Locale.T.prevMinutes(15, Locale.T.now(wire)),
          errorType: undefined,
          errorDescription: undefined,
          httpResponseCode: 200,
          httpResponseHeaders: "{\"Cache-Control\":\"private, must-revalidate\",\"Connection\":\"keep-alive\",\"Content-Type\":\"text/html; charset=UTF-8\"}",
          httpResponseBody: "{\"status\":\"ok\"}",
          httpRequestUrl: RCore.$$Option.getExn(url, undefined),
          httpRequestMethod: "post",
          httpRequestHeaders: "{\"Content-Length\":\"285\",\"Content-Type\":\"application/json\",\"X-Planado-Delivery-Attempt\":\"1\"}",
          httpRequestBody: requestBody("job_created", uuid),
          entityType: "job",
          entityUuid: Uuid.createV6(),
          entityVersion: 1,
          eventType: "job_created",
          attemptNo: 1,
          requestTimeMs: 378
        };
}

function fakeDeliveryError(wire) {
  var uuid = Uuid.createV6();
  return {
          uuid: uuid,
          status: "sent",
          sentAt: Locale.T.now(wire),
          errorType: "connection failure",
          errorDescription: "Failed to open TCP connection to :80",
          httpResponseCode: 500,
          httpResponseHeaders: "{\"Connection\":\"close\",\"Content-Length\":\"177\",\"Content-Type\":\"text/html\"}",
          httpResponseBody: "500 Internal Server Error",
          httpRequestUrl: RCore.$$Option.getExn(url, undefined),
          httpRequestMethod: "post",
          httpRequestHeaders: "{\"Content-Length\":\"2885\",\"Content-Type\":\"application/json\",\"X-Planado-Delivery-Attempt\":\"1\"}",
          httpRequestBody: requestBody("job_removed", uuid),
          entityType: "job",
          entityUuid: Uuid.createV6(),
          entityVersion: 2,
          eventType: "job_removed",
          attemptNo: 1,
          requestTimeMs: 1
        };
}

function fakeDeliveriesData(wire) {
  var successShort_uuid = Uuid.createV6();
  var successShort_sentAt = Locale.T.prevMinutes(15, Locale.T.now(wire));
  var successShort_httpResponseCode = 200;
  var successShort_entityUuid = Uuid.createV6();
  var successShort = {
    uuid: successShort_uuid,
    status: "success",
    sentAt: successShort_sentAt,
    errorType: undefined,
    errorDescription: undefined,
    httpResponseCode: successShort_httpResponseCode,
    eventType: "job_created",
    entityType: "job",
    entityUuid: successShort_entityUuid,
    entityVersion: 1
  };
  var errorShort_uuid = Uuid.createV6();
  var errorShort_sentAt = Locale.T.now(wire);
  var errorShort_errorType = "server failure";
  var errorShort_httpResponseCode = 500;
  var errorShort_entityUuid = Uuid.createV6();
  var errorShort = {
    uuid: errorShort_uuid,
    status: "error",
    sentAt: errorShort_sentAt,
    errorType: errorShort_errorType,
    errorDescription: undefined,
    httpResponseCode: errorShort_httpResponseCode,
    eventType: "job_removed",
    entityType: "job",
    entityUuid: errorShort_entityUuid,
    entityVersion: 2
  };
  return [
          errorShort,
          successShort
        ];
}

var clientCrud = [
  "client_created",
  "client_updated",
  "client_removed"
];

var siteCrud = [
  "site_created",
  "site_updated",
  "site_removed"
];

var jobCrud = [
  "job_created",
  "job_updated",
  "job_removed"
];

var jobEvents = [
  "job_posted",
  "job_scheduled",
  "job_published",
  "job_finished",
  "job_started",
  "job_assigned",
  "job_en_route",
  "job_suspended",
  "job_canceled",
  "job_printed",
  "message_sent",
  "services_updated",
  "materials_updated"
];

function WebhookEdit$StringInputs(Props) {
  var ctx = Props.ctx;
  var new_ = Props.new_;
  var state = Props.state;
  var sync = Props.sync;
  var submitted = Props.submitted;
  var errors = Props.errors;
  var wrapperClass = Js_dict.fromList({
        hd: [
          "pd-form-group",
          false
        ],
        tl: {
          hd: [
            styles.input,
            true
          ],
          tl: /* [] */0
        }
      });
  var updateUrl = function (v) {
    sync({
          uuid: state.uuid,
          url: Url.make(v),
          urlInput: v,
          secret: state.secret,
          name: state.name,
          eventTypes: state.eventTypes,
          status: state.status,
          version: state.version,
          alternativeVersions: state.alternativeVersions,
          deliveries: state.deliveries
        });
  };
  var onBlur = function (param) {
    var normalized = Url.normalizeStringToURL(state.urlInput);
    sync({
          uuid: state.uuid,
          url: Url.make(normalized),
          urlInput: normalized,
          secret: state.secret,
          name: state.name,
          eventTypes: state.eventTypes,
          status: state.status,
          version: state.version,
          alternativeVersions: state.alternativeVersions,
          deliveries: state.deliveries
        });
  };
  var tmp = {
    _type: "text",
    value: state.urlInput,
    onValueChange: updateUrl,
    onBlur: onBlur,
    labelText: "URL*",
    maxLength: 300,
    wrapperClass: wrapperClass,
    placeholder: "https://example.com/handle-planado"
  };
  var tmp$1 = submitted ? RCore.$$Option.map(Errors.get("urlField", errors), Core__List.toArray) : [];
  if (tmp$1 !== undefined) {
    tmp.errors = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: styles.hookParams
            }, React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(state.name, ""),
                  onValueChange: (function (v) {
                      sync({
                            uuid: state.uuid,
                            url: state.url,
                            urlInput: state.urlInput,
                            secret: state.secret,
                            name: Fun.optString(v),
                            eventTypes: state.eventTypes,
                            status: state.status,
                            version: state.version,
                            alternativeVersions: state.alternativeVersions,
                            deliveries: state.deliveries
                          });
                    }),
                  labelText: name$p(ctx),
                  maxLength: 100,
                  wrapperClass: wrapperClass,
                  autoFocus: new_
                }), React.createElement(FormInput.make, tmp), React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(state.secret, ""),
                  onValueChange: (function (v) {
                      sync({
                            uuid: state.uuid,
                            url: state.url,
                            urlInput: state.urlInput,
                            secret: Fun.optString(v),
                            name: state.name,
                            eventTypes: state.eventTypes,
                            status: state.status,
                            version: state.version,
                            alternativeVersions: state.alternativeVersions,
                            deliveries: state.deliveries
                          });
                    }),
                  labelText: secret$p(ctx),
                  maxLength: 100,
                  wrapperClass: wrapperClass
                }));
}

function WebhookEdit$VersionsBlock(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  var sync = Props.sync;
  var match = state.alternativeVersions;
  var oldVersion;
  var newVersion;
  var len = match.length;
  if (len !== 2) {
    if (len !== 3) {
      return null;
    }
    var oldVersion$1 = match[0];
    var newVersion$1 = match[2];
    oldVersion = oldVersion$1;
    newVersion = newVersion$1;
  } else {
    var oldVersion$2 = match[0];
    var newVersion$2 = match[1];
    oldVersion = oldVersion$2;
    newVersion = newVersion$2;
  }
  return React.createElement("div", undefined, React.createElement("label", undefined, version$p(ctx)), React.createElement(Switch.$$String.make, {
                  onValueChange: (function (v) {
                      var v$1 = RCore.$$Option.flatMap(Fun.optString(v), (function (x) {
                              return Core__Int.fromString(x, undefined);
                            }));
                      sync({
                            uuid: state.uuid,
                            url: state.url,
                            urlInput: state.urlInput,
                            secret: state.secret,
                            name: state.name,
                            eventTypes: state.eventTypes,
                            status: state.status,
                            version: v$1 !== undefined ? v$1 : state.version,
                            alternativeVersions: state.alternativeVersions,
                            deliveries: state.deliveries
                          });
                    }),
                  value: state.version.toString(),
                  states: [
                    {
                      name: old$p(ctx),
                      value: oldVersion.toString()
                    },
                    {
                      name: new$p(ctx),
                      value: newVersion.toString()
                    }
                  ]
                }));
}

function errorMessage(error) {
  return React.createElement("div", {
              className: "pd-control-error"
            }, error);
}

function processEventsError(submitted, errors) {
  var error = Core__List.get(RCore.$$Option.getOr(Errors.get("eventsFields", errors), /* [] */0), 0);
  if (error !== undefined && submitted) {
    return [
            styles.pickFromListError,
            errorMessage(error)
          ];
  } else {
    return [
            styles.pickFromList,
            null
          ];
  }
}

function onToggle(state, sync) {
  return function (w) {
    if (Js_array.includes(w, state.eventTypes)) {
      return sync({
                  uuid: state.uuid,
                  url: state.url,
                  urlInput: state.urlInput,
                  secret: state.secret,
                  name: state.name,
                  eventTypes: Js_array.filter((function (x) {
                          return x !== w;
                        }), state.eventTypes),
                  status: state.status,
                  version: state.version,
                  alternativeVersions: state.alternativeVersions,
                  deliveries: state.deliveries
                });
    } else {
      return sync({
                  uuid: state.uuid,
                  url: state.url,
                  urlInput: state.urlInput,
                  secret: state.secret,
                  name: state.name,
                  eventTypes: Belt_Array.concatMany([
                        [w],
                        state.eventTypes
                      ]),
                  status: state.status,
                  version: state.version,
                  alternativeVersions: state.alternativeVersions,
                  deliveries: state.deliveries
                });
    }
  };
}

function eventSubtype(header, elements, wh) {
  return React.createElement("div", {
              className: styles.eventTypes
            }, React.createElement("label", undefined, header), React.createElement("ul", {
                  className: styles.integrationsWebhooks
                }, elements.map(wh)));
}

function WebhookEdit$EventsBlock(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  var sync = Props.sync;
  var submitted = Props.submitted;
  var errors = Props.errors;
  var wh = function (__x) {
    var webhooks = state.eventTypes;
    var onToggle$1 = onToggle(state, sync);
    return React.createElement("li", {
                key: __x
              }, React.createElement("label", {
                    className: El.Cn.concat("checkbox", styles.integrationsWebhooksLabel)
                  }, React.createElement("input", {
                        checked: Js_array.includes(__x, webhooks),
                        type: "checkbox",
                        onChange: (function (_event) {
                            onToggle$1(__x);
                          })
                      }), tr("js.admin.api.show.event_types." + __x)(ctx)));
  };
  var match = processEventsError(submitted, errors);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: match[0]
                }, eventSubtype(jobCrud$p(ctx), jobCrud, wh), React.createElement("hr", undefined), eventSubtype(jobEvents$p(ctx), jobEvents, wh), React.createElement("hr", undefined), eventSubtype(clientCrud$p(ctx), clientCrud, wh), React.createElement("hr", undefined), eventSubtype(siteCrud$p(ctx), siteCrud, wh)), match[1]);
}

function WebhookEdit$DeleteButton(Props) {
  var uuid = Props.uuid;
  var wire = Props.wire;
  var locked = Props.locked;
  var match = React.useState(function () {
        return false;
      });
  var setConfirmation = match[1];
  var id = Hooks.useId(undefined);
  React.useEffect((function () {
          return wire.subscriptions.click.subscribe(function (param) {
                      setConfirmation(function (param) {
                            return false;
                          });
                    });
        }), []);
  var showPopover = function ($$event) {
    $$event.stopPropagation();
    $$event.preventDefault();
    setConfirmation(function (x) {
          return !x;
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: "btn btn-danger",
                  id: id,
                  disabled: locked,
                  type: "button",
                  onClick: showPopover
                }, delete$p(wire.ctx)), React.createElement(Popover.make, {
                  footer: wire.footer,
                  show: match[0],
                  buttonId: id,
                  position: "right",
                  children: React.createElement("div", undefined, React.createElement("h5", undefined, deleteQuestion$p(wire.ctx)), React.createElement("footer", {
                            className: styles.confirmationFooter
                          }, React.createElement("button", {
                                className: "btn btn-default",
                                disabled: locked,
                                onClick: (function (param) {
                                    setConfirmation(function (param) {
                                          return false;
                                        });
                                  })
                              }, cancel$p(wire.ctx)), React.createElement("button", {
                                className: "btn btn-danger",
                                disabled: locked,
                                onClick: (function (param) {
                                    Backend.finallyOnSuccess(WebhookAPI.Delete.destroy(uuid, wire), (function () {
                                            wire.navigate(undefined, {
                                                  NAME: "Integrations",
                                                  VAL: "API"
                                                });
                                          }));
                                  })
                              }, deleteConfirmation$p(wire.ctx))))
                }));
}

function WebhookEdit$Body(Props) {
  var body = Props.body;
  var formatted = React.useMemo(function () {
        var json;
        try {
          json = Json$JsonCombinators.parse(body);
        }
        catch (exn){
          return body;
        }
        if (json.TAG === "Ok") {
          return JSON.stringify(json._0, null, 2);
        } else {
          return body;
        }
      });
  return React.createElement("div", {
              className: El.Cn.concat(styles.preformatted, styles.body)
            }, formatted);
}

function decodeString(v) {
  var s = Json_Decode$JsonCombinators.decode(v, Json_Decode$JsonCombinators.string);
  if (s.TAG === "Ok") {
    return s._0;
  }
  
}

function decodeStrings(v) {
  var s = Json_Decode$JsonCombinators.decode(v, Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string));
  if (s.TAG === "Ok") {
    return s._0;
  }
  
}

function WebhookEdit$Headers(Props) {
  var headers = Props.headers;
  var formatted = React.useMemo(function () {
        var json;
        try {
          json = Json$JsonCombinators.parse(headers);
        }
        catch (exn){
          return headers;
        }
        if (json.TAG !== "Ok") {
          return headers;
        }
        var obj = Js_json.decodeObject(json._0);
        if (obj !== undefined) {
          return Js_dict.entries(obj).flatMap(function (param) {
                        var value = param[1];
                        var key = param[0];
                        var s = decodeString(value);
                        if (s !== undefined) {
                          return [[
                                    key,
                                    s
                                  ]];
                        }
                        var vs = decodeStrings(value);
                        if (vs !== undefined) {
                          return vs.map(function (v) {
                                      return [
                                              key,
                                              v
                                            ];
                                    });
                        } else {
                          return [];
                        }
                      }).map(function (param) {
                      var key = param[0];
                      return React.createElement("div", {
                                  key: key
                                }, React.createElement("strong", undefined, key), ": ", param[1]);
                    });
        } else {
          return headers;
        }
      });
  return React.createElement("div", {
              className: El.Cn.concat(styles.preformatted, styles.headers)
            }, formatted);
}

function WebhookEdit$Deliveries(Props) {
  var wire = Props.wire;
  var webhookUuid = Props.webhookUuid;
  var deliveries = Props.deliveries;
  var match = React.useState(function () {
        return new Map();
      });
  var setDetailedDeliveries = match[1];
  var detailedDeliveries = match[0];
  var match$1 = React.useState(function () {
        return new Set();
      });
  var setShownDeliveries = match$1[1];
  var shownDeliveries = match$1[0];
  var match$2 = React.useState(function () {
        return "request";
      });
  var setActiveTab = match$2[1];
  var activeTab = match$2[0];
  var ctx = wire.ctx;
  var hasFlag = Context_Types.Features.hasFlag("api", wire.ctx.features);
  var loadDelivery = function (deliveryUuid) {
    if (hasFlag) {
      return Backend.$$finally(WebhookAPI.WebhookDeliveries.Show.$$fetch(wire, webhookUuid, deliveryUuid), (function (response) {
                    if (response.TAG !== "Ok") {
                      return ;
                    }
                    var delivery = response._0;
                    setDetailedDeliveries(function (current) {
                          var newMap = new Map(current.entries());
                          newMap.set(deliveryUuid, delivery);
                          return newMap;
                        });
                  }));
    } else {
      return setDetailedDeliveries(function (current) {
                  var newMap = new Map(current.entries());
                  var match = deliveries.find(function (d) {
                        return Caml_obj.equal(d.uuid, deliveryUuid);
                      });
                  var tmp;
                  if (match !== undefined) {
                    var match$1 = match.httpResponseCode;
                    tmp = match$1 === 200 ? fakeDeliverySuccess(wire) : fakeDeliveryError(wire);
                  } else {
                    tmp = fakeDeliveryError(wire);
                  }
                  newMap.set(deliveryUuid, tmp);
                  return newMap;
                });
    }
  };
  var formatDate = Locale.T.fmtM("TimeStamp", wire);
  return React.createElement("div", {
              className: styles.deliveries
            }, deliveries.length !== 0 ? React.createElement("ul", {
                    className: styles.deliveriesList
                  }, deliveries.map(function (d) {
                        var code = d.httpResponseCode;
                        var success = code !== undefined ? code >= 200 && code < 300 : false;
                        var tmp;
                        if (shownDeliveries.has(d.uuid)) {
                          var delivery = detailedDeliveries.get(d.uuid);
                          var tmp$1;
                          if (delivery !== undefined) {
                            var secondsFloat = (delivery.requestTimeMs / 1000.0).toFixed(2);
                            var code$1 = delivery.httpResponseCode;
                            var tmp$2;
                            if (code$1 !== undefined) {
                              var headers = delivery.httpResponseHeaders;
                              var body = delivery.httpResponseBody;
                              var tmp$3;
                              var exit = 0;
                              if (body !== undefined && body !== "") {
                                tmp$3 = React.createElement("div", {
                                      className: styles.responseBody
                                    }, React.createElement("span", undefined, body$p(ctx)), React.createElement(WebhookEdit$Body, {
                                          body: body
                                        }));
                              } else {
                                exit = 1;
                              }
                              if (exit === 1) {
                                tmp$3 = React.createElement("div", undefined, emptyResponseBody$p(ctx));
                              }
                              tmp$2 = React.createElement(UI_Tabs.Tab.make, {
                                    active: activeTab === "response",
                                    renderLabel: (function () {
                                        return React.createElement("span", undefined, response$p(ctx), " ", React.createElement("span", {
                                                        className: code$1 >= 200 && code$1 < 300 ? El.Cn.concat(styles.statusCode, styles.success) : El.Cn.concat(styles.statusCode, styles.error)
                                                      }, code$1.toString()));
                                      }),
                                    onSelect: (function () {
                                        setActiveTab(function (param) {
                                              return "response";
                                            });
                                      }),
                                    children: null
                                  }, headers !== undefined ? React.createElement("div", {
                                          className: styles.responseHeaders
                                        }, React.createElement("span", undefined, headers$p(ctx)), React.createElement(WebhookEdit$Headers, {
                                              headers: headers
                                            })) : null, tmp$3);
                            } else {
                              tmp$2 = null;
                            }
                            tmp$1 = React.createElement(UI_Tabs.make, {
                                  addon: completedIn$p$1(ctx, $$Number.format(wire.ctx.localizator.numbers.delimiter, wire.ctx.localizator.numbers.separator, undefined, secondsFloat)),
                                  className: styles.deliveryData,
                                  children: null
                                }, React.createElement(UI_Tabs.Tab.make, {
                                      active: activeTab === "request",
                                      renderLabel: (function () {
                                          return React.createElement("span", undefined, request$p(ctx));
                                        }),
                                      onSelect: (function () {
                                          setActiveTab(function (param) {
                                                return "request";
                                              });
                                        }),
                                      children: React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                className: styles.requestHeaders
                                              }, React.createElement("span", undefined, headers$p(ctx)), React.createElement(WebhookEdit$Headers, {
                                                    headers: delivery.httpRequestHeaders
                                                  })), React.createElement("div", {
                                                className: styles.requestBody
                                              }, React.createElement("span", undefined, payload$p(ctx)), React.createElement(WebhookEdit$Body, {
                                                    body: delivery.httpRequestBody
                                                  })))
                                    }), tmp$2);
                          } else {
                            tmp$1 = React.createElement("div", {
                                  className: styles.spinner
                                }, React.createElement(Icon.make, {
                                      size: "lg",
                                      spin: true,
                                      status: "pending",
                                      icon: "spinner"
                                    }));
                          }
                          tmp = React.createElement("div", {
                                className: styles.deliveryDetails
                              }, tmp$1);
                        } else {
                          tmp = null;
                        }
                        return React.createElement("li", {
                                    key: Uuid.toString(d.uuid)
                                  }, React.createElement("div", undefined, React.createElement("div", {
                                            className: styles.deliveryInfo,
                                            onClick: (function (param) {
                                                var deliveryUuid = d.uuid;
                                                setShownDeliveries(function (current) {
                                                      var newSet = new Set(current.values());
                                                      if (newSet.has(deliveryUuid)) {
                                                        newSet.delete(deliveryUuid);
                                                      } else {
                                                        if (!newSet.has(deliveryUuid)) {
                                                          loadDelivery(deliveryUuid);
                                                        }
                                                        newSet.add(deliveryUuid);
                                                      }
                                                      return newSet;
                                                    });
                                              })
                                          }, success ? React.createElement(Icon.make, {
                                                  status: "success",
                                                  icon: "check"
                                                }) : React.createElement(Icon.make, {
                                                  status: "error",
                                                  icon: "exclamation-triangle"
                                                }), React.createElement("span", {
                                                className: styles.badge
                                              }, Uuid.toString(d.uuid)), React.createElement("span", {
                                                className: styles.badge
                                              }, d.eventType), React.createElement("span", {
                                                className: styles.sentAt
                                              }, formatDate(d.sentAt))), tmp));
                      })) : noRecentDeliveries$p(ctx));
}

function WebhookEdit(Props) {
  var wire = Props.wire;
  var route = Props.route;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return {
                uuid: Uuid.createV4(),
                url: undefined,
                urlInput: "",
                secret: undefined,
                name: undefined,
                eventTypes: [],
                status: "Enabled",
                version: 3,
                alternativeVersions: [],
                deliveries: []
              };
      });
  var setState = match[1];
  var state = match[0];
  var hasFlag = Context_Types.Features.hasFlag("api", wire.ctx.features);
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoaded = match$1[1];
  var match$2 = Hooks.useLocked(false);
  var unlock = match$2[2];
  var lock = match$2[1];
  var locked = match$2[0];
  var match$3 = React.useState(function () {
        return title$p(ctx);
      });
  var setTitle = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setSubmitted = match$4[1];
  var submitted = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setErrors = match$5[1];
  var errors = match$5[0];
  var sync = function (s) {
    setState(function (param) {
          return s;
        });
  };
  var showError = function (text, wire) {
    wire.subscriptions.messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: text
              })
        });
  };
  var onSubmit = function (param) {
    setSubmitted(function (param) {
          return true;
        });
    if (Core__List.length(errors) > 0) {
      return showError(hasErrors$p(wire.ctx), wire);
    } else {
      lock();
      if (typeof route === "object") {
        if (route.NAME === "Edit") {
          return Backend.$$finally(WebhookAPI.Update.patch(wire, state, route.VAL), (function (param) {
                        wire.navigate(undefined, {
                              NAME: "Integrations",
                              VAL: "API"
                            });
                      }));
        } else {
          return ;
        }
      } else {
        return Backend.$$finally(WebhookAPI.Create.post(wire, state), (function (__x) {
                      if (__x.TAG === "Ok") {
                        return wire.navigate(undefined, {
                                    NAME: "Integrations",
                                    VAL: "API"
                                  });
                      }
                      var match = __x._0;
                      if (typeof match !== "object" || !(match.TAG === "HTTPError" && Js_string.includes("Enough", match._0[1]))) {
                        return ;
                      } else {
                        return showError(tooMany$p(wire.ctx), wire);
                      }
                    }));
      }
    }
  };
  var toggleStatus = function (param) {
    setState(function (param) {
          return {
                  uuid: state.uuid,
                  url: state.url,
                  urlInput: state.urlInput,
                  secret: state.secret,
                  name: state.name,
                  eventTypes: state.eventTypes,
                  status: WebhookTypes.Status.inverse(state.status),
                  version: state.version,
                  alternativeVersions: state.alternativeVersions,
                  deliveries: state.deliveries
                };
        });
  };
  React.useEffect((function () {
          if (hasFlag) {
            if (typeof route === "object") {
              Backend.$$finally(WebhookAPI.Show.$$fetch(wire, route.VAL), (function (__x) {
                      if (__x.TAG === "Ok") {
                        var response = __x._0;
                        var name = response.name;
                        if (name !== undefined) {
                          setTitle(function (param) {
                                return name;
                              });
                        } else {
                          setTitle(function (param) {
                                return title$p(ctx);
                              });
                        }
                        setState(function (param) {
                              return response;
                            });
                        return setLoaded(function (param) {
                                    return true;
                                  });
                      }
                      var match = __x._0;
                      if (typeof match !== "object") {
                        return unlock();
                      }
                      if (match.TAG !== "HTTPError") {
                        return unlock();
                      }
                      var tmp = match._0[0];
                      if (typeof tmp !== "object" && tmp === "NotFound") {
                        return wire.navigate(undefined, "Unknown");
                      } else {
                        return unlock();
                      }
                    }));
            } else {
              setLoaded(function (param) {
                    return true;
                  });
            }
          } else {
            setState(function (param) {
                  return {
                          uuid: uuid,
                          url: url,
                          urlInput: Url.toString(RCore.$$Option.getExn(url, undefined)),
                          secret: "secret-auth-key",
                          name: "Demo",
                          eventTypes: fakeWebhookData_eventTypes,
                          status: "Enabled",
                          version: 3,
                          alternativeVersions: [],
                          deliveries: fakeDeliveriesData(wire)
                        };
                });
            setLoaded(function (param) {
                  return true;
                });
          }
        }), []);
  React.useEffect((function () {
          setSubmitted(function (param) {
                return false;
              });
          setErrors(function (param) {
                if (state.urlInput === "") {
                  return {
                          hd: [
                            "urlField",
                            blank$p({
                                  field: "URL"
                                }, ctx)
                          ],
                          tl: /* [] */0
                        };
                } else if (RCore.$$Option.isNone(state.url)) {
                  return {
                          hd: [
                            "urlField",
                            invalid$p({
                                  field: "URL"
                                }, ctx)
                          ],
                          tl: /* [] */0
                        };
                } else {
                  return /* [] */0;
                }
              });
        }), [state]);
  var disabled = state.status === "Suspended" || !hasFlag;
  var tmp = {
    active: typeof route === "object" ? route.NAME === "Edit" : true,
    renderLabel: (function () {
        if (typeof route === "object") {
          return settings$p(ctx);
        } else {
          return newWebhook$p(ctx);
        }
      }),
    children: null
  };
  var tmp$1 = typeof route === "object" ? ({
        NAME: "Integrations",
        VAL: {
          NAME: "Webhooks",
          VAL: {
            NAME: "Edit",
            VAL: route.VAL
          }
        }
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.route = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  if (typeof route === "object") {
    var uuid$1 = route.VAL;
    tmp$2 = React.createElement(UI_PageTabs.Tab.make, {
          route: {
            NAME: "Integrations",
            VAL: {
              NAME: "Webhooks",
              VAL: {
                NAME: "Deliveries",
                VAL: uuid$1
              }
            }
          },
          renderLabel: (function () {
              return recentDeliveries$p(ctx);
            }),
          children: React.createElement(WebhookEdit$Deliveries, {
                wire: wire,
                webhookUuid: uuid$1,
                deliveries: state.deliveries
              })
        });
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  values: {
                    name: match$3[0]
                  },
                  wire: wire
                }), React.createElement(Optional.make, {
                  show: match$1[0],
                  children: React.createElement(UI_PageTabs.make, {
                        children: null
                      }, React.createElement(UI_PageTabs.Tab.make, tmp, React.createElement("form", {
                                className: styles.webhookForm,
                                onSubmit: (function (prim) {
                                    prim.preventDefault();
                                  })
                              }, React.createElement(Optional.make, {
                                    show: !hasFlag,
                                    children: React.createElement(UI_FeatureTeaser.Large.make, {
                                          className: styles.teaser,
                                          feature: "api",
                                          minimalPlan: "pro"
                                        })
                                  }), React.createElement("fieldset", {
                                    disabled: disabled
                                  }, React.createElement("div", undefined, React.createElement(WebhookEdit$StringInputs, {
                                            ctx: ctx,
                                            new_: route === "New",
                                            state: state,
                                            sync: sync,
                                            submitted: submitted,
                                            errors: errors
                                          }), React.createElement(WebhookEdit$VersionsBlock, {
                                            ctx: ctx,
                                            state: state,
                                            sync: sync
                                          }), React.createElement("div", {
                                            className: styles.active
                                          }, React.createElement("label", {
                                                className: "checkbox"
                                              }, React.createElement("input", {
                                                    checked: state.status === "Enabled",
                                                    type: "checkbox",
                                                    onChange: toggleStatus
                                                  }), active$p(ctx)), React.createElement("div", {
                                                className: styles.activeDescription
                                              }, state.status === "Locked" ? React.createElement("div", {
                                                      className: styles.locked
                                                    }, lockedDescription$p(ctx)) : activeDescription$p(ctx))), React.createElement("hr", undefined), React.createElement(WebhookEdit$EventsBlock, {
                                            ctx: ctx,
                                            state: state,
                                            sync: sync,
                                            submitted: submitted,
                                            errors: errors
                                          })))), React.createElement(Optional.make, {
                                show: hasFlag,
                                children: React.createElement("footer", {
                                      className: styles.footer
                                    }, typeof route === "object" ? React.createElement(WebhookEdit$DeleteButton, {
                                            uuid: route.VAL,
                                            wire: wire,
                                            locked: locked
                                          }) : null, React.createElement("button", {
                                          className: El.Cn.concat("btn btn-default", styles.cancel),
                                          type: "button",
                                          onClick: (function (param) {
                                              wire.navigate(undefined, {
                                                    NAME: "Integrations",
                                                    VAL: "API"
                                                  });
                                            })
                                        }, cancel$p(ctx)), React.createElement("button", {
                                          className: "btn btn-primary",
                                          disabled: locked || disabled,
                                          type: "button",
                                          onClick: onSubmit
                                        }, save$p(ctx)))
                              })), tmp$2)
                }));
}

var FakeData = {
  fakeWebhookData: fakeWebhookData
};

var make = WebhookEdit;

export {
  FakeData ,
  make ,
}
/* clientCrud' Not a pure module */
