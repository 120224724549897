

import * as El from "../libraries/El.mjs";
import * as Modal from "../components/modal/Modal.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Utils from "../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../libraries/Locale.mjs";
import * as Backend from "../libraries/backend/Backend.mjs";
import * as Context from "../context/Context.mjs";
import * as UI_Icon from "./UI_Icon.mjs";
import * as Optional from "../components/common/Optional.mjs";
import * as UI_Button from "./UI_Button.mjs";
import * as AppContext from "../context/AppContext.mjs";
import * as MessengerTypes from "../components/messenger/MessengerTypes.mjs";
import * as FeatureRequest_API from "../components/admin/feature_request/FeatureRequest_API.mjs";
import * as UI_FeatureTeaserModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_FeatureTeaser.module.css";

var atLeastStandard$p = Utils.Translations.tr("js.feature_teaser.at_least_standard");

var atLeastPro$p = Utils.Translations.tr("js.feature_teaser.at_least_pro");

var atLeastProPlus$p = Utils.Translations.tr("js.feature_teaser.at_least_pro_plus");

var chooseStandardOrPro$p = Utils.Translations.tr("js.feature_teaser.choose_standard_or_pro");

var choosePro$p = Utils.Translations.tr("js.feature_teaser.choose_pro");

var chooseProPlus$p = Utils.Translations.tr("js.feature_teaser.choose_pro_plus");

var upgradePlan$p = Utils.Translations.tr("js.feature_teaser.upgrade_plan");

var youCan$p = Utils.Translations.tr("js.feature_teaser.you_can");

var tryForFree$p = Utils.Translations.tr("js.feature_teaser.try_for_free");

var or$p = Utils.Translations.tr("js.feature_teaser.or");

var getAccessForFree$p = Utils.Translations.tr("js.feature_teaser.get_access_for_free");

var days$p = Utils.Translations.tx("js.common.day_count");

var activateFeature$p = Utils.Translations.tr("js.feature_teaser.activate_feature");

var youCanGetAccessForFree$p = Utils.Translations.txr("js.feature_teaser.you_can_get_access_for_free");

function youCanGetAccessForFree$p$1(days, plan, ctx) {
  var daysText = days$p({
        count: days
      }, ctx);
  return youCanGetAccessForFree$p({
              days: daysText,
              plan: plan(ctx)
            }, ctx);
}

var internalError$p = Utils.Translations.t("js.common.internal_error");

var accessGranted$p = Utils.Translations.tx("js.feature_teaser.feature_access_granted");

function accessGranted$p$1(until, ctx) {
  return accessGranted$p({
              until: until
            }, ctx);
}

var featureAlreadyActivated$p = Utils.Translations.t("js.feature_teaser.feature_already_activated");

var featureRequestedTooSoon$p = Utils.Translations.t("js.feature_teaser.feature_requested_too_soon");

var askOwnerToChangePlan$p = Utils.Translations.t("js.feature_teaser.ask_owner_to_change_plan");

function availableOn(plan) {
  if (plan === "standard") {
    return atLeastStandard$p;
  } else if (plan === "pro") {
    return atLeastPro$p;
  } else {
    return atLeastProPlus$p;
  }
}

function choose(plan) {
  if (plan === "standard") {
    return chooseStandardOrPro$p;
  } else if (plan === "pro") {
    return choosePro$p;
  } else {
    return chooseProPlus$p;
  }
}

var styles = UI_FeatureTeaserModuleCss;

function UI_FeatureTeaser$Large(Props) {
  var className = Props.className;
  var description = Props.description;
  var feature = Props.feature;
  var minimalPlan = Props.minimalPlan;
  var children = Props.children;
  var wire = AppContext.useWire();
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowFeatureRequestModal = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setRequestInProgress = match$1[1];
  var requestInProgress = match$1[0];
  var classes = El.Cn.concatOpt(styles.teaserDescription, className);
  var requestFeature = function () {
    if (!requestInProgress) {
      setRequestInProgress(function (param) {
            return true;
          });
      return Backend.$$finally(FeatureRequest_API.Create.post(wire, [feature]), (function (response) {
                    if (response.TAG === "Ok") {
                      var response$1 = response._0;
                      if (response$1.TAG === "Granted") {
                        var until = Locale.T.fmtM("DateFull", wire)(response$1._0.finishAt);
                        messenger.trigger({
                              TAG: "Show",
                              _0: MessengerTypes.Message.make(undefined, "Success", {
                                    TAG: "Text",
                                    _0: accessGranted$p$1(until, ctx)
                                  })
                            });
                        setShowFeatureRequestModal(function (param) {
                              return false;
                            });
                        wire.reloadContext();
                      } else {
                        var tmp;
                        tmp = response$1._0.reason === "BuffAlreadyExists" ? featureAlreadyActivated$p(ctx) : featureRequestedTooSoon$p(ctx);
                        messenger.trigger({
                              TAG: "Show",
                              _0: MessengerTypes.Message.make(undefined, "Danger", {
                                    TAG: "Text",
                                    _0: tmp
                                  })
                            });
                        setShowFeatureRequestModal(function (param) {
                              return false;
                            });
                      }
                      return setRequestInProgress(function (param) {
                                  return false;
                                });
                    } else {
                      setRequestInProgress(function (param) {
                            return false;
                          });
                      return messenger.trigger({
                                  TAG: "Show",
                                  _0: MessengerTypes.Message.make(undefined, "Danger", {
                                        TAG: "Text",
                                        _0: internalError$p(ctx)
                                      })
                                });
                    }
                  }));
    }
    
  };
  var admin = Context.isAdmin(ctx);
  var canManageSubscription = Context.canManageSubscription(ctx);
  var changePlan = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (canManageSubscription) {
      return wire.navigate(undefined, "Billing");
    } else {
      return messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Warning", {
                        TAG: "Text",
                        _0: askOwnerToChangePlan$p(ctx)
                      })
                });
    }
  };
  return React.createElement("div", {
              className: classes
            }, description !== undefined ? React.createElement(React.Fragment, undefined, description, React.createElement("br", undefined), React.createElement("br", undefined)) : null, RCore.$$Option.mapOr(children, null, (function (el) {
                    return React.createElement(React.Fragment, undefined, el, React.createElement("br", undefined));
                  })), React.createElement("span", undefined, React.createElement(UI_Icon.make, {
                      icon: "angles-up"
                    }), " ", availableOn(minimalPlan)(ctx), " ", React.createElement(Optional.make, {
                      show: canManageSubscription || admin,
                      children: React.createElement("div", {
                            className: styles.buttons
                          }, React.createElement("span", {
                                className: styles.buttonsItem
                              }, youCan$p(ctx)), React.createElement(UI_Button.make, {
                                flavor: "success",
                                onClick: (function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowFeatureRequestModal(function (param) {
                                          return true;
                                        });
                                  }),
                                children: tryForFree$p(ctx)
                              }), React.createElement("span", {
                                className: styles.buttonsItem
                              }, or$p(ctx)), React.createElement(UI_Button.make, {
                                purpose: "primary",
                                onClick: changePlan,
                                children: upgradePlan$p(ctx)
                              }))
                    }), React.createElement(Modal.make, {
                      wire: wire,
                      onClose: (function () {
                          setShowFeatureRequestModal(function (param) {
                                return false;
                              });
                        }),
                      show: match[0],
                      children: React.createElement(Modal.Dialog.make, {
                            children: null
                          }, React.createElement(Modal.Header.make, {
                                children: React.createElement("h1", undefined, getAccessForFree$p(ctx))
                              }), React.createElement(Modal.Body.make, {
                                className: styles.modalBody,
                                children: youCanGetAccessForFree$p$1(14, choose(minimalPlan), ctx)
                              }), React.createElement(Modal.Footer.make, {
                                children: null
                              }, React.createElement(Modal.CloseButton.make, {
                                    ctx: ctx
                                  }), React.createElement(Modal.SubmitButton.make, {
                                    onClick: (function (param) {
                                        requestFeature();
                                      }),
                                    disabled: requestInProgress,
                                    children: activateFeature$p(ctx)
                                  })))
                    })));
}

var Large = {
  make: UI_FeatureTeaser$Large
};

var RequiredPlan = {};

export {
  RequiredPlan ,
  Large ,
}
/* atLeastStandard' Not a pure module */
