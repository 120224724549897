

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../../Router.mjs";
import * as Select from "../../inputs/Select.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Context from "../../../context/Context.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Popover from "../../common/Popover/Popover.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../common/Optional.mjs";
import * as AmoCrmApi from "./AmoCrmApi.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as AmoCrmTypes from "./AmoCrmTypes.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as TemplateSelect from "../../inputs/TemplateSelect.mjs";
import * as Shared_Lib_Reducer from "../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as AmoCrmShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/amocrm/AmoCrmShow.module.css";

var styles = AmoCrmShowModuleCss;

var integrationDescription$p = Utils.Translations.tr("js.admin.amocrm.full_description");

var wizard1$p = Utils.Translations.tr("js.admin.amocrm.wizard.connecting");

var installWidget$p = Utils.Translations.tr("js.admin.amocrm.connection.install_widget");

var enterPlanadoAddress$p = Utils.Translations.txr("js.admin.amocrm.connection.enter_planado_address");

var setUpPlanado$p = Utils.Translations.tr("js.admin.amocrm.connection.set_up_planado");

var byTemplate$p = Utils.Translations.tr("js.admin.amocrm.by_template");

var chooseTemplate$p = Utils.Translations.t("js.admin.amocrm.choose_template");

var onSuccess$p = Utils.Translations.tr("js.admin.amocrm.on_successful_job_completion");

var onUnsuccess$p = Utils.Translations.tr("js.admin.amocrm.on_unsuccessful_job_completion");

var chooseStatus$p = Utils.Translations.t("js.admin.amocrm.choose_status");

var addField$p = Utils.Translations.tr("js.admin.amocrm.add_field");

var fillJobFieldsFromLead$p = Utils.Translations.tr("js.admin.amocrm.fill_job_fields_from_lead");

var fillLeadFieldsFromReport$p = Utils.Translations.tr("js.admin.amocrm.fill_lead_fields_from_job_report");

var from$p = Utils.Translations.tr("js.admin.amocrm.from");

var chooseField$p = Utils.Translations.t("js.admin.amocrm.choose_field");

var description$p = Utils.Translations.t("js.admin.predefined_job_fields.description");

var jobType$p = Utils.Translations.t("js.admin.predefined_job_fields.job_type");

var territory$p = Utils.Translations.t("js.admin.predefined_job_fields.territory");

var address$p = Utils.Translations.t("js.admin.predefined_job_fields.address");

var addressDescription$p = Utils.Translations.t("js.admin.predefined_job_fields.address_description");

var apartment$p = Utils.Translations.t("js.admin.predefined_job_fields.apartment");

var floor$p = Utils.Translations.t("js.admin.predefined_job_fields.floor");

var entranceNo$p = Utils.Translations.t("js.admin.predefined_job_fields.entrance_no");

var contacts$p = Utils.Translations.t("js.admin.predefined_job_fields.contacts");

var contactName$p = Utils.Translations.t("js.admin.predefined_job_fields.contact_name");

var contactValue$p = Utils.Translations.t("js.admin.predefined_job_fields.contact_value");

var contactsField$p = Utils.Translations.t("js.admin.amocrm.contacts_field");

var assignee$p = Utils.Translations.t("js.admin.predefined_job_fields.assignee");

var finishedAt$p = Utils.Translations.t("js.admin.predefined_job_fields.finished_at");

var scheduledAt$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_at");

var scheduledAtDate$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_at_date");

var scheduledAtTime$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_at_time");

var scheduledDuration$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_duration");

var resolution$p = Utils.Translations.t("js.admin.predefined_job_fields.resolution");

var resolutionComment$p = Utils.Translations.t("js.admin.predefined_job_fields.resolution_comment");

var serialNo$p = Utils.Translations.t("js.admin.predefined_job_fields.serial_no");

var photoArchiveUrl$p = Utils.Translations.t("js.admin.predefined_job_fields.photo_archive_url");

var jobLink$p = Utils.Translations.t("js.admin.predefined_job_fields.job_link");

var unsupportedFieldMapping$p = Utils.Translations.tr("js.admin.amocrm.unsupported_field_mapping");

var writeToSupport$p = Utils.Translations.tr("js.admin.amocrm.write_to_support");

var leadToJobError$p = Utils.Translations.tx("js.admin.amocrm.mapping_errors.lead_to_job");

var jobToLeadError$p = Utils.Translations.tx("js.admin.amocrm.mapping_errors.job_to_lead");

function amocrmDataTypes$p(dataTypes, ctx) {
  var dealFieldDataType$p = function (dealFieldDataType) {
    var dataType = AmoCrmTypes.Field.DataType.toString(dealFieldDataType);
    return Utils.Translations.t("js.admin.amocrm.amocrm_data_types." + dataType);
  };
  return Utils.Translations.joinWithComma(Core__List.toArray(Core__List.map(dataTypes, (function (t) {
                        return dealFieldDataType$p(t)(ctx);
                      })))) + ".";
}

function predefinedLeadField$p(f, ctx) {
  if (typeof f === "object") {
    return "";
  }
  switch (f) {
    case "LeadId" :
        return Utils.Translations.t("js.admin.amocrm.predefined_amocrm_fields.lead_id")(ctx);
    case "LeadName" :
        return Utils.Translations.t("js.admin.amocrm.predefined_amocrm_fields.lead_name")(ctx);
    case "LeadStatus" :
        return Utils.Translations.t("js.admin.amocrm.predefined_amocrm_fields.lead_status")(ctx);
    case "ResponsibleUser" :
        return Utils.Translations.t("js.admin.amocrm.predefined_amocrm_fields.responsible_user")(ctx);
    case "Sale" :
        return Utils.Translations.t("js.admin.amocrm.predefined_amocrm_fields.sale")(ctx);
    
  }
}

function predefinedContactField$p(f, ctx) {
  if (typeof f !== "object") {
    return Utils.Translations.t("js.admin.amocrm.predefined_amocrm_fields.contact_name")(ctx);
  } else {
    return "";
  }
}

function predefinedField$p(field) {
  return function (ctx) {
    switch (field) {
      case "Territory" :
          return territory$p(ctx);
      case "JobType" :
          return jobType$p(ctx);
      case "Description" :
          return description$p(ctx);
      case "Assignee" :
          return assignee$p(ctx);
      case "FinishedAt" :
          return finishedAt$p(ctx);
      case "ScheduledAt" :
          return scheduledAt$p(ctx);
      case "ScheduledAtDate" :
          return scheduledAtDate$p(ctx);
      case "ScheduledAtTime" :
          return scheduledAtTime$p(ctx);
      case "ScheduledDuration" :
          return scheduledDuration$p(ctx);
      case "Address" :
          return address$p(ctx);
      case "AddressDescription" :
          return addressDescription$p(ctx);
      case "Apartment" :
          return apartment$p(ctx);
      case "Floor" :
          return floor$p(ctx);
      case "EntranceNo" :
          return entranceNo$p(ctx);
      case "Contacts" :
          return contacts$p(ctx);
      case "ContactName" :
          return contactName$p(ctx);
      case "ContactValue" :
          return contactValue$p(ctx);
      case "Resolution" :
          return resolution$p(ctx);
      case "ResolutionComment" :
          return resolutionComment$p(ctx);
      case "SerialNo" :
          return serialNo$p(ctx);
      case "PhotoArchiveUrlShared" :
          return photoArchiveUrl$p(ctx);
      case "JobLink" :
          return jobLink$p(ctx);
      
    }
  };
}

var removeIntegration$p = Utils.Translations.tr("js.admin.amocrm.remove_integration");

var removeIntegrationQuestion$p = Utils.Translations.tr("js.admin.bitrix24.remove_integration_question");

var consequences$p = Utils.Translations.t("js.admin.amocrm.consequences");

var cancel$p = Utils.Translations.tr("common.cancel");

var yesRemove$p = Utils.Translations.tr("js.admin.bitrix24.yes_remove");

function loadError$p(error, ctx) {
  return Utils.Translations.t("js.admin.amocrm.messages." + error + "_error")(ctx);
}

function AmoCrmShow$New(Props) {
  var wire = Props.wire;
  return React.createElement("div", {
              className: "form"
            }, React.createElement("section", {
                  className: styles.form
                }, React.createElement("div", {
                      className: styles.newContainer
                    }, React.createElement("div", {
                          className: styles.newHeader
                        }, integrationDescription$p(wire.ctx)), React.createElement("hr", undefined), React.createElement("form", {
                          className: styles.newFormContainer
                        }, React.createElement("h2", {
                              className: styles.caption
                            }, React.createElement("span", undefined, wizard1$p(wire.ctx))), React.createElement("ul", {
                              className: styles.stepsList
                            }, React.createElement("li", undefined, React.createElement("span", {
                                      className: styles.stepNo
                                    }, "1"), installWidget$p(wire.ctx)), React.createElement("li", undefined, React.createElement("span", {
                                      className: styles.stepNo
                                    }, "2"), enterPlanadoAddress$p({
                                      address: Context.address(wire.ctx)
                                    }, wire.ctx)), React.createElement("li", undefined, React.createElement("span", {
                                      className: styles.stepNo
                                    }, "3"), setUpPlanado$p(wire.ctx)))))));
}

var buttonId = "amocrm-options-button";

function AmoCrmShow$AccountInfo(Props) {
  var wire = Props.wire;
  var param = Props.account;
  var lock = Props.lock;
  var unlock = Props.unlock;
  var locked = Props.locked;
  var removed = Props.removed;
  var domain = param.domain;
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var reducer = function (param) {
    var tmp = param[1];
    if (typeof tmp === "object") {
      return {
              TAG: "UpdateWithSideEffect",
              _0: {
                showConfirmation: false
              },
              _1: (function (param) {
                  unlock();
                  removed();
                })
            };
    }
    switch (tmp) {
      case "HideConfirmation" :
          return {
                  TAG: "Update",
                  _0: {
                    showConfirmation: false
                  }
                };
      case "ShowConfirmation" :
          return {
                  TAG: "Update",
                  _0: {
                    showConfirmation: true
                  }
                };
      case "Destroy" :
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      var send = param.send;
                      lock();
                      Backend.$$finally(AmoCrmApi.Show.destroy(wire), (function (r) {
                              send({
                                    TAG: "Destroyed",
                                    _0: r
                                  });
                            }));
                    })
                };
      
    }
  };
  var match = Shared_Lib_Reducer.use(reducer, {
        showConfirmation: false
      });
  var send = match[1];
  React.useEffect((function () {
          return subscriptions.click.subscribe(function (_event) {
                      send("HideConfirmation");
                    });
        }), []);
  var domainUrl = "https://" + domain;
  var onRemove = function ($$event) {
    $$event.stopPropagation();
    send("Destroy");
  };
  var hideConfirmation = function ($$event) {
    $$event.preventDefault();
    send("HideConfirmation");
  };
  var showConfirmation = function ($$event) {
    $$event.stopPropagation();
    $$event.preventDefault();
    send("ShowConfirmation");
  };
  return React.createElement("div", {
              className: styles.domain
            }, React.createElement("a", {
                  href: domainUrl,
                  target: "_blank"
                }, React.createElement("i", {
                      className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "check"), styles.check)
                    }), domain + " "), React.createElement("button", {
                  className: "btn btn-default",
                  id: buttonId,
                  disabled: locked,
                  type: "button",
                  onClick: showConfirmation
                }, removeIntegration$p(ctx)), React.createElement(Popover.make, {
                  footer: wire.footer,
                  show: match[0].showConfirmation,
                  buttonId: buttonId,
                  position: "bottom",
                  className: styles.popover,
                  children: React.createElement("div", undefined, React.createElement("h5", undefined, removeIntegrationQuestion$p(ctx)), React.createElement("div", undefined, React.createElement("ul", undefined, consequences$p(ctx).split("\n").map(function (line, idx) {
                                    return React.createElement("li", {
                                                key: idx.toString()
                                              }, line);
                                  }))), React.createElement("footer", undefined, React.createElement("button", {
                                className: "btn btn-default",
                                disabled: locked,
                                onClick: hideConfirmation
                              }, cancel$p(ctx)), React.createElement("button", {
                                className: "btn btn-danger",
                                disabled: locked,
                                onClick: onRemove
                              }, yesRemove$p(ctx))))
                }));
}

function predefinedJobFields(ctx) {
  var territoryField = Context_Types.Features.hasFlag("territories", ctx.features) ? ["Territory"] : [];
  return Belt_Array.concatMany([
                territoryField,
                [
                  "JobType",
                  "Description",
                  "Assignee",
                  "ScheduledAt",
                  "ScheduledAtDate",
                  "ScheduledAtTime",
                  "ScheduledDuration",
                  "Address",
                  "AddressDescription",
                  "Apartment",
                  "Floor",
                  "EntranceNo",
                  "Contacts",
                  "ContactName",
                  "ContactValue"
                ]
              ]).map(function (f) {
              return [
                      AmoCrmTypes.JobField.Predefined.toId(f),
                      predefinedField$p(f),
                      {
                        TAG: "Predefined",
                        _0: f
                      }
                    ];
            });
}

var predefinedPlanadoFields = [
    "Assignee",
    "Resolution",
    "ResolutionComment",
    "SerialNo",
    "FinishedAt",
    "PhotoArchiveUrlShared",
    "JobLink"
  ].map(function (f) {
      return [
              AmoCrmTypes.JobField.Predefined.toId(f),
              predefinedField$p(f),
              {
                TAG: "Predefined",
                _0: f
              }
            ];
    });

function listSupportedAmocrmDataTypesForJobType(jobFieldDataType) {
  switch (jobFieldDataType) {
    case "String" :
        return {
                hd: "AdStat",
                tl: {
                  hd: "Text",
                  tl: {
                    hd: "TextArea",
                    tl: {
                      hd: "Numeric",
                      tl: {
                        hd: "RadioButton",
                        tl: {
                          hd: "MultiText",
                          tl: {
                            hd: "MultiSelect",
                            tl: {
                              hd: "Date",
                              tl: {
                                hd: "OrgLegalName",
                                tl: {
                                  hd: "StreetAddress",
                                  tl: {
                                    hd: "Datetime",
                                    tl: {
                                      hd: "Status",
                                      tl: {
                                        hd: "User",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              };
    case "Integer" :
    case "Decimal" :
        return {
                hd: "Numeric",
                tl: {
                  hd: "Text",
                  tl: {
                    hd: "TextArea",
                    tl: /* [] */0
                  }
                }
              };
    case "Address" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "StreetAddress",
                    tl: {
                      hd: "SmartAddress",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Assignee" :
        return {
                hd: "Select",
                tl: {
                  hd: "User",
                  tl: /* [] */0
                }
              };
    case "Time" :
        return {
                hd: "Date",
                tl: {
                  hd: "Datetime",
                  tl: /* [] */0
                }
              };
    case "TimeString" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: /* [] */0
                }
              };
    case "Date" :
        return {
                hd: "Date",
                tl: /* [] */0
              };
    case "Dictionary" :
        return {
                hd: "Select",
                tl: {
                  hd: "Status",
                  tl: {
                    hd: "RadioButton",
                    tl: /* [] */0
                  }
                }
              };
    case "Contacts" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "Numeric",
                    tl: {
                      hd: "RadioButton",
                      tl: {
                        hd: "MultiText",
                        tl: {
                          hd: "MultiSelect",
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              };
    case "Attachment" :
        return {
                hd: "File",
                tl: /* [] */0
              };
    case "Duration" :
        return {
                hd: "Numeric",
                tl: {
                  hd: "Text",
                  tl: /* [] */0
                }
              };
    case "JobType" :
    case "Territory" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "Select",
                    tl: /* [] */0
                  }
                }
              };
    case "URL" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "URL",
                    tl: /* [] */0
                  }
                }
              };
    case "Currency" :
        return {
                hd: "Numeric",
                tl: /* [] */0
              };
    default:
      return /* [] */0;
  }
}

function canMapToJobField(amocrmFieldDataType, jobFieldDataType) {
  var supportedTypes = listSupportedAmocrmDataTypesForJobType(jobFieldDataType);
  return Core__List.some(supportedTypes, (function (amocrmType) {
                return Caml_obj.equal(amocrmType, amocrmFieldDataType);
              }));
}

function listSupportedAmocrmDataTypesForJobType$1(jobFieldDataType) {
  switch (jobFieldDataType) {
    case "String" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "Numeric",
                    tl: {
                      hd: "Date",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Integer" :
    case "Decimal" :
        return {
                hd: "Numeric",
                tl: {
                  hd: "Text",
                  tl: {
                    hd: "TextArea",
                    tl: /* [] */0
                  }
                }
              };
    case "Assignee" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "Select",
                    tl: /* [] */0
                  }
                }
              };
    case "Date" :
        return {
                hd: "Date",
                tl: {
                  hd: "Datetime",
                  tl: /* [] */0
                }
              };
    case "Boolean" :
        return {
                hd: "Checkbox",
                tl: /* [] */0
              };
    case "Dictionary" :
    case "Resolution" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "RadioButton",
                    tl: {
                      hd: "Select",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Attachment" :
    case "URL" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "URL",
                    tl: /* [] */0
                  }
                }
              };
    case "Datetime" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "Date",
                    tl: {
                      hd: "Datetime",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Currency" :
        return {
                hd: "Text",
                tl: {
                  hd: "TextArea",
                  tl: {
                    hd: "Numeric",
                    tl: /* [] */0
                  }
                }
              };
    default:
      return /* [] */0;
  }
}

function canMapToAmocrmField(amocrmFieldDataType, jobFieldDataType) {
  var supportedTypes = listSupportedAmocrmDataTypesForJobType$1(jobFieldDataType);
  return Core__List.some(supportedTypes, (function (amocrmType) {
                return Caml_obj.equal(amocrmType, amocrmFieldDataType);
              }));
}

function AmoCrmShow$StatusConfiguration(Props) {
  var param = Props.wire;
  var statusId = Props.statusId;
  var statuses = Props.statuses;
  var statusConfig = Props.statusConfig;
  var templates = Props.templates;
  var customFields = Props.customFields;
  var updateSettings = Props.updateSettings;
  var locked = Props.locked;
  var ctx = param.ctx;
  var templateUuid = statusConfig.templateUuid;
  var template;
  if (templateUuid !== undefined) {
    var templateUuid$1 = Caml_option.valFromOption(templateUuid);
    template = (function (__x) {
          return Js_array.find((function (param) {
                        return Uuid.equal(param.uuid, templateUuid$1);
                      }), __x);
        })(templates);
  } else {
    template = undefined;
  }
  var addJobMapping = function ($$event) {
    $$event.preventDefault();
    $$event.target.blur();
    updateSettings(undefined, undefined, undefined, Belt_Array.concatMany([
              statusConfig.jobFields,
              [AmoCrmTypes.JobFieldMapping.empty]
            ]), undefined, undefined);
  };
  var addLeadMapping = function ($$event) {
    $$event.preventDefault();
    $$event.target.blur();
    updateSettings(undefined, undefined, undefined, undefined, Belt_Array.concatMany([
              statusConfig.leadFields,
              [AmoCrmTypes.LeadFieldMapping.empty]
            ]), undefined);
  };
  var keepOnlyPredefinedFieldsInJobMapping = function () {
    return statusConfig.jobFields.map(function (param) {
                var amocrmField = param.amocrmField;
                var jobField = param.jobField;
                if (jobField === undefined) {
                  return {
                          jobField: undefined,
                          amocrmField: amocrmField
                        };
                }
                switch (jobField.TAG) {
                  case "Predefined" :
                      return {
                              jobField: jobField,
                              amocrmField: amocrmField
                            };
                  case "Custom" :
                  case "Report" :
                      return {
                              jobField: undefined,
                              amocrmField: amocrmField
                            };
                  
                }
              });
  };
  var keepOnlyPredefinedFieldsInLeadMapping = function () {
    return statusConfig.leadFields.map(function (param) {
                var planadoField = param.planadoField;
                var leadField = param.leadField;
                if (planadoField === undefined) {
                  return {
                          leadField: leadField,
                          planadoField: undefined
                        };
                }
                switch (planadoField.TAG) {
                  case "Predefined" :
                      return {
                              leadField: leadField,
                              planadoField: planadoField
                            };
                  case "Custom" :
                  case "Report" :
                      return {
                              leadField: leadField,
                              planadoField: undefined
                            };
                  
                }
              });
  };
  var updateStateFromTemplate = function (state, template) {
    return {
            templates: templates.map(function (param) {
                  return {
                          uuid: param.uuid,
                          name: param.name
                        };
                }),
            jobFields: Belt_Array.concatMany([
                  predefinedJobFields(ctx),
                  template.fields.map(function (param) {
                        var name = param.name;
                        var uuid = param.uuid;
                        return [
                                uuid,
                                (function (param) {
                                    return name;
                                  }),
                                {
                                  TAG: "Custom",
                                  _0: uuid
                                }
                              ];
                      })
                ]),
            amocrmFields: state.amocrmFields,
            leadFields: state.leadFields,
            planadoFields: Belt_Array.concatMany([
                  predefinedPlanadoFields,
                  template.reportFields.map(function (param) {
                        var name = param.name;
                        var uuid = param.uuid;
                        return [
                                uuid,
                                (function (param) {
                                    return name;
                                  }),
                                {
                                  TAG: "Report",
                                  _0: uuid
                                }
                              ];
                      })
                ])
          };
  };
  var findTemplate = function (templateUuid) {
    return Js_array.find((function (param) {
                  return Uuid.equal(param.uuid, templateUuid);
                }), templates);
  };
  var requiredFieldError = function (error) {
    if (error !== undefined) {
      return React.createElement("div", undefined, React.createElement("p", undefined, error));
    } else {
      return null;
    }
  };
  var fieldMappingError = function (jobField, amocrmField, fieldMapping) {
    if (jobField === undefined) {
      return null;
    }
    if (amocrmField === undefined) {
      return null;
    }
    var customFields = RCore.$$Option.getOr(RCore.$$Option.map(template, (function (t) {
                return t.fields;
              })), []);
    var reportFields = RCore.$$Option.getOr(RCore.$$Option.map(template, (function (t) {
                return t.reportFields;
              })), []);
    var jobFieldDataType;
    switch (jobField.TAG) {
      case "Predefined" :
          jobFieldDataType = AmoCrmTypes.JobField.Predefined.toDataType(jobField._0);
          break;
      case "Custom" :
          var id = jobField._0;
          jobFieldDataType = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(customFields, (function (f) {
                          return Caml_obj.equal(f.uuid, id);
                        })), (function (f) {
                      return f.dataType;
                    })), "Unknown");
          break;
      case "Report" :
          var id$1 = jobField._0;
          jobFieldDataType = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(reportFields, (function (f) {
                          return Caml_obj.equal(f.uuid, id$1);
                        })), (function (f) {
                      return f.dataType;
                    })), "Unknown");
          break;
      
    }
    var amocrmFieldDataType = AmoCrmTypes.AmocrmField.dataType(amocrmField);
    var canMap;
    canMap = fieldMapping === "LeadToJob" ? canMapToJobField(amocrmFieldDataType, jobFieldDataType) : canMapToAmocrmField(amocrmFieldDataType, jobFieldDataType);
    var buildMessage = function () {
      if (fieldMapping === "LeadToJob") {
        var supportedDataTypes = listSupportedAmocrmDataTypesForJobType(jobFieldDataType);
        if (!supportedDataTypes) {
          return unsupportedFieldMapping$p(ctx);
        }
        var typesStr = amocrmDataTypes$p(supportedDataTypes, ctx);
        var baseStr = leadToJobError$p({
              count: Core__List.length(supportedDataTypes)
            }, ctx);
        return baseStr + typesStr;
      }
      var supportedDataTypes$1 = listSupportedAmocrmDataTypesForJobType$1(jobFieldDataType);
      if (!supportedDataTypes$1) {
        return unsupportedFieldMapping$p(ctx);
      }
      var typesStr$1 = amocrmDataTypes$p(supportedDataTypes$1, ctx);
      var baseStr$1 = jobToLeadError$p({
            count: Core__List.length(supportedDataTypes$1)
          }, ctx);
      return baseStr$1 + typesStr$1;
    };
    return React.createElement(Optional.make, {
                show: !canMap,
                children: React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement("p", undefined, buildMessage()), React.createElement("p", undefined, writeToSupport$p(ctx))))
              });
  };
  var predefinedLeadFields = AmoCrmTypes.Predefined.Lead.all.map(function (f) {
        return [
                AmoCrmTypes.Predefined.Lead.toId(f),
                predefinedLeadField$p(f, ctx),
                {
                  TAG: "PredefinedLead",
                  _0: f
                }
              ];
      });
  var predefinedContactFields = AmoCrmTypes.Predefined.Contact.all.map(function (f) {
        return [
                AmoCrmTypes.Predefined.Contact.toId(f),
                predefinedContactField$p(f, ctx),
                {
                  TAG: "PredefinedContact",
                  _0: f
                }
              ];
      });
  var leadCustomFields = customFields.lead.map(function (f) {
        return [
                f.id,
                f.name,
                {
                  TAG: "LeadField",
                  _0: AmoCrmTypes.LeadField.makeFromCustomField(f)
                }
              ];
      });
  var contactCustomFields = customFields.contacts.map(function (f) {
        return [
                f.id,
                f.name + contactsField$p(ctx),
                {
                  TAG: "ContactField",
                  _0: AmoCrmTypes.ContactField.makeFromCustomField(f)
                }
              ];
      });
  var leadFields = Belt_Array.concatMany([
        predefinedLeadFields,
        leadCustomFields
      ]);
  var contactFields = Belt_Array.concatMany([
        predefinedContactFields,
        contactCustomFields
      ]);
  var amocrmFieldsTotal = Belt_Array.concatMany([
        leadFields,
        contactFields
      ]);
  var initial_templates = templates.map(function (param) {
        return {
                uuid: param.uuid,
                name: param.name
              };
      });
  var initial_jobFields = [];
  var initial_leadFields = Belt_Array.concatMany([
        customFields.lead.map(function (f) {
              return [
                      f.id,
                      f.name,
                      AmoCrmTypes.LeadField.makeFromCustomField(f)
                    ];
            }),
        [[
            AmoCrmTypes.Predefined.Lead.toId("Sale"),
            predefinedLeadField$p("Sale", ctx),
            AmoCrmTypes.LeadField.makeFromPredefinedLead("Sale")
          ]]
      ]);
  var initial_planadoFields = [];
  var initial = {
    templates: initial_templates,
    jobFields: initial_jobFields,
    amocrmFields: amocrmFieldsTotal,
    leadFields: initial_leadFields,
    planadoFields: initial_planadoFields
  };
  var initialState = template !== undefined ? updateStateFromTemplate(initial, template) : initial;
  var reducer = function (param) {
    var action = param[1];
    var state = param[0];
    switch (action.TAG) {
      case "UpdateTemplate" :
          var template = action._0;
          if (template !== undefined) {
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: updateStateFromTemplate(state, template),
                    _1: (function (param) {
                        updateSettings(Caml_option.some(Caml_option.some(template.uuid)), undefined, undefined, keepOnlyPredefinedFieldsInJobMapping(), keepOnlyPredefinedFieldsInLeadMapping(), undefined);
                      })
                  };
          } else {
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: {
                      templates: state.templates,
                      jobFields: predefinedJobFields(ctx),
                      amocrmFields: state.amocrmFields,
                      leadFields: state.leadFields,
                      planadoFields: state.planadoFields
                    },
                    _1: (function (param) {
                        updateSettings(Caml_option.some(undefined), undefined, undefined, keepOnlyPredefinedFieldsInJobMapping(), keepOnlyPredefinedFieldsInLeadMapping(), undefined);
                      })
                  };
          }
      case "UpdateJobFieldDestination" :
          var idx = action._0;
          var jobField = RCore.$$Option.map(RCore.$$Option.flatMap(action._1, (function (fieldId) {
                      var __x = state.jobFields;
                      return Js_array.find((function (param) {
                                    return Types.Id.equal(param[0], fieldId);
                                  }), __x);
                    })), (function (param) {
                  return param[2];
                }));
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      updateSettings(undefined, undefined, undefined, statusConfig.jobFields.map(function (f, i) {
                                if (i === idx) {
                                  return {
                                          jobField: jobField,
                                          amocrmField: f.amocrmField
                                        };
                                } else {
                                  return f;
                                }
                              }), undefined, undefined);
                    })
                };
      case "UpdateJobFieldSource" :
          var idx$1 = action._0;
          var amocrmField = RCore.$$Option.flatMap(action._1, (function (fieldId) {
                  return RCore.$$Option.map((function (__x) {
                                  return Js_array.find((function (param) {
                                                return Types.Id.equal(fieldId, param[0]);
                                              }), __x);
                                })(state.amocrmFields), (function (param) {
                                return param[2];
                              }));
                }));
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      updateSettings(undefined, undefined, undefined, statusConfig.jobFields.map(function (f, i) {
                                if (i === idx$1) {
                                  return {
                                          jobField: f.jobField,
                                          amocrmField: amocrmField
                                        };
                                } else {
                                  return f;
                                }
                              }), undefined, undefined);
                    })
                };
      case "RemoveJobField" :
          var idx$2 = action._0;
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      updateSettings(undefined, undefined, undefined, RCore.$$Array.filterMap(statusConfig.jobFields.map(function (f, i) {
                                    if (i === idx$2) {
                                      return ;
                                    } else {
                                      return f;
                                    }
                                  }), Fun.id), undefined, undefined);
                    })
                };
      case "UpdateLeadFieldDestination" :
          var idx$3 = action._0;
          var leadField = RCore.$$Option.flatMap(action._1, (function (fieldId) {
                  var match = AmoCrmTypes.Predefined.Lead.fromId(fieldId);
                  if (typeof match !== "object" && match === "Sale") {
                    return AmoCrmTypes.LeadField.makeFromPredefinedLead("Sale");
                  }
                  return RCore.$$Option.map((function (__x) {
                                  return Js_array.find((function (param) {
                                                return Types.Id.equal(fieldId, param[0]);
                                              }), __x);
                                })(state.leadFields), (function (param) {
                                return param[2];
                              }));
                }));
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      updateSettings(undefined, undefined, undefined, undefined, statusConfig.leadFields.map(function (f, i) {
                                if (i === idx$3) {
                                  return {
                                          leadField: leadField,
                                          planadoField: f.planadoField
                                        };
                                } else {
                                  return f;
                                }
                              }), undefined);
                    })
                };
      case "UpdateLeadFieldSource" :
          var idx$4 = action._0;
          var planadoField = RCore.$$Option.map(RCore.$$Option.flatMap(action._1, (function (fieldId) {
                      var __x = state.planadoFields;
                      return Js_array.find((function (param) {
                                    return Types.Id.equal(param[0], fieldId);
                                  }), __x);
                    })), (function (param) {
                  return param[2];
                }));
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      updateSettings(undefined, undefined, undefined, undefined, statusConfig.leadFields.map(function (f, i) {
                                if (i === idx$4) {
                                  return {
                                          leadField: f.leadField,
                                          planadoField: planadoField
                                        };
                                } else {
                                  return f;
                                }
                              }), undefined);
                    })
                };
      case "RemoveLeadField" :
          var idx$5 = action._0;
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      updateSettings(undefined, undefined, undefined, undefined, RCore.$$Array.filterMap(statusConfig.leadFields.map(function (f, i) {
                                    if (i === idx$5) {
                                      return ;
                                    } else {
                                      return f;
                                    }
                                  }), Fun.id), undefined);
                    })
                };
      
    }
  };
  var match = Shared_Lib_Reducer.use(reducer, initialState);
  var send = match[1];
  var state = match[0];
  var onChangeTemplate = function (templateUuid) {
    var t = RCore.$$Option.flatMap(templateUuid === null ? undefined : Caml_option.some(templateUuid), findTemplate);
    send({
          TAG: "UpdateTemplate",
          _0: t
        });
  };
  var fullScheduledAtConfigured = statusConfig.jobFields.some(function (f) {
        var match = f.jobField;
        if (match === undefined) {
          return false;
        }
        switch (match.TAG) {
          case "Predefined" :
              if (match._0 === "ScheduledAt") {
                return true;
              } else {
                return false;
              }
          case "Custom" :
          case "Report" :
              return false;
          
        }
      });
  var combinedScheduledAtConfigured = statusConfig.jobFields.some(function (f) {
        var match = f.jobField;
        if (match === undefined) {
          return false;
        }
        switch (match.TAG) {
          case "Predefined" :
              switch (match._0) {
                case "ScheduledAtDate" :
                case "ScheduledAtTime" :
                    return true;
                default:
                  return false;
              }
          case "Custom" :
          case "Report" :
              return false;
          
        }
      });
  var jobFieldDestinations = RCore.$$Array.keepMap(state.jobFields, (function (f) {
          var match = f[2];
          switch (match.TAG) {
            case "Predefined" :
                switch (match._0) {
                  case "ScheduledAt" :
                      if (combinedScheduledAtConfigured) {
                        return ;
                      }
                      break;
                  case "ScheduledAtDate" :
                  case "ScheduledAtTime" :
                      if (fullScheduledAtConfigured) {
                        return ;
                      }
                      break;
                  default:
                    
                }
                break;
            case "Custom" :
            case "Report" :
                break;
            
          }
          return {
                  value: f[0],
                  label: f[1](ctx)
                };
        }));
  return React.createElement("div", {
              className: styles.statusConfig
            }, React.createElement("div", {
                  className: styles.configGroup
                }, React.createElement("label", undefined, byTemplate$p(ctx)), React.createElement(TemplateSelect.make, {
                      placeholder: chooseTemplate$p(ctx),
                      onChange: onChangeTemplate,
                      value: Js_null.fromOption(statusConfig.templateUuid),
                      disabled: locked,
                      options: state.templates.map(function (param) {
                            return {
                                    value: param.uuid,
                                    label: param.name
                                  };
                          })
                    }), requiredFieldError(statusConfig.errors.templateUuid)), React.createElement("div", {
                  className: styles.fieldMapping
                }, React.createElement("label", undefined, fillJobFieldsFromLead$p(ctx)), statusConfig.jobFields.map(function (param, i) {
                      var amocrmField = param.amocrmField;
                      var jobField = param.jobField;
                      return React.createElement("div", {
                                  key: i.toString()
                                }, React.createElement("div", {
                                      className: styles.fieldOptions
                                    }, React.createElement(Select.make, {
                                          placeholder: template !== undefined ? chooseField$p(ctx) : chooseTemplate$p(ctx),
                                          onChange: (function (fieldId) {
                                              send({
                                                    TAG: "UpdateJobFieldDestination",
                                                    _0: i,
                                                    _1: fieldId === null ? undefined : Caml_option.some(fieldId)
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(jobField, AmoCrmTypes.JobField.getId)),
                                          disabled: false,
                                          options: jobFieldDestinations,
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("span", {
                                          className: styles.from
                                        }, from$p(ctx)), React.createElement(Select.make, {
                                          placeholder: chooseField$p(ctx),
                                          onChange: (function (fieldId) {
                                              send({
                                                    TAG: "UpdateJobFieldSource",
                                                    _0: i,
                                                    _1: fieldId === null ? undefined : Caml_option.some(fieldId)
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(amocrmField, AmoCrmTypes.AmocrmField.getId)),
                                          disabled: locked,
                                          options: state.amocrmFields.map(function (param) {
                                                return {
                                                        value: param[0],
                                                        label: param[1]
                                                      };
                                              }),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("button", {
                                          className: "btn",
                                          type: "button",
                                          onClick: (function (param) {
                                              send({
                                                    TAG: "RemoveJobField",
                                                    _0: i
                                                  });
                                            })
                                        }, React.createElement("i", {
                                              className: Icon.style(undefined, undefined, undefined, undefined, "trash")
                                            })), fieldMappingError(jobField, amocrmField, "LeadToJob")));
                    }), React.createElement("div", {
                      className: styles.addFieldMapping
                    }, React.createElement("button", {
                          className: "btn-link add-link",
                          type: "button",
                          onClick: addJobMapping
                        }, React.createElement("i", {
                              className: Icon.style(undefined, undefined, undefined, undefined, "plus")
                            }), addField$p(ctx)))), React.createElement("div", {
                  className: styles.fieldMapping
                }, React.createElement("label", undefined, fillLeadFieldsFromReport$p(ctx)), statusConfig.leadFields.map(function (param, i) {
                      var planadoField = param.planadoField;
                      var leadField = param.leadField;
                      return React.createElement("div", {
                                  key: i.toString()
                                }, React.createElement("div", {
                                      className: styles.fieldOptions
                                    }, React.createElement(Select.make, {
                                          placeholder: chooseField$p(ctx),
                                          onChange: (function (fieldId) {
                                              send({
                                                    TAG: "UpdateLeadFieldDestination",
                                                    _0: i,
                                                    _1: fieldId === null ? undefined : Caml_option.some(fieldId)
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(leadField, AmoCrmTypes.LeadField.getId)),
                                          disabled: locked,
                                          options: state.leadFields.map(function (param) {
                                                return {
                                                        value: param[0],
                                                        label: param[1]
                                                      };
                                              }),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("span", {
                                          className: styles.from
                                        }, from$p(ctx)), React.createElement(Select.make, {
                                          placeholder: chooseField$p(ctx),
                                          onChange: (function (fieldId) {
                                              send({
                                                    TAG: "UpdateLeadFieldSource",
                                                    _0: i,
                                                    _1: fieldId === null ? undefined : Caml_option.some(fieldId)
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(planadoField, AmoCrmTypes.JobField.getId)),
                                          disabled: locked,
                                          options: state.planadoFields.map(function (param) {
                                                return {
                                                        value: param[0],
                                                        label: param[1](ctx)
                                                      };
                                              }),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("button", {
                                          className: "btn",
                                          type: "button",
                                          onClick: (function (param) {
                                              send({
                                                    TAG: "RemoveLeadField",
                                                    _0: i
                                                  });
                                            })
                                        }, React.createElement("i", {
                                              className: Icon.style(undefined, undefined, undefined, undefined, "trash")
                                            })), fieldMappingError(planadoField, RCore.$$Option.map(leadField, (function (f) {
                                                return {
                                                        TAG: "LeadField",
                                                        _0: f
                                                      };
                                              })), "JobToLead")));
                    }), React.createElement("div", {
                      className: styles.addFieldMapping
                    }, React.createElement("button", {
                          className: "btn-link add-link",
                          type: "button",
                          onClick: addLeadMapping
                        }, React.createElement("i", {
                              className: Icon.style(undefined, undefined, undefined, undefined, "plus")
                            }), addField$p(ctx)))), React.createElement("div", {
                  className: styles.configGroup
                }, React.createElement("label", undefined, onSuccess$p(ctx)), React.createElement(Select.make, {
                      placeholder: chooseStatus$p(ctx),
                      onChange: (function (statusIdOnSuccess) {
                          updateSettings(undefined, Caml_option.some(statusIdOnSuccess === null ? undefined : Caml_option.some(statusIdOnSuccess)), undefined, undefined, undefined, undefined);
                        }),
                      value: Js_null.fromOption(statusConfig.statusIdOnSuccess),
                      disabled: locked,
                      options: RCore.$$Array.filterMap(statuses, (function (s) {
                              if (Types.Id.equal(s.id, statusId)) {
                                return ;
                              } else {
                                return {
                                        value: s.id,
                                        label: s.name
                                      };
                              }
                            }))
                    }), requiredFieldError(statusConfig.errors.statusIdOnSuccess)), React.createElement("div", {
                  className: styles.configGroup
                }, React.createElement("label", undefined, onUnsuccess$p(ctx)), React.createElement(Select.make, {
                      placeholder: chooseStatus$p(ctx),
                      onChange: (function (statusIdOnFailure) {
                          updateSettings(undefined, undefined, Caml_option.some(statusIdOnFailure === null ? undefined : Caml_option.some(statusIdOnFailure)), undefined, undefined, undefined);
                        }),
                      value: Js_null.fromOption(statusConfig.statusIdOnFailure),
                      disabled: locked,
                      options: RCore.$$Array.filterMap(statuses, (function (s) {
                              if (Types.Id.equal(s.id, statusId)) {
                                return ;
                              } else {
                                return {
                                        value: s.id,
                                        label: s.name
                                      };
                              }
                            }))
                    }), requiredFieldError(statusConfig.errors.statusIdOnFailure)));
}

var pipelines$p = Utils.Translations.tr("js.admin.amocrm.pipelines");

var choosePipeline$p = Utils.Translations.tr("js.admin.amocrm.choose_pipeline");

var toPipelines$p = Utils.Translations.tr("js.admin.amocrm.to_pipelines");

var configurePipeline$p = Utils.Translations.tr("js.admin.amocrm.configure_pipeline");

var forLeadsInStatusesCreateJob$p = Utils.Translations.tr("js.admin.amocrm.for_leads_in_statuses_create_a_job");

var save$p = Utils.Translations.tr("common.save");

var errorOnSave$p = Utils.Translations.t("common.messages.error_on_save");

var formContainsErrors$p = Utils.Translations.t("js.errors.form_contains_errors");

var chooseStatus$p$1 = Utils.Translations.t("js.admin.amocrm.choose_status");

var chooseTemplate$p$1 = Utils.Translations.t("js.admin.amocrm.choose_template");

function updateSettings(configured, templateUuid, statusIdOnSuccess, statusIdOnFailure, jobFields, leadFields, account, settings, pipelineId, statusId, param) {
  return {
          pipelines: account.pipelines.map(function (p) {
                var s = AmoCrmTypes.Settings.configuredPipeline(settings, p.id);
                var pipelineSettings = s !== undefined ? s : AmoCrmTypes.ConfiguredPipeline.empty(p.id);
                var statuses = p.statuses.map(function (s) {
                      var s$1 = AmoCrmTypes.Settings.configuredStatus(pipelineSettings.statuses, s.id);
                      var statusSettings = s$1 !== undefined ? s$1 : AmoCrmTypes.ConfiguredStatus.empty(s.id);
                      if (Types.Id.equal(p.id, pipelineId) && Types.Id.equal(s.id, statusId)) {
                        return {
                                uuid: statusSettings.uuid,
                                configured: RCore.$$Option.getOr(configured, statusSettings.configured),
                                statusId: statusSettings.statusId,
                                templateUuid: RCore.$$Option.getOr(templateUuid, statusSettings.templateUuid),
                                statusIdOnSuccess: RCore.$$Option.getOr(statusIdOnSuccess, statusSettings.statusIdOnSuccess),
                                statusIdOnFailure: RCore.$$Option.getOr(statusIdOnFailure, statusSettings.statusIdOnFailure),
                                jobFields: RCore.$$Option.getOr(jobFields, statusSettings.jobFields),
                                leadFields: RCore.$$Option.getOr(leadFields, statusSettings.leadFields),
                                errors: statusSettings.errors
                              };
                      } else {
                        return statusSettings;
                      }
                    });
                return AmoCrmTypes.ConfiguredPipeline.make(p.id, statuses);
              })
        };
}

function AmoCrmShow$Show(Props) {
  var wire = Props.wire;
  var pathPipeline = Props.pathPipeline;
  var configuration = Props.configuration;
  var templates = Props.templates;
  var removed = Props.removed;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = configuration.account.pipelines;
  var chosenPipeline;
  if (match.length !== 1) {
    if (pathPipeline !== undefined) {
      var id = Caml_option.valFromOption(pathPipeline);
      chosenPipeline = (function (__x) {
            return Js_array.find((function (p) {
                          return Types.Id.equal(p.id, id);
                        }), __x);
          })(match);
    } else {
      chosenPipeline = undefined;
    }
  } else {
    chosenPipeline = match[0];
  }
  var validateSettings = function (settings) {
    var hasErrors = {
      contents: false
    };
    var validatedPipelines = settings.pipelines.map(function (p) {
          var statusesWithErrors = p.statuses.map(function (status) {
                if (!status.configured) {
                  return {
                          uuid: status.uuid,
                          configured: status.configured,
                          statusId: status.statusId,
                          templateUuid: status.templateUuid,
                          statusIdOnSuccess: status.statusIdOnSuccess,
                          statusIdOnFailure: status.statusIdOnFailure,
                          jobFields: status.jobFields,
                          leadFields: status.leadFields,
                          errors: AmoCrmTypes.ConfiguredStatus.emptyErrors
                        };
                }
                var match = status.templateUuid;
                var templateUuidError = match !== undefined ? undefined : chooseTemplate$p$1(ctx);
                var match$1 = status.statusIdOnSuccess;
                var statusIdOnSuccessError = match$1 !== undefined ? undefined : chooseStatus$p$1(ctx);
                var match$2 = status.statusIdOnFailure;
                var statusIdOnFailureError = match$2 !== undefined ? undefined : chooseStatus$p$1(ctx);
                var errors = {
                  templateUuid: templateUuidError,
                  statusIdOnSuccess: statusIdOnSuccessError,
                  statusIdOnFailure: statusIdOnFailureError
                };
                if (Caml_obj.notequal(errors, AmoCrmTypes.ConfiguredStatus.emptyErrors)) {
                  hasErrors.contents = true;
                }
                return {
                        uuid: status.uuid,
                        configured: status.configured,
                        statusId: status.statusId,
                        templateUuid: status.templateUuid,
                        statusIdOnSuccess: status.statusIdOnSuccess,
                        statusIdOnFailure: status.statusIdOnFailure,
                        jobFields: status.jobFields,
                        leadFields: status.leadFields,
                        errors: errors
                      };
              });
          return {
                  id: p.id,
                  statuses: statusesWithErrors
                };
        });
    var validatedSettings = {
      pipelines: validatedPipelines
    };
    if (hasErrors.contents === true) {
      return {
              TAG: "Error",
              _0: validatedSettings
            };
    } else {
      return {
              TAG: "Ok",
              _0: validatedSettings
            };
    }
  };
  var initialState = {
    locked: false,
    configuration: configuration
  };
  var reducer = function (param) {
    var action = param[1];
    var state = param[0];
    var configuration = state.configuration;
    if (typeof action !== "object") {
      switch (action) {
        case "Submit" :
            var settingsWithErrors = validateSettings(configuration.settings);
            if (settingsWithErrors.TAG === "Ok") {
              return {
                      TAG: "UpdateWithSideEffect",
                      _0: {
                        locked: true,
                        configuration: {
                          account: configuration.account,
                          settings: settingsWithErrors._0
                        }
                      },
                      _1: (function (param) {
                          var send = param.send;
                          Backend.$$finally(AmoCrmApi.Show.update(wire, configuration), (function (v) {
                                  send({
                                        TAG: "Updated",
                                        _0: v
                                      });
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "UpdateWithSideEffect",
                      _0: {
                        locked: state.locked,
                        configuration: {
                          account: configuration.account,
                          settings: settingsWithErrors._0
                        }
                      },
                      _1: (function (param) {
                          messenger.trigger({
                                TAG: "Show",
                                _0: MessengerTypes.Message.make(undefined, "Danger", {
                                      TAG: "Text",
                                      _0: formContainsErrors$p(ctx)
                                    })
                              });
                        })
                    };
            }
        case "Lock" :
            return {
                    TAG: "Update",
                    _0: {
                      locked: true,
                      configuration: state.configuration
                    }
                  };
        case "Unlock" :
            return {
                    TAG: "Update",
                    _0: {
                      locked: false,
                      configuration: state.configuration
                    }
                  };
        
      }
    } else {
      if (action.TAG === "UpdateSettings") {
        return {
                TAG: "Update",
                _0: {
                  locked: state.locked,
                  configuration: {
                    account: configuration.account,
                    settings: action._0
                  }
                }
              };
      }
      var data = action._0;
      if (data.TAG !== "Ok") {
        return {
                TAG: "UpdateWithSideEffect",
                _0: {
                  locked: false,
                  configuration: state.configuration
                },
                _1: (function (param) {
                    messenger.trigger({
                          TAG: "Show",
                          _0: MessengerTypes.Message.make(undefined, "Danger", {
                                TAG: "Text",
                                _0: errorOnSave$p(ctx)
                              })
                        });
                  })
              };
      }
      var url = data._0.redirect;
      if (url !== undefined) {
        return {
                TAG: "SideEffect",
                _0: (function (param) {
                    Router.redirect(url);
                  })
              };
      } else {
        return {
                TAG: "Update",
                _0: {
                  locked: false,
                  configuration: state.configuration
                }
              };
      }
    }
  };
  var match$1 = Shared_Lib_Reducer.use(reducer, initialState);
  var send = match$1[1];
  var state = match$1[0];
  var submit = function ($$event) {
    $$event.preventDefault();
    send("Submit");
  };
  var match$2 = state.configuration;
  var settings = match$2.settings;
  var account = match$2.account;
  var customFields = account.customFields;
  var locked = state.locked;
  var international = ctx.localizator.lang !== "ru";
  var tmp;
  if (chosenPipeline !== undefined) {
    var statuses = chosenPipeline.statuses;
    var chosenId = chosenPipeline.id;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("form", {
              className: styles.newFormContainer
            }, React.createElement("h2", {
                  className: styles.caption
                }, React.createElement("span", undefined, chosenPipeline.name, El.space, El.mdash, El.space, configurePipeline$p(ctx)), React.createElement(AmoCrmShow$AccountInfo, {
                      wire: wire,
                      account: account,
                      lock: (function (_event) {
                          send("Lock");
                        }),
                      unlock: (function (_event) {
                          send("Unlock");
                        }),
                      locked: locked,
                      removed: removed
                    })), React.createElement("h3", {
                  className: styles.statusesHeader
                }, forLeadsInStatusesCreateJob$p(ctx)), React.createElement("ul", {
                  className: styles.statusList
                }, statuses.map(function (param) {
                      var id = param.id;
                      var pipeline = Js_array.find((function (param) {
                              return Types.Id.equal(chosenId, param.id);
                            }), settings.pipelines);
                      var statusConfig = RCore.$$Option.flatMap(pipeline, (function (param) {
                              var __x = param.statuses;
                              return Js_array.find((function (param) {
                                            return Types.Id.equal(id, param.statusId);
                                          }), __x);
                            }));
                      var configured = RCore.$$Option.mapWithDefault(statusConfig, false, (function (param) {
                              return param.configured;
                            }));
                      var tmp;
                      if (statusConfig !== undefined) {
                        var configured$1 = statusConfig.configured;
                        tmp = configured$1 ? React.createElement(AmoCrmShow$StatusConfiguration, {
                                wire: wire,
                                statusId: id,
                                statuses: statuses,
                                statusConfig: statusConfig,
                                templates: templates,
                                customFields: customFields,
                                updateSettings: (function (templateUuid, statusIdOnSuccess, statusIdOnFailure, jobFields, leadFields, param) {
                                    send({
                                          TAG: "UpdateSettings",
                                          _0: updateSettings(configured$1, templateUuid, statusIdOnSuccess, statusIdOnFailure, jobFields, leadFields, account, settings, chosenId, id, undefined)
                                        });
                                  }),
                                locked: locked
                              }) : null;
                      } else {
                        tmp = null;
                      }
                      return React.createElement("li", {
                                  key: Types.Id.toString(id)
                                }, React.createElement("div", {
                                      className: styles.statusName
                                    }, React.createElement(Checkbox.make, {
                                          onToggle: (function (configured) {
                                              send({
                                                    TAG: "UpdateSettings",
                                                    _0: updateSettings(configured, undefined, undefined, undefined, undefined, undefined, account, settings, chosenId, id, undefined)
                                                  });
                                            }),
                                          checked: configured,
                                          label: param.name
                                        })), tmp);
                    }))), React.createElement("div", {
              className: styles.footer
            }, React.createElement(Link.make, {
                  wire: wire,
                  route: {
                    NAME: "Integrations",
                    VAL: {
                      NAME: "amoCRM",
                      VAL: {
                        NAME: "Show",
                        VAL: [
                          international,
                          undefined
                        ]
                      }
                    }
                  },
                  className: "btn " + styles.back,
                  children: React.createElement(React.Fragment, undefined, React.createElement("i", {
                            className: Icon.style(undefined, undefined, undefined, undefined, "chevron-left")
                          }), El.space, toPipelines$p(ctx))
                }), React.createElement("button", {
                  className: "btn btn-primary",
                  disabled: locked,
                  onClick: submit
                }, save$p(ctx))));
  } else {
    tmp = React.createElement("form", {
          className: styles.newFormContainer
        }, React.createElement("h2", {
              className: styles.caption
            }, React.createElement("span", undefined, pipelines$p(ctx))), React.createElement("div", undefined, choosePipeline$p(ctx)), React.createElement("ul", {
              className: styles.pipelines
            }, account.pipelines.map(function (param) {
                  var id = param.id;
                  return React.createElement("li", {
                              key: Types.Id.toString(id)
                            }, React.createElement(Link.make, {
                                  wire: wire,
                                  route: {
                                    NAME: "Integrations",
                                    VAL: {
                                      NAME: "amoCRM",
                                      VAL: {
                                        NAME: "Show",
                                        VAL: [
                                          international,
                                          Caml_option.some(id)
                                        ]
                                      }
                                    }
                                  },
                                  children: param.name
                                }));
                })));
  }
  return React.createElement("div", {
              className: "form"
            }, React.createElement("section", {
                  className: styles.form
                }, tmp));
}

function reducer(param) {
  var action = param[1];
  var state = param[0];
  if (typeof action !== "object") {
    return {
            TAG: "Update",
            _0: {
              loaded: state.loaded,
              credentials: state.credentials,
              configuration: undefined,
              templates: state.templates,
              error: state.error
            }
          };
  }
  var match = action._0;
  if (match.TAG !== "Ok") {
    return "NoUpdate";
  }
  var match$1 = match._0;
  return {
          TAG: "Update",
          _0: {
            loaded: true,
            credentials: match$1.credentials,
            configuration: match$1.configuration,
            templates: match$1.templates,
            error: match$1.error
          }
        };
}

var initialState_templates = [];

var initialState = {
  loaded: false,
  credentials: undefined,
  configuration: undefined,
  templates: initialState_templates,
  error: undefined
};

function AmoCrmShow(Props) {
  var wire = Props.wire;
  var pathPipeline = Props.pathPipeline;
  var match = Shared_Lib_Reducer.use(reducer, initialState);
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          Backend.$$finally(AmoCrmApi.Show.show(wire), (function (v) {
                  send({
                        TAG: "Loaded",
                        _0: v
                      });
                }));
        }), []);
  var error = state.error;
  var configuration = state.configuration;
  var loaded = state.loaded;
  if (error !== undefined && loaded) {
    wire.subscriptions.messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: loadError$p(error, wire.ctx)
              })
        });
  }
  return React.createElement("span", undefined, loaded ? (
                configuration !== undefined ? React.createElement(AmoCrmShow$Show, {
                        wire: wire,
                        pathPipeline: pathPipeline,
                        configuration: configuration,
                        templates: state.templates,
                        removed: (function (_event) {
                            send("Removed");
                          })
                      }) : React.createElement(AmoCrmShow$New, {
                        wire: wire
                      })
              ) : null);
}

var make = AmoCrmShow;

export {
  make ,
}
/* styles Not a pure module */
