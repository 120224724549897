

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as EncodeJson from "../../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as JobFieldType from "./JobFieldType.mjs";
import * as JobViewDecoder from "./views/JobViewDecoder.mjs";
import * as JobIndexIdToUuid from "./JobIndexIdToUuid.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";

function encode(c) {
  if (typeof c !== "object") {
    return EncodeJson.object([]);
  } else if (c.TAG === "Exact") {
    return EncodeJson.object([[
                  "exact",
                  EncodeJson.$$int(c._0)
                ]]);
  } else {
    return EncodeJson.object([[
                  "approx",
                  EncodeJson.$$int(c._0)
                ]]);
  }
}

var decode = JsonDecode.object(function (field) {
      var number = field.optional("exact", JsonDecode.$$int);
      if (number !== undefined) {
        return {
                TAG: "Exact",
                _0: number
              };
      }
      var number$1 = field.optional("approx", JsonDecode.$$int);
      if (number$1 !== undefined) {
        return {
                TAG: "Approx",
                _0: number$1
              };
      } else {
        return "Unknown";
      }
    });

function encode$1(currentFilter) {
  return function (value) {
    if (value.TAG === "Uuids") {
      return {
              type: "uuids",
              uuids: value._0.map(Uuid.toString),
              filter: undefined,
              excludeUuids: undefined
            };
    } else {
      return {
              type: "filter",
              uuids: undefined,
              filter: currentFilter,
              excludeUuids: value._0.map(Uuid.toString)
            };
    }
  };
}

var OperationSet = {
  encode: encode$1
};

function fromAssignee(assignee) {
  if (assignee.TAG === "Worker") {
    return {
            TAG: "Worker",
            _0: assignee._0.uuid
          };
  } else {
    return {
            TAG: "Team",
            _0: assignee._0.uuid
          };
  }
}

var Assignee = {
  fromAssignee: fromAssignee
};

function encode$2(op) {
  if (typeof op !== "object") {
    return {
            type: "delete",
            value: undefined
          };
  }
  if (op.TAG !== "AssignMultiple") {
    return {
            type: "schedule",
            value: RCore.$$Option.getOr(RCore.$$Option.map(op._0, (function (v) {
                        return Locale.T.formatM("DateTimeISO")(v);
                      })), null)
          };
  }
  var encoded = MultipleAssignee.serialize(MultipleAssignee.encode(op._0));
  return {
          type: "assign_multiple",
          value: encoded
        };
}

var BulkOperation = {
  Assignee: Assignee,
  encode: encode$2
};

function decode$1(field) {
  var match = field.required("type", JsonDecode.string);
  switch (match) {
    case "action" :
        return "Action";
    case "barcode" :
        return "Barcode";
    case "button" :
        return "Button";
    case "checkbox" :
        return "Checkbox";
    case "currency" :
        return "Currency";
    case "date_picker" :
        return "DatePicker";
    case "datetime_picker" :
        return "DatetimePicker";
    case "dictionary" :
        return {
                TAG: "Dictionary",
                _0: field.required("dictionaryUuid", Uuid.decode)
              };
    case "duration" :
        return "Duration";
    case "file" :
        return "File";
    case "image" :
        return "Image";
    case "input" :
        return "Input";
    case "link" :
        return "Link";
    case "location" :
        return "Location";
    case "signature" :
        return "Signature";
    case "textarea" :
        return "Textarea";
    case "time_picker" :
        return "TimePicker";
    default:
      return JsonDecode.cannotDecode("invalid field type");
  }
}

function encodeType(fieldType) {
  if (typeof fieldType === "object") {
    return "dictionary";
  }
  switch (fieldType) {
    case "DatePicker" :
        return "date_picker";
    case "Input" :
        return "input";
    case "DatetimePicker" :
        return "datetime_picker";
    case "TimePicker" :
        return "time_picker";
    case "Image" :
        return "image";
    case "Location" :
        return "location";
    case "Duration" :
        return "duration";
    case "Signature" :
        return "signature";
    case "File" :
        return "file";
    case "Checkbox" :
        return "checkbox";
    case "Action" :
        return "action";
    case "Barcode" :
        return "barcode";
    case "Link" :
        return "link";
    case "Currency" :
        return "currency";
    case "Button" :
        return "button";
    case "Textarea" :
        return "textarea";
    
  }
}

function encodeDictUuid(fieldType) {
  if (typeof fieldType !== "object") {
    return null;
  } else {
    return fieldType._0;
  }
}

function make(uuid, name) {
  return {
          uuid: uuid,
          name: name
        };
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Value = {
  make: make,
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              uuid: field.required("uuid", Uuid.decode),
              values: field.required("values", JsonDecode.array(decode$2))
            };
    });

var Dictionary = {
  Value: Value,
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              key: field.required("key", JobFieldType.decode),
              enabled: field.required("enabled", JsonDecode.bool)
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              typeUuid: field.required("typeUuid", Uuid.decode),
              enabled: field.required("enabled", JsonDecode.bool)
            };
    });

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              predefinedFields: field.required("predefinedFields", JsonDecode.array(decode$4)),
              customFields: field.required("customFields", JsonDecode.array(decode$5)),
              reportFields: field.required("reportFields", JsonDecode.array(decode$5))
            };
    });

var decode$7 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              system: field.required("system", JsonDecode.bool)
            };
    });

function decodeString(dataType) {
  switch (dataType) {
    case "attachment" :
        return "Attachment";
    case "boolean" :
        return "Boolean";
    case "currency" :
        return "Currency";
    case "date" :
        return "Date";
    case "datetime" :
        return "Datetime";
    case "decimal" :
        return "Decimal";
    case "dictionary" :
        return "Dictionary";
    case "duration" :
        return "Duration";
    case "integer" :
        return "Integer";
    case "location" :
        return "Location";
    case "string" :
        return "String";
    case "time" :
        return "Time";
    case "url" :
        return "Url";
    default:
      return ;
  }
}

var decode$8 = JsonDecode.fromVariant(JsonDecode.string, decodeString);

var decode$9 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              type_: decode$1(field),
              dataType: field.required("dataType", decode$8)
            };
    });

var decode$10 = JsonDecode.object(function (field) {
      var value = field.required("successful", JsonDecode.bool);
      return {
              id: field.required("id", Types.Id.decode),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              type_: value ? "Successful" : "Unsuccessful"
            };
    });

var decode$11 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$12 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$13 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$14 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              fullName: field.required("fullName", JsonDecode.string)
            };
    });

var decode$15 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var GroupedJobs = {};

var decode$16 = JsonDecode.custom(function (json) {
      return json;
    });

var Job = {
  decode: decode$16
};

function decode$17(ctx) {
  return JsonDecode.flatMap(JobIndexIdToUuid.decode, (function (mapping) {
                return JsonDecode.object(function (field) {
                            return {
                                    jobs: field.required("jobs", JsonDecode.array(decode$16)),
                                    views: field.required("views", JsonDecode.array(JobViewDecoder.decode(ctx, mapping))),
                                    reportFields: field.required("reportFields", JsonDecode.array(decode$9)),
                                    customFields: field.required("customFields", JsonDecode.array(decode$9)),
                                    services: field.required("services", JsonDecode.array(decode$13)),
                                    resolutions: field.required("resolutions", JsonDecode.array(decode$10)),
                                    territories: field.required("territories", JsonDecode.array(decode$12)),
                                    templates: field.required("templates", JsonDecode.array(decode$11)),
                                    workers: field.required("workers", JsonDecode.array(decode$14)),
                                    teams: field.required("teams", JsonDecode.array(decode$15)),
                                    types: field.required("types", JsonDecode.array(decode$7)),
                                    count: field.required("count", decode),
                                    dictionaries: field.required("dictionaries", JsonDecode.array(decode$3)),
                                    filters: field.required("filters", JsonDecode.option(decode$6))
                                  };
                          });
              }));
}

var Count = {
  decode: decode,
  encode: encode
};

var FieldType = {
  encodeType: encodeType,
  encodeDictUuid: encodeDictUuid
};

var JobFilters_PredefinedField = {};

var JobFilters_CustomField = {};

var JobFilters = {
  PredefinedField: JobFilters_PredefinedField,
  CustomField: JobFilters_CustomField,
  decode: decode$6
};

var Type = {};

var DataType = {};

var JobsData_Field = {};

var JobsData_Resolution = {};

var JobsData_Template = {};

var JobsData_Territory = {};

var JobsData_Service = {};

var JobsData_Worker = {};

var JobsData_Team = {};

var JobsData = {
  Field: JobsData_Field,
  Resolution: JobsData_Resolution,
  Template: JobsData_Template,
  Territory: JobsData_Territory,
  Service: JobsData_Service,
  $$Worker: JobsData_Worker,
  Team: JobsData_Team
};

export {
  Count ,
  OperationSet ,
  BulkOperation ,
  FieldType ,
  Dictionary ,
  JobFilters ,
  Type ,
  DataType ,
  JobsData ,
  GroupedJobs ,
  Job ,
  decode$17 as decode,
}
/* decode Not a pure module */
