

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Link from "../common/Link/Link.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Modal from "../modal/Modal.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Spinner from "../common/Spinner/Spinner.mjs";
import * as Optional from "../common/Optional.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModalQuery from "../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BackgroundTasksApi from "./BackgroundTasksApi.mjs";
import * as ModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/modal/Modal.module.css";
import * as BackgroundBulkModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/background_task/BackgroundBulkModal.module.css";

var styles = BackgroundBulkModalModuleCss;

var modalStyles = ModalModuleCss;

var success$p = Utils.Translations.tr("js.background_tasks.bulk.modal.success");

var canceled$p = Utils.Translations.tr("js.background_tasks.bulk.modal.user_canceled");

var emptyFilter$p = Utils.Translations.tr("js.background_tasks.bulk.modal.empty_filter");

var jobRemoved$p = Utils.Translations.tr("js.background_tasks.bulk.modal.job_removed");

var versionChanged$p = Utils.Translations.tr("js.background_tasks.bulk.errors.version_changed");

var errorEnroute$p = Utils.Translations.tr("js.background_tasks.bulk.errors.error_enroute");

var errorStarted$p = Utils.Translations.tr("js.background_tasks.bulk.errors.error_started");

var errorSuspended$p = Utils.Translations.tr("js.background_tasks.bulk.errors.error_suspended");

var errorFinished$p = Utils.Translations.tr("js.background_tasks.bulk.errors.error_finished");

var errorCanceled$p = Utils.Translations.tr("js.background_tasks.bulk.errors.error_canceled");

var noEditAssignee$p = Utils.Translations.tr("js.background_tasks.bulk.errors.no_edit_assignee");

var tooManyAssignees$p = Utils.Translations.tr("js.background_tasks.bulk.errors.too_many_assignees");

var tooManyEditAssignees$p = Utils.Translations.tr("js.background_tasks.bulk.errors.too_many_edit_assignees");

var noTeam$p = Utils.Translations.tr("js.background_tasks.bulk.errors.no_team");

var noUser$p = Utils.Translations.tr("js.background_tasks.bulk.errors.no_user");

var notStarted$p = Utils.Translations.tr("js.background_tasks.bulk.errors.not_started");

var error$p = Utils.Translations.tr("js.background_tasks.bulk.errors.general");

var all$p = Utils.Translations.tr("js.background_tasks.bulk.modal.all_jobs");

var successful$p = Utils.Translations.tr("js.background_tasks.bulk.modal.successful_jobs");

var unsuccessful$p = Utils.Translations.tr("js.background_tasks.bulk.modal.unsuccessful_jobs");

var showMore$p = Utils.Translations.tr("js.background_tasks.bulk.modal.show_more");

var retry$p = Utils.Translations.tr("js.background_tasks.bulk.modal.retry");

var deleteHeader$p = Utils.Translations.txr("js.background_tasks.bulk.modal.header.delete");

var scheduleHeader$p = Utils.Translations.txr("js.background_tasks.bulk.modal.header.schedule");

var assignHeader$p = Utils.Translations.txr("js.background_tasks.bulk.modal.header.assign");

var deleteTitle$p = Utils.Translations.tr("js.background_tasks.bulk.modal.title.delete");

var scheduleTitle$p = Utils.Translations.txr("js.background_tasks.bulk.modal.title.schedule");

var unschedule$p = Utils.Translations.tr("js.background_tasks.bulk.modal.title.unschedule");

var assignTitle$p = Utils.Translations.txr("js.background_tasks.bulk.modal.title.assign");

function multipleAssigneesSuffix$p(count, ctx) {
  return Utils.Translations.tx("js.background_tasks.bulk.modal.title.assignees")({
              count: count
            }, ctx);
}

var unassign$p = Utils.Translations.tr("js.background_tasks.bulk.modal.title.unassign");

function BackgroundBulkModal$Button(Props) {
  var onClick = Props.onClick;
  var activeOpt = Props.active;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var active = activeOpt !== undefined ? activeOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var buttonCN = El.Cn.concat(styles.button, active ? styles.active : "");
  return React.createElement("button", {
              className: buttonCN,
              disabled: disabled,
              type: "button",
              onClick: onClick
            }, children);
}

function BackgroundBulkModal$Job(Props) {
  var job = Props.job;
  var onSkip = Props.onSkip;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var formatDate = function (v) {
    return Locale.T.fmtM("DateFull", wire)(v);
  };
  var formatTime = function (v) {
    return Locale.T.fmtM("Time", wire)(v);
  };
  var error = job.status;
  var renderStatus;
  if (typeof error !== "object") {
    switch (error) {
      case "Canceled" :
          renderStatus = React.createElement("div", {
                className: styles.canceled
              }, canceled$p(ctx), React.createElement("i", {
                    className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "times-circle"), styles.timesCircle),
                    onClick: onSkip
                  }));
          break;
      case "NotStarted" :
          renderStatus = React.createElement("div", {
                className: styles.error
              }, notStarted$p(ctx), React.createElement("i", {
                    className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "times-circle"), styles.timesCircle),
                    onClick: onSkip
                  }));
          break;
      case "Success" :
          renderStatus = React.createElement("div", {
                className: styles.success
              }, success$p(ctx));
          break;
      
    }
  } else {
    var error$1 = error._0;
    var tmp;
    if (error$1.length !== 1) {
      tmp = error$p(ctx);
    } else {
      var match = error$1[0];
      switch (match) {
        case "VersionChanged" :
            tmp = versionChanged$p(ctx);
            break;
        case "CantChangeFinished" :
            tmp = errorFinished$p(ctx);
            break;
        case "CantChangeEnroute" :
            tmp = errorEnroute$p(ctx);
            break;
        case "CantChangeSuspended" :
            tmp = errorSuspended$p(ctx);
            break;
        case "CantChangeStarted" :
            tmp = errorStarted$p(ctx);
            break;
        case "CantChangeCanceled" :
            tmp = errorCanceled$p(ctx);
            break;
        case "NoEditAssignee" :
            tmp = noEditAssignee$p(ctx);
            break;
        case "TooManyAssignees" :
            tmp = tooManyAssignees$p(ctx);
            break;
        case "TooManyEditAssignees" :
            tmp = tooManyEditAssignees$p(ctx);
            break;
        case "NoTeam" :
            tmp = noTeam$p(ctx);
            break;
        case "NoUser" :
            tmp = noUser$p(ctx);
            break;
        
      }
    }
    renderStatus = React.createElement("div", {
          className: styles.error
        }, tmp, React.createElement("i", {
              className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "times-circle"), styles.timesCircle),
              onClick: onSkip
            }));
  }
  return React.createElement("tr", {
              className: styles.row
            }, React.createElement("td", undefined, React.createElement(Link.make, {
                      wire: wire,
                      route: wire.route.path,
                      modals: {
                        hd: {
                          NAME: "Job",
                          VAL: {
                            NAME: "Edit",
                            VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, job.uuid)
                          }
                        },
                        tl: /* [] */0
                      },
                      target: "blank",
                      children: job.serialNo.toString()
                    })), React.createElement("td", undefined, RCore.$$Option.getOr(job.template, "")), React.createElement("td", {
                  className: styles.alignEnd
                }, RCore.$$Option.getOr(RCore.$$Option.map(job.scheduledAt, formatDate), null)), React.createElement("td", undefined, RCore.$$Option.getOr(RCore.$$Option.map(job.scheduledAt, formatTime), null)), React.createElement("td", undefined, RCore.$$Option.getOr(job.assignee, "")), React.createElement("td", {
                  className: El.Cn.concat(styles.alignEnd, styles.status)
                }, renderStatus));
}

var deafultParams = BackgroundTasksApi.BulkOperation.Filter.deafultParams;

function BackgroundBulkModal(Props) {
  var taskUuid = Props.taskUuid;
  var operation = Props.operation;
  var onClose = Props.onClose;
  var retry = Props.retry;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return true;
      });
  var setAllJobsShown = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFetching = match$1[1];
  var fetching = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSpinnedMin = match$2[1];
  var match$3 = React.useState(function () {
        return [];
      });
  var setJobs = match$3[1];
  var jobs = match$3[0];
  var match$4 = React.useState(function () {
        return [];
      });
  var setJobUuids = match$4[1];
  var jobUuids = match$4[0];
  var match$5 = React.useState(function () {
        switch (operation.TAG) {
          case "Delete" :
              return {
                      TAG: "Unsuccessful",
                      _0: BackgroundTasksApi.BulkOperation.Filter.deafultParams
                    };
          case "Schedule" :
          case "AssignMultiple" :
              return BackgroundTasksApi.BulkOperation.Filter.$$default;
          
        }
      });
  var setFilter = match$5[1];
  var filter = match$5[0];
  var match$6 = React.useState(function () {
        return [];
      });
  var setSkipJobs = match$6[1];
  var skipJobs = match$6[0];
  var handleShowMore = function (jobUuids) {
    var limit = filter._0[1];
    setAllJobsShown(function (param) {
          return jobUuids.length < limit;
        });
  };
  var handleShowResponse = function (response) {
    if (response.TAG === "Ok") {
      var match = response._0;
      var jobs = match.jobs;
      var jobUuids = match.jobUuids;
      handleShowMore(jobUuids);
      setJobs(function (param) {
            return jobs;
          });
      setJobUuids(function (param) {
            return jobUuids;
          });
    } else {
      console.log(response._0);
    }
    setFetching(function (param) {
          return false;
        });
  };
  var handleShowMoreResponse = function (response) {
    if (response.TAG === "Ok") {
      var match = response._0;
      var jobs = match.jobs;
      var jobUuids = match.jobUuids;
      handleShowMore(jobUuids);
      setJobs(function (prevJobs) {
            return Belt_Array.concatMany([
                        prevJobs,
                        jobs
                      ]);
          });
      setJobUuids(function (prevUuids) {
            return Belt_Array.concatMany([
                        prevUuids,
                        jobUuids
                      ]);
          });
    } else {
      console.log(response._0);
    }
    setFetching(function (param) {
          return false;
        });
  };
  React.useEffect((function () {
          setFetching(function (param) {
                return true;
              });
          setTimeout((function () {
                  setSpinnedMin(function (param) {
                        return true;
                      });
                }), 1000);
          Backend.$$finally(BackgroundTasksApi.BulkOperation.get(wire, taskUuid, BackgroundTasksApi.BulkOperation.Filter.toJson(filter)), handleShowResponse);
        }), []);
  var filterDisabled;
  switch (operation.TAG) {
    case "Delete" :
        filterDisabled = true;
        break;
    case "Schedule" :
    case "AssignMultiple" :
        filterDisabled = false;
        break;
    
  }
  var onButtonClick = function (newFilter) {
    setSpinnedMin(function (param) {
          return false;
        });
    setFetching(function (param) {
          return true;
        });
    setFilter(function (param) {
          return newFilter;
        });
    setTimeout((function () {
            setSpinnedMin(function (param) {
                  return true;
                });
          }), 1000);
    Backend.$$finally(BackgroundTasksApi.BulkOperation.get(wire, taskUuid, BackgroundTasksApi.BulkOperation.Filter.toJson(newFilter)), handleShowResponse);
  };
  var displayJobUuids = RCore.$$Array.keep(jobUuids, (function (j) {
          return !skipJobs.some(function (s) {
                      return Uuid.equal(s, j);
                    });
        }));
  var hasErrors = jobs.some(function (j) {
        var match = j.status;
        if (typeof match !== "object" && match === "Success") {
          return false;
        } else {
          return true;
        }
      });
  var match$7;
  switch (filter.TAG) {
    case "All" :
        match$7 = [
          true,
          false,
          false
        ];
        break;
    case "Successful" :
        match$7 = [
          false,
          true,
          false
        ];
        break;
    case "Unsuccessful" :
        match$7 = [
          false,
          false,
          true
        ];
        break;
    
  }
  var showMore = function () {
    var newFilter = BackgroundTasksApi.BulkOperation.Filter.increaseOffset(filter);
    setFilter(function (param) {
          return newFilter;
        });
    Backend.$$finally(BackgroundTasksApi.BulkOperation.get(wire, taskUuid, BackgroundTasksApi.BulkOperation.Filter.toJson(newFilter)), handleShowMoreResponse);
  };
  var renderJobs = React.createElement("table", undefined, React.createElement("tbody", undefined, jobUuids.length === 0 && !fetching ? React.createElement("tr", {
                  className: styles.emptyFilter
                }, React.createElement("td", undefined, emptyFilter$p(ctx))) : displayJobUuids.map(function (uuid) {
                  var job = RCore.$$Array.getBy(jobs, (function (j) {
                          return Uuid.equal(j.uuid, uuid);
                        }));
                  if (job !== undefined) {
                    return React.createElement(BackgroundBulkModal$Job, {
                                job: job,
                                onSkip: (function (param) {
                                    setSkipJobs(function (prev) {
                                          return Belt_Array.concatMany([
                                                      prev,
                                                      [job.uuid]
                                                    ]);
                                        });
                                  }),
                                key: Uuid.toString(job.uuid)
                              });
                  } else {
                    return React.createElement("tr", {
                                className: El.Cn.concat(styles.row, styles.jobRemoved)
                              }, React.createElement("td", {
                                    colSpan: 6
                                  }, jobRemoved$p(ctx)));
                  }
                })));
  var tmp;
  switch (operation.TAG) {
    case "Delete" :
        tmp = deleteHeader$p({
              count: operation._0 - skipJobs.length | 0
            }, ctx);
        break;
    case "Schedule" :
        tmp = scheduleHeader$p({
              count: operation._0 - skipJobs.length | 0
            }, ctx);
        break;
    case "AssignMultiple" :
        tmp = assignHeader$p({
              count: operation._0 - skipJobs.length | 0
            }, ctx);
        break;
    
  }
  var tmp$1;
  switch (operation.TAG) {
    case "Delete" :
        tmp$1 = deleteTitle$p(ctx);
        break;
    case "Schedule" :
        var date = operation._1;
        tmp$1 = date !== undefined ? scheduleTitle$p({
                date: Locale.T.fmtM("DateTime", wire)(Caml_option.valFromOption(date))
              }, ctx) : unschedule$p(ctx);
        break;
    case "AssignMultiple" :
        var count = operation._1;
        tmp$1 = count !== 0 ? assignTitle$p({
                name: multipleAssigneesSuffix$p(count, ctx)
              }, ctx) : unassign$p(ctx);
        break;
    
  }
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              children: React.createElement("div", {
                    className: El.Cn.concat(modalStyles.dialog, styles.dialog)
                  }, React.createElement(Modal.Header.make, {
                        children: React.createElement("div", {
                              className: styles.headerHeadline
                            }, tmp)
                      }), React.createElement(Modal.Body.make, {
                        className: styles.modalContainer,
                        children: null
                      }, React.createElement("div", {
                            className: styles.title
                          }, React.createElement("span", undefined, tmp$1), React.createElement("div", {
                                className: styles.group
                              }, React.createElement(BackgroundBulkModal$Button, {
                                    onClick: (function (param) {
                                        onButtonClick({
                                              TAG: "All",
                                              _0: deafultParams
                                            });
                                      }),
                                    active: match$7[0],
                                    disabled: filterDisabled,
                                    children: all$p(ctx)
                                  }), React.createElement(BackgroundBulkModal$Button, {
                                    onClick: (function (param) {
                                        onButtonClick({
                                              TAG: "Successful",
                                              _0: deafultParams
                                            });
                                      }),
                                    active: match$7[1],
                                    disabled: filterDisabled,
                                    children: successful$p(ctx)
                                  }), React.createElement(BackgroundBulkModal$Button, {
                                    onClick: (function (param) {
                                        onButtonClick({
                                              TAG: "Unsuccessful",
                                              _0: deafultParams
                                            });
                                      }),
                                    active: match$7[2],
                                    disabled: filterDisabled,
                                    children: unsuccessful$p(ctx)
                                  }))), React.createElement("div", {
                            className: styles.jobsContainer
                          }, match$2[0] || fetching ? React.createElement(React.Fragment, undefined, renderJobs, React.createElement(Optional.make, {
                                      show: !match[0] && !fetching,
                                      children: React.createElement("button", {
                                            className: styles.moreButton,
                                            onClick: (function (param) {
                                                showMore();
                                              })
                                          }, showMore$p(ctx))
                                    })) : React.createElement("div", {
                                  className: styles.spinner
                                }, React.createElement(Spinner.make, {
                                      show: true
                                    })))), React.createElement(Modal.Footer.make, {
                        className: styles.footer,
                        children: null
                      }, React.createElement(Optional.make, {
                            show: hasErrors,
                            children: React.createElement("button", {
                                  className: El.Cn.concat("btn", styles.retry),
                                  disabled: displayJobUuids.length === 0,
                                  onClick: (function (param) {
                                      retry(taskUuid, skipJobs);
                                    })
                                }, retry$p(ctx))
                          }), React.createElement(Modal.CloseButton.make, {
                            ctx: ctx,
                            buttonClass: "btn-primary"
                          })))
            });
}

var make = BackgroundBulkModal;

export {
  make ,
}
/* styles Not a pure module */
