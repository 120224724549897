

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Note from "../../common/Note/Note.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as HeaderTypes from "../../header/HeaderTypes.mjs";
import * as Billing_Utils from "./Billing_Utils.mjs";
import * as Billing_Wizard_Page from "./wizard/Billing_Wizard_Page.mjs";
import * as Billing_Components_Blocks from "./components/Billing_Components_Blocks.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var billing$p = Utils.Translations.tr("js.billing.title.view");

var dueBill$p = Utils.Translations.tr("js.billing.show.due_bill");

var billNo$p = Utils.Translations.tr("js.billing.due_bill.bill_no");

var amount$p = Utils.Translations.tr("js.billing.due_bill.amount");

var period$p = Utils.Translations.tr("js.billing.due_bill.period");

var status$p = Utils.Translations.tr("js.billing.due_bill.status");

var payBill$p = Utils.Translations.txr("js.billing.due_bill.pay_bill");

var warning$p = Utils.Translations.tr("js.billing.due_bill.warning");

var paymentQuestions$p = Utils.Translations.th("js.billing.show.questions");

var dueAt$p = Utils.Translations.txr("js.billing.due_bill.due_at");

var latestPayments$p = Utils.Translations.tr("js.billing.show.latest_payments");

var docNo$p = Utils.Translations.tr("js.billing.payment.doc_no");

var amountPaid$p = Utils.Translations.tr("js.billing.payment.amount_paid");

var bill$p = Utils.Translations.tr("js.billing.payment.bill");

var invoice$p = Utils.Translations.tr("js.billing.payment.invoice");

var dateLabel$p = Utils.Translations.tr("js.billing.payment.date");

var nextBill$p = Utils.Translations.txr("js.billing.subscription.next_bill");

var nextCharge$p = Utils.Translations.txr("js.billing.subscription.next_charge");

var paidTill$p = Utils.Translations.txr("js.billing.subscription.paid_till");

var download$p = Utils.Translations.tr("js.billing.due_bill.download");

function date$p(v, w) {
  return Utils.Translations.dateFromDate(Utils.Translations.dateFromIsoStr(v), w);
}

var accessDenied$p = Utils.Translations.tr("js.billing.access_denied");

function make(billUuid, invoiceUuid, billFileUuid, invoiceFileUuid, billPaymentAccount, invoicePaymentAccount) {
  return {
          billUuid: billUuid,
          invoiceUuid: invoiceUuid,
          billFileUuid: billFileUuid,
          invoiceFileUuid: invoiceFileUuid,
          billPaymentAccount: billPaymentAccount,
          invoicePaymentAccount: invoicePaymentAccount
        };
}

function bill(d) {
  var uuid = d.billUuid;
  if (uuid === undefined) {
    return ;
  }
  var fileUuid = d.billFileUuid;
  if (fileUuid === undefined) {
    return ;
  }
  var match = d.billPaymentAccount;
  if (match !== undefined && match.accountType === "BankRub") {
    return [
            Caml_option.valFromOption(uuid),
            Caml_option.valFromOption(fileUuid)
          ];
  }
  
}

function invoice(d) {
  var uuid = d.invoiceUuid;
  if (uuid === undefined) {
    return ;
  }
  var fileUuid = d.invoiceFileUuid;
  if (fileUuid === undefined) {
    return ;
  }
  var match = d.invoicePaymentAccount;
  if (match === undefined) {
    return ;
  }
  switch (match.accountType) {
    case "Paddle" :
    case "Other" :
        return ;
    default:
      return [
              Caml_option.valFromOption(uuid),
              Caml_option.valFromOption(fileUuid)
            ];
  }
}

function hasDocs(d) {
  if (RCore.$$Option.isSome(bill(d))) {
    return true;
  } else {
    return RCore.$$Option.isSome(invoice(d));
  }
}

var styles = BillingModuleCss;

function Billing_Show(Props) {
  var data = Props.data;
  var updateData = Props.updateData;
  var wire = Props.wire;
  var reloadOpt = Props.reload;
  var reload = reloadOpt !== undefined ? reloadOpt : (function (prim) {
        
      });
  var ctx = wire.ctx;
  var subscriptionUuid = RCore.$$Option.map(data.subscription, (function (param) {
          return param.uuid;
        }));
  var match = React.useState(function () {
        return subscriptionUuid;
      });
  var currentUuid = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowPlanDialog = match$1[1];
  var openWizard = function ($$event) {
    $$event.preventDefault();
    setShowPlanDialog(function (param) {
          return true;
        });
  };
  var $$fetch = function () {
    setShowPlanDialog(function (param) {
          return false;
        });
    reload();
  };
  var changed = currentUuid !== undefined && subscriptionUuid !== undefined ? !Uuid.equal(Caml_option.valFromOption(currentUuid), Caml_option.valFromOption(subscriptionUuid)) : false;
  if (changed) {
    match[1](function (param) {
          return subscriptionUuid;
        });
  }
  React.useEffect((function () {
          if (!(ctx.enabled && ctx.licenseSource === "external")) {
            return ;
          }
          var route = HeaderTypes.Menu.defaultRoute(HeaderTypes.Menu.make(ctx));
          return RCore.$$Option.flatMap(route, (function (r) {
                        wire.navigate(undefined, r);
                      }));
        }), []);
  var subscription = data.subscription;
  if (subscription === undefined) {
    return null;
  }
  var latestPayments = data.latestPayments;
  var unpaidBill = data.unpaidBill;
  var order = data.order;
  var tmp;
  if (ctx.licenseSource === "internal") {
    var tmp$1;
    if (unpaidBill !== undefined) {
      tmp$1 = ctx.enabled ? React.createElement(Note.make, {
              title: payBill$p({
                    date: date$p(unpaidBill.due, wire)
                  }, ctx),
              children: null,
              flavor: "warning"
            }, warning$p(ctx), React.createElement("br", undefined), paymentQuestions$p(ctx)) : React.createElement(Note.make, {
              title: payBill$p({
                    date: date$p(unpaidBill.due, wire)
                  }, ctx),
              children: paymentQuestions$p(ctx),
              flavor: "danger"
            });
    } else if (!ctx.enabled && !ctx.recycled) {
      tmp$1 = React.createElement(Note.make, {
            title: accessDenied$p(ctx),
            children: paymentQuestions$p(ctx),
            flavor: "danger"
          });
    } else {
      var match$2 = order.paymentMethod;
      var tmp$2;
      var exit = 0;
      switch (match$2) {
        case "Card" :
            tmp$2 = nextCharge$p({
                  next_charge: date$p(subscription.nextBilling, wire)
                }, ctx);
            break;
        case "Invoice" :
        case "Crypto" :
            exit = 1;
            break;
        
      }
      if (exit === 1) {
        tmp$2 = nextBill$p({
              next_bill: date$p(subscription.nextBill, wire),
              next_due: date$p(subscription.nextBillDue, wire)
            }, ctx);
      }
      tmp$1 = React.createElement(Note.make, {
            title: paidTill$p({
                  date: date$p(subscription.paidTill, wire)
                }, ctx),
            children: null,
            flavor: "success"
          }, tmp$2, React.createElement("br", undefined), paymentQuestions$p(ctx));
    }
    var tmp$3;
    if (unpaidBill !== undefined) {
      var match$3 = order.paymentMethod;
      var tmp$4;
      switch (match$3) {
        case "Invoice" :
            var uuid = Uuid.toString(unpaidBill.uuid);
            tmp$4 = React.createElement("div", {
                  className: El.Cn.concat("row", styles.downloadPdf)
                }, React.createElement("div", {
                      className: "col-md-8"
                    }, React.createElement("a", {
                          className: "btn btn-primary",
                          href: "/admin/billing/bills/" + uuid,
                          target: "_blank"
                        }, download$p(ctx))));
            break;
        case "Card" :
        case "Crypto" :
            tmp$4 = null;
            break;
        
      }
      tmp$3 = React.createElement("div", {
            className: El.Cn.concat(El.Cn.concat(styles.unpaidBill, styles.panelBody), styles.billingContainer)
          }, React.createElement("div", {
                className: styles.billingBlock
              }, React.createElement("div", {
                    className: El.Cn.concat("row", styles.title)
                  }, React.createElement("div", {
                        className: "col-md-6"
                      }, React.createElement("h4", undefined, dueBill$p(ctx)))), React.createElement("div", {
                    className: El.Cn.concat("row", styles.content)
                  }, React.createElement("div", {
                        className: "col-md-12"
                      }, React.createElement("div", {
                            className: El.Cn.concat("row", styles.header)
                          }, React.createElement("div", {
                                className: "col-md-2"
                              }, billNo$p(ctx)), React.createElement("div", {
                                className: "col-md-2"
                              }, amount$p(ctx)), React.createElement("div", {
                                className: "col-md-3"
                              }, period$p(ctx)), React.createElement("div", {
                                className: "col-md-4"
                              }, status$p(ctx))), React.createElement("div", {
                            className: "row"
                          }, React.createElement("div", {
                                className: "col-md-2"
                              }, unpaidBill.no), React.createElement("div", {
                                className: "col-md-2"
                              }, Billing_Utils.Format.money$p(unpaidBill.totalAmount)), React.createElement("div", {
                                className: "col-md-3"
                              }, date$p(unpaidBill.periodStart, wire), El.mdash, date$p(unpaidBill.periodEnd, wire)), React.createElement("div", {
                                className: "col-md-4"
                              }, React.createElement("div", {
                                    className: "text-warning"
                                  }, dueAt$p({
                                        date: date$p(unpaidBill.due, wire)
                                      }, ctx)))), tmp$4))));
    } else {
      tmp$3 = null;
    }
    var onPlanChange = data.canChangePlan ? openWizard : undefined;
    var tmp$5 = {
      availablePlanTypes: data.availablePlanTypes,
      order: {
        planTypeId: subscription.planTypeId,
        paidSeats: subscription.paidSeats,
        freeSeats: order.freeSeats,
        monthsCycle: subscription.monthsCycle,
        organizationName: order.organizationName,
        billingAddress: order.billingAddress,
        paymentMethod: order.paymentMethod,
        currency: order.currency,
        technicalDiscount: order.technicalDiscount,
        saleDiscount: order.saleDiscount,
        extraFeatures: subscription.extraFeatures
      },
      monthlyPayment: subscription.monthlyPayment,
      wire: wire,
      actualPriceList: data.actualPriceList,
      currentSubscriptionPlanTypeId: Caml_option.some(subscription.planTypeId)
    };
    if (onPlanChange !== undefined) {
      tmp$5.onChange = Caml_option.valFromOption(onPlanChange);
    }
    tmp = React.createElement("div", {
          className: "billing " + styles.billing
        }, React.createElement("div", {
              className: "form-container"
            }, tmp$1, tmp$3, React.createElement("div", {
                  className: El.Cn.concat(El.Cn.concat(styles.panelBody, styles.billingContainer), styles.orderBlock)
                }, React.createElement(Billing_Components_Blocks.Order.make, tmp$5), React.createElement(Billing_Components_Blocks.PaymentMethod.make, {
                      subscription: subscription,
                      order: order,
                      wire: wire,
                      paddleSubscription: data.paddleSubscription,
                      paddleSettings: data.paddleSettings
                    })), React.createElement(Optional.make, {
                  show: latestPayments.length > 0,
                  children: React.createElement("div", {
                        className: El.Cn.concat(styles.panelBody, styles.billingContainer)
                      }, React.createElement("div", {
                            className: styles.billingBlock
                          }, React.createElement("div", {
                                className: El.Cn.concat("row", styles.title)
                              }, React.createElement("div", {
                                    className: "col-md-6"
                                  }, React.createElement("h4", undefined, latestPayments$p(ctx)))), React.createElement("div", {
                                className: El.Cn.concat("row", styles.latestPaymentsBlock)
                              }, React.createElement("div", {
                                    className: El.Cn.concat("row", styles.header)
                                  }, React.createElement("div", {
                                        className: "col-md-2"
                                      }, docNo$p(ctx)), React.createElement("div", {
                                        className: "col-md-2"
                                      }, dateLabel$p(ctx)), React.createElement("div", {
                                        className: "col-md-2"
                                      }, amountPaid$p(ctx))), latestPayments.map(function (p) {
                                    var docs = make(p.billUuid, p.invoiceUuid, p.billScreenFileUuid, p.invoiceScreenFileUuid, p.billPaymentAccount, p.invoicePaymentAccount);
                                    var bill$1 = bill(docs);
                                    var invoice$1 = invoice(docs);
                                    var billAndInvoice = bill$1 !== undefined && invoice$1 !== undefined ? [
                                        bill$1,
                                        invoice$1
                                      ] : undefined;
                                    var billNo = p.billNo;
                                    if (billNo === undefined) {
                                      return null;
                                    }
                                    var tmp;
                                    if (bill$1 !== undefined) {
                                      var uuid = Uuid.toString(bill$1[0]);
                                      var fileUuid = Uuid.toString(bill$1[1]);
                                      tmp = React.createElement("a", {
                                            href: "/admin/billing/bills/" + uuid + "/files/" + fileUuid,
                                            target: "_blank"
                                          }, bill$p(ctx));
                                    } else {
                                      tmp = null;
                                    }
                                    var tmp$1;
                                    if (invoice$1 !== undefined) {
                                      var uuid$1 = Uuid.toString(invoice$1[0]);
                                      var fileUuid$1 = Uuid.toString(invoice$1[1]);
                                      tmp$1 = React.createElement("a", {
                                            href: "/admin/billing/invoices/" + uuid$1 + "/files/" + fileUuid$1,
                                            target: "_blank"
                                          }, invoice$p(ctx));
                                    } else {
                                      tmp$1 = null;
                                    }
                                    return React.createElement("div", {
                                                key: Uuid.toString(p.uuid),
                                                className: El.Cn.concat("row", styles.paymentEntry)
                                              }, React.createElement("div", {
                                                    className: "col-md-2"
                                                  }, billNo), React.createElement("div", {
                                                    className: "col-md-2"
                                                  }, date$p(p.createdAt, wire)), React.createElement("div", {
                                                    className: "col-md-3"
                                                  }, Billing_Utils.Format.money$p({
                                                        amount: p.amount.amount,
                                                        currency: p.amount.currency
                                                      }), hasDocs(docs) ? " (" : null, tmp, RCore.$$Option.isSome(billAndInvoice) ? ", " : null, tmp$1, hasDocs(docs) ? ")" : null));
                                  }))))
                })));
  } else {
    tmp = ctx.enabled || ctx.recycled ? null : React.createElement("div", {
            className: styles.billing
          }, React.createElement("div", {
                className: "form-container"
              }, React.createElement(Note.make, {
                    title: accessDenied$p(ctx),
                    children: paymentQuestions$p(ctx),
                    flavor: "danger"
                  })));
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "page-header"
                }, React.createElement("div", {
                      className: "row"
                    }, React.createElement("h1", {
                          className: "col-xs-12 col-sm-4 text-center text-left-sm"
                        }, billing$p(ctx)))), tmp, React.createElement(Modal.make, {
                  wire: wire,
                  onClose: (function () {
                      setShowPlanDialog(function (param) {
                            return false;
                          });
                    }),
                  show: match$1[0],
                  children: React.createElement(Billing_Wizard_Page.make, {
                        data: data,
                        updateData: updateData,
                        reload: reload,
                        onComplete: Fun.omit($$fetch),
                        windowed: true
                      })
                }));
}

var Types;

var make$1 = Billing_Show;

export {
  Types ,
  make$1 as make,
}
/* billing' Not a pure module */
