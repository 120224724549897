

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Radio from "../../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_CustomWrapper from "./JobIndexFilter_CustomWrapper.mjs";
import * as JobIndexFilter_CustomModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/custom/JobIndexFilter_Custom.module.css";

var styles = JobIndexFilter_CustomModuleCss;

var filled$p = Utils.Translations.tr("js.jobs.index.filters.string.filled.name");

var any$p = Utils.Translations.t("js.jobs.index.filters.string.filled.any");

var containsText$p = Utils.Translations.t("js.jobs.index.filters.string.filled.contains");

var notFilled$p = Utils.Translations.tr("js.jobs.index.filters.string.not_filled");

var containsTextWithValue$p = Utils.Translations.txr("js.jobs.index.filters.string.contains_with_text");

function textValue(value) {
  if (value === undefined) {
    return ;
  }
  if (typeof value !== "object") {
    return ;
  }
  var v = value._0;
  if (v !== undefined) {
    return v;
  }
  
}

function JobIndexFilter_CustomString(Props) {
  var label = Props.label;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var filterByField = Props.filterByField;
  var value = Props.value;
  var show = Props.show;
  var disabled = Props.disabled;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return textValue(value);
      });
  var setText = match$1[1];
  var text = match$1[0];
  React.useEffect((function () {
          setText(function (param) {
                return textValue(value);
              });
        }), [value]);
  var onBlur = function (_evt) {
    filterByField({
          TAG: "Filled",
          _0: text
        });
  };
  var onKeyPress = function (evt) {
    if (evt.key === "Enter") {
      return filterByField({
                  TAG: "Filled",
                  _0: text
                });
    }
    
  };
  var onChange = function (evt) {
    var value = evt.target.value;
    setText(function (param) {
          return value;
        });
  };
  var match$2 = value !== undefined ? (
      typeof value !== "object" ? (
          value === "NotFilled" ? [
              true,
              false,
              false
            ] : [
              true,
              true,
              false
            ]
        ) : [
          true,
          true,
          true
        ]
    ) : [
      false,
      false,
      false
    ];
  var hasSpecificValue = match$2[2];
  var hasFilledValue = match$2[1];
  var hasValue = match$2[0];
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter(false);
  };
  var tmp;
  if (value !== undefined) {
    if (typeof value !== "object") {
      tmp = value === "NotFilled" ? notFilled$p(ctx) : any$p(ctx);
    } else {
      var text$1 = value._0;
      tmp = text$1 !== undefined ? containsTextWithValue$p({
              text: text$1
            }, ctx) : any$p(ctx);
    }
  } else {
    tmp = null;
  }
  var renderSelected = React.createElement("div", {
        className: "j-filter__selected"
      }, tmp);
  return React.createElement(JobIndexFilter_CustomWrapper.make, {
              toggleFilter: toggleFilter,
              onResetClick: onResetClick,
              renderSelected: renderSelected,
              label: label,
              show: show,
              hasValue: hasValue,
              leftButton: {
                onClick: (function (param) {
                    filterByField("Any");
                  }),
                checked: hasFilledValue,
                text: filled$p(ctx)
              },
              rightButton: {
                onClick: (function (param) {
                    filterByField("NotFilled");
                  }),
                checked: hasValue && !hasFilledValue,
                text: notFilled$p(ctx)
              },
              disabled: disabled,
              children: React.createElement(Optional.make, {
                    show: hasFilledValue,
                    children: null
                  }, React.createElement(Radio.make, {
                        onChange: (function (param) {
                            filterByField("Any");
                          }),
                        checked: !hasSpecificValue,
                        labelText: any$p(ctx),
                        wrapperClass: styles.radio,
                        disabled: disabled
                      }), React.createElement(Radio.make, {
                        onChange: (function (param) {
                            filterByField({
                                  TAG: "Filled",
                                  _0: undefined
                                });
                          }),
                        checked: hasSpecificValue,
                        labelText: containsText$p(ctx),
                        wrapperClass: styles.radio,
                        disabled: disabled
                      }), React.createElement(Optional.make, {
                        show: hasSpecificValue,
                        children: React.createElement("div", {
                              className: styles.textInputContainer
                            }, React.createElement("div", {
                                  className: El.Cn.concat(El.Cn.concat(styles.searchSymbol, styles.textInputSearchSymbol), Icon.style(undefined, undefined, undefined, undefined, "search"))
                                }), React.createElement("div", undefined, React.createElement("input", {
                                      className: El.Cn.concat("pd-form-control", styles.textInput),
                                      autoFocus: true,
                                      disabled: disabled,
                                      type: "text",
                                      value: RCore.$$Option.getOr(text, ""),
                                      onKeyPress: onKeyPress,
                                      onBlur: onBlur,
                                      onChange: onChange
                                    })))
                      }))
            });
}

var make = JobIndexFilter_CustomString;

export {
  make ,
}
/* styles Not a pure module */
