

import * as Icon from "../../Icon.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateRangeFilter from "../common/DateRangeFilter/DateRangeFilter.mjs";
import * as Reports_DatesFilterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports_DatesFilter.module.css";

var thisWeek$p = Utils.Translations.tr("js.reports.jobs.date_filter.this_week");

var prevWeek$p = Utils.Translations.tr("js.reports.jobs.date_filter.prev_week");

var fourWeek$p = Utils.Translations.tr("js.reports.jobs.date_filter.four_week");

var q1$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.first");

var q2$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.second");

var q3$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.third");

var q4$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.fourth");

var Translations = {
  thisWeek$p: thisWeek$p,
  prevWeek$p: prevWeek$p,
  fourWeek$p: fourWeek$p,
  q1$p: q1$p,
  q2$p: q2$p,
  q3$p: q3$p,
  q4$p: q4$p
};

var styles = Reports_DatesFilterModuleCss;

function Reports_DatesFilter(Props) {
  var wire = Props.wire;
  var period = Props.period;
  var setPeriodDate = Props.setPeriodDate;
  var $staropt$star = Props.label;
  var ctx = wire.ctx;
  var label = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : null;
  var match = React.useState(function () {
        return "week";
      });
  var setCurrentRange = match[1];
  var currentRange = match[0];
  var onDatesChange = function (start, end) {
    setPeriodDate(start, end);
    setCurrentRange(function (param) {
          
        });
  };
  var last4weeks = function () {
    return [
            Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: -4
                    })),
            Locale.T.endOf("day", Locale.T.now(wire))
          ];
  };
  var setQurterRange = (function () {
      setCurrentRange(function (param) {
            return "quarter";
          });
    });
  var match$1 = Locale.T.quarter(Locale.T.now(wire));
  var quarterButtons;
  switch (match$1) {
    case 1 :
        quarterButtons = [
          [
            "Q4",
            q4$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              }),
            setQurterRange
          ],
          [
            "Q1",
            q1$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              }),
            setQurterRange
          ]
        ];
        break;
    case 2 :
        quarterButtons = [
          [
            "Q1",
            q1$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              }),
            setQurterRange
          ],
          [
            "Q2",
            q2$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              }),
            setQurterRange
          ]
        ];
        break;
    case 3 :
        quarterButtons = [
          [
            "Q2",
            q2$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              }),
            setQurterRange
          ],
          [
            "Q3",
            q3$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              }),
            setQurterRange
          ]
        ];
        break;
    case 4 :
        quarterButtons = [
          [
            "Q3",
            q3$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              }),
            setQurterRange
          ],
          [
            "Q4",
            q4$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              }),
            setQurterRange
          ]
        ];
        break;
    default:
      quarterButtons = [];
  }
  var buttons = [
        [
          "this-week",
          thisWeek$p(ctx),
          (function () {
              return Locale.T.makeRange(Locale.T.now(wire), {
                          NAME: "weeks",
                          VAL: 0
                        });
            }),
          (function () {
              setCurrentRange(function (param) {
                    return "week";
                  });
            })
        ],
        [
          "last-week",
          prevWeek$p(ctx),
          (function () {
              return Locale.T.makeRange(Locale.T.now(wire), {
                          NAME: "weeks",
                          VAL: -1
                        });
            }),
          (function () {
              setCurrentRange(function (param) {
                    return "week";
                  });
            })
        ],
        [
          "4-weeks",
          fourWeek$p(ctx),
          last4weeks,
          (function () {
              setCurrentRange(function (param) {
                    return "fourWeeks";
                  });
            })
        ]
      ].concat(quarterButtons).concat([[
          "this-year",
          String(Locale.T.year(Locale.T.now(wire))),
          (function () {
              return Locale.T.makeRange(Locale.T.now(wire), {
                          NAME: "years",
                          VAL: 0
                        });
            }),
          (function () {
              setCurrentRange(function (param) {
                    return "year";
                  });
            })
        ]]);
  var setPrevPeriod = function (param) {
    var start = period[0];
    if (start === undefined) {
      return ;
    }
    var end = period[1];
    var start$1 = Caml_option.valFromOption(start);
    if (end !== undefined && currentRange === undefined) {
      var end$1 = Caml_option.valFromOption(end);
      var between = Locale.T.daysBetween(start$1, end$1) - 1 | 0;
      return setPeriodDate(Caml_option.some(Locale.T.nextDays(between)(start$1)), Caml_option.some(Locale.T.nextDays(between)(end$1)));
    }
    if (currentRange === undefined) {
      return ;
    }
    var dates = currentRange === "fourWeeks" ? Locale.T.makeRange(start$1, {
            NAME: "weeks",
            VAL: -4
          }) : (
        currentRange === "week" ? Locale.T.makeRange(start$1, {
                NAME: "weeks",
                VAL: -1
              }) : (
            currentRange === "year" ? Locale.T.makeRange(start$1, {
                    NAME: "years",
                    VAL: -1
                  }) : Locale.T.makeRange(start$1, {
                    NAME: "quarters",
                    VAL: -1
                  })
          )
      );
    setPeriodDate(Caml_option.some(dates[0]), Caml_option.some(dates[1]));
  };
  var setNextPeriod = function (param) {
    var start = period[0];
    if (start === undefined) {
      return ;
    }
    var end = period[1];
    var start$1 = Caml_option.valFromOption(start);
    if (end !== undefined && currentRange === undefined) {
      var end$1 = Caml_option.valFromOption(end);
      var between = Locale.T.daysBetween(start$1, end$1) - 1 | 0;
      return setPeriodDate(Caml_option.some(Locale.T.prevDays(between)(start$1)), Caml_option.some(Locale.T.prevDays(between)(end$1)));
    }
    if (currentRange === undefined) {
      return ;
    }
    var dates = currentRange === "fourWeeks" ? Locale.T.makeRange(start$1, {
            NAME: "weeks",
            VAL: 4
          }) : (
        currentRange === "week" ? Locale.T.makeRange(start$1, {
                NAME: "weeks",
                VAL: 1
              }) : (
            currentRange === "year" ? Locale.T.makeRange(start$1, {
                    NAME: "years",
                    VAL: 1
                  }) : Locale.T.makeRange(start$1, {
                    NAME: "quarters",
                    VAL: 1
                  })
          )
      );
    setPeriodDate(Caml_option.some(dates[0]), Caml_option.some(dates[1]));
  };
  return React.createElement("div", {
              className: styles.container
            }, label, React.createElement("div", {
                  className: styles.filter
                }, React.createElement("button", {
                      className: styles.prev,
                      onClick: setPrevPeriod
                    }, React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "chevron-left")
                        })), React.createElement(DateRangeFilter.make, {
                      onDatesChange: onDatesChange,
                      value: period,
                      buttons: buttons,
                      disabled: false
                    }), React.createElement("button", {
                      className: styles.next,
                      onClick: setNextPeriod
                    }, React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "chevron-right")
                        }))));
}

var make = Reports_DatesFilter;

export {
  Translations ,
  styles ,
  make ,
}
/* thisWeek' Not a pure module */
