

import * as React from "react";
import * as Tooltip from "../Tooltip/Tooltip.mjs";
import * as Optional from "../Optional.mjs";
import * as ProgressBarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/ProgressBar/ProgressBar.module.css";

var styles = ProgressBarModuleCss;

function ProgressBar(Props) {
  var percent = Props.percent;
  var hint = Props.hint;
  var stats = Props.stats;
  var progressBarStyle = {
    width: percent.toString() + "%"
  };
  var match = stats !== undefined ? [
      React.createElement("div", {
            className: styles.stats
          }, React.createElement("span", undefined, stats)),
      true
    ] : [
      null,
      false
    ];
  var className = match[1] ? styles.progress : styles.progress + " " + styles.noStats;
  var ref = React.useRef(null);
  var content = React.createElement("div", {
        ref: ref,
        className: className
      }, match[0], React.createElement(Optional.make, {
            show: percent > 0,
            children: React.createElement("div", {
                  className: styles.progressBar,
                  style: progressBarStyle
                })
          }));
  if (hint !== undefined) {
    return React.createElement(Tooltip.make, {
                elementRef: ref,
                position: "top",
                content: hint,
                children: content
              });
  } else {
    return content;
  }
}

var make = ProgressBar;

export {
  make ,
}
/* styles Not a pure module */
