

import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as EncodeJson from "../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Pages_Client_Portal_Types from "./Pages_Client_Portal_Types.mjs";

function get(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: "Index"
              }
            });
}

var Show = {
  get: get
};

function post(wire) {
  return Backend.post(undefined, undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: "Index"
              }
            }, {
              NAME: "Json",
              VAL: null
            });
}

var Create = {
  post: post
};

function encode(settings) {
  return EncodeJson.object([
              [
                "templateUuids",
                EncodeJson.jsonArray(settings.templateUuids.map(EncodeJson.uuid))
              ],
              [
                "jobView",
                EncodeJson.object([
                      [
                        "jobFields",
                        EncodeJson.jsonArray(settings.jobView.jobFields.map(function (f) {
                                  return EncodeJson.string(Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.toString(f));
                                }))
                      ],
                      [
                        "customFields",
                        EncodeJson.jsonArray(settings.jobView.customFields.map(EncodeJson.uuid))
                      ],
                      [
                        "reportFields",
                        EncodeJson.jsonArray(settings.jobView.reportFields.map(EncodeJson.uuid))
                      ]
                    ])
              ],
              [
                "jobIndexColumns",
                EncodeJson.object([
                      [
                        "jobFields",
                        EncodeJson.jsonArray(settings.jobIndexColumns.jobFields.map(function (f) {
                                  return EncodeJson.string(Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.toString(f));
                                }))
                      ],
                      [
                        "customFields",
                        EncodeJson.jsonArray(settings.jobIndexColumns.customFields.map(EncodeJson.uuid))
                      ],
                      [
                        "reportFields",
                        EncodeJson.jsonArray(settings.jobIndexColumns.reportFields.map(EncodeJson.uuid))
                      ]
                    ])
              ]
            ]);
}

function patch(wire, portal) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: "Index"
              }
            }, {
              NAME: "Obj",
              VAL: {
                uuid: EncodeJson.uuid(portal.uuid),
                logoFileUuid: Js_null.fromOption(RCore.$$Option.map(portal.logo, (function (l) {
                            return EncodeJson.uuid(l.uuid);
                          }))),
                settings: encode(portal.settings)
              }
            });
}

var decode = JsonDecode.object(function (field) {
      return {
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, Pages_Client_Portal_Types.State.Portal.Status.fromString))
            };
    });

function patch$1(wire, portalUuid, status) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "ClientPortal",
                    VAL: "UpdateStatus"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    uuid: EncodeJson.uuid(portalUuid),
                    status: Pages_Client_Portal_Types.State.Portal.Status.toString(status)
                  }
                }), decode);
}

function post$1(wire, portalUuid, clientUuid) {
  return Backend.post(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: {
                  NAME: "Users",
                  VAL: "Create"
                }
              }
            }, {
              NAME: "Obj",
              VAL: {
                portalUuid: EncodeJson.uuid(portalUuid),
                clientUuid: EncodeJson.uuid(clientUuid)
              }
            });
}

var Create$1 = {
  post: post$1
};

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: {
                  NAME: "Users",
                  VAL: {
                    NAME: "Delete",
                    VAL: uuid
                  }
                }
              }
            });
}

var Delete = {
  $$delete: $$delete
};

var Users = {
  Create: Create$1,
  Delete: Delete
};

function patch$2(wire, portal) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: "Settings"
              }
            }, {
              NAME: "Obj",
              VAL: {
                uuid: EncodeJson.uuid(portal.uuid),
                emailInvitationStencil: EncodeJson.string(portal.emailInvitationStencil),
                smsInvitationStencil: EncodeJson.string(portal.smsInvitationStencil)
              }
            });
}

var Update = {
  patch: patch$2
};

var Settings = {
  Update: Update
};

var Index_Update = {
  patch: patch
};

var Index = {
  Show: Show,
  Create: Create,
  Update: Index_Update
};

var UpdateStatus_Response = {};

var UpdateStatus = {
  $$Response: UpdateStatus_Response,
  patch: patch$1
};

export {
  Index ,
  UpdateStatus ,
  Users ,
  Settings ,
}
/* decode Not a pure module */
