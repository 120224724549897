

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.mjs";

function partition(xs, f) {
  var ys = [];
  var zs = [];
  var _i = 0;
  var _yi = 0;
  var _zi = 0;
  while(true) {
    var zi = _zi;
    var yi = _yi;
    var i = _i;
    var x = xs[i];
    if (x === undefined) {
      return [
              ys,
              zs
            ];
    }
    var x$1 = Caml_option.valFromOption(x);
    if (f(x$1)) {
      ys[yi] = x$1;
      _yi = yi + 1 | 0;
      _i = i + 1 | 0;
      continue ;
    }
    zs[zi] = x$1;
    _zi = zi + 1 | 0;
    _i = i + 1 | 0;
    continue ;
  };
}

function getBy(prim0, prim1) {
  return prim0.find(prim1);
}

function range(start, finish) {
  var res = [];
  var loop = function (_i) {
    while(true) {
      var i = _i;
      if (i > finish) {
        return ;
      }
      res[i - start | 0] = i;
      _i = i + 1 | 0;
      continue ;
    };
  };
  loop(start);
  return res;
}

function removeOne(xs, at) {
  return xs.toSpliced(at, 1);
}

function keep(prim0, prim1) {
  return prim0.filter(prim1);
}

var $$Array = {
  make: Core__Array.make,
  fromInitializer: Core__Array.fromInitializer,
  equal: Core__Array.equal,
  compare: Core__Array.compare,
  indexOfOpt: Core__Array.indexOfOpt,
  lastIndexOfOpt: Core__Array.lastIndexOfOpt,
  reduce: Core__Array.reduce,
  reduceWithIndex: Core__Array.reduceWithIndex,
  reduceRight: Core__Array.reduceRight,
  reduceRightWithIndex: Core__Array.reduceRightWithIndex,
  findIndexOpt: Core__Array.findIndexOpt,
  filterMap: Core__Array.filterMap,
  keepSome: Core__Array.keepSome,
  toShuffled: Core__Array.toShuffled,
  shuffle: Core__Array.shuffle,
  findMap: Core__Array.findMap,
  last: Core__Array.last,
  partition: partition,
  getBy: getBy,
  range: range,
  removeOne: removeOne,
  keep: keep,
  keepMap: Core__Array.filterMap
};

function flatten(x) {
  if (x === undefined) {
    return ;
  }
  var y = Caml_option.valFromOption(x);
  if (y !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(y));
  }
  
}

var $$Option = {
  filter: Core__Option.filter,
  forEach: Core__Option.forEach,
  getExn: Core__Option.getExn,
  mapOr: Core__Option.mapOr,
  mapWithDefault: Core__Option.mapWithDefault,
  map: Core__Option.map,
  flatMap: Core__Option.flatMap,
  getOr: Core__Option.getOr,
  getWithDefault: Core__Option.getWithDefault,
  orElse: Core__Option.orElse,
  isSome: Core__Option.isSome,
  isNone: Core__Option.isNone,
  equal: Core__Option.equal,
  compare: Core__Option.compare,
  flatten: flatten
};

var Core;

var List;

var $$Map;

var $$Set;

var Float;

var Int;

var Result;

export {
  Core ,
  List ,
  $$Map ,
  $$Set ,
  $$Array ,
  $$Option ,
  Float ,
  Int ,
  Result ,
}
/* No side effect */
