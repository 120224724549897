

import * as RCore from "../../libraries/RCore.mjs";
import * as Context from "../../context/Context.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";

function name(s) {
  if (typeof s === "object") {
    return "settings";
  }
  switch (s) {
    case "Map" :
        return "map";
    case "Schedule" :
        return "schedule";
    case "Jobs" :
        return "jobs";
    case "Teams" :
        return "teams";
    case "Shifts" :
        return "shifts";
    case "Addresses" :
        return "addresses";
    case "SuccessPath" :
        return "success_path";
    case "Support" :
        return "support";
    case "Profile" :
        return "profile";
    case "Reports" :
        return "reports";
    
  }
}

var Section = {
  name: name
};

function make(perms, ctx) {
  if (perms.admin === "Restricted") {
    return ;
  } else {
    return {
            catalog: Context_Types.Features.hasFlag("catalog", ctx.features),
            territories: Context_Types.Features.hasFlag("territories", ctx.features),
            recurringJobs: Context_Types.Features.hasFlag("recurringJobs", ctx.features),
            integrations: Context_Types.Features.hasAnyFlag([
                  "amocrm",
                  "bitrix24",
                  "api",
                  "zapier"
                ], ctx.features),
            billing: Context.canManageSubscription(ctx)
          };
  }
}

var Settings = {
  make: make
};

function make$1(ctx) {
  var match = ctx.enabled;
  var match$1 = ctx.recycled;
  var match$2 = ctx.trial;
  var match$3 = ctx.permissions;
  var perms;
  var exit = 0;
  if (match) {
    var exit$1 = 0;
    if (match$2 !== undefined && match$2.expired) {
      exit = 2;
    } else {
      exit$1 = 3;
    }
    if (exit$1 === 3) {
      if (match$3 === undefined) {
        return {
                schedule: false,
                jobs: false,
                map: false,
                clients: false,
                teams: false,
                shifts: false,
                reports: false,
                settings: undefined
              };
      }
      perms = match$3;
    }
    
  } else {
    exit = 2;
  }
  if (exit === 2) {
    if (!match$1) {
      return {
              schedule: false,
              jobs: false,
              map: false,
              clients: false,
              teams: false,
              shifts: false,
              reports: false,
              settings: undefined
            };
    }
    if (match$3 === undefined) {
      return {
              schedule: false,
              jobs: false,
              map: false,
              clients: false,
              teams: false,
              shifts: false,
              reports: false,
              settings: undefined
            };
    }
    perms = match$3;
  }
  var schedule = perms.schedule !== "Restricted";
  var jobs = perms.jobs !== "Restricted";
  var map = perms.map !== "Restricted";
  var clients = perms.clients !== "Restricted";
  var hasShiftsFlag = Context_Types.Features.hasFlag("shifts", ctx.features);
  var teams = perms.teams !== "Restricted" && Context_Types.Features.hasFlag("oldTeamsUi", ctx.features) && !hasShiftsFlag;
  var shifts = hasShiftsFlag && perms.shifts !== "Restricted";
  var reports = perms.admin !== "Restricted";
  var settings = make(perms, ctx);
  return {
          schedule: schedule,
          jobs: jobs,
          map: map,
          clients: clients,
          teams: teams,
          shifts: shifts,
          reports: reports,
          settings: settings
        };
}

function defaultRoute(menu) {
  if (menu.schedule) {
    return {
            NAME: "Schedule",
            VAL: {
              NAME: "Timeline",
              VAL: "Day"
            }
          };
  } else if (menu.jobs) {
    return {
            NAME: "Job",
            VAL: "Index"
          };
  } else if (menu.map) {
    return {
            NAME: "MapShow",
            VAL: "Workers"
          };
  } else if (menu.clients) {
    return {
            NAME: "Client",
            VAL: "Index"
          };
  } else if (menu.teams) {
    return {
            NAME: "Team",
            VAL: "Index"
          };
  } else {
    return ;
  }
}

function empty(menu) {
  return !(menu.schedule || menu.jobs || menu.map || menu.clients || menu.teams || menu.shifts || menu.reports || RCore.$$Option.isSome(menu.settings));
}

var Menu = {
  Settings: Settings,
  make: make$1,
  defaultRoute: defaultRoute,
  empty: empty
};

export {
  Section ,
  Menu ,
}
/* Context Not a pure module */
