

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as JobFieldsListModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/JobFieldsList.module.css";

var styles = JobFieldsListModuleCss;

var jobFields$p = Utils.Translations.tr("js.jobs.index.views.columns.job_fields");

var customFields$p = Utils.Translations.tr("js.jobs.index.views.columns.custom_fields");

var reportFields$p = Utils.Translations.tr("js.jobs.index.views.columns.report_fields");

var services$p = Utils.Translations.tr("js.jobs.index.views.columns.services");

function JobFieldsList(Props) {
  var jobFields = Props.jobFields;
  var customFields = Props.customFields;
  var reportFields = Props.reportFields;
  var catalog = Props.catalog;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var hasCatalogSettings = Context_Types.Features.hasFlag("catalog", ctx.features) && Caml_obj.notequal(catalog, null);
  return React.createElement(React.Fragment, undefined, React.createElement("section", {
                  className: styles.content
                }, React.createElement("div", {
                      className: styles.column
                    }, React.createElement("div", {
                          className: styles.headline
                        }, jobFields$p(ctx))), React.createElement("div", {
                      className: styles.column
                    }, React.createElement("div", {
                          className: styles.headline
                        }, customFields$p(ctx))), React.createElement("div", {
                      className: styles.column
                    }, React.createElement("div", {
                          className: styles.headline
                        }, reportFields$p(ctx))), React.createElement(Optional.make, {
                      show: hasCatalogSettings,
                      children: React.createElement("div", {
                            className: styles.catalogColumn
                          }, React.createElement("div", {
                                className: styles.headline
                              }, services$p(ctx)))
                    })), React.createElement("section", {
                  className: styles.content
                }, React.createElement("div", {
                      className: styles.contentColumn
                    }, jobFields), React.createElement("div", {
                      className: styles.contentColumn
                    }, customFields), React.createElement("div", {
                      className: styles.contentColumn
                    }, reportFields), React.createElement(Optional.make, {
                      show: hasCatalogSettings,
                      children: React.createElement("div", {
                            className: styles.catalogContentColumn
                          }, catalog)
                    })));
}

var make = JobFieldsList;

export {
  make ,
}
/* styles Not a pure module */
