

import * as Icon from "../../../../../../Icon.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

function JobForm_ResolutionPopup(Props) {
  var possibleResolutions = Props.possibleResolutions;
  var initResolution = Props.initResolution;
  var initResolutionComment = Props.initResolutionComment;
  var resolutionComment = Props.resolutionComment;
  var locked = Props.locked;
  var onClose = Props.onClose;
  var submitForm = Props.submitForm;
  var resolution = Props.resolution;
  var title = Props.title;
  var closeText = Props.closeText;
  var buttonText = Props.buttonText;
  var children = Props.children;
  var onFinishButtonClicked = function ($$event) {
    $$event.preventDefault();
    if (resolution !== undefined) {
      return submitForm(resolution);
    } else {
      console.error("Trying to submit null resolution");
      return ;
    }
  };
  var onCloseModal = function ($$event) {
    $$event.preventDefault();
    onClose();
  };
  var isResolutionCommentRequired;
  if (resolution !== undefined) {
    var resolutionUuid = resolution.resolutionUuid;
    isResolutionCommentRequired = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(possibleResolutions, (function (r) {
                    return Caml_obj.equal(r.resolutionUuid, resolutionUuid);
                  })), (function (r) {
                return r.isCommentRequired;
              })), false);
  } else {
    isResolutionCommentRequired = false;
  }
  var isCommentEmpty = resolutionComment !== undefined ? resolutionComment.trim().length === 0 : true;
  var isResolutionUpdated = initResolution !== undefined ? (
      resolution !== undefined ? (
          Caml_obj.equal(initResolution.resolutionUuid, resolution.resolutionUuid) ? (
              initResolutionComment !== undefined ? (
                  resolutionComment !== undefined ? initResolutionComment !== resolutionComment : true
                ) : resolutionComment !== undefined
            ) : true
        ) : false
    ) : resolution !== undefined;
  var disabled = locked || RCore.$$Option.isNone(resolution) || isResolutionCommentRequired && isCommentEmpty || !isResolutionUpdated;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "modal-header modal-header_job_resolution"
                }, React.createElement("div", {
                      className: "modal-header__title"
                    }, title), React.createElement("div", {
                      className: "close",
                      onClick: onCloseModal
                    }, React.createElement("span", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "times")
                        }))), React.createElement("div", {
                  className: "modal-body"
                }, children), React.createElement("div", {
                  className: "modal-footer"
                }, React.createElement("div", undefined, React.createElement("button", {
                          className: "btn btn-default",
                          type: "button",
                          onClick: onCloseModal
                        }, closeText), React.createElement("button", {
                          className: "btn btn-primary btn-submit",
                          disabled: disabled,
                          name: "submit-resolution",
                          type: "button",
                          value: "true",
                          onClick: onFinishButtonClicked
                        }, buttonText))));
}

var make = JobForm_ResolutionPopup;

export {
  make ,
}
/* Icon Not a pure module */
