

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var FilterEntity = {
  decode: decode
};

function decode$1(ctx) {
  return JsonDecode.object(function (field) {
              var system = field.required("system", JsonDecode.bool);
              var name = system ? Utils.Translations.t("js.reports.jobs.system_type")(ctx) : field.required("name", JsonDecode.string);
              return {
                      uuid: field.required("uuid", Uuid.decode),
                      name: name,
                      system: field.required("system", JsonDecode.bool)
                    };
            });
}

function toFilterEntity(jobType) {
  return {
          uuid: jobType.uuid,
          name: jobType.name
        };
}

function toFilterEntities(jobTypes) {
  return jobTypes.map(toFilterEntity);
}

var JobType = {
  decode: decode$1,
  toFilterEntity: toFilterEntity,
  toFilterEntities: toFilterEntities
};

function decode$2(ctx) {
  return JsonDecode.object(function (field) {
              return {
                      templates: field.required("templates", JsonDecode.array(decode)),
                      resolutions: field.required("resolutions", JsonDecode.array(decode)),
                      types: field.required("types", JsonDecode.array(decode$1(ctx))).map(toFilterEntity),
                      territories: field.required("territories", JsonDecode.array(decode)),
                      assignees: field.required("assignees", JsonDecode.array(decode))
                    };
            });
}

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Reports",
                    VAL: "Jobs"
                  }
                }), decode$2(wire.ctx));
}

var Jobs = {
  decode: decode$2,
  index: index
};

function decode$3(ctx) {
  return JsonDecode.object(function (field) {
              return {
                      templates: field.required("templates", JsonDecode.array(decode)),
                      types: field.required("types", JsonDecode.array(decode$1(ctx))).map(toFilterEntity),
                      territories: field.required("territories", JsonDecode.array(decode))
                    };
            });
}

function index$1(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Reports",
                    VAL: "Workers"
                  }
                }), decode$3(wire.ctx));
}

var Workers = {
  decode: decode$3,
  index: index$1
};

function buildReportsUrl(ctx, url) {
  var match = ctx.localizator.lang;
  return url + (
          match === "en" ? "?lang=en" : (
              match === "es" ? "?lang=es" : (
                  match === "ru" ? "?lang=ru" : ""
                )
            )
        );
}

export {
  FilterEntity ,
  JobType ,
  Jobs ,
  Workers ,
  buildReportsUrl ,
}
/* decode Not a pure module */
