

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as CurrencyText from "../../inputs/CurrencyText.mjs";
import * as Filters_Button from "../../common/Filters/Filters_Button.mjs";
import * as Filters_ButtonGroup from "../../common/Filters/Filters_ButtonGroup.mjs";
import * as Template_Catalog_Types from "./Template_Catalog_Types.mjs";
import * as Template_ServicesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template_catalog/Template_Services.module.css";

var emptyFilter$p = Utils.Translations.tr("js.templates.services.empty_filter");

var filterAll$p = Utils.Translations.tr("js.templates.services.filter_all");

var filterServices$p = Utils.Translations.tr("js.templates.services.filter_services");

var filterCategories$p = Utils.Translations.tr("js.templates.services.filter_categories");

var itemFromCategory$p = Utils.Translations.txr("js.templates.services.item_from_category");

var styles = Template_ServicesModuleCss;

function Template_Services_Select$Filter(Props) {
  var ctx = Props.ctx;
  var filterQuery = Props.filterQuery;
  var setFilterQuery = Props.setFilterQuery;
  var activeFilters = Props.activeFilters;
  var setActiveFilters = Props.setActiveFilters;
  var onQueryChange = function ($$event) {
    var value = $$event.target.value;
    setFilterQuery(function (param) {
          return Fun.optString(value);
        });
  };
  return React.createElement("div", {
              className: styles.filterWrapper
            }, React.createElement(Filters_ButtonGroup.make, {
                  className: styles.controlsSection,
                  children: null
                }, React.createElement(Filters_Button.make, {
                      onClick: (function (param) {
                          setActiveFilters(function (param) {
                                return "All";
                              });
                        }),
                      active: activeFilters === "All",
                      className: styles.filterButton,
                      children: filterAll$p(ctx)
                    }), React.createElement(Filters_Button.make, {
                      onClick: (function (param) {
                          setActiveFilters(function (param) {
                                return "Services";
                              });
                        }),
                      active: activeFilters === "Services",
                      className: styles.filterButton,
                      children: filterServices$p(ctx)
                    }), React.createElement(Filters_Button.make, {
                      onClick: (function (param) {
                          setActiveFilters(function (param) {
                                return "Categories";
                              });
                        }),
                      active: activeFilters === "Categories",
                      className: styles.filterButton,
                      children: filterCategories$p(ctx)
                    })), React.createElement("div", undefined, React.createElement("input", {
                      className: styles.filter,
                      value: RCore.$$Option.getOr(filterQuery, ""),
                      onChange: onQueryChange
                    }), RCore.$$Option.isNone(filterQuery) ? React.createElement("i", {
                        className: El.Cn.concat(styles.filterIcon, Icon.style(undefined, undefined, undefined, undefined, "search"))
                      }) : React.createElement("button", {
                        className: El.Cn.concat(styles.filterIcon, Icon.style(undefined, undefined, undefined, undefined, "times")),
                        onClick: (function (param) {
                            setFilterQuery(function (param) {
                                  
                                });
                          })
                      })));
}

function Template_Services_Select(Props) {
  var ctx = Props.ctx;
  var onSelect = Props.onSelect;
  var availableItems = Props.availableItems;
  var match = React.useState(function () {
        
      });
  var filterQuery = match[0];
  var match$1 = React.useState(function () {
        return "All";
      });
  var activeFilters = match$1[0];
  var filterByType = function (list) {
    switch (activeFilters) {
      case "All" :
          return list;
      case "Services" :
          return RCore.$$Array.keep(list, Template_Catalog_Types.ListItem.isService);
      case "Categories" :
          return RCore.$$Array.keep(list, Template_Catalog_Types.ListItem.isCategory);
      
    }
  };
  var filter = function (list) {
    var list$1 = filterByType(list);
    if (filterQuery !== undefined) {
      return RCore.$$Array.keep(list$1, (function (item) {
                    return Js_string.includes(filterQuery.toLowerCase(), Template_Catalog_Types.ListItem.name(item).toLowerCase());
                  }));
    } else {
      return list$1;
    }
  };
  var onClick = function (item) {
    return function (e) {
      e.preventDefault();
      onSelect(item);
    };
  };
  var listItems = filter(availableItems);
  var itemCn = El.Cn.concat("filter-item", styles.filterItem);
  var renderItem = function (item) {
    if (item.TAG === "Category") {
      var cat = item._0;
      var desc = cat.description;
      return React.createElement("div", {
                  key: Uuid.toString(cat.uuid),
                  className: itemCn,
                  onClick: onClick({
                        TAG: "Category",
                        _0: cat
                      })
                }, React.createElement("i", {
                      className: Icon.style("lg", undefined, undefined, undefined, "list")
                    }), React.createElement("div", undefined, React.createElement("div", undefined, cat.name), React.createElement("div", {
                          className: styles.description
                        }, desc !== undefined ? desc : null)));
    } else {
      var serv = item._0;
      var match = serv.priceDefault;
      var match$1 = serv.description;
      var match$2 = serv.category;
      return React.createElement("div", {
                  key: Uuid.toString(serv.uuid),
                  className: itemCn,
                  onClick: onClick({
                        TAG: "Service",
                        _0: serv
                      })
                }, React.createElement("i", {
                      className: Icon.style("lg", undefined, undefined, undefined, "wrench")
                    }), React.createElement("div", undefined, React.createElement("div", undefined, serv.name), React.createElement("div", {
                          className: styles.description
                        }, match !== undefined ? React.createElement(CurrencyText.make, {
                                ctx: ctx,
                                currency: serv.currency,
                                value: match.toString()
                              }) : (
                            match$1 !== undefined ? match$1 : (
                                match$2 !== undefined ? itemFromCategory$p({
                                        name: match$2.name
                                      }, ctx) : null
                              )
                          ))));
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                  show: availableItems.length > 8,
                  children: React.createElement(Template_Services_Select$Filter, {
                        ctx: ctx,
                        filterQuery: filterQuery,
                        setFilterQuery: match[1],
                        activeFilters: activeFilters,
                        setActiveFilters: match$1[1]
                      })
                }), listItems.length === 0 ? React.createElement("div", {
                    className: styles.emptyFilter
                  }, emptyFilter$p(ctx)) : listItems.map(renderItem));
}

var make = Template_Services_Select;

export {
  make ,
}
/* emptyFilter' Not a pure module */
