

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Types from "./Types.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as ModalQuery from "./ModalQuery.mjs";
import * as QueryUtils from "../utils/QueryUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var RecurringJobParams = {};

var BitrixEditParams = {};

function parts(route) {
  return RCore.$$Array.keep((function (__x) {
                  return Js_string.split("/", __x);
                })(route), (function (x) {
                return x !== "";
              }));
}

function parsePath(route, search) {
  var match = parts(route);
  var len = match.length;
  if (len >= 5) {
    return "Unknown";
  }
  switch (len) {
    case 0 :
        return "Unknown";
    case 1 :
        var match$1 = match[0];
        switch (match$1) {
          case "403" :
              return "Forbidden";
          case "blocked" :
              return "Blocked";
          case "clients" :
              return {
                      NAME: "Client",
                      VAL: "Index"
                    };
          case "jobs" :
              return {
                      NAME: "Job",
                      VAL: "Index"
                    };
          case "login" :
              return "Login";
          case "map" :
              return {
                      NAME: "MapShow",
                      VAL: "Workers"
                    };
          case "recover" :
              return "Recover";
          case "reports" :
              return {
                      NAME: "Reports",
                      VAL: "Jobs"
                    };
          case "schedule" :
              return {
                      NAME: "Schedule",
                      VAL: {
                        NAME: "Timeline",
                        VAL: "Day"
                      }
                    };
          case "shifts" :
              return "Shifts";
          case "sites" :
              return {
                      NAME: "Site",
                      VAL: "Index"
                    };
          case "teams" :
              return {
                      NAME: "Team",
                      VAL: "Index"
                    };
          case "trial-ended" :
              return "TrialEnded";
          default:
            return "Unknown";
        }
    case 2 :
        var match$2 = match[0];
        switch (match$2) {
          case "activate" :
              var token = match[1];
              return {
                      NAME: "Activation",
                      VAL: token
                    };
          case "admin" :
              var match$3 = match[1];
              switch (match$3) {
                case "api" :
                    return {
                            NAME: "Integrations",
                            VAL: "API"
                          };
                case "audit-log" :
                    return "AuditLog";
                case "billing" :
                    return "Billing";
                case "client-forms" :
                    return {
                            NAME: "ClientForm",
                            VAL: "Index"
                          };
                case "client-portal" :
                    return {
                            NAME: "ClientPortal",
                            VAL: "Layout"
                          };
                case "custom_fields" :
                    return {
                            NAME: "CustomFields",
                            VAL: "Users"
                          };
                case "dictionaries" :
                    return {
                            NAME: "Dictionary",
                            VAL: "Index"
                          };
                case "integrations" :
                    return {
                            NAME: "Integrations",
                            VAL: "Index"
                          };
                case "print_layouts" :
                    return {
                            NAME: "PrintLayout",
                            VAL: "Index"
                          };
                case "products" :
                    return {
                            NAME: "Products",
                            VAL: "Index"
                          };
                case "recurring_jobs" :
                    return {
                            NAME: "RecurringJob",
                            VAL: "Index"
                          };
                case "services" :
                    return {
                            NAME: "Services",
                            VAL: "Index"
                          };
                case "settings" :
                    return "GeneralSettings";
                case "skills" :
                    return "Skills";
                case "templates" :
                    return {
                            NAME: "Template",
                            VAL: "Index"
                          };
                case "territories" :
                    return {
                            NAME: "Territories",
                            VAL: "Index"
                          };
                case "users" :
                    return {
                            NAME: "User",
                            VAL: "Index"
                          };
                default:
                  return "Unknown";
              }
          case "calendar" :
              var match$4 = match[1];
              switch (match$4) {
                case "month" :
                    return {
                            NAME: "Schedule",
                            VAL: {
                              NAME: "Calendar",
                              VAL: "Month"
                            }
                          };
                case "week" :
                    return {
                            NAME: "Schedule",
                            VAL: {
                              NAME: "Calendar",
                              VAL: "Week"
                            }
                          };
                default:
                  return "Unknown";
              }
          case "jobs" :
              var match$5 = match[1];
              if (match$5 === "upload") {
                return {
                        NAME: "Job",
                        VAL: "Upload"
                      };
              } else {
                return "Unknown";
              }
          case "map" :
              var match$6 = match[1];
              if (match$6 === "jobs") {
                return {
                        NAME: "MapShow",
                        VAL: "Jobs"
                      };
              } else {
                return "Unknown";
              }
          case "recover" :
              var token$1 = match[1];
              return {
                      NAME: "ResetPassword",
                      VAL: token$1
                    };
          case "reports" :
              var match$7 = match[1];
              switch (match$7) {
                case "jobs" :
                    return {
                            NAME: "Reports",
                            VAL: "Jobs"
                          };
                case "workers" :
                    return {
                            NAME: "Reports",
                            VAL: "Workers"
                          };
                default:
                  return "Unknown";
              }
          case "sign-in-by-token" :
              var token$2 = match[1];
              return {
                      NAME: "SignInByToken",
                      VAL: [
                        token$2,
                        QueryUtils.query(search, "redirect")
                      ]
                    };
          case "teams" :
              var uuid = match[1];
              if (uuid === "new") {
                return {
                        NAME: "Team",
                        VAL: "New"
                      };
              } else if (Uuid.test(uuid)) {
                return {
                        NAME: "Team",
                        VAL: {
                          NAME: "Edit",
                          VAL: Uuid.fromString(uuid)
                        }
                      };
              } else {
                return "Unknown";
              }
          case "schedule" :
          case "timeline" :
              break;
          default:
            return "Unknown";
        }
        var match$8 = match[1];
        switch (match$8) {
          case "day" :
              return {
                      NAME: "Schedule",
                      VAL: {
                        NAME: "Timeline",
                        VAL: "Day"
                      }
                    };
          case "week" :
              return {
                      NAME: "Schedule",
                      VAL: {
                        NAME: "Timeline",
                        VAL: "Week"
                      }
                    };
          default:
            return "Unknown";
        }
        break;
    case 3 :
        var match$9 = match[0];
        switch (match$9) {
          case "admin" :
              var match$10 = match[1];
              switch (match$10) {
                case "billing" :
                    var match$11 = match[2];
                    if (match$11 === "paddle-success") {
                      return "PaddlePaymentSuccess";
                    } else {
                      return "Unknown";
                    }
                case "client-forms" :
                    var uuid$1 = match[2];
                    if (uuid$1 === "new") {
                      return {
                              NAME: "ClientForm",
                              VAL: "New"
                            };
                    } else if (Uuid.test(uuid$1)) {
                      return {
                              NAME: "ClientForm",
                              VAL: {
                                NAME: "Edit",
                                VAL: Uuid.fromString(uuid$1)
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                case "client-portal" :
                    var match$12 = match[2];
                    switch (match$12) {
                      case "layout" :
                          return {
                                  NAME: "ClientPortal",
                                  VAL: "Layout"
                                };
                      case "settings" :
                          return {
                                  NAME: "ClientPortal",
                                  VAL: "Settings"
                                };
                      case "users" :
                          return {
                                  NAME: "ClientPortal",
                                  VAL: "Users"
                                };
                      default:
                        return "Unknown";
                    }
                case "custom_fields" :
                    var match$13 = match[2];
                    if (match$13 === "users") {
                      return {
                              NAME: "CustomFields",
                              VAL: "Users"
                            };
                    } else {
                      return "Unknown";
                    }
                case "dictionaries" :
                    var uuid$2 = match[2];
                    if (uuid$2 === "new") {
                      return {
                              NAME: "Dictionary",
                              VAL: "New"
                            };
                    } else if (Uuid.test(uuid$2)) {
                      return {
                              NAME: "Dictionary",
                              VAL: {
                                NAME: "Edit",
                                VAL: Uuid.fromString(uuid$2)
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                case "integrations" :
                    var match$14 = match[2];
                    switch (match$14) {
                      case "amocrm" :
                          return {
                                  NAME: "Integrations",
                                  VAL: {
                                    NAME: "amoCRM",
                                    VAL: {
                                      NAME: "Show",
                                      VAL: [
                                        false,
                                        QueryUtils.queryId(search, "pipeline")
                                      ]
                                    }
                                  }
                                };
                      case "bitrix24" :
                          return {
                                  NAME: "Integrations",
                                  VAL: {
                                    NAME: "Bitrix24",
                                    VAL: "Index"
                                  }
                                };
                      case "kommo" :
                          return {
                                  NAME: "Integrations",
                                  VAL: {
                                    NAME: "amoCRM",
                                    VAL: {
                                      NAME: "Show",
                                      VAL: [
                                        true,
                                        QueryUtils.queryId(search, "pipeline")
                                      ]
                                    }
                                  }
                                };
                      default:
                        return "Unknown";
                    }
                case "print_layouts" :
                    var uuid$3 = match[2];
                    if (uuid$3 === "new") {
                      return {
                              NAME: "PrintLayout",
                              VAL: "New"
                            };
                    } else if (Uuid.test(uuid$3)) {
                      return {
                              NAME: "PrintLayout",
                              VAL: {
                                NAME: "Edit",
                                VAL: Uuid.fromString(uuid$3)
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                case "recurring_jobs" :
                    var uuid$4 = match[2];
                    if (uuid$4 !== "new") {
                      if (Uuid.test(uuid$4)) {
                        return {
                                NAME: "RecurringJob",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: Uuid.fromString(uuid$4)
                                }
                              };
                      } else {
                        return "Unknown";
                      }
                    }
                    var template = QueryUtils.queryUuid(search, "template");
                    if (template !== undefined) {
                      return {
                              NAME: "RecurringJob",
                              VAL: {
                                NAME: "New",
                                VAL: {
                                  client: QueryUtils.queryUuid(search, "client"),
                                  site: QueryUtils.queryUuid(search, "site"),
                                  template: Caml_option.valFromOption(template)
                                }
                              }
                            };
                    } else {
                      return {
                              NAME: "RecurringJob",
                              VAL: "Index"
                            };
                    }
                case "settings" :
                    var match$15 = match[2];
                    switch (match$15) {
                      case "job_types" :
                          return "JobTypeIndex";
                      case "resolutions" :
                          return "ResolutionIndex";
                      default:
                        return "Unknown";
                    }
                case "templates" :
                    var uuid$5 = match[2];
                    if (uuid$5 === "new") {
                      return {
                              NAME: "Template",
                              VAL: {
                                NAME: "New",
                                VAL: QueryUtils.queryUuid(search, "copy")
                              }
                            };
                    } else if (Uuid.test(uuid$5)) {
                      return {
                              NAME: "Template",
                              VAL: {
                                NAME: "Edit",
                                VAL: Uuid.fromString(uuid$5)
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                case "territories" :
                    var uuid$6 = match[2];
                    if (uuid$6 === "new") {
                      return {
                              NAME: "Territories",
                              VAL: "New"
                            };
                    } else if (Uuid.test(uuid$6)) {
                      return {
                              NAME: "Territories",
                              VAL: {
                                NAME: "Edit",
                                VAL: Uuid.fromString(uuid$6)
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                case "users" :
                    var uuid$7 = match[2];
                    if (uuid$7 === "new") {
                      var match$16 = QueryUtils.query(search, "role");
                      if (match$16 === undefined) {
                        return {
                                NAME: "User",
                                VAL: {
                                  NAME: "Custom",
                                  VAL: "New"
                                }
                              };
                      }
                      switch (match$16) {
                        case "field" :
                            return {
                                    NAME: "User",
                                    VAL: {
                                      NAME: "Field",
                                      VAL: "New"
                                    }
                                  };
                        default:
                          return {
                                  NAME: "User",
                                  VAL: {
                                    NAME: "Custom",
                                    VAL: "New"
                                  }
                                };
                      }
                    } else {
                      if (!Uuid.test(uuid$7)) {
                        return "Unknown";
                      }
                      var match$17 = QueryUtils.query(search, "role");
                      if (match$17 !== undefined) {
                        switch (match$17) {
                          case "field" :
                              return {
                                      NAME: "User",
                                      VAL: {
                                        NAME: "Field",
                                        VAL: {
                                          NAME: "Edit",
                                          VAL: Uuid.fromString(uuid$7)
                                        }
                                      }
                                    };
                          default:
                            
                        }
                      }
                      return {
                              NAME: "User",
                              VAL: {
                                NAME: "Custom",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: Uuid.fromString(uuid$7)
                                }
                              }
                            };
                    }
                    break;
                case "webhooks" :
                    var uuid$8 = match[2];
                    if (uuid$8 === "new") {
                      return {
                              NAME: "Integrations",
                              VAL: {
                                NAME: "Webhooks",
                                VAL: "New"
                              }
                            };
                    } else {
                      return {
                              NAME: "Integrations",
                              VAL: {
                                NAME: "Webhooks",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: Uuid.fromString(uuid$8)
                                }
                              }
                            };
                    }
                default:
                  return "Unknown";
              }
              break;
          case "jobs" :
              var match$18 = match[1];
              if (match$18 !== "upload") {
                return "Unknown";
              }
              var match$19 = match[2];
              if (match$19 === "sample") {
                return {
                        NAME: "Job",
                        VAL: "CsvSample"
                      };
              } else {
                return "Unknown";
              }
          case "users" :
              var match$20 = match[1];
              if (match$20 !== "activate") {
                return "Unknown";
              }
              var token$3 = match[2];
              var match$21 = QueryUtils.query(search, "form");
              if (match$21 === undefined) {
                return "Unknown";
              }
              switch (match$21) {
                case "email" :
                    return {
                            NAME: "ActivateFieldUser",
                            VAL: [
                              "Email",
                              token$3
                            ]
                          };
                case "sms" :
                    return {
                            NAME: "ActivateFieldUser",
                            VAL: [
                              "Sms",
                              token$3
                            ]
                          };
                default:
                  return "Unknown";
              }
          default:
            return "Unknown";
        }
        break;
    case 4 :
        var match$22 = match[0];
        if (match$22 !== "admin") {
          return "Unknown";
        }
        var match$23 = match[1];
        switch (match$23) {
          case "integrations" :
              var match$24 = match[2];
              switch (match$24) {
                case "amocrm" :
                    var match$25 = match[3];
                    if (match$25 !== "connect") {
                      return "Unknown";
                    }
                    var host = QueryUtils.query(search, "amocrm_host");
                    if (host !== undefined) {
                      return {
                              NAME: "Integrations",
                              VAL: {
                                NAME: "amoCRM",
                                VAL: {
                                  NAME: "Connect",
                                  VAL: [
                                    false,
                                    host
                                  ]
                                }
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                case "bitrix24" :
                    var uid = match[3];
                    if (uid === "new") {
                      return {
                              NAME: "Integrations",
                              VAL: {
                                NAME: "Bitrix24",
                                VAL: "New"
                              }
                            };
                    } else {
                      return {
                              NAME: "Integrations",
                              VAL: {
                                NAME: "Bitrix24",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: {
                                    category: QueryUtils.queryId(search, "category"),
                                    bitrixTenantUid: uid
                                  }
                                }
                              }
                            };
                    }
                case "kommo" :
                    var match$26 = match[3];
                    if (match$26 !== "connect") {
                      return "Unknown";
                    }
                    var host$1 = QueryUtils.query(search, "amocrm_host");
                    if (host$1 !== undefined) {
                      return {
                              NAME: "Integrations",
                              VAL: {
                                NAME: "amoCRM",
                                VAL: {
                                  NAME: "Connect",
                                  VAL: [
                                    true,
                                    host$1
                                  ]
                                }
                              }
                            };
                    } else {
                      return "Unknown";
                    }
                default:
                  return "Unknown";
              }
          case "webhooks" :
              var uuid$9 = match[2];
              var match$27 = match[3];
              if (match$27 === "deliveries") {
                return {
                        NAME: "Integrations",
                        VAL: {
                          NAME: "Webhooks",
                          VAL: {
                            NAME: "Deliveries",
                            VAL: Uuid.fromString(uuid$9)
                          }
                        }
                      };
              } else {
                return "Unknown";
              }
          default:
            return "Unknown";
        }
    
  }
}

function parseModalUrl(route, search) {
  var match = parts(route);
  var len = match.length;
  if (len >= 3) {
    return ;
  }
  switch (len) {
    case 0 :
        return ;
    case 1 :
        var match$1 = match[0];
        switch (match$1) {
          case "clients" :
              var match$2 = ModalQuery.parseUploadQuery(search);
              if (match$2 !== undefined) {
                if (match$2 === "Sites") {
                  return {
                          path: {
                            NAME: "Client",
                            VAL: "Index"
                          },
                          modals: {
                            hd: {
                              NAME: "Client",
                              VAL: {
                                NAME: "Upload",
                                VAL: "Sites"
                              }
                            },
                            tl: /* [] */0
                          }
                        };
                } else {
                  return {
                          path: {
                            NAME: "Client",
                            VAL: "Index"
                          },
                          modals: {
                            hd: {
                              NAME: "Client",
                              VAL: {
                                NAME: "Upload",
                                VAL: "Clients"
                              }
                            },
                            tl: /* [] */0
                          }
                        };
                }
              } else {
                return ;
              }
          case "sites" :
              var match$3 = ModalQuery.parseUploadQuery(search);
              if (match$3 !== undefined) {
                if (match$3 === "Sites") {
                  return {
                          path: {
                            NAME: "Site",
                            VAL: "Index"
                          },
                          modals: {
                            hd: {
                              NAME: "Site",
                              VAL: {
                                NAME: "Upload",
                                VAL: "Sites"
                              }
                            },
                            tl: /* [] */0
                          }
                        };
                } else {
                  return {
                          path: {
                            NAME: "Site",
                            VAL: "Index"
                          },
                          modals: {
                            hd: {
                              NAME: "Site",
                              VAL: {
                                NAME: "Upload",
                                VAL: "Clients"
                              }
                            },
                            tl: /* [] */0
                          }
                        };
                }
              } else {
                return ;
              }
          default:
            return ;
        }
    case 2 :
        var match$4 = match[0];
        switch (match$4) {
          case "clients" :
              var uuid = match[1];
              if (uuid === "new") {
                return {
                        path: {
                          NAME: "Client",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Client",
                            VAL: "New"
                          },
                          tl: /* [] */0
                        }
                      };
              } else if (Uuid.test(uuid)) {
                return {
                        path: {
                          NAME: "Client",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Client",
                            VAL: {
                              NAME: "Edit",
                              VAL: Uuid.fromString(uuid)
                            }
                          },
                          tl: /* [] */0
                        }
                      };
              } else {
                return ;
              }
          case "jobs" :
              var uuid$1 = match[1];
              if (uuid$1 === "new") {
                return {
                        path: {
                          NAME: "Job",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Job",
                            VAL: {
                              NAME: "New",
                              VAL: ModalQuery.Job.NewOptions.decode(search)
                            }
                          },
                          tl: /* [] */0
                        }
                      };
              } else if (Uuid.test(uuid$1)) {
                return {
                        path: {
                          NAME: "Job",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Job",
                            VAL: {
                              NAME: "Edit",
                              VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, Uuid.fromString(uuid$1))
                            }
                          },
                          tl: /* [] */0
                        }
                      };
              } else {
                return ;
              }
          case "sites" :
              var uuid$2 = match[1];
              if (uuid$2 === "new") {
                return {
                        path: {
                          NAME: "Site",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Site",
                            VAL: "New"
                          },
                          tl: /* [] */0
                        }
                      };
              } else if (Uuid.test(uuid$2)) {
                return {
                        path: {
                          NAME: "Site",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Site",
                            VAL: {
                              NAME: "Edit",
                              VAL: Uuid.fromString(uuid$2)
                            }
                          },
                          tl: /* [] */0
                        }
                      };
              } else {
                return ;
              }
          default:
            return ;
        }
    
  }
}

function parseUrl(route, search) {
  var route$1 = parseModalUrl(route, search);
  if (route$1 !== undefined) {
    return route$1;
  } else {
    return {
            path: parsePath(route, search),
            modals: ModalQuery.parseQuery(search)
          };
  }
}

function pagePath_(page) {
  if (typeof page !== "object") {
    if (page === "AuditLog") {
      return "/admin/audit-log";
    } else if (page === "Shifts") {
      return "/shifts";
    } else if (page === "Forbidden") {
      return "/403";
    } else if (page === "TrialEnded") {
      return "/trial-ended";
    } else if (page === "ResolutionIndex") {
      return "/admin/settings/resolutions";
    } else if (page === "Recover") {
      return "/recover";
    } else if (page === "Login") {
      return "/login";
    } else if (page === "Skills") {
      return "/admin/skills";
    } else if (page === "Blocked") {
      return "/blocked";
    } else if (page === "Password") {
      return "/password";
    } else if (page === "JobTypeIndex") {
      return "/admin/settings/job_types";
    } else if (page === "Billing") {
      return "/admin/billing";
    } else if (page === "GeneralSettings") {
      return "/admin/settings";
    } else if (page === "Unknown") {
      return "/404";
    } else if (page === "PaddlePaymentSuccess") {
      return "/admin/billing/paddle-success";
    } else {
      return "/profile";
    }
  }
  var variant = page.NAME;
  if (variant === "ClientPortal") {
    var match = page.VAL;
    if (match === "Layout") {
      return "/admin/client-portal/layout";
    } else if (match === "Users") {
      return "/admin/client-portal/users";
    } else {
      return "/admin/client-portal/settings";
    }
  }
  if (variant === "CustomFields") {
    return "/admin/custom_fields/users";
  }
  if (variant === "Integrations") {
    var match$1 = page.VAL;
    if (typeof match$1 !== "object") {
      if (match$1 === "API") {
        return "/admin/api";
      } else {
        return "/admin/integrations";
      }
    }
    var variant$1 = match$1.NAME;
    if (variant$1 === "Bitrix24") {
      var match$2 = match$1.VAL;
      if (typeof match$2 === "object") {
        return "/admin/integrations/bitrix24/" + match$2.VAL.bitrixTenantUid;
      } else if (match$2 === "New") {
        return "/admin/integrations/bitrix24/new";
      } else {
        return "/admin/integrations/bitrix24";
      }
    }
    if (variant$1 === "Webhooks") {
      var match$3 = match$1.VAL;
      if (typeof match$3 === "object") {
        if (match$3.NAME === "Edit") {
          return "/admin/webhooks/" + Uuid.toString(match$3.VAL);
        } else {
          return "/admin/webhooks/" + Uuid.toString(match$3.VAL) + "/deliveries";
        }
      } else {
        return "/admin/webhooks/new";
      }
    }
    var match$4 = match$1.VAL;
    if (match$4.NAME === "Show") {
      if (match$4.VAL[0]) {
        return "/admin/integrations/kommo";
      } else {
        return "/admin/integrations/amocrm";
      }
    } else if (match$4.VAL[0]) {
      return "/admin/integrations/kommo/connect";
    } else {
      return "/admin/integrations/amocrm/connect";
    }
  }
  if (variant === "PrintLayout") {
    var match$5 = page.VAL;
    if (typeof match$5 === "object") {
      if (match$5.NAME === "Edit") {
        return "/admin/print_layouts/" + Uuid.toString(match$5.VAL);
      } else {
        return "/admin/print_layouts/" + Uuid.toString(match$5.VAL) + "/download";
      }
    } else if (match$5 === "New") {
      return "/admin/print_layouts/new";
    } else {
      return "/admin/print_layouts";
    }
  }
  if (variant === "Reports") {
    if (page.VAL === "Jobs") {
      return "/reports/jobs";
    } else {
      return "/reports/workers";
    }
  }
  if (variant === "ActivateFieldUser") {
    return "/users/activate/" + page.VAL[1];
  }
  if (variant === "MapShow") {
    if (page.VAL === "Workers") {
      return "/map";
    } else {
      return "/map/jobs";
    }
  }
  if (variant === "Job") {
    var match$6 = page.VAL;
    if (typeof match$6 === "object") {
      return "/jobs/" + Uuid.toString(match$6.VAL) + ".pdf";
    } else if (match$6 === "Index") {
      return "/jobs";
    } else if (match$6 === "CsvSample") {
      return "/jobs/upload/sample/csv";
    } else if (match$6 === "XlsxSample") {
      return "/jobs/upload/sample/xlsx";
    } else {
      return "/jobs/upload";
    }
  }
  if (variant === "Template") {
    var match$7 = page.VAL;
    if (typeof match$7 === "object") {
      if (match$7.NAME === "Edit") {
        return "/admin/templates/" + Uuid.toString(match$7.VAL);
      } else {
        return "/admin/templates/new";
      }
    } else {
      return "/admin/templates";
    }
  }
  if (variant === "SignInByToken") {
    return "/sign-in-by-token/" + page.VAL[0];
  }
  if (variant === "Tasks") {
    return "/tasks/" + Uuid.toString(page.VAL.VAL);
  }
  if (variant === "Schedule") {
    var match$8 = page.VAL;
    if (match$8.NAME === "Calendar") {
      if (match$8.VAL === "Week") {
        return "/calendar/week";
      } else {
        return "/calendar/month";
      }
    } else if (match$8.VAL === "Week") {
      return "/timeline/week";
    } else {
      return "/timeline/day";
    }
  }
  if (variant === "ClientForm") {
    var match$9 = page.VAL;
    if (typeof match$9 === "object") {
      if (match$9.NAME === "Edit") {
        return "/admin/client-forms/" + Uuid.toString(match$9.VAL);
      } else {
        return "/admin/client-forms/" + Uuid.toString(match$9.VAL) + "/download";
      }
    } else if (match$9 === "New") {
      return "/admin/client-forms/new";
    } else {
      return "/admin/client-forms";
    }
  }
  if (variant === "Products") {
    return "/admin/products";
  }
  if (variant === "File") {
    return "/files/" + Uuid.toString(page.VAL) + "/download";
  }
  if (variant === "Territories") {
    var match$10 = page.VAL;
    if (typeof match$10 === "object") {
      return "/admin/territories/" + Uuid.toString(match$10.VAL);
    } else if (match$10 === "New") {
      return "/admin/territories/new";
    } else {
      return "/admin/territories";
    }
  }
  if (variant === "Activation") {
    return "/activate/" + page.VAL;
  }
  if (variant === "Site") {
    if (page.VAL === "CsvSample") {
      return "/sites/upload/sample";
    } else {
      return "/sites";
    }
  }
  if (variant === "Team") {
    var match$11 = page.VAL;
    if (typeof match$11 === "object") {
      return "/teams/" + Uuid.toString(match$11.VAL);
    } else if (match$11 === "New") {
      return "/teams/new";
    } else {
      return "/teams";
    }
  }
  if (variant === "User") {
    var match$12 = page.VAL;
    if (typeof match$12 !== "object") {
      return "/admin/users";
    }
    if (match$12.NAME === "Field") {
      var match$13 = match$12.VAL;
      if (typeof match$13 === "object") {
        return "/admin/users/" + Uuid.toString(match$13.VAL);
      } else {
        return "/admin/users/new";
      }
    }
    var match$14 = match$12.VAL;
    if (typeof match$14 === "object") {
      return "/admin/users/" + Uuid.toString(match$14.VAL);
    } else {
      return "/admin/users/new";
    }
  }
  if (variant === "Dictionary") {
    var match$15 = page.VAL;
    if (typeof match$15 === "object") {
      return "/admin/dictionaries/" + Uuid.toString(match$15.VAL);
    } else if (match$15 === "New") {
      return "/admin/dictionaries/new";
    } else {
      return "/admin/dictionaries";
    }
  }
  if (variant === "Client") {
    if (page.VAL === "CsvSample") {
      return "/clients/upload/sample";
    } else {
      return "/clients";
    }
  }
  if (variant === "Services") {
    return "/admin/services";
  }
  if (variant !== "RecurringJob") {
    return "/recover/" + page.VAL;
  }
  var match$16 = page.VAL;
  if (typeof match$16 === "object") {
    if (match$16.NAME === "Edit") {
      return "/admin/recurring_jobs/" + Uuid.toString(match$16.VAL);
    } else {
      return "/admin/recurring_jobs/new";
    }
  } else {
    return "/admin/recurring_jobs";
  }
}

function modalPath(modals, route) {
  var match = Core__List.head(modals);
  if (match === undefined) {
    return ;
  }
  if (typeof match !== "object") {
    return ;
  }
  var variant = match.NAME;
  if (variant === "Job") {
    if (!Caml_obj.equal(route, {
            NAME: "Job",
            VAL: "Index"
          })) {
      return ;
    }
    var mode = match.VAL;
    if (mode.NAME === "Edit") {
      return "/jobs/" + Uuid.toString(mode.VAL.uuid);
    }
    var options = ModalQuery.Job.NewOptions.encode(mode.VAL);
    return "/jobs/new" + (
            options === "" ? "" : "?" + options
          );
  }
  if (variant === "Site") {
    if (!Caml_obj.equal(route, {
            NAME: "Site",
            VAL: "Index"
          })) {
      return ;
    }
    var mode$1 = match.VAL;
    if (typeof mode$1 === "object") {
      if (mode$1.NAME === "Edit") {
        return "/sites/" + Uuid.toString(mode$1.VAL);
      } else if (mode$1.VAL === "Sites") {
        return "/sites?upload=sites";
      } else {
        return "/sites?upload=clients";
      }
    } else {
      return "/sites/new";
    }
  }
  if (variant !== "Client") {
    return ;
  }
  if (!Caml_obj.equal(route, {
          NAME: "Client",
          VAL: "Index"
        })) {
    return ;
  }
  var mode$2 = match.VAL;
  if (typeof mode$2 === "object") {
    if (mode$2.NAME === "Edit") {
      return "/clients/" + Uuid.toString(mode$2.VAL);
    } else if (mode$2.VAL === "Sites") {
      return "/clients?upload=sites";
    } else {
      return "/clients?upload=clients";
    }
  } else {
    return "/clients/new";
  }
}

function pagePath(modalsOpt, route) {
  var modals = modalsOpt !== undefined ? modalsOpt : /* [] */0;
  var path = modalPath(modals, route);
  if (path !== undefined) {
    return path;
  }
  var pathname = pagePath_(route);
  var routeQuery;
  if (typeof route === "object") {
    var variant = route.NAME;
    if (variant === "Integrations") {
      var match = route.VAL;
      if (typeof match === "object") {
        var variant$1 = match.NAME;
        if (variant$1 === "amoCRM") {
          var match$1 = match.VAL;
          if (match$1.NAME === "Show") {
            var pipeline = match$1.VAL[1];
            routeQuery = pipeline !== undefined ? "pipeline=" + Types.Id.toString(Caml_option.valFromOption(pipeline)) : undefined;
          } else {
            routeQuery = "amocrm_host=" + match$1.VAL[1];
          }
        } else if (variant$1 === "Bitrix24") {
          var match$2 = match.VAL;
          if (typeof match$2 === "object" && match$2.NAME === "Edit") {
            var category = match$2.VAL.category;
            routeQuery = category !== undefined ? "category=" + Types.Id.toString(Caml_option.valFromOption(category)) : undefined;
          } else {
            routeQuery = undefined;
          }
        } else {
          routeQuery = undefined;
        }
      } else {
        routeQuery = undefined;
      }
    } else if (variant === "ActivateFieldUser") {
      routeQuery = route.VAL[0] === "Email" ? "form=email" : "form=sms";
    } else if (variant === "Template") {
      var match$3 = route.VAL;
      if (typeof match$3 === "object" && match$3.NAME === "New") {
        var copy = match$3.VAL;
        routeQuery = copy !== undefined ? "copy=" + Uuid.toString(Caml_option.valFromOption(copy)) : undefined;
      } else {
        routeQuery = undefined;
      }
    } else if (variant === "SignInByToken") {
      var redirect = route.VAL[1];
      routeQuery = redirect !== undefined ? "redirect=" + redirect : undefined;
    } else if (variant === "User") {
      var match$4 = route.VAL;
      if (typeof match$4 === "object") {
        var variant$2 = match$4.NAME;
        routeQuery = variant$2 === "Custom" ? "role=custom" : (
            variant$2 === "Field" ? "role=field" : undefined
          );
      } else {
        routeQuery = undefined;
      }
    } else if (variant === "RecurringJob") {
      var match$5 = route.VAL;
      if (typeof match$5 === "object" && match$5.NAME === "New") {
        var match$6 = match$5.VAL;
        var site = match$6.site;
        var client = match$6.client;
        var query = "template=" + Uuid.toString(match$6.template);
        routeQuery = client !== undefined ? query + "&client=" + Uuid.toString(Caml_option.valFromOption(client)) : (
            site !== undefined ? query + "&site=" + Uuid.toString(Caml_option.valFromOption(site)) : query
          );
      } else {
        routeQuery = undefined;
      }
    } else {
      routeQuery = undefined;
    }
  } else {
    routeQuery = undefined;
  }
  var modalQuery = ModalQuery.makeQuery(modals);
  var query$1 = routeQuery !== undefined ? (
      modalQuery !== undefined ? "?" + routeQuery + "&" + modalQuery : "?" + routeQuery
    ) : (
      modalQuery !== undefined ? "?" + modalQuery : ""
    );
  return pathname + query$1;
}

export {
  RecurringJobParams ,
  BitrixEditParams ,
  parts ,
  parsePath ,
  parseModalUrl ,
  parseUrl ,
  modalPath ,
  pagePath ,
}
/* Uuid Not a pure module */
