

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Radio from "../../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as DomUtils from "../../../../../../DomUtils.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MultiSelect from "../../../../../inputs/MultiSelect.mjs";
import * as JobIndexFilter_CustomWrapper from "./JobIndexFilter_CustomWrapper.mjs";
import * as JobIndexFilter_CustomModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/custom/JobIndexFilter_Custom.module.css";

var styles = JobIndexFilter_CustomModuleCss;

var filled$p = Utils.Translations.tr("js.jobs.index.filters.dictionary.filled.name");

var any$p = Utils.Translations.t("js.jobs.index.filters.dictionary.filled.any");

var specific$p = Utils.Translations.t("js.jobs.index.filters.dictionary.filled.specific");

var notFilled$p = Utils.Translations.tr("js.jobs.index.filters.dictionary.not_filled");

var selected$p = Utils.Translations.txr("js.jobs.index.filters.dictionary.selected");

function toSelectOptions(array) {
  return array.map(function (param) {
              return {
                      value: param.uuid,
                      label: param.name
                    };
            });
}

function toSelectValues(value, options) {
  if (value === undefined) {
    return [];
  }
  if (typeof value !== "object") {
    if (value === "Any") {
      return [];
    } else {
      return [];
    }
  }
  var values = value._0;
  return RCore.$$Array.filterMap(options, (function (o) {
                if (values.some(function (v) {
                        return Uuid.equal(v, o.uuid);
                      })) {
                  return Caml_option.some(o.uuid);
                }
                
              }));
}

function JobIndexFilter_CustomDictionary(Props) {
  var label = Props.label;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var options = Props.options;
  var filterByField = Props.filterByField;
  var value = Props.value;
  var show = Props.show;
  var haveAnySectionOpt = Props.haveAnySection;
  var disabled = Props.disabled;
  var haveAnySection = haveAnySectionOpt !== undefined ? haveAnySectionOpt : true;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var selectRef = React.useRef(null);
  var match$1 = value !== undefined ? (
      typeof value !== "object" ? (
          value === "Any" ? [
              true,
              true,
              false,
              false
            ] : [
              true,
              false,
              false,
              false
            ]
        ) : [
          true,
          true,
          true,
          value._0.length > 0
        ]
    ) : [
      false,
      false,
      false,
      false
    ];
  var hasSelectedValues = match$1[3];
  var hasSpecificValue = match$1[2];
  var hasFilledValue = match$1[1];
  var hasValue = match$1[0];
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter(false);
  };
  var onChange = function (valuesArray) {
    filterByField({
          TAG: "Filled",
          _0: valuesArray
        });
  };
  var setFilled = function () {
    filterByField(haveAnySection ? "Any" : ({
              TAG: "Filled",
              _0: []
            }));
  };
  var tmp;
  if (value !== undefined) {
    if (typeof value !== "object") {
      tmp = value === "Any" ? any$p(ctx) : notFilled$p(ctx);
    } else {
      var array = value._0;
      if (array.length !== 1) {
        tmp = selected$p({
              count: array.length
            }, ctx);
      } else {
        var v = array[0];
        tmp = RCore.$$Option.getExn(RCore.$$Array.getBy(options, (function (o) {
                    return Uuid.equal(o.uuid, v);
                  })), undefined).name;
      }
    }
  } else {
    tmp = null;
  }
  var renderSelected = React.createElement("div", {
        className: "j-filter__selected"
      }, tmp);
  var select = selectRef.current;
  var openUp;
  if (select == null) {
    openUp = false;
  } else {
    var position = DomUtils.BoundingClientRect.get(select);
    openUp = ((position.bottom | 0) + 300 | 0) >= DomUtils.windowInnerHeight();
  }
  return React.createElement(JobIndexFilter_CustomWrapper.make, {
              toggleFilter: toggleFilter,
              onResetClick: onResetClick,
              renderSelected: renderSelected,
              label: label,
              show: show,
              hasValue: hasValue,
              leftButton: {
                onClick: (function (param) {
                    setFilled();
                  }),
                checked: hasFilledValue,
                text: filled$p(ctx)
              },
              rightButton: {
                onClick: (function (param) {
                    filterByField("NotFilled");
                  }),
                checked: hasValue && !hasFilledValue,
                text: notFilled$p(ctx)
              },
              disabled: disabled,
              children: React.createElement("div", {
                    ref: selectRef
                  }, React.createElement(Optional.make, {
                        show: hasFilledValue,
                        children: null
                      }, React.createElement(Optional.make, {
                            show: haveAnySection,
                            children: React.createElement(Radio.make, {
                                  onChange: (function (param) {
                                      filterByField("Any");
                                    }),
                                  checked: !hasSpecificValue,
                                  labelText: any$p(ctx),
                                  wrapperClass: styles.radio,
                                  disabled: disabled
                                })
                          }), React.createElement(Radio.make, {
                            onChange: (function (param) {
                                filterByField({
                                      TAG: "Filled",
                                      _0: []
                                    });
                              }),
                            checked: hasSpecificValue,
                            labelText: specific$p(ctx),
                            wrapperClass: styles.radio,
                            disabled: disabled
                          }), React.createElement(Optional.make, {
                            show: hasSpecificValue,
                            children: React.createElement("div", undefined, React.createElement(Optional.make, {
                                      show: !hasSelectedValues,
                                      children: React.createElement("div", {
                                            className: El.Cn.concat(styles.searchSymbol, Icon.style(undefined, undefined, undefined, undefined, "search"))
                                          })
                                    }), React.createElement(MultiSelect.Uuid.make, {
                                      placeholder: "",
                                      inputProps: {
                                        className: hasSelectedValues ? "" : styles.selectInputMargin
                                      },
                                      onChange: onChange,
                                      value: toSelectValues(value, options),
                                      disabled: false,
                                      options: toSelectOptions(options),
                                      className: styles.select,
                                      autoFocus: true,
                                      multi: true,
                                      up: openUp
                                    }))
                          })))
            });
}

var make = JobIndexFilter_CustomDictionary;

export {
  make ,
}
/* styles Not a pure module */
