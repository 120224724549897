

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Spinner from "../../common/Spinner/Spinner.mjs";
import * as ApiRoute from "../../../types/ApiRoute.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as AddButton from "../../common/AddButton/AddButton.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as PrintLayoutAPI from "./PrintLayoutAPI.mjs";
import * as JobSettingsTabs from "../JobSettingsTabs.mjs";
import * as PrintLayoutShow from "./PrintLayoutShow.mjs";
import * as PrintLayoutTypes from "./PrintLayoutTypes.mjs";
import * as UI_FeatureTeaser from "../../../ui/UI_FeatureTeaser.mjs";
import * as JobSettingsHeader from "../JobSettingsHeader.mjs";
import * as TableModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Table.module.css";
import * as PrintLayoutModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/print_layouts/PrintLayout.module.css";

var addNewLayout$p = Utils.Translations.tr("js.admin.print_layouts.add_layout");

var name$p = Utils.Translations.tr("js.admin.print_layouts.name");

var defaultLayout$p = Utils.Translations.t("js.admin.print_layouts.default_layout");

var description$p = Utils.Translations.tr("js.admin.print_layouts.description");

var formatDescription$p = Utils.Translations.tr("js.admin.print_layouts.format_description");

var printLayouts$p = Utils.Translations.tr("js.admin.print_layouts.print_layouts");

var learnMore$p = Utils.Translations.tr("js.admin.print_layouts.learn_more");

var format$p = Utils.Translations.tr("js.admin.print_layouts.format");

var file$p = Utils.Translations.tr("js.admin.print_layouts.file");

var fakeNameOne$p = Utils.Translations.t("js.admin.print_layouts.fake_data.names.1st");

var fakeNameTwo$p = Utils.Translations.t("js.admin.print_layouts.fake_data.names.2nd");

var styles = PrintLayoutModuleCss;

var tableStyles = TableModuleCss;

function fakeStencilData(ctx) {
  return [
          {
            TAG: "Default",
            uuid: Uuid.createV4(),
            type_: "JobLayout",
            filename: "job_layout.html"
          },
          {
            TAG: "Custom",
            uuid: Uuid.createV4(),
            name: fakeNameOne$p(ctx),
            type_: "JobLayout",
            templates: [],
            jobTypes: [],
            outputFormat: "PDF",
            filename: "contract.html"
          },
          {
            TAG: "Custom",
            uuid: Uuid.createV4(),
            name: fakeNameTwo$p(ctx),
            type_: "JobLayout",
            templates: [],
            jobTypes: [],
            outputFormat: "HTML",
            filename: "invoice.html"
          }
        ];
}

function PrintLayoutIndex$Download(Props) {
  var filename = Props.filename;
  var uuid = Props.uuid;
  return React.createElement("td", {
              className: styles.filename
            }, React.createElement("a", {
                  download: "download",
                  href: ApiRoute.path({
                        NAME: "API",
                        VAL: {
                          NAME: "PrintLayout",
                          VAL: {
                            NAME: "Download",
                            VAL: uuid
                          }
                        }
                      }),
                  target: "_blank"
                }, filename));
}

function PrintLayoutIndex$StencilRow(Props) {
  var stencil = Props.stencil;
  var hasFlag = Props.hasFlag;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  if (!hasFlag) {
    if (stencil.TAG === "Default") {
      return React.createElement("tr", undefined, React.createElement("td", undefined, defaultLayout$p(ctx)), React.createElement("td", {
                      className: styles.filename
                    }, stencil.filename), React.createElement("td", undefined, "PDF"));
    } else {
      return React.createElement("tr", undefined, React.createElement("td", undefined, stencil.name), React.createElement("td", {
                      className: styles.filename
                    }, stencil.filename), React.createElement("td", undefined, PrintLayoutTypes.OutputFormat.toHumanString(stencil.outputFormat)));
    }
  }
  if (stencil.TAG === "Default") {
    var uuid = stencil.uuid;
    return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                        wire: wire,
                        route: {
                          NAME: "PrintLayout",
                          VAL: {
                            NAME: "Edit",
                            VAL: uuid
                          }
                        },
                        children: defaultLayout$p(ctx)
                      })), React.createElement(PrintLayoutIndex$Download, {
                    filename: stencil.filename,
                    uuid: uuid
                  }), React.createElement("td", undefined, "PDF"));
  }
  var uuid$1 = stencil.uuid;
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "PrintLayout",
                        VAL: {
                          NAME: "Edit",
                          VAL: uuid$1
                        }
                      },
                      children: stencil.name
                    })), React.createElement(PrintLayoutIndex$Download, {
                  filename: stencil.filename,
                  uuid: uuid$1
                }), React.createElement("td", undefined, PrintLayoutTypes.OutputFormat.toHumanString(stencil.outputFormat)));
}

function PrintLayoutIndex$List(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var hasFlag = Context_Types.Features.hasFlag("printLayouts", ctx.features);
  var reload = function () {
    var loaded = function (response) {
      if (response.TAG !== "Ok") {
        return ;
      }
      var stencils = response._0.stencils;
      setState(function (param) {
            return PrintLayoutTypes.Index.State.make(stencils);
          });
    };
    Backend.$$finally(PrintLayoutAPI.Index.index(wire), loaded);
  };
  React.useEffect((function () {
          if (hasFlag) {
            reload();
          } else {
            setState(function (param) {
                  return PrintLayoutTypes.Index.State.make(fakeStencilData(ctx));
                });
          }
        }), []);
  var match$1 = ctx.localizator.lang;
  var href = match$1 === "ru" ? "https://planado.freshdesk.com/a/solutions/articles/13000086753?lang=ru-RU" : "https://planado.freshdesk.com/a/solutions/articles/13000086753?lang=en";
  var tmp;
  var exit = 0;
  if (typeof mode === "object" || mode === "New") {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(PrintLayoutShow.make, {
          mode: mode,
          onAction: reload
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "panel-body__hl"
                }, printLayouts$p(ctx)), React.createElement("div", {
                  className: styles.description
                }, description$p(ctx), React.createElement("a", {
                      href: href,
                      target: "blank"
                    }, learnMore$p(ctx), El.space, React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, undefined, "external-link-alt")
                        }))), React.createElement("div", {
                  className: styles.description
                }, formatDescription$p(ctx)), React.createElement(Optional.make, {
                  show: !hasFlag,
                  children: React.createElement(UI_FeatureTeaser.Large.make, {
                        className: styles.teaser,
                        feature: "printLayouts",
                        minimalPlan: "pro"
                      })
                }), tmp, state !== undefined ? React.createElement("table", {
                    className: El.Cn.concat(tableStyles.table, styles.stencils)
                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", {
                                className: styles.filename
                              }, file$p(ctx)), React.createElement("th", undefined, format$p(ctx)))), React.createElement("tbody", undefined, state.stencils.map(function (stencil) {
                            return React.createElement(PrintLayoutIndex$StencilRow, {
                                        stencil: stencil,
                                        hasFlag: hasFlag,
                                        key: Uuid.toString(PrintLayoutTypes.Index.Stencil.uuid(stencil))
                                      });
                          }))) : React.createElement(Spinner.make, {}), React.createElement(Optional.make, {
                  show: hasFlag,
                  children: React.createElement(AddButton.make, {
                        wire: wire,
                        route: {
                          NAME: "PrintLayout",
                          VAL: "New"
                        },
                        children: addNewLayout$p(ctx)
                      })
                }));
}

function PrintLayoutIndex(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("form", {
                      className: "form-horizontal"
                    }, React.createElement(JobSettingsHeader.make, {
                          ctx: wire.ctx
                        }), React.createElement("div", {
                          className: "settings-page row"
                        }, React.createElement(JobSettingsTabs.make, {
                              wire: wire,
                              tab: "PrintLayouts"
                            }), React.createElement("div", {
                              className: "tab-content"
                            }, React.createElement("div", {
                                  className: "tab-pane active"
                                }, React.createElement("div", {
                                      className: "panel"
                                    }, React.createElement("div", {
                                          className: "panel-body"
                                        }, React.createElement(PrintLayoutIndex$List, {
                                              wire: wire,
                                              mode: mode
                                            })))))))));
}

var make = PrintLayoutIndex;

export {
  make ,
}
/* addNewLayout' Not a pure module */
