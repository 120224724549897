

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Modal from "../../modal/Modal.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobUploadAPI from "./JobUploadAPI.mjs";
import * as OldFileInput from "../../inputs/OldFileInput/OldFileInput.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as ModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/modal/Modal.module.css";
import * as JobUploadModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/JobUpload.module.css";

var buttonText$p = Utils.Translations.tr("js.jobs.upload.button_text");

var title$p = Utils.Translations.tr("js.jobs.upload.title");

var inProgress$p = Utils.Translations.tr("js.jobs.upload.in_progress");

var finished$p = Utils.Translations.tr("js.jobs.upload.finished");

var file$p = Utils.Translations.tr("js.jobs.upload.file");

var upload$p = Utils.Translations.tr("js.jobs.upload.upload");

var results$p = Utils.Translations.tr("js.jobs.upload.results");

var currentResults$p = Utils.Translations.tr("js.jobs.upload.current_results");

var jobsCreated$p = Utils.Translations.t("js.jobs.upload.jobs_created");

var jobsUpdated$p = Utils.Translations.t("js.jobs.upload.jobs_updated");

var clientsCreated$p = Utils.Translations.t("js.jobs.upload.clients_created");

var clientsUpdated$p = Utils.Translations.t("js.jobs.upload.clients_updated");

var sitesCreated$p = Utils.Translations.t("js.jobs.upload.sites_created");

var sitesUpdated$p = Utils.Translations.t("js.jobs.upload.sites_updated");

var usersCreated$p = Utils.Translations.t("js.jobs.upload.users_created");

var usersUpdated$p = Utils.Translations.t("js.jobs.upload.users_updated");

var uploadErrors$p = Utils.Translations.tr("js.jobs.upload.errors");

var description$p = Utils.Translations.tr("js.jobs.upload.description");

var ruleSingleQuotes$p = Utils.Translations.tr("js.jobs.upload.use_single_quotes");

var ruleHeadersRequired$p = Utils.Translations.tr("js.jobs.upload.headers_required");

var downloadCsvExample$p = Utils.Translations.tr("js.jobs.upload.download_csv_example");

var downloadXlsxExample$p = Utils.Translations.tr("js.jobs.upload.download_xlsx_example");

var internalError$p = Utils.Translations.t("js.jobs.upload.internal_error");

var documentation$p = Utils.Translations.tr("js.jobs.upload.documentation");

var documentationURL$p = Utils.Translations.t("js.jobs.upload.documentation_url");

var styles = JobUploadModuleCss;

var modalStyles = ModalModuleCss;

var spinner = React.createElement("span", {
      className: El.Cn.concat(Icon.style(undefined, true, undefined, undefined, "spinner"), styles.spinner)
    });

function fromChosenFile(file) {
  if (file === undefined) {
    return "Other";
  }
  var filename = Caml_option.valFromOption(file).name;
  if (!$$String.contains(filename, /* '.' */46)) {
    return "Other";
  }
  var from = $$String.rindex(filename, /* '.' */46) + 1 | 0;
  var count = filename.length - from | 0;
  var match = $$String.sub(filename, from, count);
  switch (match) {
    case "csv" :
        return "CSV";
    case "xlsx" :
        return "XLSX";
    default:
      return "Other";
  }
}

function JobUpload$Description(Props) {
  var wire = Props.wire;
  var chosenFileFormat = Props.chosenFileFormat;
  var ctx = wire.ctx;
  var match = ctx.localizator.lang;
  var link;
  if (match === "ru" || match === "en") {
    link = React.createElement("a", {
          className: styles.documentationUrl,
          href: documentationURL$p(ctx),
          target: "blank"
        }, documentation$p(ctx));
  } else {
    var csvLink = React.createElement(Link.make, {
          wire: wire,
          route: {
            NAME: "Job",
            VAL: "CsvSample"
          },
          target: "self",
          children: downloadCsvExample$p(ctx)
        });
    var xlsxLink = React.createElement(Link.make, {
          wire: wire,
          route: {
            NAME: "Job",
            VAL: "XlsxSample"
          },
          target: "self",
          children: downloadXlsxExample$p(ctx)
        });
    switch (chosenFileFormat) {
      case "CSV" :
          link = csvLink;
          break;
      case "XLSX" :
          link = xlsxLink;
          break;
      case "Other" :
          link = React.createElement(React.Fragment, undefined, React.createElement("li", undefined, csvLink), React.createElement("li", undefined, xlsxLink));
          break;
      
    }
  }
  var rules;
  var exit = 0;
  switch (chosenFileFormat) {
    case "CSV" :
        rules = React.createElement(React.Fragment, undefined, React.createElement("li", undefined, ruleHeadersRequired$p(ctx)), React.createElement("li", undefined, ruleSingleQuotes$p(ctx)), React.createElement("li", undefined, link));
        break;
    case "XLSX" :
    case "Other" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    rules = React.createElement(React.Fragment, undefined, React.createElement("li", undefined, link));
  }
  return React.createElement("div", {
              className: "note note-info " + styles.description
            }, React.createElement("p", undefined, description$p(ctx)), React.createElement("ul", undefined, rules));
}

function errorsContainer(ctx, errors) {
  return React.createElement("div", {
              className: "note note-danger " + styles.result
            }, React.createElement("p", undefined, uploadErrors$p(ctx)), React.createElement("ul", undefined, errors.map(function (e, i) {
                      return React.createElement("li", {
                                  key: i.toString()
                                }, e);
                    })));
}

function JobUpload$UploadResult(Props) {
  var ctx = Props.ctx;
  var upload = Props.upload;
  var resultLine = function (name, count) {
    if (count === 0) {
      return null;
    } else {
      return React.createElement("li", undefined, name + count.toString());
    }
  };
  var match = upload.status;
  switch (match) {
    case "Waiting" :
        return React.createElement(React.Fragment, undefined, inProgress$p(ctx), spinner);
    case "InProgress" :
    case "Finished" :
        break;
    case "Unknown" :
        return null;
    
  }
  if (!upload.success) {
    return errorsContainer(ctx, upload.errors);
  }
  var tmp;
  switch (match) {
    case "InProgress" :
        tmp = React.createElement(React.Fragment, undefined, inProgress$p(ctx), spinner);
        break;
    case "Finished" :
        tmp = finished$p(ctx);
        break;
    case "Waiting" :
    case "Unknown" :
        tmp = null;
        break;
    
  }
  var tmp$1;
  switch (match) {
    case "InProgress" :
        tmp$1 = currentResults$p(ctx);
        break;
    case "Finished" :
        tmp$1 = results$p(ctx);
        break;
    case "Waiting" :
    case "Unknown" :
        tmp$1 = null;
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: styles.status
                }, tmp), React.createElement("div", {
                  className: El.Cn.concat("note note-success", styles.result)
                }, React.createElement("p", undefined, tmp$1), React.createElement("ul", undefined, resultLine(jobsCreated$p(ctx), upload.jobsCreated), resultLine(jobsUpdated$p(ctx), upload.jobsUpdated), resultLine(clientsCreated$p(ctx), upload.clientsCreated), resultLine(clientsUpdated$p(ctx), upload.clientsUpdated), resultLine(sitesCreated$p(ctx), upload.sitesCreated), resultLine(sitesUpdated$p(ctx), upload.sitesUpdated), resultLine(usersCreated$p(ctx), upload.usersCreated), resultLine(usersUpdated$p(ctx), upload.usersUpdated))));
}

function JobUpload$RefreshBulkUpload(Props) {
  var refresh = Props.refresh;
  React.useEffect((function () {
          var intervalId = setInterval((function () {
                  refresh();
                }), 2000);
          return (function () {
                    clearInterval(intervalId);
                  });
        }), []);
  return null;
}

var Status = {};

function JobUpload$Window(Props) {
  var wire = Props.wire;
  var onClose = Props.onClose;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setFile = match[1];
  var file = match[0];
  var match$1 = React.useState(function () {
        return "Unknown";
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var handleFileSubmitResponse = function (response) {
    if (response.TAG === "Ok") {
      var bulkUpload = response._0;
      return setStatus(function (param) {
                  return {
                          TAG: "Processing",
                          _0: bulkUpload
                        };
                });
    }
    var match = response._0;
    if (typeof match === "object" && match.TAG === "HTTPError") {
      var match$1 = match._0;
      var tmp = match$1[0];
      if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
        var text = match$1[1];
        return setStatus(function (param) {
                    return {
                            TAG: "Failed",
                            _0: JobUploadAPI.Upload.$$Error.fromString(text)
                          };
                  });
      }
      
    }
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: internalError$p(ctx)
              })
        });
    setFile(function (param) {
          
        });
    setStatus(function (param) {
          return "ReadyToUpload";
        });
  };
  var handleBulkUploadResponse = function (response) {
    if (response.TAG === "Ok") {
      var res = response._0;
      return setStatus(function (param) {
                  return {
                          TAG: "Processing",
                          _0: res
                        };
                });
    }
    var match = response._0;
    if (typeof match !== "object") {
      console.log("error");
      return ;
    }
    if (match.TAG === "HTTPError") {
      var tmp = match._0[0];
      if (typeof tmp !== "object") {
        if (tmp === "NotFound") {
          return setStatus(function (param) {
                      return "ReadyToUpload";
                    });
        }
        console.log("error");
        return ;
      } else {
        console.log("error");
        return ;
      }
    } else {
      console.log("error");
      return ;
    }
  };
  var getBulkUpload = function (uuid) {
    if (uuid !== undefined) {
      return Backend.$$finally(JobUploadAPI.Show.get(wire, Caml_option.valFromOption(uuid)), handleBulkUploadResponse);
    } else {
      return Backend.$$finally(JobUploadAPI.Show.getCurrent(wire), handleBulkUploadResponse);
    }
  };
  React.useEffect((function () {
          getBulkUpload(undefined);
        }), []);
  React.useEffect((function () {
          if (typeof status === "object" && status.TAG === "Processing" && status._0.status === "Finished") {
            setFile(function (param) {
                  
                });
          }
          
        }), [status]);
  var submit = function (evt) {
    setStatus(function (param) {
          return "Uploading";
        });
    if (file !== undefined) {
      Backend.$$finally(JobUploadAPI.Upload.post(wire, Caml_option.valFromOption(file)), handleFileSubmitResponse);
    }
    evt.preventDefault();
    evt.stopPropagation();
  };
  var chosenFileFormat = fromChosenFile(file);
  var locked;
  if (file !== undefined) {
    if (typeof status !== "object") {
      switch (status) {
        case "ReadyToUpload" :
            locked = false;
            break;
        case "Unknown" :
        case "Uploading" :
            locked = true;
            break;
        
      }
    } else {
      locked = status.TAG === "Processing" ? status._0.status !== "Finished" : false;
    }
  } else {
    locked = true;
  }
  var fileInput = React.createElement("div", {
        className: styles.input
      }, React.createElement("label", {
            className: styles.label,
            htmlFor: "file"
          }, file$p(ctx)), React.createElement(OldFileInput.make, {
            ctx: ctx,
            onFileChooseAction: (function (f) {
                setFile(function (param) {
                      return Caml_option.some(f);
                    });
              }),
            onFileClearAction: (function () {
                setFile(function (param) {
                      
                    });
              }),
            file: file,
            limit: [
              3145728,
              "limited"
            ]
          }));
  var tmp;
  if (typeof status !== "object") {
    switch (status) {
      case "Unknown" :
      case "ReadyToUpload" :
          tmp = fileInput;
          break;
      case "Uploading" :
          tmp = React.createElement(React.Fragment, undefined, inProgress$p(ctx), spinner);
          break;
      
    }
  } else if (status.TAG === "Processing") {
    var bulkUpload = status._0;
    var uuid = bulkUpload.uuid;
    var match$2 = bulkUpload.status;
    var tmp$1;
    var exit = 0;
    switch (match$2) {
      case "Waiting" :
      case "InProgress" :
          exit = 1;
          break;
      case "Finished" :
          tmp$1 = fileInput;
          break;
      case "Unknown" :
          tmp$1 = null;
          break;
      
    }
    if (exit === 1) {
      tmp$1 = React.createElement(JobUpload$RefreshBulkUpload, {
            refresh: (function () {
                getBulkUpload(Caml_option.some(uuid));
              })
          });
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(JobUpload$UploadResult, {
              ctx: ctx,
              upload: bulkUpload
            }), tmp$1);
  } else {
    tmp = React.createElement(React.Fragment, undefined, errorsContainer(ctx, status._0.errors), fileInput);
  }
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              children: React.createElement("div", {
                    className: El.Cn.concat(modalStyles.dialog, styles.modalDialog)
                  }, React.createElement(Modal.Header.make, {
                        className: styles.header,
                        children: React.createElement("h1", {
                              className: styles.title
                            }, title$p(ctx))
                      }), React.createElement(Modal.Body.make, {
                        children: React.createElement("div", {
                              className: styles.modalBody
                            }, React.createElement(JobUpload$Description, {
                                  wire: wire,
                                  chosenFileFormat: chosenFileFormat
                                }), tmp)
                      }), React.createElement(Modal.Footer.make, {
                        children: React.createElement("div", {
                              className: styles.buttonsContainer
                            }, React.createElement(Modal.CloseButton.make, {
                                  ctx: ctx
                                }), React.createElement(Modal.SubmitButton.make, {
                                  onClick: submit,
                                  disabled: locked,
                                  children: upload$p(ctx)
                                }))
                      }))
            });
}

function JobUpload$Button(Props) {
  var wire = AppContext.useWire();
  return React.createElement("button", {
              className: styles.uploadButton,
              onClick: (function (evt) {
                  evt.preventDefault();
                  wire.navigate(undefined, {
                        NAME: "Job",
                        VAL: "Upload"
                      });
                })
            }, buttonText$p(wire.ctx));
}

var Button = {
  make: JobUpload$Button
};

function JobUpload(Props) {
  var wire = Props.wire;
  if (!Caml_obj.equal(wire.route.path, {
          NAME: "Job",
          VAL: "Upload"
        })) {
    return null;
  }
  var navigate = wire.navigate;
  return React.createElement(JobUpload$Window, {
              wire: wire,
              onClose: (function () {
                  navigate(undefined, {
                        NAME: "Job",
                        VAL: "Index"
                      });
                })
            });
}

var make = JobUpload;

export {
  Status ,
  Button ,
  make ,
}
/* buttonText' Not a pure module */
