

import * as El from "../../libraries/El.mjs";
import * as Fun from "../../libraries/Fun.mjs";
import * as Icon from "../../Icon.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../inputs/Checkbox.mjs";
import * as Optional from "../common/Optional.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Reports_FilterComponentModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports_FilterComponent.module.css";

var styles = Reports_FilterComponentModuleCss;

function Reports_FilterComponent$Popover(Props) {
  var options = Props.options;
  var selected = Props.selected;
  var onToggle = Props.onToggle;
  var close = Props.close;
  var labelEmpty = Props.labelEmpty;
  var children = Props.children;
  var popoverRef = React.useRef(null);
  Hooks.useOnClickOutside(undefined, popoverRef, close);
  Hooks.useOnEscPress(undefined, close);
  var itemsBlock = options.length === 0 ? React.createElement("div", {
          className: styles.empty
        }, labelEmpty) : React.createElement("div", {
          className: styles.items
        }, options.map(function (param) {
              var uuid = param.uuid;
              return React.createElement(Checkbox.make, {
                          onToggle: (function (param) {
                              onToggle(uuid);
                            }),
                          checked: selected.some(function (u) {
                                return Uuid.equal(u, uuid);
                              }),
                          label: param.name,
                          wrapperClasses: Js_dict.fromList({
                                hd: [
                                  styles.item,
                                  true
                                ],
                                tl: /* [] */0
                              }),
                          key: Uuid.toString(uuid)
                        });
            }));
  return React.createElement("div", {
              ref: popoverRef,
              className: styles.popover
            }, children, itemsBlock);
}

var Popover = {
  make: Reports_FilterComponent$Popover
};

function Reports_FilterComponent$Button(Props) {
  var disabled = Props.disabled;
  var selected = Props.selected;
  var active = Props.active;
  var togglePopover = Props.togglePopover;
  var resetFilter = Props.resetFilter;
  var label = Props.label;
  var labelSelected = Props.labelSelected;
  var textBlock = selected ? React.createElement(React.Fragment, undefined, labelSelected, React.createElement("div", {
              className: styles.resetButton,
              type: "button",
              onClick: resetFilter
            }, React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, undefined, "times")
                }))) : label;
  var className = El.Cn.concat(El.Cn.concat(styles.button, selected ? styles.selected : ""), active ? styles.active : "");
  return React.createElement("button", {
              className: className,
              disabled: disabled,
              type: "button",
              onClick: togglePopover
            }, textBlock);
}

var Button = {
  make: Reports_FilterComponent$Button
};

function toggle(uuids, uuid) {
  var opt = RCore.$$Option.map(RCore.$$Array.getBy(uuids, (function (u) {
              return Caml_obj.equal(u, uuid);
            })), (function (uuid) {
          return RCore.$$Array.keep(uuids, (function (u) {
                        return Caml_obj.notequal(u, uuid);
                      }));
        }));
  if (opt !== undefined) {
    return opt;
  } else {
    return Belt_Array.concatMany([
                uuids,
                [uuid]
              ]);
  }
}

function Reports_FilterComponent(Props) {
  var options = Props.options;
  var selected = Props.selected;
  var setFilter = Props.setFilter;
  var label = Props.label;
  var labelSelected = Props.labelSelected;
  var labelEmpty = Props.labelEmpty;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var show = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setQuery = match$1[1];
  var query = match$1[0];
  var togglePopover = function (_event) {
    setShow(function (param) {
          return !show;
        });
  };
  var closePopover = function () {
    setShow(function (param) {
          return false;
        });
  };
  var resetFilter = function ($$event) {
    $$event.stopPropagation();
    setShow(function (param) {
          return false;
        });
    setFilter([]);
  };
  var toggleFilterVariant = function (uuid) {
    setFilter(toggle(selected, uuid));
  };
  var hasOptions = options.length > 0;
  var hasSelected = selected.length > 0;
  var onSearchInputChange = function (evt) {
    var q = evt.target.value;
    setQuery(function (param) {
          return Fun.optString(q);
        });
  };
  var filteredOptions = query !== undefined ? RCore.$$Array.keep(options, (function (param) {
            return Fun.stringContains(query, param.name);
          })) : options;
  var searchTemplates = options.length > 16 ? React.createElement("div", {
          className: styles.searchInputContainer
        }, React.createElement("input", {
              className: styles.searchInput,
              value: RCore.$$Option.getOr(query, ""),
              onChange: onSearchInputChange
            }), RCore.$$Option.isSome(query) ? React.createElement("span", {
                className: El.Cn.concat(Icon.style(undefined, undefined, undefined, undefined, "times"), styles.reset),
                onClick: (function (param) {
                    setQuery(function (param) {
                          
                        });
                  })
              }) : React.createElement("span", {
                className: styles.filterIcon
              })) : null;
  var buttonBlock = React.createElement(Reports_FilterComponent$Button, {
        disabled: !hasOptions,
        selected: hasSelected,
        active: show,
        togglePopover: togglePopover,
        resetFilter: resetFilter,
        label: label,
        labelSelected: labelSelected
      });
  return React.createElement("div", {
              className: styles.container
            }, hasOptions ? React.createElement(React.Fragment, undefined, buttonBlock, React.createElement(Optional.make, {
                        show: show,
                        children: React.createElement(Reports_FilterComponent$Popover, {
                              options: filteredOptions,
                              selected: selected,
                              onToggle: toggleFilterVariant,
                              close: closePopover,
                              labelEmpty: labelEmpty,
                              children: React.createElement("div", undefined, RCore.$$Option.getOr(children, null), searchTemplates)
                            })
                      })) : buttonBlock);
}

var make = Reports_FilterComponent;

export {
  styles ,
  Popover ,
  Button ,
  toggle ,
  make ,
}
/* styles Not a pure module */
