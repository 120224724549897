

import * as El from "../libraries/El.mjs";
import * as Link from "../components/common/Link/Link.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../components/common/Optional.mjs";
import * as AppContext from "../context/AppContext.mjs";
import * as UI_PageTabsModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_PageTabs.module.css";

var styles = UI_PageTabsModuleCss;

function UI_PageTabs$Tab(Props) {
  var route = Props.route;
  var activeOpt = Props.active;
  var renderLabel = Props.renderLabel;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var active = activeOpt !== undefined ? activeOpt : true;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var wire = AppContext.useWire();
  var isActive = route !== undefined ? Caml_obj.equal(route, wire.route.path) : active;
  var labelContent = React.useMemo(Curry.__1(renderLabel));
  var label;
  var exit = 0;
  if (route !== undefined && !isActive) {
    label = React.createElement(Link.make, {
          wire: wire,
          route: route,
          disabled: disabled,
          className: styles.label,
          children: labelContent
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var style = isActive ? El.Cn.concat(styles.label, styles.active) : styles.label;
    label = React.createElement("div", {
          className: style
        }, labelContent);
  }
  return React.createElement(React.Fragment, undefined, label, React.createElement(Optional.make, {
                  show: isActive,
                  children: React.createElement("div", {
                        className: styles.tab
                      }, children)
                }));
}

var Tab = {
  make: UI_PageTabs$Tab
};

function UI_PageTabs(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.tabs, className)
            }, children);
}

var make = UI_PageTabs;

export {
  Tab ,
  make ,
}
/* styles Not a pure module */
